import React from "react";
import Section from "../Section/Section";
import * as S from "./WorkshopReviewsSection.styled";
import { pageToMeta } from "@Shared/util/clientPagesMeta";
import PartnerRecomendationCards from "@FEClient/views/commonComps/PartnerRecomendationCards/PartnerRecomendationCards";

const WorkshopReviewsSection: React.FC = () => {
    return (
        <Section title="Partnerių atsiliepimai" titleProps={{ variant: "h2" }}>
            <PartnerRecomendationCards />
            <S.BtnLink to={pageToMeta.BECOME_PARTNER.url}>
                <S.BecomePartnerBtn>Tapti partneriu</S.BecomePartnerBtn>
            </S.BtnLink>
        </Section>
    );
};

export default WorkshopReviewsSection;
