import { MergedServiceOrPackageForWorkshop } from "@Shared/util/mergeServicesWithPackagesForWorkshop";
import { ServiceOrSubCateg, SubCategory } from "./ServicesSection.types";

export function isSubCateg(s: object): s is SubCategory {
    return "type" in s && s.type === "SUBCATEGORY";
}

export function isPackage(serviceOrSubcateg: ServiceOrSubCateg): boolean {
    return "package" in serviceOrSubcateg && Boolean(serviceOrSubcateg.package);
}

export function getAllPackageNServices(
    servicesOrSubcategs: ServiceOrSubCateg[]
): MergedServiceOrPackageForWorkshop[] {
    return servicesOrSubcategs.flatMap((s) => {
        if (isSubCateg(s)) {
            return s.services;
        } else {
            return s;
        }
    });
}

export function servicesOrSubcategsToNames(
    servicesOrSubcategs: ServiceOrSubCateg[]
): string[] {
    return servicesOrSubcategs.flatMap((s) => {
        if (isSubCateg(s)) {
            return s.services.map((s) => s.serviceName);
        }
        return s.serviceName;
    });
}
