import ConfirmationTitle from "@FEClient/views/commonComps/ConfirmationTitle/ConfirmationTitle";
import React from "react";
import * as S from "./ReservationSuccess.styled";
import * as CS from "../../commonComps/ConfirmationDetailsBox/ConfirmationDetailsBox.styled";
import useIsMobile from "../../../../../FEShared/hooks/useIsMobile";
import ConfirmationSidePanel from "@FEClient/views/commonComps/ConfirmationSidePanel/ConfirmationSidePanel";
import { observer, useLocalObservable } from "mobx-react-lite";
import ConfirmationRowItem from "@FEClient/views/commonComps/ConfirmationRowItem/ConfirmationRowItem";
import useStore, { history } from "@FEClient/logic/store";
import { useParams } from "react-router-dom";
import { pageToMeta } from "@Shared/util/clientPagesMeta";
import { runInAction } from "mobx";
import { dateToObj } from "@Shared/util/dateFormat";
import FollowSocialButtons from "@FEShared/components/UI/FollowSocialButtons/FollowSocialButtons";
import prodRealUsersOnly from "@FEClient/logic/utils/prodRealUsersOnly";
import Button from "@FEShared/components/UI/Button/Button";
import {
    OrderStatus,
    useOrderQuery,
} from "@FEShared/graphql/generated/graphql";
import gqlResHandler from "@FEShared/utils/gqlResHandler";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import useToken from "@FEClient/logic/hooks/useToken/useToken";
import useShowLoadingScreenForQuery from "@FEShared/hooks/useShowLoadingScreen";
import { carDataObjToArr } from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.utils";
import mergeOrder from "@Shared/util/mergeOrder";
import isNullableValue from "@Shared/util/isNullableValue";
import Icon from "@FEShared/components/UI/Icon/Icon";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";
import sha256 from "@FEShared/utils/sha256";
import ReservationCancelModal from "./ReservationCancelModal/ReservationCancelModal";

const SMS_AND_EMAIL_TEXT =
    "Tavo vizitui rezervuotas meistras. Apie apsilankymą tave informuosime SMS bei el. pašto žinutėmis!";

const ReservationSuccess: React.FC<{}> = observer(() => {
    const token = useToken();
    const GS = useStore();
    const isMobile = useIsMobile();
    const params = useParams<{ orderId: string }>();
    const LS = useLocalObservable(() => ({
        cancelModalOpen: false,
    }));

    const [getOrderRes, getOrderReq] = useOrderQuery({
        variables: {
            accessToken: token,
            id: +params.orderId,
        },
        pause: true,
    });
    const completedOrder = GS.reservationSuccessPageState.completedOrder;
    const selectedWorkshop = GS.reservationSuccessPageState.selectedWorkshop;
    useShowLoadingScreenForQuery(
        getOrderRes,
        GS,
        () => !completedOrder || !selectedWorkshop
    );

    React.useEffect(() => {
        if (!selectedWorkshop || !completedOrder) {
            return console.error(
                "No selectedWorkshop or completedOrder in reservation success page!"
            );
        }

        prodRealUsersOnly(async () => {
            if (GS.searchPageState.altFlow.order) {
                window.gtag?.("event", "reservation_success_alt");
                window.hj?.("event", "reservation_success_alt");
                window.fbq?.("trackCustom", "reservation_success_alt");
            } else {
                const email = completedOrder.email?.toLowerCase().trim();

                const phone = completedOrder.phoneNumber?.startsWith("86")
                    ? completedOrder.phoneNumber.replace("86", "+370").trim()
                    : completedOrder.phoneNumber?.trim();

                const city = completedOrder.city
                    .toLowerCase()
                    .trim()
                    .replace(/ /g, "");

                const priceNDuration =
                    GS.getSelectedServicesPriceNDurationForWorkshop(
                        selectedWorkshop
                    );
                window.gtag?.("event", "reservation_success", {
                    value: (priceNDuration?.servicesPrice.value || 0) * 0.19,
                    transaction_id: completedOrder.ID,
                    currency: "EUR",
                });
                window.gtag?.("event", "reservation_success", {
                    send_to: "AW-11026165722/nNc7CM3exocZENrf14kp",
                    value: (priceNDuration?.servicesPrice.value || 0) * 0.19,
                    transaction_id: completedOrder.ID,
                    currency: "EUR",
                });
                window.fbq?.("track", "Purchase", {
                    value: (priceNDuration?.servicesPrice.value || 0) * 0.19,
                    currency: "EUR",
                    em: email ? await sha256(email) : undefined,
                    ph: phone ? await sha256(phone) : undefined,
                    city: await sha256(city),
                });
                window.hj?.("event", "reservation_success");
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        runInAction(() => {
            // reset primarySearchResults so if user clicks back on page, he will be redirected back to home.
            GS.searchPageState.primarySearchResults = undefined;
        });
    }, [GS.searchPageState]);

    React.useEffect(() => {
        if (!params.orderId) {
            history.push(pageToMeta.HOME.url);
            return;
        }

        if (!completedOrder || !selectedWorkshop) {
            getOrderReq();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (completedOrder && selectedWorkshop) return;
        gqlResHandler(getOrderRes, (data) => {
            const workshop =
                data.order.acceptedService ||
                data.order.invitations.find((i) => i.primary)?.service;
            if (!workshop) {
                return console.error(
                    `Didn't find workshop in reservation succ page! OrderID: ${data.order.ID}`
                );
            }
            runInAction(() => {
                const mergedOrder = mergeOrder(data.order);
                GS.reservationSuccessPageState.completedOrder = mergedOrder;
                GS.reservationSuccessPageState.selectedWorkshop = workshop;
            });
        });
    }, [
        getOrderRes,
        GS.reservationSuccessPageState,
        completedOrder,
        selectedWorkshop,
    ]);

    const dateObj = completedOrder
        ? dateToObj(new Date(completedOrder.arrivalDate))
        : undefined;

    const clientInfo = React.useMemo(() => {
        if (!completedOrder) return;

        return [
            {
                id: 1,
                label: "Telefono numeris",
                value: completedOrder.phoneNumber,
                icon: "icon-phone",
            },
            {
                id: 2,
                label: "El. paštas",
                value: completedOrder.email,
                icon: "icon-email",
            },
            completedOrder.customerComment && {
                id: 3,
                label: "Komentaras",
                value: completedOrder.customerComment,
                icon: "icon-board",
            },
        ].filter(Boolean) as {
            id: number;
            label: string;
            value: string;
            icon: string;
        }[];
    }, [completedOrder]);

    const changeReservationDate = React.useCallback(() => {
        if (!completedOrder) return;

        runInAction(() => {
            if (completedOrder.createdByWorkshop) {
                GS.workshopPageState.fromBookNow = true;
            }

            GS.reservationConfirmationPageState.date.obj = dateToObj(
                completedOrder.arrivalDate
            );
            GS.searchState.carDataArr = carDataObjToArr(completedOrder);
            GS.searchState.selectedServicesNames =
                completedOrder.categories.map((s) => s.serviceName);
            GS.reservationConfirmationPageState = {
                ...GS.reservationConfirmationPageState,
                comment: completedOrder.customerComment || "",
                emailInputVal: completedOrder.email || "",
                phoneInputVal: completedOrder.phoneNumber || "",
                selectedWorkshop: selectedWorkshop,
                rebookingFromOrderID: completedOrder.ID,
            };
        });
        history.push(pageToMeta.RESERVATION_CONFIRM.url);
    }, [GS, completedOrder, selectedWorkshop]);

    if (!completedOrder || !selectedWorkshop || !clientInfo || !dateObj) {
        return <div>Kraunama...</div>;
    }

    return (
        <S.SuccessContainer>
            <ReservationCancelModal
                changeReservationDate={changeReservationDate}
                isOpen={LS.cancelModalOpen}
                onClose={() => {
                    runInAction(() => {
                        LS.cancelModalOpen = false;
                    });
                }}
            />
            <S.LeftPanel>
                {!isMobile && (
                    <ConfirmationTitle
                        showWarranty={false}
                        color="#41b46f"
                        title="Rezervacija patvirtinta"
                        iconClassName="icon-checkmark-circle"
                        subtitle={SMS_AND_EMAIL_TEXT}
                    />
                )}
                <CS.ConfirmationDetailsBox>
                    {clientInfo.map((infoRow) => (
                        <ConfirmationRowItem
                            key={infoRow.id}
                            id={infoRow.id}
                            value={infoRow.value ?? ""}
                            label={infoRow.label}
                            iconClassName={infoRow.icon}
                        />
                    ))}
                </CS.ConfirmationDetailsBox>
                <Box mt="32px" bgcolor="#ffffff" padding={3} rounded>
                    <Text semiBold mb={1}>
                        🔒 Garantija
                    </Text>
                    {completedOrder.createdByWorkshop ? (
                        <>
                            <Text variant="subtitle1">
                                Nevažiuoja garantija užsakymui yra taikoma tik
                                kai rezervacija yra atliekama tiesiogiai per
                                nevažiuoja platformą.
                            </Text>
                            <Button
                                sx={{ mb: 2, mt: 2 }}
                                fullWidth
                                disabled
                                color="error"
                                leftIconClass="icon-lock"
                            >
                                Nevažiuoja garantija užsakymui netaikoma
                            </Button>
                        </>
                    ) : (
                        <>
                            <Text variant="subtitle1">
                                Po sėkmingai atliktų darbų{" "}
                                <Text span semiBold variant="inherit">
                                    gausite čekį ir SMS/Email pranešimus
                                    garantijos aktyvavimui.
                                </Text>
                            </Text>
                            {completedOrder.status === "completed" &&
                            !isNullableValue(completedOrder.goodPrice) &&
                            !isNullableValue(
                                completedOrder.servicesCompletion
                            ) ? (
                                <Text
                                    color="success.main"
                                    semiBold
                                    fontSize={16}
                                    mt={1}
                                >
                                    <Icon
                                        className="icon-checkmark-circle"
                                        mr={0.5}
                                    />
                                    Garantija sėkmingai aktyvuota
                                </Text>
                            ) : (
                                <Button
                                    sx={{ mb: 2, mt: 2 }}
                                    fullWidth
                                    disabled={
                                        completedOrder.status !== "completed"
                                    }
                                    color="success"
                                    onClick={() => {
                                        history.push(
                                            `${pageToMeta.WARRANTY_ACTIVATION.url}/${completedOrder.ID}?t=${token}`
                                        );
                                    }}
                                    leftIconClass="icon-lock"
                                >
                                    {completedOrder.status === "completed"
                                        ? "Aktyvuoti garantija"
                                        : "Aktyvuokite garantiją po sėkmingai atliktų darbų"}
                                </Button>
                            )}
                        </>
                    )}
                </Box>
                <Box mt="32px" bgcolor="#ffffff" padding={3} rounded>
                    <Text semiBold mb={1}>
                        ⭐ Atsiliepimas
                    </Text>
                    <Text variant="subtitle1">
                        Po sėkmingai atliktų darbų,{" "}
                        <Text span semiBold variant="inherit">
                            gausite SMS/Email pranešimus palikti atsiliepimui.
                        </Text>{" "}
                        Autoservisai dažnai yra nedideli, nepriklausomi verslai,
                        todėl tavo pasidalinimas patirtimi jiems reiškia labai
                        daug ❤️
                    </Text>
                    <Button
                        sx={{ mb: 2, mt: 2 }}
                        fullWidth
                        disabled={completedOrder.status !== "completed"}
                        color="tertiary"
                        onClick={() => {
                            const url = `${
                                completedOrder.createdByWorkshop
                                    ? pageToMeta.INTERNAL_ORDER_REVIEW.url
                                    : pageToMeta.WARRANTY_N_REVIEW.url
                            }/${completedOrder.ID}?${
                                QUERY_PARAMS.USER_ORDER_TOKEN
                            }=${completedOrder.userAccessToken}`;

                            history.push(url);
                        }}
                        leftIconClass="icon-star-full"
                    >
                        {completedOrder.status === "completed"
                            ? "Palikti atsiliepimą"
                            : "Palikite atsiliepimą po sėkmingai atliktų darbų"}
                    </Button>
                </Box>
                <Box mt="32px" bgcolor="#ffffff" padding={3} rounded>
                    <Text semiBold mb={1}>
                        ✏️ Rezervacijos valdymas
                    </Text>
                    <Text variant="subtitle1">
                        Autoservisai dažnai yra nedideli verslai, kurie
                        planuojasi savo darbus ir{" "}
                        <Text span variant="inherit" semiBold>
                            jūsų vizitui rezervuoja keltą, meistrą ir kitus
                            įrankius.
                        </Text>{" "}
                        Kiekvienas klientas jiems yra labai svarbus. Prašome
                        redaguoti rezervaciją tik išimtinais atvejais.
                    </Text>
                    <Button
                        sx={{ mb: 2, mt: 2 }}
                        fullWidth
                        color="greyish"
                        onClick={changeReservationDate}
                    >
                        Keisti rezervacijos laiką
                    </Button>
                    <Box mt={5}>
                        <Text variant="subtitle1">
                            Jeigu turite galimybe, prašome pakeisti rezervacijos
                            laiką, o ne ją atšaukti. Atšaukus rezervaciją likus
                            mažiau nei 24val. iki vizito ar neatvykus į
                            rezervaciją, autoservisas pasilieka teisę{" "}
                            <Text span semiBold variant="inherit">
                                nepriimti tavęs ateities registracijoms
                            </Text>
                        </Text>
                        <Button
                            sx={{
                                textDecoration: "underline",
                                fontSize: 12,
                                mt: 1,
                                color: "rgba(0,0,0,0.6)",
                            }}
                            disabled={[
                                OrderStatus.Completed,
                                OrderStatus.ClientCancelled,
                                OrderStatus.WorkshopCancelled,
                            ].includes(completedOrder.status)}
                            color="greyish"
                            variant="text"
                            onClick={async () => {
                                runInAction(() => {
                                    LS.cancelModalOpen = true;
                                });
                            }}
                        >
                            Atšaukti rezervaciją
                        </Button>
                        {[
                            OrderStatus.Completed,
                            OrderStatus.ClientCancelled,
                            OrderStatus.WorkshopCancelled,
                        ].includes(completedOrder.status) && (
                            <Text variant="subtitle2" mt={1}>
                                Užsakymo atšaukti negalima, nes jis yra
                                atšauktas arba užbaigtas.
                            </Text>
                        )}
                    </Box>
                </Box>
                <Box mt="64px">
                    <FollowSocialButtons />
                </Box>
            </S.LeftPanel>
            <ConfirmationSidePanel
                createdByWorkshop={completedOrder.createdByWorkshop}
                confirmationTitle={
                    isMobile ? (
                        <ConfirmationTitle
                            showWarranty={false}
                            color="#41b46f"
                            title="Rezervacija patvirtinta"
                            iconClassName="icon-checkmark-circle"
                            subtitle={SMS_AND_EMAIL_TEXT}
                        />
                    ) : undefined
                }
                carData={completedOrder}
                dateObj={dateObj}
                selectedWorkshop={selectedWorkshop}
                selectedServiceNames={completedOrder.categories.map(
                    (c) => c.serviceName
                )}
            />
        </S.SuccessContainer>
    );
});

export default ReservationSuccess;
