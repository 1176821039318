// @ts-nocheck

import { ContentContainer } from "@FEClient/views/commonComps/ContentContainer/ContentContainer.styled";
import React from "react";
import * as S from "./PartnerForm.styled";

const PartnerForm: React.FC = () => {
    const iframeRef = React.useRef<HTMLIFrameElement>();
    return (
        <div>
            <ContentContainer>
                <S.Iframe
                    ref={iframeRef}
                    src={"https://tally.so/r/w2XPDj?transparentBackground=1"}
                />
            </ContentContainer>
        </div>
    );
};

export default PartnerForm;
