import useIsMobile from "@FEShared/hooks/useIsMobile";
import { pageToMeta } from "@Shared/util/clientPagesMeta";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as S from "./TermsOfService.styled";

const TermsOfService: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const isMobile = useIsMobile();

    return (
        <S.TOSContentContainer>
            {isMobile && (
                <S.BackBtn
                    onClick={() => {
                        if (location.key) {
                            history.goBack();
                        } else {
                            history.push(pageToMeta.HOME.url);
                        }
                    }}
                >
                    Atgal
                </S.BackBtn>
            )}

            <h1>Paslaugų teikimo sąlygos</h1>
            <p>
                Ačiū, kad naudojatės interneto portalu{" "}
                <a href="http://nevaziuoja.lt">https://nevaziuoja.lt</a>.
                Interneto portalas{" "}
                <a href="http://www.nevaziuoja.lt">https://nevaziuoja.lt</a>{" "}
                priklauso įmonei MB &ldquo;Nevažiuoja&rdquo;, juridinio asmens
                kodas{" "}
                <span>
                    306066889, registruota adresu Krokuvos g. 53-3, LT-09306,
                    Vilnius (toliau - <strong>&ldquo;Nevažiuoja&rdquo;</strong>
                    ).
                </span>{" "}
                &Scaron;ios taisyklės yra sutartis tarp &ldquo;Nevažiuoja&rdquo;
                ir Jūsų (toliau - <strong>Naudotojas</strong>) dėl naudojimosi
                interneto portalu, kuris pasiekiamas internetiniu adresu{" "}
                <a href="http://www.nevaziuoja.lt">https://nevaziuoja.lt</a>{" "}
                (toliau - <strong>Portalas</strong>), o taip pat ir taisyklės
                (toliau - <strong>Taisyklės</strong>), kurių privalo laikytis
                visi asmenys, apsilankę ir/arba besinaudojantys Portalo
                teikiamomis paslaugomis (toliau -&nbsp;
                <strong>Paslaugos</strong>). &Scaron;ios taisyklės taikomos ir
                naudotojams, kurie nėra registruoti portalo lankytojai ar
                naudotojai.
            </p>
            <p>
                <br />
            </p>
            <p>
                Prie&scaron; pradėdami naudotis mūsų interneto Portalu ir
                prie&scaron; užsisakydami bet kokias trečiosios &scaron;alies
                prekes ar paslaugas per mūsų interneto Portalą, pra&scaron;ome
                atidžiai perskaityti &scaron;ias sąlygas. Naudodamiesi mūsų
                interneto Portalu patvirtinate, kad sutinkate su &scaron;iomis
                interneto Portalo naudojimosi sąlygomis ir pasižadate jų
                laikytis. Jei nesutinkate su &scaron;iomis Portalo naudojimosi
                sąlygomis, negalite naudotis mūsų interneto Portalu.
            </p>
            <strong>Bendrosios nuostatos</strong>
            <p>
                &ldquo;Nevažiuoja&rdquo; suteikia Naudotojams virtualią erdvę,
                t.y Portalą kurioje transporto priemonių techninės priežiūros ir
                remonto paslaugas teikiantys juridiniai ir fiziniai subjektai
                (toliau -&nbsp;<strong>Servisai</strong>) įkelia informaciją
                apie save ir savo teikiamas paslaugas, o Naudotojai,
                pasinaudodami Portalu gali peržiūrėti Servisų teikiamą
                informaciją apie jų teikiamas paslaugas ir rezervuotis laiką
                atvykimui į Servisą. Naudotojas lankydamasis Portale ir jame
                atlikdamas bet kokius veiksmus, kuriuos leidžia atlikti Portalo
                funkcionalumas įsipareigoja laikytis visų &scaron;iose sąlygose
                nustatytų reikalavimų. Naudotojas yra laisvas pasirinkti
                paslaugas, kuriomis jis nori naudotis Portale, rū&scaron;į ir
                apimtį. Keičiant Portalo funkcionalumą ir teikiamų paslaugų
                kiekį ar pobūdį &ldquo;Nevažiuoja&rdquo; turi teisę bet kuriuo
                metu pakeisti &scaron;ias Taisykles. Su pasikeitusiomis
                Taisyklėmis Naudotojai visada galės susipažinti skelbiant
                naujausią Taisyklių versiją Portale. Prieiga prie mūsų Portalo
                suteikiama laikinai. Pasiliekame teisę bę įspėjimo panaikinti
                arba keisti savo interneto Portalą (taip pat visus joje/jose
                siūlomus produktus ar paslaugas). Neprisiimame atsakomybės už
                tai, jei atitinkamu metu mūsų interneto Portalas ar bet kuri jo
                dalis yra dėl kokios nors priežasties neprieinama.
            </p>
            <strong>Portalo teikiamų paslaugų bendrosios nuostatos</strong>
            <p>
                &ldquo;Nevažiuoja&rdquo; jokiu būdu nedalyvaus tarp Portalo
                naudotojų ir Servisų sudaromuose sandoriuose dėl pasirinktų
                Paslaugų atlikimo, &ldquo;Nevažiuoja&quot; nekils jokių teisinių
                pareigų susijusių su tokiais sandoriais tarp Servisų ir jų
                paslaugų gavėjų bei jokiais atvejais &quot;Nevažiuoja&quot;
                negarantuoja ir nebus atsakingas dėl tinkamo paslaugų suteikimo.
                Naudotojas pripažįsta ir sutinka, kad jis pats, o ne
                &quot;Nevažiuoja&quot;, yra atsakingas už visą ir bet kurią
                informaciją (toliau -&nbsp;<strong>Informacija</strong>), kurią
                siunčia ar bet kuriais kitais būdais perduoda naudodamasis
                Portalu ir/arba Paslaugomis, ir su tuo susijusius teisinius
                santykius. Naudodamasis Portalu ir/arba jo teikiamomis
                Paslaugomis Naudotojas privalo paisyti ir nepažeisti
                nusistovėjusių elgesio ir moralės normų, nepažeisti teisės aktų
                reikalavimų bei trečiųjų asmenų teisių ir teisėtų interesų,
                įskaitant teisės aktų, reglamentuojančių intelektinės nuosavybės
                apsaugą, asmens duomenų apsaugą, reklamos reguliavimą ir kitų.
                Naudojantis Portalu ir/arba jo Paslaugomis Naudotojui
                draudžiama:
            </p>
            <p>
                a) tyčia paskleisti virusus, Trojos arklius, kirminus, logines
                bombas, laiko bombas, klaviatūros paspaudimų registravimo
                programas, &scaron;nipinėjimo programas, reklamos programas ar
                kitą medžiagą, programą ar kodą, kuris daro (ar yra
                užprogramuotas daryti) neigiamą įtaką bet kurios kompiuterinės
                ar aparatinės programinės įrangos veikimui; ir (arba)
            </p>
            <p>
                b) neteisėtai prisijungiant ar mėginant neteisėtai prisijungti
                prie mūsų Interneto Portalo serverio ar bet kokio serverio,
                kompiuterio ar duomenų bazės, kuri prijungta prie mūsų Interneto
                Portalo; ir (arba)
            </p>
            <p>
                c) atakuojant mūsų Interneto Portalą per atsisakymo aptarnauti
                kibernetinę ataką ar paskirstytą atsisakymo aptarnauti ataką.
            </p>
            <p>
                d) apsimesti bet kokiu kitu asmeniu ar subjektu, įskaitant, bet
                neapsiribojant &ldquo;Nevažiuoja&rdquo; darbuotojais arba kitaip
                klaidinančiai pareik&scaron;ti apie savo ry&scaron;ius su kokias
                nors asmenimis.
            </p>
            <p>
                Be auk&scaron;čiau minėto, Naudotojas įsipareigoja nenaudoti
                Portalo ir/arba Paslaugų:
            </p>
            <p>
                a) bet kokiems veiksmams, kurie galėtų pakenkit bet kuriems
                asmenims, jų turtui ar teisėtiems interesams, atlikti.
            </p>
            <p>
                b) teisės aktais draudžiamos, įžeidžiančios, apgaulingos,
                grąsinančios, asmenų teisės pažeidžiančios informacijos ar
                kitokio pana&scaron;aus pobūdžio Informacijos platinimui.
            </p>
            <p>
                c) pažeisdamas asmenų teisės į intelektinę nuosavybę, tame tarpe
                ir turtinės autoriaus teisės ar teisės į prekių ženklus.
            </p>
            <p>
                &Scaron;ių punktų reikalavimų pažeidimas gali būti laikomas
                baudžiamuoju nusikaltimu pagal Baudžiamąjį kodeksą. Apie tokius
                pažeidimus prane&scaron;ime atitinkamoms teisėtvarkos
                institucijoms, su kuriomis bendradarbiausime atskleidžiant joms
                jūsų tapatybę. Tokio pažeidimo atveju, jūs i&scaron;kart
                netenkate teisės naudotis mūsų interneto Portalu ir/arba jo
                teikiamomis Paslaugomis. Naudotojas sutinka, kad
                &ldquo;Nevažiuoja&rdquo; viena&scaron;ali&scaron;kai spręstų
                apie tai, ar Naudotojas pažeidė &scaron;ias Taisykles, ir
                įsipareigoja pripažinti bet kokį &ldquo;Nevažiuoja&rdquo;
                sprendimą kaip teisėtą ir teisingą. Naudotojas supranta ir
                sutinka, jog &ldquo;Nevažiuoja&rdquo; nekontroliuoja kitų
                naudotojų ar trečiujų &scaron;alių Informacijos, kurią
                Naudotojas gali gauti, perskaityti, ar kitaip sužinoti
                naudodamasis Portalu ar Paslaugomis, todėl
                &ldquo;Nevažiuoja&quot; neužtikrina tokios informacijos
                tikslumo, vientisumo ir kokybės.
            </p>
            <strong>Portalo teikiamų paslaugų ribojimas</strong>
            <p>
                &ldquo;Nevažiuoja&rdquo; dėl kažkokių priežasčių modifikuodamas
                Portalą ir/arba keisdamas teikiamas Paslaugas pasilieka teisę
                bet kada neprane&scaron;ęs Naudotojui keisti Portalą ir/arba
                teikiamas Paslaugas, o taip pat Portale pateikiama informaciją.
                Naudotojas supranta ir sutinka, kad &ldquo;Nevažiuoja&rdquo;
                niekada nebus laikomas atsakingu už jokius tokiais ir kitais
                pana&scaron;iais veiksmais sukeltus neigiamus padarinius
                Naudotojui. &ldquo;Nevažiuoja&rdquo; turi teisę bet kada, be
                i&scaron;ankstinio perspėjimo:
            </p>
            <p>
                a) panaikinti ir/arba keisti visą ar dalį Naudotojo Prisijungimo
                duomenų, o taip pat ir kitą informaciją, kurią Naudotojas bet
                kokiu būdu pateikia naudodamasis Portalo Paslaugomis, jeigu
                Naudotojas naudodamasis portalu pažeidžia &scaron;ias Taisykles,
                ir/arba kitus Lietuvos Respublikoje galiojančius teisės aktus.
            </p>
            <p>
                b) &ldquo;Nevažiuoja&rdquo; turi teisę profilaktinių arba kitų
                darbų metu apriboti ar nutraukti Portalo teikiamų Paslaugų
                teikimą atitinkamam terminui.
            </p>
            <p>
                &Scaron;alys susitaria, kad &ldquo;Nevažiuoja&rdquo; turi teisę
                bet kada, be i&scaron;ankstinio įspėjimo nutraukti Portalo
                veiklą. Naudotojas turi teisę bet kuriuo metu atsisakyti
                naudotis Paslaugomis panaikindamas savo Prisijungimo duomenis ir
                duomenis apie transporto priemones Portale nurodytu būdu.&nbsp;
            </p>
            <strong>Teisės į skelbiamą informaciją</strong>
            <p>
                Naudotojas supranta ir sutinka su tuo, jog visa asmeninė
                Naudotojo informacija (įskaitant, bet neapsiribojant
                Prisijungimo duomenimis) ir visa Informacija, kurią jis bet
                kokiu būdu pateikia naudodamasis Portalu, gali būti saugoma 10
                metų nuo paskutinio Naudotojo apsilankymo Portale.
            </p>
            <p>
                Naudotojas supranta, kad jis pats, o ne
                &ldquo;Nevažiuoja&rdquo;, yra atsakingas už visą Informaciją,
                kurią Naudotojas siunčia, peržiūri, laiko ar platina
                naudodamasis Portalu ir/arba jo teikiamomis Paslaugomis.
            </p>
            <p>
                Naudotojas supranta ir sutinka su tuo, kad
                &ldquo;Nevažiuoja&rdquo; turi teisę, tačiau ne pareigą, savo
                nuožiūra tikrinti Naudotojo gaunamą ir perduodamą Informaciją,
                siekiant aptikti nepageidaujama ar pažeidžiančią &scaron;ias
                Taisykles pripažįstamą Informaciją, o tokią Informaciją aptikus
                &ndash; ją &scaron;alinti ar blokuoti tokios Informacijos bet
                kokį perdavimą.
            </p>
            <p>
                Visos teisės į Portalą ir jame esančius kūrinius yra saugomos.
                Jokia Portale esanti informacija negali būti atgaminama,
                padaroma vie&scaron;ai prieinama arba platinama be
                i&scaron;ankstinio ra&scaron;ti&scaron;ko
                &ldquo;Nevažiuoja&rdquo; sutikimo.
            </p>
            <strong>Intelektinė Nuosavybė</strong>
            <p>
                Jungtis prie &scaron;io Interneto Portalo, peržiūrėti ir
                atsispausdinti vieną jo kopiją, taip pat vieną kopiją Interneto
                Portale esančios informacijos, vaizdų ir kito turinio
                (i&scaron;skyrus vartotojo sukurtą turinį) (toliau &ndash;&nbsp;
                <strong>Medžiaga</strong>) galite tik griežtai vadovaudamiesi
                &scaron;iomis Interneto Portalo naudojimo sąlygomis.
            </p>
            <p>
                Peržiūrėti, atsispausdinti, naudoti, cituoti, remtis Interneto
                svetaine ir Medžiaga galite tik savo asmeniniais, o ne
                komerciniais, tikslais ir su sąlyga, kad pripažįstate, jog
                nuosavybės teisės į tokią informaciją priklauso
                &ldquo;Nevažiuoja&rdquo;.
            </p>
            <p>
                Jokios &nbsp;minėtų teisių nuostatos nesumažina ar neapriboja
                autoriaus neturtinių &nbsp;teisių Medžiagos atžvilgiu.
            </p>
            <p>
                Mes ai&scaron;kiai pasiliekame sau visas intelektinės nuosavybės
                teises į Interneto Portale esančią Medžiagą, ir jūs galite
                naudotis Interneto Portalo Medžiaga su tam tikrais apribojimais,
                pagal kuriuos jums draudžiama:
            </p>
            <ol>
                <ol>
                    <ol>
                        <li>
                            &scaron;alinti &nbsp;bet kokius Medžiagoje esančius
                            prane&scaron;imus apie autorines teises ar kitą
                            &nbsp;patentuotą informaciją; ir (arba)
                        </li>
                        <li>
                            naudoti Interneto Portale esančią Medžiagą tokiu
                            būdu, dėl kurio gali būti pažeistos mūsų ar bet
                            kokių trečiųjų &scaron;alių autorių teisės,
                            intelektinės nuosavybės teisės ar nuosavybės teisės;
                            ir (arba)
                        </li>
                        <li>
                            naudoti ar skatinti kitus naudoti bet kokią
                            automatinę sistemą ar programinę įrangą, siekiant
                            i&scaron;gauti turinį ar duomenis i&scaron;
                            &scaron;io Interneto Portalo (toliau &ndash;
                            &bdquo;ekrano duomenų analizė&ldquo;),
                            i&scaron;skyrus atvejus, kai jūs ar atitinkama
                            trečioji &scaron;alis tiesiogiai su mumis esate
                            sudarę ra&scaron;ytinę licencijos sutartį, pagal
                            kurią tokia veikla yra leidžiama; ir (arba)
                        </li>
                        <li>
                            atgaminti, keisti, rodyti, atlikti, publikuoti,
                            platinti, skleisti, transliuoti, kadruoti (arba
                            naudoti bet kokią kitą nar&scaron;yklę ar įrėmintą
                            aplinką), perduoti į vie&scaron;umą ar
                            i&scaron;siųsti bet kuriai trečiai &scaron;aliai
                            arba naudoti &scaron;į Interneto Portalą, ir (arba)
                            Medžiagą kitais komerciniais tikslais be mūsų
                            i&scaron;ankstinio ra&scaron;ytinio sutikimo, duoto
                            pagal licencinę sutartį.
                        </li>
                    </ol>
                </ol>
            </ol>
            <strong>&ldquo;Nevažiuoja&rdquo; atsakomybės ribojimas</strong>
            <p>
                Naudotojas sutinka, kad &ldquo;Nevažiuoja&rdquo; nėra ir nebus
                atsakingas už Portalo ir/arba paslaugų veikimo sutrikimus ir dėl
                to Naudotojo ar trečiųjų asmenų patirtus nuostolius ar žalą.
            </p>
            <p>
                &ldquo;Nevažiuoja&rdquo; jokiais atvejais nebus atsakingas už
                Portale esančių Servisų teikiamų transporto priemonių techninės
                priežiūros ir remonto ir/arba kitokių jų teikiamų paslaugų
                kokybę.
            </p>
            <p>
                &ldquo;Nevažiuoja&rdquo; neatsako už jokią žalą ir/arba
                nuostolius, kuriuos gali patirti Naudotojas naudodamasis Portalu
                ir/arba paslaugomis bei įkraudamas, siųsdamas, bet kuriais
                kitais būdais perduodamas ar padarydamas vie&scaron;ai prieinama
                bet kokią Informaciją.
            </p>
            <p>
                Naudotojas supranta ir sutinka su tuo, jog
                &ldquo;Nevažiuoja&rdquo; nebus atsakingas už bet kokiu būdu
                Naudotoją ar kitas trečiąsias &scaron;alis pasiekiančią
                Informaciją, Naudotojui naudojantis Portalu ir/arba paslaugomis,
                nuostolius ar žalą, kurią sukėlė atitinkama Informacija ar
                tokios Informacijos paskelbimas, gavimas, siuntimas ar kitoks
                perdavimas naudojantis Paslaugomis.
            </p>
            <p>
                Naudotojas supranta ir sutinka, kad &ldquo;Nevažiuoja&rdquo;
                niekada ir dėl jokių priežasčių nebus atsakingas už visus ir bet
                kuriuos savo veiksmus arba neveikimą susijusį su Naudotojo
                naudojimusi Portalu ir/arba paslaugomis ir &scaron;ių Taisyklių
                laikymusi. Naudotojas prisiima visą atsakomybę dėl bet kokio
                pobūdžio žalos ar nuostolių atlyginimo, kurie jam ar tretiesiems
                asmenims gali atsirasti bet kokiu būdu Naudotojui naudojantis
                Portalu ar Paslaugomis.
            </p>
            <p>
                Naudotojas įsipareigoja užtikrinti, kad &ldquo;Nevažiuoja&rdquo;
                niekada nekiltų jokia teisinė atsakomybė, pareiga apmokėti,
                atlyginti ar kitaip kompensuoti bet kokius nuostolius, žalą ar
                kitokias i&scaron;laidas, atsiradusias dėl Naudotojo veiksmų,
                kurie pažeidžia &scaron;ias Taisykles ir/arba kitus Lietuvos
                Respublikos teises aktus, Naudotojui naudojantis Portalu ir/arba
                Paslaugomis.
            </p>
            <p>
                Mes neprisiimame jokios atsakomybės už nepakankamą Interneto
                Portalo priežiūrą ir (arba) pavėluotą ar neįvykusį bet kokios
                Medžiagos pateikimą. Mes neatsakome už jokius nuostolius,
                kilusius dėl jūsų veiksmų ar neveikimo, kurie buvo grindžiami
                &scaron;iame Interneto Portale pateikta Medžiaga. Tačiau jokios
                &scaron;ių Interneto Portalo naudojimo sąlygų nuostatos neturi
                įtakos jūsų įstatyminėms teisėms ir neapriboja mūsų atsakomybės
                asmens mirties ar sužalojimo atveju, jei to priežastis buvo mūsų
                aplaidumas, taip pat atsakomybės dėl tyčios ar didelio
                neatsargumo ir (arba) bet kokios kitos atsakomybės, kurios
                negalima atmesti ar apriboti pagal Lietuvos teisę. Medžiagoje
                gali pasitaikyti netikslumų ir ra&scaron;ybos klaidų. Mes
                negarantuojame, kad Medžiaga bus tiksli ar i&scaron;sami.
            </p>
            <strong>&ldquo;Nevažiuoja&rdquo; atsakomybė</strong>
            <p>
                <span>
                    &ldquo;Nevažiuoja&rdquo; suteikia 30 dienų garantiją teikti
                    ne&scaron;ali&scaron;ko autoserviso ir (arba) techninio
                    advokato konsultaciją Naudotojui i&scaron;rei&scaron;kus
                    skundą dėl atliktų darbų ar paslaugų kokybės kurie buvo
                    suteikti užsiregistravus per Portalą. Garantinius gedimus
                    autoservisas taiso nemokamai. Toks skundas turėtų būti
                    i&scaron;siųstas ne vėliau negu 30 dienų po apsilankymo
                    trečiosios &scaron;alies autoservise ar gavus jų paslaugas
                    arba (ir) prekes.
                </span>{" "}
                Skundo formoje turėtų būti nurodyta bendrinė informacija
                (užsakymo numeris, automobilio VIN, autoserviso suteikto darbų
                sąmato kopija, užsakymo suma) ir &scaron;i forma turėtų būti
                siunčiama el.pa&scaron;tu&nbsp;
                <a href="mailto:labas@nevaziuoja.lt">labas@nevaziuoja.lt</a>. Į
                visas parai&scaron;kas &ldquo;Nevažiuoja&rdquo; komanda atsako
                per 5 darbo dienas.
            </p>
            <p>&ldquo;Nevažiuoja&rdquo; atsakomybės neprisiima:</p>
            <p>
                a) Už nepakankamą Interneto Portalo priežiūrą ir (arba)
                pavėluotą ar neįvykusį bet kokios Medžiagos pateikimą.
            </p>
            <p>
                b) Visais įstatymų nustatytais atvejais mes neprisiimame ir
                nepripažįstame jokių garantijų, terminų, sąlygų ir
                parei&scaron;kimų, kuriuos gali kitaip numatyti įstatymai
                &scaron;io Interneto Portalo atžvilgiu, t. y. mes nedarome jokių
                parei&scaron;kimų ir negarantuojame, kad Interneto Portalas bus
                be klaidų, neturės virusų ar kitų kenksmingų komponentų, ar, kad
                defektai bus i&scaron;taisyti. &Scaron;iuo atžvilgiu turite
                patys imtis atsargumo priemonių
            </p>
            <p>
                c) Bet kuriuo atveju, mes nebūsime atsakingi už žalą ar
                nuostolius, kuriuos sukėlė paskirstyta atsisakymo aptarnauti
                ataka, virusai ar kita technologi&scaron;kai kenksminga
                medžiaga, galinti užkrėsti jūsų kompiuterinę įrangą,
                kompiuterines programas, duomenis ar kitą nuosavą medžiagą dėl
                to, kad naudojatės mūsų Interneto Portalu.
            </p>
            <p>
                <br />
            </p>
            <p>
                Atkreipiame dėmesį, kad mūsų Portalas yra skirtas tik vidiniam
                ir privačiam naudojimui, todėl jūs patvirtinate, kad nenaudosite
                mūsų Interneto Portalo komerciniais ar verslo tikslais,
                i&scaron;skyrus atvejus, kai būsite patvirtintas kaip Partneris.
            </p>
            <p>
                Mes &nbsp;neprisiimame jokios atsakomybės už toliau nurodytus
                nuostolius (net jei &nbsp;tokie nuostoliai yra i&scaron; anksto
                numatyti): pajamų ar įplaukų netekimą, &nbsp;verslo praradimą,
                pelno netekimą, numatytų santaupų praradimą, duomenų
                &nbsp;praradimą ar vadovybės ar darbuotojų laiko
                i&scaron;&scaron;vaistymą.
            </p>
            <strong>
                <span>T</span>
                <span>
                    rečiosios &scaron;alies medžiaga ir trečiosios &scaron;alies
                    svet
                </span>
                <span>ainės</span>
            </strong>
            <p>
                Portale gali būti trečiųjų &scaron;alių pateikiama reklama.
                Tokios trečios &scaron;alys yra i&scaron;imtinai atsakingos už
                tokios reklamos turinį ir privalo užtikrinti, kad jis atitiktų
                visus taikomus įstatymus bei norminius teisės aktus. Mes
                neprisiimame jokios atsakomybės už trečiųjų &scaron;alių
                reklamos turinį.
            </p>
            <p>
                Portale ir (arba) Medžiagoje gali būti pateikiamos nuorodos į
                trečiųjų &scaron;alių svetaines (įskaitant mūsų Partnerių
                svetaines). Lankytis trečiosios &scaron;alies svetainėje galite
                savo pačių rizika. Mes neprisiimame jokios tiesioginės ar
                netiesioginės atsakomybės nei už turinį, informacijos tikslumą
                ar nuomones, i&scaron;reik&scaron;tas tokiose svetainėse, nei už
                jose siūlomų prekių ir paslaugų kokybę. Jei nėra ai&scaron;kiai
                nurodyta kitaip, nuorodos neturi būti suprantamos taip, kad mes,
                mūsų Interneto svetainė ar mūsų Programėlės yra siejamos ar
                susijusios su tokiomis svetainėmis.
            </p>
            <p>
                Mūsų &nbsp;informacinėje medžiagoje gali būti informacijos,
                kurios &scaron;altinis &ndash; &nbsp;trečiųjų &scaron;alių
                interneto svetainės. Medžiaga, paimta i&scaron; trečiosios
                &scaron;alies &nbsp;svetainės, bus atitinkamai pažymėta, be to,
                gali būti pateikiama nuoroda &nbsp;į pirminę interneto svetainę.
                Mes neprisiimame jokios atsakomybės nei už &nbsp;medžiagą,
                paimtą ar skelbiamą trečiosios &scaron;alies svetainėje, kuri
                yra &nbsp;pateikiama kaip nuoroda mūsų jums skirtoje
                informacinėje medžiagoje, nei &nbsp;už tokios trečiosios
                &scaron;alies naudojamus asmens duomenis.
            </p>
            <p>
                Bet &nbsp;kokia informacinėje medžiagoje mūsų pateikta nuoroda į
                svetainę &nbsp;nerei&scaron;kia, kad mes ją palaikome. Jei
                norite prisijungti prie trečios &nbsp;&scaron;alies interneto
                svetainių, į kurias pateikiama nuoroda, galite tai &nbsp;daryti
                savo pačių rizika.
            </p>
            <p>
                Primename, &nbsp;kad jei pasinaudojate nuoroda apsilankyti
                kitame internetiniame puslapyje &nbsp;i&scaron; mūsų Interneto
                svetainės ar Programėlių, mūsų Sąlygos ir politikos
                &nbsp;(įskaitant mūsų Privatumo ir slapukų naudojimo politiką)
                nebetaikomos. &nbsp;Kitose interneto svetainėse (įskaitant tas,
                į kurias pateikiama nuoroda) &nbsp;galite nar&scaron;yti ir
                bendrauti vadovaudamiesi jų sąlygomis ir politikomis.
                &nbsp;Prie&scaron; pradėdami tai daryti, susipažinkite su
                tokiomis sąlygomis ir &nbsp;politikomis.
            </p>
            <strong>Baigiamosios nuostatos&nbsp;</strong>
            <p>
                &Scaron;alys susitaria, kad &scaron;ios Taisyklės turi
                vir&scaron;enybę visų Portale Naudotojui prieinamų
                paai&scaron;kinimų ir Paslaugų apra&scaron;ymų atžvilgiu, o taip
                pat dėl to, kad visi ginčai tarp &scaron;alių bus sprendžiami
                remiantis &scaron;iomis Taisyklėmis ir jų tekstu.
            </p>
            <p>
                Visi tarp Naudotojo ir &ldquo;Nevažiuoja&rdquo; kilę nesutarimai
                dėl &scaron;ių Taisyklių vykdymo yra sprendžiami derybų keliu.
                &Scaron;alims nei&scaron;sprendus ginčo derybų būdu per 30
                (trisde&scaron;imt) dienų nuo tos dienos, kai viena i&scaron;
                &scaron;alių įteikė kitai &scaron;aliai pra&scaron;ymą
                i&scaron;spręsti ginčą, toks ginčas galutinai sprendžiamas
                Lietuvos Respublikos įstatymų nustatyta tvarka teismuose pagal
                &ldquo;Nevažiuoja&rdquo; buveinės vietą.
            </p>
            <p>
                <br />
            </p>
        </S.TOSContentContainer>
    );
};

export default TermsOfService;
