import { SeoPages } from "@FEShared/types/common";

// since this is included in
const FECLIENT_SERVICES_SEO_PAGES: SeoPages = {
    "/tepalu-keitimas": {
        seoServiceName: "Tepalų keitimas",
        serviceDefinitionID: "1_1",
        footer: true,
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kuris atlieka {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} tepalų keitimo kaina?",
                answer: "{carModel} Tepalų keitimo kaina gali skirtis, bet vidutiniškai ji svyruoja nuo 30 iki 80 EUR. Kainos gali skirtis priklausomai nuo naudojamos alyvos rūšies ir autoserviso įkainių. Tikslesnę kainą skirtinguose autoservisuose gali rasti mūsų paieškos puslapyje.",
            },
            {
                question: "Kaip žinoti, kad reikia keisti {carModel} tepalus?",
                answer: "Įprasti požymiai, kad automobiliui reikalingas {carModel} {serviceName}, yra deganti tepalų keitimo lemputė prietaisų skydelyje, tamsus ir nešvarus tepalas, variklio triukšmas ir pastebimas degalų efektyvumo sumažėjimas. Reguliarus tepalų lygio ir kokybės tikrinimas gali padėti nustatyti, kada reikia keisti tepalus.",
            },
            {
                question: "Kas kiek laiko reikia keisti {carModel} tepalus?",
                answer: "Paprastai {carModel} {serviceName} rekomenduojamas atlikti kas 5,000–10,000 km, atsižvelgiant į automobilio markę ir modelį, taip pat nuo naudojamo tepalų tipo. Konkrečius nurodymus rasi transporto priemonės savininko vadove.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai trunka nuo 30 minučių iki valandos.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/padangu-ratu-montavimas": {
        footer: true,

        seoServiceName: "Padangų/ratų montavimas",
        serviceDefinitionID: "29_53",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} padangų/ratų montavimo kaina?",
                answer: "{carModel} Padangų/ratų montavimo kaina gali skirtis, tačiau vidutiniškai kaina būna 50 EUR. Kainos gali skirtis priklausomai nuo padangų dydžio.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad reikalingas {carModel} {serviceName} yra matomas padangų susidėvėjimas, pvz. protektoriaus gylis mažesnis nei 1,6 mm, padangos šoninių sienelių įtrūkimai ar išsipūtimai, dažnas padangų slėgio mažėjimas ir pastebimas sukibimo ar valdymo sumažėjimas. Reguliariai tikrinant padangas galima lengviau nustatyti, kada laikas jas keisti.",
            },
            {
                question:
                    "Kas kiek laiko reikalingas {carModel} padangų keitimas?",
                answer: "Paprastai rekomenduojama pasikeisti padangas kas 40,000–80,000 km, atsižvelgiant į padangų tipą ir vairavimo sąlygas.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai trunka nuo 30 minučių iki valandos.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/vaziuokles-pakabos-patikra": {
        footer: true,

        seoServiceName: "Važiuoklės (pakabos) patikra",
        serviceDefinitionID: "29_78",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question:
                    "Kokia {carModel} važiuoklės (pakabos) patikros kaina?",
                answer: "{carModel} Važiuoklės (pakabos) patikros kaina gali skirtis, tačiau vidutiniškai ji svyruoja nuo 20 iki 50 EUR.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Dažni požymiai, kad {carModel} automobiliui reikia važiuoklės (pakabos) patikros, yra neįprasti garsai važiuojant per nelygumus, nelygus ar šokinėjantis važiavimas, netolygus padangų susidėvėjimas ir vairavimo ar valdymo sunkumai. Jei pastebėsite, kad automobilis labiau linksta į kažkurią pusę arba jei transporto priemonė stovi netolygiai kažkuriame kampe, gali būti laikas atlikti {carModel} važiuoklės (pakabos) patikrą.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai trunka nuo 30 minučių iki valandos.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/vaziuokles-pakabos-remontas": {
        footer: true,

        seoServiceName: "Važiuoklės (Pakabos) remontas",
        serviceDefinitionID: "11_0",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question:
                    "Kokia {carModel} važiuoklės (pakabos) remonto kaina?",
                answer: "{carModel} {serviceName} kaina gali skirtis, tačiau vidutiniškai ji svyruoja nuo 50 iki 300 EUR. Kainos gali skirtis priklausomai nuo remonto apimties.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Dažni požymiai, kad {carModel} automobiliui reikia važiuoklės (pakabos) remonto, yra neįprasti garsai važiuojant per nelygumus, nelygus ar šokinėjantis važiavimas, netolygus padangų susidėvėjimas ir vairavimo ar valdymo sunkumai. Jei pastebi, kad automobilis labiau linksta į kažkurią pusę arba transporto priemonė stovi netolygiai kažkuriame kampe, gali būti laikas atlikti {carModel} važiuoklės (pakabos) remontą.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai trunka maždaug 1-3 valandas. Tai gali skirtis priklausomai nuo reikalingo remonto apimties.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/stabdziu-remontas": {
        footer: true,

        seoServiceName: "Stabdžių remontas",
        serviceDefinitionID: "13_0",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} stabdžių remonto kaina?",
                answer: "{carModel} Stabdžių remonto kaina gali skirtis, tačiau vidutiniškai ji svyruoja nuo 100 iki 300 EUR. Kainos gali skirtis priklausomai nuo stabdžių dalių ir remonto sudėtingumo.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad automobiliui reikia {carModel} stabdžių remonto, yra girgždėjimas arba šlifavimas stabdant, ilgesnis stabdymo kelias ir stabdžių įspėjamoji lemputė prietaisų skydelyje. Jei stabdant pastebi vibraciją arba traukimą į vieną pusę, gali būti, kad laikas atlikti {carModel} stabdžių remontą.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai užtrunka apie 1–3 valandas. Trukmė gali skirtis priklausomai nuo reikalingo remonto apimties.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/stabdziu-kaladeliu-keitimas": {
        footer: true,
        seoServiceName: "Stabdžių kaladėlių keitimas",
        serviceDefinitionID: "29_63",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} stabdžių kaladėlių keitimo kaina?",
                answer: "{carModel} Stabdžių kaladėlių keitimo kaina gali skirtis, tačiau vidutiniškai ji svyruoja nuo 100 iki 200 EUR. Kainos gali skirtis priklausomai nuo stabdžių kaladėlių tipo ir autoserviso.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad automobiliui reikia stabdžių kaladėlių keitimo, yra girgždėjimas arba cypiantis garsas stabdant, šlifavimo garsas, sumažėjęs stabdymo efektyvumas ir stabdžių įspėjamoji lemputė prietaisų skydelyje. Jei pastebi vibraciją arba pulsuojantį stabdžių pedalą, gali būti, kad laikas atlikti {carModel} stabdžių kaladėlių keitimą.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai trunka maždaug 1–2 valandas.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/stabdziu-disku-keitimas": {
        footer: true,
        seoServiceName: "Stabdžių diskų keitimas",
        serviceDefinitionID: "29_62",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} stabdžių diskų keitimo kaina?",
                answer: "Vidutinė {carModel} stabdžių diskų keitimo kaina gali svyruoti nuo 200 iki 400 eurų, priklausomai nuo transporto priemonės markės ir modelio bei stabdžių diskų.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Požymiai, kad reikia keisti {carModel} stabdžių diskus, yra pulsuojantis stabdžių pedalas, neįprasti garsai, pvz. girgždėjimas ar šlifavimas stabdant, ir matomi grioveliai ar įbrėžimai ant stabdžių diskų. Jei jauti kurį nors iš šių simptomų, labai svarbu patikrinti stabdžių diskus ir, jei reikia, juos pakeisti.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Stabdžių diskų keitimas paprastai trunka apie 1 valandą.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/stabdziu-disku-remontas": {
        seoServiceName: "Stabdžių diskų remontas",
        serviceDefinitionID: "29_61",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} stabdžių diskų remonto kaina?",
                answer: "Vidutinė {carModel} stabdžių disko remonto kaina gali svyruoti nuo 100 iki 300 eurų, priklausomai nuo transporto priemonės markės ir modelio bei remonto sudėtingumo.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Požymiai, kad reikia remontuoti {carModel} stabdžių diskus, yra pulsuojantis stabdžių pedalas, neįprasti garsai, pvz. girgždėjimas ar šlifavimas stabdant, ir matomi grioveliai ar įbrėžimai ant stabdžių diskų. Jei jauti kurį nors iš šių simptomų, labai svarbu patikrinti stabdžių diskus ir, jei reikia, juos remontuoti.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Stabdžių diskų remontas paprastai trunka apie 1 valandą, priklausomai nuo darbo sudėtingumo.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/automobilio-patikra": {
        footer: true,
        seoServiceName: "Automobilio patikra",
        serviceDefinitionID: "1_0",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} automobilio patikros kaina?",
                answer: "Vidutinė {carModel} automobilio patikros kaina gali svyruoti nuo 50 iki 150 eurų, priklausomai nuo apžiūros gylio ir konkrečių įtrauktų paslaugų.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad automobiliui reikalingas {carModel} {serviceName}, yra neįprasti garsai, suprastėjęs veikimas, įspėjamieji ženklai prietaisų skydelyje ir valdymo ar stabdymo nelygumai. Pastebėjus bet kurią iš šių problemų, patartina suplanuoti techninės būklės įvertinimą, kad būtų galima diagnozuoti ir išspręsti galimas problemas.",
            },
            {
                question:
                    "Kaip dažnai reikia atlikti {carModel} automobilio patikrą?",
                answer: "{carModel} Automobilio patikrą rekomenduojama atlikti bent kartą per metus arba kas 20,000 km. Be to, prieš ilgas keliones arba jei įtari kokių nors problemų dėl savo transporto priemonės.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Automobilio patikra paprastai trunka apie 1–2 valandas.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigi {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigiw {carModel} {serviceName}.",
            },
        ],
    },
    "/kondicionieriu-remontas": {
        footer: true,
        seoServiceName: "Kondicionierių remontas",
        serviceDefinitionID: "2_8",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} kondicionierių remonto kaina?",
                answer: "Vidutinė {carModel} kondicionieriaus remonto kaina gali svyruoti nuo 50 € iki 200 €, priklausomai nuo konkrečios problemos ir dalių, kurias reikia keisti.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Dažni ženklai, kad automobiliui reikia {carModel} kondicionierių remonto, yra silpnas oro srautas, šiltas oras, pučiamas iš ventiliacijos angų, neįprasti garsai, kai veikia kondicionierius, ir nemalonūs kvapai, sklindantys iš ventiliacijos angų. Jei kyla bet kuri iš šių problemų, patartina patikrinti kondicionieriaus sistemą ir, jei reikia, ją taisyti. Rekomenduojama atlikti {carModel} kondicionieriaus patikrą bent kartą per metus, geriausia prieš vasaros sezoną.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Kondicionieriaus remontas paprastai trunka apie 1–3 valandas, atsižvelgiant į problemos sudėtingumą ir konkretų transporto priemonės modelį.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/kondicionieriaus-pildymas": {
        footer: true,
        seoServiceName: "Kondicionieriaus pildymas",
        serviceDefinitionID: "29-53",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} kondicionieriaus pildymo kaina?",
                answer: "Vidutinė {carModel} kondicionieriaus pildymo kaina gali svyruoti nuo 50 € iki 100 €, priklausomai nuo reikalingo freono kiekio.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad reikia papildyti {carModel} kondicionierių, yra silpnas vėsinimas arba šiltas oro srautas iš ventiliacijos angų, kondicionieriaus sistema ilgiau atvėsina saloną ir neįprasti garsai, kai veikia kondicionierius. Jei pastebėjote bet kurį iš šių simptomų, patartina patikrinti kondicionavimo sistemą ir, jei reikia, ją papildyti.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai trunka nuo 30 minučių iki 1 valandos, atsižvelgiant į konkretų transporto priemonės modelį ir kondicionavimo sistemos būklę.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/automatines-pavaru-dezes-tepalu-keitimas": {
        footer: true,
        seoServiceName: "Automatinės pavarų dėžės tepalų keitimas",
        serviceDefinitionID: "29_36",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question:
                    "Kokia {carModel} automatinės pavarų dėžės tepalų keitimo kaina?",
                answer: "Vidutinė {carModel} automatinės pavarų dėžės tepalų keitimo kaina gali svyruoti nuo 100 € iki 300 €, priklausomai nuo transporto priemonės markės ir modelio bei tepalų kokybės.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Dažni ženklai, kad reikia pakeisti {carModel} automatinės pavarų dėžės tepalus, yra uždelstas arba šiurkštus pavarų perjungimas, neįprasti garsai, tokie kaip šlifavimas, degėsio kvapas, sklindantis iš transmisijos.",
            },
            {
                question:
                    "Kaip dažnai reikia keisti {carModel} automatinės pavarų dėžės tepalus?",
                answer: "Rekomenduojama {carModel} automatinės pavarų dėžės tepalus keisti kas 60,000–100,000 km arba kaip nurodyta automobilio savininko vadove.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai trunka apie 1–2 valandas, priklausomai nuo konkretaus automobilio modelio ir transmisijos būklės.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/mechanines-pavaru-dezes-tepalu-keitimas": {
        seoServiceName: "Mechaninės pavarų dėžės tepalų keitimas",
        serviceDefinitionID: "29_35",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question:
                    "Kokia {carModel} mechaninės pavarų dėžės tepalų keitimo kaina?",
                answer: "Vidutinė {carModel} mechaninės pavarų dėžės tepalų keitimo kaina gali svyruoti nuo 70 € iki 150 €, priklausomai nuo transporto priemonės markės ir modelio bei tepalų kokybės.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Dažni ženklai, kad reikia pakeisti {carModel} mechaninės pavarų dėžės tepalus, yra uždelstas arba šiurkštus pavarų perjungimas, neįprasti garsai, tokie kaip šlifavimas, degėsio kvapas, sklindantis iš transmisijos.",
            },
            {
                question:
                    "Kaip dažnai reikia keisti {carModel} automatinės pavarų dėžės tepalus?",
                answer: "Rekomenduojama {carModel} mechaninės pavarų dėžės tepalus keisti kas 80,000–100,000 km arba kaip nurodyta automobilio savininko vadove.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai trunka apie 1–2 valandas, priklausomai nuo konkretaus automobilio modelio ir transmisijos būklės.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/pusiau-automatines-pavaru-dezes-tepalu-keitimas": {
        seoServiceName: "Pusiau automatinės pavarų dėžės tepalų keitimas",
        serviceDefinitionID: "29_36",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question:
                    "Kokia {carModel} pusiau-automatinės pavarų dėžės tepalų keitimo kaina?",
                answer: "Vidutinė {carModel} pusiau-automatinės pavarų dėžės tepalų keitimo kaina gali svyruoti nuo 100 € iki 250 €, priklausomai nuo transporto priemonės markės ir modelio bei tepalų kokybės.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Dažni ženklai, kad reikia pakeisti {carModel} pusiau-automatinės pavarų dėžės tepalus, yra uždelstas arba šiurkštus pavarų perjungimas, neįprasti garsai, tokie kaip šlifavimas, degėsio kvapas, sklindantis iš transmisijos.",
            },
            {
                question:
                    "Kaip dažnai reikia keisti {carModel} pusiau-automatinės pavarų dėžės tepalus?",
                answer: "Rekomenduojama {carModel} pusiau-automatinės pavarų dėžės tepalus keisti kas 60,000–100,000 km arba kaip nurodyta automobilio savininko vadove.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai trunka apie 1–2 valandas, priklausomai nuo konkretaus automobilio modelio ir transmisijos būklės.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/zibintu-reguliavimas": {
        footer: true,
        seoServiceName: "Žibintų reguliavimas",
        serviceDefinitionID: "2_10",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} žibintų reguliavimo kaina?",
                answer: "Vidutinė {carModel} žibintų reguliavimo kaina gali svyruoti nuo 10 € iki 40 €, priklausomai nuo specifinio automobilio modelio.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad reikia reguliuoti priekinius {carModel} žibintus, yra netolygus arba silpnas apšvietimas, per aukštai arba per žemai nukreipti priekiniai žibintai ir sunku matyti kelią naktį arba esant blogoms oro sąlygoms. Pastebėjus bet kurią iš šių problemų, patartina patikrinti ir prireikus sureguliuoti priekinius žibintus.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Priekinių žibintų reguliavimas paprastai trunka 30 minučių.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/zibintu-remontas": {
        footer: true,
        seoServiceName: "Žibintų remontas",
        serviceDefinitionID: "2_6",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} žibintų remonto kaina?",
                answer: "Vidutinė {carModel} priekinių žibintų remonto kaina gali svyruoti nuo 15 € iki 50 €, priklausomai nuo konkrečios problemos ir dalių, kurias reikia keisti.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Dažni požymiai, kad reikia remontuoti {carModel} priekinius žibintus, yra blausūs arba mirksintys žibintai, vienas arba abu priekiniai žibintai neveikiantys, matomi priekinio žibinto lęšio arba korpuso pažeidimai ir drėgmė arba kondensatas priekinių žibintų viduje. Pastebėjus bet kurią iš šių problemų, patartina patikrinti ir prireikus pataisyti priekinius žibintus.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Priekinių žibintų remontas paprastai trunka apie 1–2 valandas, atsižvelgiant į problemos sudėtingumą.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/variklio-diagnostika": {
        footer: true,
        seoServiceName: "Variklio diagnostika",
        serviceDefinitionID: "29_86",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} variklio diagnostikos kaina?",
                answer: "Vidutinė {carModel} variklio diagnostikos kaina gali svyruoti nuo 50 € iki 100 €",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad reikia atlikti {carModel} variklio diagnostiką, yra deganti CHECK ENGINE lemputė, neįprasti variklio garsai, sumažėjęs degalų efektyvumas, šiurkšti automobilio eiga, prastas pagreitis. Jei atsiranda bet kuris iš šių simptomų, patartina patikrinti variklį naudojant diagnostikos įrankį.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Variklio diagnostika paprastai trunka 30 minučių.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigi {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigi {carModel} {serviceName}.",
            },
        ],
    },
    "/variklio-remontas": {
        footer: true,
        seoServiceName: "Variklio remontas",
        serviceDefinitionID: "0_0",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} variklio remonto kaina?",
                answer: "Vidutinė {carModel} variklio remonto kaina gali labai skirtis priklausomai nuo konkrečios problemos ir dalių, kurias reikia pakeisti. Smulkus remontas gali kainuoti apie 50–500 eurų.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad reikalingas {carModel} {serviceName}, yra įsijungianti CHECK ENGINE lemputė, neįprasti garsai, pvz. variklio beldimas , sumažėjęs veikimas ar galia, per daug dūmų iš išmetimo vamzdžių ir alyvos arba aušinimo skysčio nuotėkis. Jei pajutote bet kurį iš šių simptomų, labai svarbu patikrinti variklį ir, jei reikia, jį suremontuoti, kad išvengti brangių remontų.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Variklio remontas gali užtrukti nuo kelių valandų iki kelių dienų, atsižvelgiant į problemos sudėtingumą ir konkretų transporto priemonės modelį. Smulkus remontas gali būti atliktas per dieną.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/variklio-kapitalinis-remontas": {
        footer: true,
        seoServiceName: "Variklio kapitalinis remontas",
        serviceDefinitionID: "6_2",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question:
                    "Kokia {carModel} kapitalinio variklio remonto kaina?",
                answer: "Vidutinė {carModel} variklio kapitalinio remonto kaina gali svyruoti nuo 1,000 EUR iki 5,000 EUR ar daugiau, priklausomai nuo transporto priemonės markės ir modelio bei reikalingų darbų apimties.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad automobiliui reikia atlikti {carModel} variklio kapitalinį remontą, yra nuolatinis variklio bildėjimas/triukšmas, didelis galios praradimas, per didelės alyvos sąnaudos, dažnas perkaitimas ir matomos metalo drožlės alyvoje. Jei jaučiate kurį nors iš šių simptomų, labai svarbu patikrinti variklį ir, jei reikia, atlikti kapitalinį remontą.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Variklio kapitalinis remontas paprastai trunka apie 1–2 savaites, priklausomai nuo darbo sudėtingumo ir konkretaus transporto priemonės modelio. Tai apima laiką, kurio reikia išardymui, patikrinimui, remontui, surinkimui ir bandymui.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/variklio-grandines-keitimas": {
        footer: true,
        seoServiceName: "Variklio grandinės keitimas",
        serviceDefinitionID: "6_4",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} variklio grandinės keitimo kaina?",
                answer: "Vidutinė {carModel} variklio grandinės keitimo kaina gali svyruoti nuo 500 iki 1500 EUR, priklausomai nuo transporto priemonės markės ir modelio bei darbo sudėtingumo.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Dažni požymiai, kad automobiliui reikia pakeisti {carModel} variklio grandinę, yra variklio barškėjimas arba tiksėjimas, variklio užvedimo sunkumai, prastas variklio darbas ir užsidega CHECK ENGINE lemputė. Jei atsiranda bet kuris iš šių simptomų, patartina patikrinti paskirstymo grandinę ir, jei reikia, ją pakeisti. Nutrūkus variklio grandinei automobilis visiškai neužsiveda - šiuo atvėju reikia išsikviesti tralą ir atvežti automobilį iki autoserviso.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Variklio grandinės keitimas paprastai trunka apie 8 valandas, priklausomai nuo konkretaus transporto priemonės modelio ir variklio būklės.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/pagrindinio-dirzo-keitimas": {
        footer: true,
        seoServiceName: "Pagrindinio diržo keitimas",
        serviceDefinitionID: "1_7",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} pagrindinio diržo keitimo kaina?",
                answer: "Vidutinė {carModel} paskirstymo diržo keitimo kaina gali svyruoti nuo 300 iki 600 eurų, priklausomai nuo transporto priemonės markės ir modelio bei darbo sudėtingumo.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad automobiliui reikia pakeisti {carModel} paskirstymo diržą, yra variklio skleidžiamas tiksėjimas, variklio uždegimo sutrikimas, tepalų nutekėjimas iš variklio priekio, sunkumai užvedant automobilį arba visai neužsivedantis automobilis. Jei jaučiate kurį nors iš šių simptomų, labai svarbu patikrinti paskirstymo diržą ir, jei reikia, jį pakeisti.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Paskirstymo diržo keitimas paprastai trunka apie 2–4 valandas, priklausomai nuo konkretaus transporto priemonės modelio ir variklio būklės.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/pakaitinimo-zvakiu-keitimas": {
        footer: true,
        seoServiceName: "Pakaitinimo žvakių keitimas",
        serviceDefinitionID: "3_4",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} pakaitinimo žvakių keitimo kaina?",
                answer: "Vidutinė {carModel} pakaitinimo žvakių keitimo kaina gali svyruoti nuo 50 iki 150 eurų, priklausomai nuo transporto priemonės markės ir modelio bei naudojamų žvakių tipo.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Įprasti požymiai, kad reikia pakeisti {carModel} pakaitinimo žvakes, yra sunkumai užvedant variklį, prastas pagreitis, sumažėjęs degalų efektyvumas ir deganti CHECK ENGINE lemputė. Jei atsiranda bet kuris iš šių simptomų, patartina patikrinti uždegimo žvakes ir, jei reikia, pakeisti.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} {serviceName} paprastai trunka apie 1 valandą, priklausomai nuo konkretaus transporto priemonės modelio ir variklio būklės.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/nuluzusiu-zvakiu-keitimas": {
        seoServiceName: "Nulūžusių žvakių keitimas",
        serviceDefinitionID: "6_8",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} nulūžusių žvakių keitimo kaina?",
                answer: "Vidutinė {carModel} nulūžusių žvakių keitimo kaina gali svyruoti nuo 100 EUR iki 300 EUR.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Nulūžusių žvakių keitimas paprastai trunka apie 1–3 valandas.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/ratu-suvedimas": {
        footer: true,
        seoServiceName: "Ratų suvedimas",
        serviceDefinitionID: "11_9",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} ratų suvedimo kaina?",
                answer: "Vidutinė {carModel} ratų suvedimo kaina gali svyruoti nuo 25 iki 70 eurų, priklausomai nuo specifinio autoserviso įkainių.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Dažni požymiai, kad {carModel} reikalingas {serviceName}, yra netolygus padangų susidėvėjimas, transporto priemonės traukimas į vieną pusę, kreivas vairas važiuojant tiesiai ir vairo vibracija. Jei jaučiate kurį nors iš šių simptomų, patartina patikrinti ratų suvedimą ir, jei reikia, sureguliuoti.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Ratų suvedimas paprastai trunka apie 30 minučių.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
    "/rato-guolio-keitimas": {
        footer: true,
        seoServiceName: "Rato guolio keitimas",
        serviceDefinitionID: "11_10",
        faq: [
            {
                question:
                    "Kur atliekama {carModel} {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
                answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
            },
            {
                question: "Kokia {carModel} rato guolio keitimo kaina?",
                answer: "Vidutinė {carModel} rato guolio keitimo kaina gali svyruoti nuo 150 EUR iki 300 EUR, priklausomai nuo transporto priemonės markės ir modelio.",
            },
            {
                question:
                    "Kaip žinoti, kad reikalinga {carModel} {serviceName}?",
                answer: "Dažni požymiai, kad reikalingas {carModel} {serviceName}, yra sklindantis ratų dūzgimas arba šlifavimas, netolygus padangų susidėvėjimas, vairo vibracija ir transporto priemonės traukimas į vieną pusę. Jei atsiranda bet kuris iš šių simptomų, patartina patikrinti ratų guolius ir, jei reikia, pakeisti.",
            },
            {
                question: "Kiek trunka {carModel} {serviceName}?",
                answer: "{carModel} Rato guolio keitimas paprastai trunka apie 1 valandą, atsižvelgiant į konkretų transporto priemonės modelį ir rato mazgo būklę.",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} savaitgalį (šeštadienį/sekmadienį).",
            },
            {
                question:
                    "Kur atliekama {carModel} {serviceName} po darbo valandų?",
                answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {carModel} {serviceName} po darbo valandų.",
            },
            {
                question: "Kur atliekama pigus {carModel} {serviceName}?",
                answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {carModel} {serviceName}.",
            },
        ],
    },
};
export default FECLIENT_SERVICES_SEO_PAGES;
