import capFirst from "./capFirst";

export const UNKNOWN_NAME = "Vardas nenurodytas";

/** Return "Name S." format of name */
export default function formatReviewName(fullName: string): string {
    if (fullName === UNKNOWN_NAME) return fullName;

    return fullName
        .trim()
        .toLowerCase()
        .split(" ")
        .slice(0, 2)
        .map((namePart) => namePart.trim())
        .filter(Boolean) // clear empty strings
        .map((namePart, i) =>
            i > 0
                ? `${namePart.slice(0, 1).toUpperCase()}.`
                : capFirst(namePart)
        )
        .join(" ");
}
