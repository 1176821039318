import useStore from "@FEClient/logic/store";
import Dropdown from "@FEShared/components/UI/Dropdown/Dropdown";
import showToast from "@FEShared/utils/showToast";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import Text from "@FEShared/components/UI/Text/Text";
import Input from "@FEShared/components/UI/Input/Input";
import Button from "@FEShared/components/UI/Button/Button";
import {
    REASON_TO_TEXT,
    OTHER_WORKSHOP_FIND_REASON_TO_TEXT,
    CancelReason,
    OtherWorkshopFindReason,
} from "../ReservationCancelModal.const";
import { ModalStep } from "../ReservationCancelModal.types";

const CANCEL_REASON_OPTIONS: { value: string; text: string }[] = Object.entries(
    REASON_TO_TEXT
).map(([cancelReason, cancelText]) => {
    return {
        text: cancelText,
        value: cancelReason,
    };
});

const OTHER_WORKSHOP_FIND_REASON_OPTIONS: { value: string; text: string }[] =
    Object.entries(OTHER_WORKSHOP_FIND_REASON_TO_TEXT).map(
        ([cancelReason, cancelText]) => {
            return {
                text: cancelText,
                value: cancelReason,
            };
        }
    );

const CancelStep: React.FC<{
    onClose: () => void;
    nextStep: (step: ModalStep) => void;
    selectedCancelReason?: CancelReason;
    otherWorkshopFindReason?: OtherWorkshopFindReason;
    otherReason?: string;
    editReasons: (p: {
        selectedReason?: CancelReason;
        otherWorkshopFindReason?: OtherWorkshopFindReason;
        otherReason?: string;
    }) => void;
}> = observer((p) => {
    const GS = useStore();
    const LS = useLocalObservable(() => ({
        isLoading: false,
        forceError: false,
    }));
    const RSPS = GS.reservationSuccessPageState;
    const order = RSPS.completedOrder;

    const isReasonErr = !p.selectedCancelReason;

    const isOtherWorkshopFindReasonErr =
        p.selectedCancelReason === CancelReason.FOUND_NON_PLATFORM_WORKSHOP &&
        !p.otherWorkshopFindReason;

    const reasonTextErr =
        (p.selectedCancelReason === CancelReason.OTHER ||
            (p.selectedCancelReason ===
                CancelReason.FOUND_NON_PLATFORM_WORKSHOP &&
                p.otherWorkshopFindReason === OtherWorkshopFindReason.OTHER)) &&
        !p.otherReason;

    const isErr = isReasonErr || reasonTextErr || isOtherWorkshopFindReasonErr;

    return (
        <div>
            <Text mt={2} variant="body2">
                Padėkite suprasti, kodėl atšaukiate užsakymą, kad galėtume
                tobulėti 🙏❤️
            </Text>
            <Dropdown
                sx={{ mt: 1 }}
                fullWidth
                options={CANCEL_REASON_OPTIONS}
                value={p.selectedCancelReason}
                onChange={(e) => {
                    p.editReasons({
                        selectedReason: e.target.value as CancelReason,
                    });
                }}
                error={isReasonErr}
                forceError={LS.forceError}
                helperText={"Pasirinkite priežastį"}
                placeholder="Pasirinkite atšaukimo priežastį"
                MenuProps={{
                    style: { zIndex: 10001 },
                }}
            />
            {p.selectedCancelReason ===
                CancelReason.FOUND_NON_PLATFORM_WORKSHOP && (
                <>
                    <Text mt={2} variant="body2">
                        Kodėl ieškojote kito autoserviso?
                    </Text>
                    <Dropdown
                        sx={{ mt: 1 }}
                        fullWidth
                        options={OTHER_WORKSHOP_FIND_REASON_OPTIONS}
                        value={p.otherWorkshopFindReason}
                        onChange={(e) => {
                            p.editReasons({
                                otherWorkshopFindReason: e.target
                                    .value as OtherWorkshopFindReason,
                            });
                        }}
                        error={isOtherWorkshopFindReasonErr}
                        forceError={LS.forceError}
                        helperText={"Pasirinkite priežastį"}
                        placeholder="Pasirinkite atšaukimo priežastį"
                        MenuProps={{
                            style: { zIndex: 10001 },
                        }}
                    />
                </>
            )}
            {(p.selectedCancelReason === CancelReason.OTHER ||
                (p.selectedCancelReason ===
                    CancelReason.FOUND_NON_PLATFORM_WORKSHOP &&
                    p.otherWorkshopFindReason ===
                        OtherWorkshopFindReason.OTHER)) && (
                <Input
                    fullWidth
                    multiline
                    placeholder={
                        p.selectedCancelReason ===
                        CancelReason.FOUND_NON_PLATFORM_WORKSHOP
                            ? "Kodėl ieškojote kito autoserviso?"
                            : "Atšaukimo priežastis"
                    }
                    value={p.otherReason}
                    sx={{ mt: 1 }}
                    onChange={(e) => {
                        p.editReasons({
                            otherReason: e.target.value,
                        });
                    }}
                    forceError={LS.forceError}
                    error={reasonTextErr}
                    helperText={"Būtina užpildyti"}
                />
            )}
            <Button
                sx={{ mt: 4 }}
                fullWidth
                disabled={LS.isLoading}
                isLoading={LS.isLoading}
                onClick={async () => {
                    runInAction(() => {
                        LS.forceError = true;
                    });

                    if (isErr) return;
                    if (!order || !p.selectedCancelReason)
                        return showToast.error(
                            "Įvyko klaida atšaukiant užsakymą. Klaidos kodas: #TP",
                            true
                        );

                    if (
                        !order.createdByWorkshop &&
                        order.billable &&
                        [
                            CancelReason.OTHER,
                            CancelReason.WORKSHOP_CANT_ACCEPT_ME,
                            CancelReason.FOUND_NON_PLATFORM_WORKSHOP,
                        ].includes(p.selectedCancelReason)
                    ) {
                        p.nextStep("2_PROMO_OFFER");
                    } else {
                        p.nextStep("2_NO_PROMO");
                    }
                }}
            >
                Atšaukti užsakymą
            </Button>
        </div>
    );
});

export default CancelStep;
