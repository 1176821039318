export function checkIsOpenNow(
    calendarDay?: {
        open: { hour: number; minute: number };
        close: { hour: number; minute: number };
    } | null
) {
    if (!calendarDay) return false;

    const { open, close } = calendarDay;
    const d = new Date();
    const hours = d.getHours();
    const mins = d.getMinutes();
    return (
        (hours > open.hour || (hours === open.hour && mins >= open.minute)) &&
        (hours < close.hour || (hours === close.hour && mins <= close.minute))
    );
}

export function timeAgo(date: Date): string {
    const now = new Date();
    const inputDate = new Date(date);
    const msPerMin = 1 * 60 * 1000;
    const msPerHour = 1 * 60 * 60 * 1000;
    const msPerDay = 24 * 60 * 60 * 1000;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerMonth * 12;

    const msDiff = now.getTime() - inputDate.getTime();

    if (msDiff >= msPerYear) {
        const years = Math.floor(msDiff / msPerYear);
        return `prieš ${years} metus`;
    } else if (msDiff >= msPerMonth) {
        const months = Math.floor(msDiff / msPerMonth);
        return `prieš ${months}mėn.`;
    } else {
        const days = Math.floor(msDiff / msPerDay);
        const hours = Math.floor(msDiff / msPerHour);
        if (hours === 0) {
            return `prieš ${Math.floor(msDiff / msPerMin) || 1}min.`;
        } else if (days === 0) {
            return `prieš ${Math.floor(msDiff / msPerHour) || 1}val.`;
        } else if (days === 1) {
            return "vakar";
        }

        return `prieš ${days}d.`;
    }
}

export function calcPriceRange(hourCost: number): string {
    const hourPriceAvg = 42;

    const priceRanges = {
        oneDollarRange: hourPriceAvg * 0.66,
        twoDollarRange: hourPriceAvg * 1.33,
        threeDollarRange: hourPriceAvg * 1.33 * 2,
    };

    if (hourCost <= priceRanges.oneDollarRange) {
        return "€";
    } else if (
        priceRanges.oneDollarRange < hourCost &&
        hourCost <= priceRanges.twoDollarRange
    ) {
        return "€€";
    } else if (
        priceRanges.twoDollarRange < hourCost &&
        hourCost >= priceRanges.twoDollarRange
    ) {
        return "€€€";
    } else {
        console.error("Unhandled dollar ranges case");
        return "€€";
    }
}
