import React from "react";
import * as S from "./InvitationExpired.styled";

const ServiceClientDeclined: React.FC = () => {
    return (
        <S.InvitationExpiredPage
            failureStatus
            title="Šios rezervacijos patvirtinimo laikas baigėsi"
            description="Ši registracija buvo atšaukta ir klientas nukreiptas kitam nevaziuoja.lt partneriui."
        />
    );
};

export default ServiceClientDeclined;
