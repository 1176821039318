const DISTRICTS = {
    Vilnius: [
        "Antakalnis",
        "Antavilis",
        "Aukštieji Paneriai",
        "Bajorai",
        "Balsiai",
        "Baltupiai",
        "Belmontas",
        "Bireliai",
        "Buivydiškės",
        "Bukčiai",
        "Burbiškės",
        "Centras",
        "Didieji Gulbinai",
        "Dvarčionys",
        "Fabijoniškės",
        "Filaretai",
        "Galgiai",
        "Gariūnai",
        "Gineitiškės",
        "Grigiškės",
        "Gudeliai",
        "Guriai",
        "Jeruzalė",
        "Justiniškės",
        "Kairėnai",
        "Kalnėnai",
        "Karoliniškės",
        "Kirtimai",
        "Kryžiokai",
        "Kuprijoniškės",
        "Lazdynai",
        "Lazdynėliai",
        "Liepkalnis",
        "Markučiai",
        "Mažieji Gulbinai",
        "Medžiakalnis",
        "Naujamiestis",
        "Naujaneriai",
        "Naujininkai",
        "Naujoji Vilnia",
        "Ožkiniai",
        "Pagiriai",
        "Paneriai",
        "Pašilaičiai",
        "Paupys",
        "Pavilnys",
        "Pilaitė",
        "Rasos",
        "Salininkai",
        "Santariškės",
        "Saulėtekis",
        "Senamiestis",
        "Šeškinė",
        "Šiaurės miestelis",
        "Šnipiškės",
        "Tarandė",
        "Trakų Vokė",
        "Turniškės",
        "Užupis",
        "Užusienis",
        "Vaidotai",
        "Valakampiai",
        "Valakupiai",
        "Verkiai",
        "Vilkpėdė",
        "Viršuliškės",
        "Visoriai",
        "Zujūnai",
        "Žemieji Paneriai",
        "Žirmūnai",
        "Žvėrynas",
    ],
    Kaunas: [
        "Aleksotas",
        "Amaliai",
        "Aukštieji Šančiai",
        "Aukštutiniai Kaniūkai",
        "Birutė",
        "Centras",
        "Dainava",
        "Domeikava",
        "Eiguliai",
        "Freda",
        "Garliava",
        "Gričiupis",
        "Kalniečiai",
        "Kazlinskai",
        "Kleboniškis",
        "Lampėdžiai",
        "Marvelė",
        "Murava",
        "Naujasodis",
        "Naugardiškė",
        "Noreikiškės",
        "Palemonas",
        "Panemunė",
        "Panerys",
        "Petrašiūnai",
        "Raudondvaris",
        "Rokai",
        "Romainiai",
        "Sargėnai",
        "Senamiestis",
        "Smėliai",
        "Šančiai",
        "Šilainiai",
        "Vaišvydava",
        "Versvai",
        "Vičiūnai",
        "Vilijampolė",
        "Vytėnai",
        "Žaliakalnis",
        "Žemieji Šančiai",
        "Žemutiniai Kaniūkai",
    ],
    Klaipėda: [
        "Alksnynė",
        "Baltija",
        "Bandužiai",
        "Birutė",
        "Centras",
        "Debrecenas",
        "Gandrališkės",
        "Gedminai",
        "Giruliai",
        "Kaunas",
        "Jūrininkai",
        "Labrenciškės",
        "Laukininkai",
        "Liepoja",
        "Lypkiai",
        "Luizė",
        "Mažasis kaimelis",
        "Melnragė",
        "Miškas",
        "Mokykla",
        "Naujakiemis",
        "Naujamiestis",
        "Paupiai",
        "Plytinė",
        "Poilsis",
        "Pušynas",
        "Rimkai",
        "Rumpiškės",
        "Senamiestis",
        "Sendvaris",
        "Smeltė",
        "Smiltynė",
        "Sportininkai",
        "Šauliai",
        "Tauralaukis",
        "Trekas",
        "Varpai",
        "Vėtrungė",
        "Vingis",
        "Žardininkai",
        "Žardė",
        "Žvejybos uostas",
    ],
    Panevėžys: [
        "Centras",
        "Klaipėdos",
        "Kniaudiškiai",
        "Molainiai",
        "Pilėnai",
        "Pramonės",
        "Rožės",
        "Skaistakalnis",
        "Stetiškiai",
        "Tulpės",
        "Vaivados",
        "Žemaičiai",
    ],
    Alytus: [
        "Dainava",
        "Likiškėliai",
        "Likiškiai",
        "Pirmas Alytus",
        "Putinai",
        "Senamiestis",
        "Vidzgiris",
    ],
};
export default DISTRICTS;
