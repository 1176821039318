import { SeoPageData } from "@FEShared/types/common";
import { DISTRICTS_URL_PREFIX } from "@Shared/consts/FECLIENT_DISTRICT_SEO_PAGES";
import FECLIENT_SERVICES_SEO_PAGES from "@Shared/consts/FECLIENT_SERVICES_SEO_PAGES";
import FECLIENT_DISTRICT_SEO_PAGES from "@Shared/consts/FECLIENT_DISTRICT_SEO_PAGES";

import capFirst from "@Shared/util/capFirst";
import FECLIENT_BRANDS_SEO_PAGES, {
    BRANDS_URL_PREFIX,
} from "@Shared/consts/FECLIENT_BRANDS_SEO_PAGES";
import FECLIENT_ALL_SERVICES_SEO_PAGES from "@Shared/consts/FECLIENT_ALL_SERVICES_SEO_PAGES";

type SeoType = "DISTRICT" | "BRAND" | "SERVICE";
type SeoData = SeoPageData & { type: SeoType };

export function getSeoPageData(): SeoData | undefined {
    const urlSlug = window.location.pathname;

    if (window.location.pathname.includes(DISTRICTS_URL_PREFIX)) {
        const seoPageData = FECLIENT_DISTRICT_SEO_PAGES[urlSlug] as
            | undefined
            | SeoPageData;

        return seoPageData ? { ...seoPageData, type: "DISTRICT" } : undefined;
    } else if (window.location.pathname.includes(BRANDS_URL_PREFIX)) {
        const seoPageData = FECLIENT_BRANDS_SEO_PAGES[urlSlug] as
            | undefined
            | SeoPageData;

        return seoPageData
            ? {
                  ...seoPageData,
                  type: "BRAND", // ah would have been neater if this was added in the actual const files, like FECLIENT_BRANDS_SEO_PAGES
              }
            : undefined;
    } else {
        const seoPageData =
            FECLIENT_SERVICES_SEO_PAGES[urlSlug] ||
            (FECLIENT_ALL_SERVICES_SEO_PAGES[urlSlug] as
                | undefined
                | SeoPageData);

        if (seoPageData) {
            seoPageData.faq.map((faq) => {
                faq.question = capFirst(
                    faq.question
                        .replace(/ {carModel}/g, "")
                        .replace(/{carModel}/g, "")
                        .replace(/{serviceName}/g, seoPageData.seoServiceName)
                );

                faq.answer = capFirst(
                    faq.answer
                        .replace(/ {carModel}/g, "")
                        .replace(/{carModel}/g, "")
                        .replace(/{serviceName}/g, seoPageData.seoServiceName)
                );

                return faq;
            });
        }

        return seoPageData
            ? {
                  ...seoPageData,
                  type: "SERVICE",
              }
            : undefined;
    }
}
