import React from "react";
import ExpandedMapPoint from "./ExpandedMapPoint/ExpandedMapPoint";
import CollapsedMapPoint from "./CollapsedMapPoint/CollapsedMapPoint";
import { runInAction } from "mobx";
import useStore from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";
import * as S from "./MapPoint.styled";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import { WorkshopOmitted } from "../../../Search.types";
import isWorkshopSpecialized from "@FEClient/logic/utils/isWorkshopSpecialized";

interface P {
    mapItem: WorkshopOmitted;
    priceRanges: {
        oneDollarRange: number;
        twoDollarRange: number;
        threeDollarRange: number;
    };
}
const MapPoint: React.FC<P> = observer((p) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const GS = useStore();
    const isMobile = useIsMobile();
    const specialization = isWorkshopSpecialized(p.mapItem, {
        vehicleBrand: GS.searchState.carData.vehicleBrand,
        servicesIDs: GS.searchState.selectedServiceIds,
    });
    const handleOnOpen = React.useCallback(
        () =>
            runInAction(() => {
                GS.searchPageState.selectedServiceId = p.mapItem.ID;
            }),
        [GS, p.mapItem.ID]
    );
    const handleOnClose = React.useCallback(
        () =>
            runInAction(() => {
                GS.searchPageState.selectedServiceId = undefined;
            }),
        [GS]
    );

    const review =
        p.mapItem.reviewCountGmap && p.mapItem.reviewRatingGmap
            ? {
                  count: p.mapItem.reviewCountGmap,
                  rating: p.mapItem.reviewRatingGmap,
              }
            : undefined;

    return (
        <S.MapPointContainer ref={containerRef}>
            {!isMobile /* We don't want to see this on mobile as we use `MobileServiceSelection` on mobile.*/ &&
                GS.searchPageState.selectedServiceId === p.mapItem.ID && (
                    <ExpandedMapPoint
                        mapItem={p.mapItem}
                        handleClose={handleOnClose}
                        specializedString={specialization.displayString}
                    />
                )}
            <CollapsedMapPoint
                workshop={p.mapItem}
                price={p.mapItem.hourCost}
                review={review}
                onClick={handleOnOpen}
                priceRanges={p.priceRanges}
                specialized={specialization.isSpecializedForUser}
            />
        </S.MapPointContainer>
    );
});

export default MapPoint;
