window._IS_PROD = window.location.host.includes("nevaziuoja.lt"); // should be typed and moved into seperate file `setupGlobals.ts` later

export {}; // hack to prevent ts error below;
declare global {
    interface Window {
        _IS_PROD: boolean;
        // all below are optional because of adblockers
        hj?: (...arguments: any[]) => void;
        fbq?: (...arguments: any[]) => void;
        gtag?: (...arguments: any[]) => void;
        _chatlio?: Record<string, (...arguments: any[]) => void>;
    }
}
