import React from "react";
import * as WS from "../Workshop.styled";
import Box from "@FEShared/components/UI/Box/Box";
import AmenityRow from "./AmenityRow/AmenityRow";
import { WorkshopDTO } from "../Workshop.types";
import { GROUPED_AMENITIES } from "./AmenitiesSection.const";
import lOrderBy from "lodash/orderBy";

const AmenitiesSection: React.FC<{
    selectedWorkshop: WorkshopDTO;
}> = (p) => {
    if (p.selectedWorkshop.amenities.length === 0) return null;

    const sortedRows = lOrderBy(
        GROUPED_AMENITIES,
        (g) =>
            g.amenities.some((a) => p.selectedWorkshop.amenities.includes(a)),
        ["desc"]
    );

    return (
        <WS.WorkshopSection $marginBottom={32}>
            <WS.ServicesTitleWrap>
                <WS.PurpleVerticalBar />
                <WS.ServicesTitleSubWrap>
                    {/* <WS.ServicesSubTitle>
                                Ieškai daugiau?
                            </WS.ServicesSubTitle> */}
                    <WS.ServicesTitle>Patogumai</WS.ServicesTitle>
                </WS.ServicesTitleSubWrap>
            </WS.ServicesTitleWrap>
            <Box displayFlex vertical>
                {sortedRows.map((g) => (
                    <AmenityRow
                        key={g.amenities.join("-")}
                        leftText={g.leftText}
                        iconClass={g.iconClass}
                        amenities={g.amenities}
                        enabledAmenities={p.selectedWorkshop.amenities}
                    />
                ))}
            </Box>
        </WS.WorkshopSection>
    );
};

export default AmenitiesSection;
