import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import * as S from "./ReviewsSection.styled";
import _orderBy from "lodash/orderBy";
import StarsComponent from "@FEClient/views/commonComps/StarsComponent/StarsComponent";
import { runInAction } from "mobx";
import { timeAgo } from "../Workshop.utils";
import * as WS from "../Workshop.styled";
import { WorkshopDTO } from "../Workshop.types";
import Box from "@FEShared/components/UI/Box/Box";
import { OrderReview } from "@FEShared/graphql/generated/graphql";
import formatVehicleDescription from "@Shared/util/formatVehicleDescription";
import Text from "@FEShared/components/UI/Text/Text";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import Tooltip from "@mui/material/Tooltip";
import formatReviewName from "@Shared/util/formatReviewName";

const ReviewsSection: React.FC<{ selectedWorkshop: WorkshopDTO }> = observer(
    (p) => {
        const LS = useLocalObservable(() => ({
            showReviewsMultiplier: 1,
            get showingReviewsCount() {
                return LS.showReviewsMultiplier * 5;
            },
        }));
        const isMobile = useIsMobile();

        const MAX_SERVICES_TO_SHOW = isMobile ? 2 : 6;

        const reviews =
            p.selectedWorkshop.orderReviews &&
            p.selectedWorkshop.orderReviews.length > 0
                ? p.selectedWorkshop.orderReviews
                : [
                      {
                          authorName: "",
                          rating: 5,
                          createdAt: new Date(
                              new Date().getTime() - 31556926000
                          ),
                      } as OrderReview,
                  ];

        return (
            <WS.ReviewsSection id="reviews">
                <WS.ServicesTitleWrap>
                    <WS.PurpleVerticalBar />
                    <WS.ServicesTitleSubWrap>
                        <WS.ServicesTitle>
                            Autoserviso įvertinimas
                        </WS.ServicesTitle>
                        <WS.Rating>
                            <WS.RatingScore $fontSize="24px">
                                {(
                                    p.selectedWorkshop.reviewRatingGmap || 5
                                ).toFixed(1)}{" "}
                            </WS.RatingScore>
                            <StarsComponent
                                rating={
                                    p.selectedWorkshop.reviewRatingGmap || 5
                                }
                                className="workshop-page-small"
                            />
                            <WS.RatingCount>
                                {p.selectedWorkshop.orderReviews?.length || 1}{" "}
                                atsiliepimai
                            </WS.RatingCount>
                        </WS.Rating>
                    </WS.ServicesTitleSubWrap>
                </WS.ServicesTitleWrap>
                {reviews.slice(0, LS.showingReviewsCount).map((review, i) => {
                    const vehicleDescr =
                        review.vehicle &&
                        formatVehicleDescription({
                            vehicleBrand: review.vehicle.brand,
                            vehicleModel: review.vehicle.model,
                            vehicleYear: review.vehicle.year,
                        });

                    return (
                        <WS.ReviewRow key={i}>
                            <WS.ReviewLeftSide>
                                <Box
                                    displayFlex
                                    width={1}
                                    alignVertical="center"
                                >
                                    <StarsComponent rating={review.rating} />
                                    <WS.ReviewTimeAgo>
                                        {timeAgo(review.createdAt)}
                                    </WS.ReviewTimeAgo>
                                </Box>
                                <WS.ReviewText>{review.text}</WS.ReviewText>
                                {review.serviceNames &&
                                    review.serviceNames.length > 0 && (
                                        <WS.ReviewServiceTagWrap>
                                            {!review.authorName &&
                                                !vehicleDescr && (
                                                    <Text
                                                        component="i"
                                                        className="icon-checkmark-circle"
                                                        fontSize={14}
                                                        color="primary"
                                                        mr={0.5}
                                                    />
                                                )}
                                            {review.serviceNames
                                                .slice(0, MAX_SERVICES_TO_SHOW)
                                                .map((service) => (
                                                    <WS.ReviewServiceTag
                                                        key={service}
                                                    >
                                                        {service}
                                                    </WS.ReviewServiceTag>
                                                ))}
                                            {review.serviceNames.length >
                                                MAX_SERVICES_TO_SHOW && (
                                                <Tooltip
                                                    enterTouchDelay={0}
                                                    title={review.serviceNames
                                                        .slice(
                                                            MAX_SERVICES_TO_SHOW
                                                        )
                                                        .join(", ")}
                                                >
                                                    <WS.ReviewServiceTag>
                                                        +
                                                        {review.serviceNames
                                                            .length -
                                                            MAX_SERVICES_TO_SHOW}
                                                    </WS.ReviewServiceTag>
                                                </Tooltip>
                                            )}
                                        </WS.ReviewServiceTagWrap>
                                    )}
                                <Box displayFlex alignVertical="center">
                                    {(review.authorName || vehicleDescr) && (
                                        <Text
                                            component="i"
                                            className="icon-checkmark-circle"
                                            fontSize={14}
                                            color="primary"
                                            mr={0.5}
                                        />
                                    )}
                                    {review.authorName && (
                                        <Text color="primary">
                                            {formatReviewName(
                                                review.authorName
                                            )}
                                        </Text>
                                    )}
                                    {vehicleDescr && (
                                        <>
                                            {review.authorName &&
                                                vehicleDescr && (
                                                    <Text
                                                        ml={1}
                                                        mr={1}
                                                        color="#BBBED1"
                                                    >
                                                        •
                                                    </Text>
                                                )}
                                            <Text color="#7B7F93">
                                                {vehicleDescr}
                                            </Text>
                                        </>
                                    )}
                                </Box>
                            </WS.ReviewLeftSide>
                        </WS.ReviewRow>
                    );
                })}
                {p.selectedWorkshop.orderReviews &&
                    p.selectedWorkshop.orderReviews.length >
                        LS.showingReviewsCount && (
                        <Box component="a" role="button" rel="next">
                            <S.ReviewTextButton
                                variant="text"
                                onClick={() => {
                                    runInAction(() => {
                                        LS.showReviewsMultiplier++;
                                    });
                                }}
                            >
                                Rodyti daugiau atsilepimų...
                            </S.ReviewTextButton>
                        </Box>
                    )}
            </WS.ReviewsSection>
        );
    }
);
export default ReviewsSection;
