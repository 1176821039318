import useStore from "@FEClient/logic/store";
import formatVehicleDescription from "@Shared/util/formatVehicleDescription";
import { observer } from "mobx-react-lite";
import React from "react";
import * as S from "./Search.styled";
import SearchMap from "./SearchMap/SearchMap";
import SearchSidebar from "./SearchSidebar/SearchSidebar";
import { UrlPathnameParser } from "./Search.logic";
import { runInAction } from "mobx";
import { useInitiateSearchMutation } from "@FEShared/graphql/generated/graphql";
import showToast from "@FEShared/utils/showToast";
import useInitSearchFormInfo from "@FEClient/logic/hooks/useInitSearchFormInto/useInitSearchFormInfo";
import { Helmet } from "react-helmet";
import { WHERE_CITY } from "@FEShared/consts/CITIES";
import lRemove from "lodash/remove";
import latinize from "latinize";
import capFirst from "@Shared/util/capFirst";

const Search: React.FC = observer((_p) => {
    const GS = useStore();
    const SPS = GS.searchPageState;
    const [initiateSearchRes, initiateSearch] = useInitiateSearchMutation();
    const primarySearchResults = GS.searchPageState.primarySearchResults;
    useInitSearchFormInfo();

    React.useEffect(() => {
        runInAction(() => {
            // this is not the cleanest and most straightforward solution, but w.e for now.
            SPS.altFlow.order = undefined;
        });
    }, [SPS.altFlow]);

    React.useEffect(() => {
        /**
         * reset workshop page date picker, when user navigates back to search page, to handle the following scenario:
         * 1. user goes to a workshop, where earliest available time is 05-30 (but today is 05-15). Therefore we choose the default opened date to be 05-30
         * 2. user goes back to search page, and visits another workshop, where earliest date is 05-15. Therefore we should reset the date picker to 05-15.
         * - We don't want to simply reset `workshopPageState.date` everytime the page is opened, because we want it to stay the same when user navigates between workshop page & reservation conf page.
         */
        GS.workshopPageState.date.onChange({
            YYYY_MM_DD: undefined,
            HH_MM: undefined,
        });
    }, [GS.workshopPageState]);

    React.useEffect(() => {
        runInAction(() => {
            if (initiateSearchRes.fetching) {
                GS.searchPageState.loaders = ["SEARCH_LOADER", "FAKE_LOADER"];
            } else {
                lRemove(
                    GS.searchPageState.loaders,
                    (v) => v === "SEARCH_LOADER"
                );
                GS.searchPageState.loaders = [...GS.searchPageState.loaders];
            }
        });
    }, [initiateSearchRes, GS.searchPageState]);

    React.useEffect(() => {
        const pathname = location.pathname;
        const parsedParams = UrlPathnameParser.pathnameToParams(pathname);
        runInAction(() => {
            if (parsedParams.city) {
                GS.searchState.city = parsedParams.city;
            }
            if (parsedParams.selectedServiceName) {
                GS.searchState.selectedServicesNames = [
                    parsedParams.selectedServiceName,
                ];
            }
            if (parsedParams.carBrand) {
                GS.searchState.carDataArr = [parsedParams.carBrand];
            }
        });
        async function initSearch() {
            const res = await initiateSearch({
                params: GS.searchState.initiateSearchParams,
            });

            if (res.error) {
                return showToast.error(res.error.message);
            }

            runInAction(() => {
                if (res.data) {
                    GS.searchState.setInitateSearchRes(res.data.initiateSearch);
                } else {
                    showToast.error("no data");
                }
            });
        }
        initSearch();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const carBrand = GS.searchState.carData.vehicleBrand
        ? GS.searchState.carData.vehicleBrand
        : "TOP";

    const firstServiceName = GS.searchState.selectedServicesNames[0];
    const serviceNameWSpace = firstServiceName ? firstServiceName + " " : "";

    return (
        <S.SearchContainer id="search-container">
            <Helmet>
                <title>
                    {serviceNameWSpace
                        ? `${serviceNameWSpace} | 
                        ${carBrand} Autoservisai ${
                              WHERE_CITY[GS.searchState.city]
                          } | Kaina, 24/7, Garantija`
                        : `
                        ${carBrand} Autoservisai ${
                              WHERE_CITY[GS.searchState.city]
                          } | Kainos, 24/7, Garantija
                        `}
                </title>
                <meta
                    name="description"
                    content={
                        firstServiceName
                            ? `${firstServiceName} - Kaina, 24/7 Registracija, 100% Garantija. Lygink ${carBrand} servisus ${
                                  WHERE_CITY[GS.searchState.city]
                              } pagal kainą, užimtumą, atsiliepimus, vietą.`
                            : `Darbų Kainos, 24/7 Registracija, 100% Garantija. Lygink ${carBrand} servisus ${
                                  WHERE_CITY[GS.searchState.city]
                              } pagal kainą, užimtumą, atsiliepimus, vietą.`
                    }
                />
                <link
                    rel="canonical"
                    href={`https://nevaziuoja.lt${UrlPathnameParser.paramsToPathname(
                        {
                            city: GS.searchState.city,
                            carBrand: GS.searchState.carData.vehicleBrand,
                            selectedServiceName: firstServiceName,
                            selectedServiceID:
                                GS.searchState.selectedServiceIds[0],
                        }
                    )}`}
                />
                <script type="application/ld+json">
                    {`
                            {
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "numberOfItems": ${serviceNameWSpace ? 3 : 2},
                                "itemListElement": [
                                  {
                                    "@type": "ListItem",
                                    "position": 1,
                                    "item": {
                                      "@type": "WebPage",
                                      "@id": "https://nevaziuoja.lt/autoservisai",
                                      "name": "Autoservisai"
                                    }
                                  },
                                  {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "item": {
                                      "@type": "WebPage",
                                      "@id": "https://nevaziuoja.lt/autoservisai/${UrlPathnameParser.urlifyCity(
                                          GS.searchState.city
                                      )}",
                                      "name": "${capFirst(
                                          latinize(GS.searchState.city)
                                      )}"
                                    }
                                  }
                                  ${
                                      firstServiceName
                                          ? `,{
                                    "@type": "ListItem",
                                    "position": 3,
                                    "item": {
                                      "@type": "WebPage",
                                      "@id": "https://nevaziuoja.lt/autoservisai/${UrlPathnameParser.urlifyCity(
                                          GS.searchState.city
                                      )}/${UrlPathnameParser.urlifyService({
                                                serviceName: firstServiceName,
                                            })}",
                                      "name": "${capFirst(
                                          latinize(firstServiceName)
                                      )}"
                                    }
                                  }`
                                          : ""
                                  }
                                ]
                              }
                        `}
                </script>
            </Helmet>
            {/* <TopSearchBar /> */}
            <S.SidebarNMapWrapper>
                <SearchSidebar
                    isLoading={
                        !primarySearchResults || GS.searchPageState.isLoading
                    }
                    carDescrTitle={
                        primarySearchResults?.order
                            ? formatVehicleDescription(
                                  primarySearchResults.order
                              )
                            : ""
                    }
                    workshops={primarySearchResults?.services || []}
                />
                <SearchMap
                    city={GS.searchState.city}
                    mapItems={primarySearchResults?.services || []}
                />
            </S.SidebarNMapWrapper>
        </S.SearchContainer>
    );
});

export default Search;
