import { WorkshopService } from "@FEShared/graphql/generated/graphql";

export function minsToString(mins: number, isFromDuration?: boolean): string {
    const hours = Math.max(Math.round(mins / 30) / 2, 0.5);
    return `${hours}${isFromDuration ? "+" : ""} val.`;
}

// this should be remade probably to use function below and just add text.
export function getServiceDurationString(
    service: Pick<WorkshopService, "durationFromMins" | "durationToMins"> & {
        type: Pick<
            WorkshopService["type"],
            "durationMinsPerUnit" | "defaultUnitCount"
        >;
    }
): string {
    if (
        service.durationFromMins &&
        service.durationToMins &&
        service.durationFromMins === service.durationToMins
    ) {
        return `${minsToString(
            service.durationFromMins * service.type.defaultUnitCount
        )}`;
    } else if (service.durationFromMins && service.durationToMins) {
        const avgDuration = Math.floor(
            (service.durationFromMins + service.durationToMins / 2) *
                service.type.defaultUnitCount
        );
        return `${minsToString(avgDuration)}`;
    } else if (service.durationFromMins) {
        return `${minsToString(
            service.durationFromMins * service.type.defaultUnitCount,
            true
        )}`;
    } else if (service.durationToMins) {
        // probably should almost never happen
        return `iki ${minsToString(
            service.durationToMins * service.type.defaultUnitCount
        )}`;
    } else if (service.type.durationMinsPerUnit) {
        return `${minsToString(
            service.type.durationMinsPerUnit * service.type.defaultUnitCount,
            true
        )}`;
    } else {
        return "";
    }
}

export default function getServiceDurationMins(
    service: Pick<WorkshopService, "durationFromMins" | "durationToMins"> & {
        type: Pick<
            WorkshopService["type"],
            "durationMinsPerUnit" | "defaultUnitCount"
        >;
    }
): number {
    if (
        service.durationFromMins &&
        service.durationToMins &&
        service.durationFromMins === service.durationToMins
    ) {
        return service.durationFromMins * service.type.defaultUnitCount;
    } else if (service.durationFromMins && service.durationToMins) {
        return service.durationFromMins * service.type.defaultUnitCount;
    } else if (service.durationFromMins) {
        return service.durationFromMins * service.type.defaultUnitCount;
    } else if (service.durationToMins) {
        return service.durationToMins * service.type.defaultUnitCount;
    } else {
        return service.type.durationMinsPerUnit * service.type.defaultUnitCount;
    }
}
