import React from "react";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import Button from "@FEShared/components/UI/Button/Button";
import { OrderReviewSummaryQuery } from "@FEShared/graphql/generated/graphql";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { observer, useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import NumberInput from "@FEShared/components/UI/NumberInput/NumberInput";

const CompletionSumStep: React.FC<{
    order: OrderReviewSummaryQuery["order"];
    onBtnClick: () => void;
    goodPrice?: boolean;
    onChange: (value: boolean) => void;
    onNewPriceChange: (num?: number) => void;
    clientProvidedPrice?: number;
}> = observer((p) => {
    const LS = useLocalObservable(() => ({
        forceError: false,
        error: undefined as undefined | "NO_OPTION" | "NO_PRICE",
    }));

    return (
        <Box>
            <Box>
                <Text fontSize="48px" semiBold span>
                    €{p.order.paymentSum}
                </Text>
                <Text span ml={1} variant="subtitle1">
                    galutinė suma (su PVM)
                </Text>
            </Box>
            <Box displayFlex vertical>
                <Text mr="12px">Šiai sumai bus suteikta garantija.</Text>
                <FormControl sx={{ mt: 3 }}>
                    <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        sx={{ color: "black!important", fontWeight: 500 }}
                    >
                        Sumokėta suma autoservisui už visas suteiktas paslaugas
                        (įskaitant paslaugas kurių nesirinkote registracijos
                        metu) ir detales/medžiagas, su PVM:
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={p.goodPrice}
                        onChange={(e) => {
                            p.onChange(e.target.value === "true");
                        }}
                    >
                        <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Neteisinga"
                        />
                        {p.goodPrice === false && (
                            <NumberInput
                                leftIconClass="icon-eur"
                                error={!p.clientProvidedPrice}
                                forceError={LS.forceError}
                                helperText={
                                    "Įveskite sumą kurią sumokėjote autoservisui"
                                }
                                value={p.clientProvidedPrice}
                                placeholder="Suma kurią sumokėjote autoservisui"
                                onNumChange={(num) => p.onNewPriceChange(num)}
                            />
                        )}

                        <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Teisinga"
                        />
                    </RadioGroup>
                </FormControl>
                {LS.error === "NO_OPTION" && p.goodPrice === undefined && (
                    <Text color="error" fontSize={12}>
                        Patvirtinkite ar kaina yra teisinga
                    </Text>
                )}
                <Button
                    sx={{ minWidth: 240, mt: 1 }}
                    onClick={async () => {
                        runInAction(() => {
                            LS.forceError = true;
                        });
                        if (p.goodPrice === undefined) {
                            runInAction(() => {
                                LS.error = "NO_OPTION";
                            });
                            return;
                        }
                        if (
                            p.goodPrice === false &&
                            p.clientProvidedPrice === undefined
                        ) {
                            runInAction(() => {
                                LS.error = "NO_PRICE";
                            });
                            return;
                        }

                        p.onBtnClick();
                    }}
                >
                    Tęsti
                </Button>
            </Box>
        </Box>
    );
});

export default CompletionSumStep;
