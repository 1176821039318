import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import React from "react";
import * as S from "./FAQSection.styled";
import useStore from "@FEClient/logic/store";
import { WorkshopDTO } from "../Workshop.types";
import { CarDataObj } from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.types";
import formatVehicleDescription from "@Shared/util/formatVehicleDescription";
import { OTHER_BRAND } from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.consts";

export function formQuestions(p: {
    fixedCarBrands: string[];
    carDataObj?: Partial<CarDataObj>;
}) {
    const QUESTIONS = [
        {
            question:
                "Kada galite mane priimti automobilio remontui/aptarnavimams?",
            answer: 'Artimiausią laisvą laiką vizitui galite pasirinkti sekcijoje "Laisvi laikai registracijai". Anksčiausias matomas laikas yra artimiausias laikas, kada autoservisas gali tave priimti.',
        },
        {
            question: "Kiek kainuos automobilio remontas/darbai?",
            answer: "Visos prie paslaugų matomos kainos yra nurodytos pačio autoserviso (neįskaičiuojant dalių ar medžiagų). Tikslesnę kainą įmanoma nustatyti tik meistrui apžiūrėjus automobilį ir įvertinus jo stovį, bei remonto sudėtingumą.",
        },
        {
            question: `Ar remontuojate ${
                p.carDataObj?.vehicleBrand
                    ? formatVehicleDescription(p.carDataObj)
                    : "mano"
            } automobilį?`,
            answer: [
                p.carDataObj?.vehicleBrand
                    ? p.fixedCarBrands.includes(p.carDataObj?.vehicleBrand)
                        ? `Taip, remontuojame šį automobilį.`
                        : `Deja, šio automobilio neremontuojame.`
                    : undefined,
                `Autoservisas remontuoja šias automobilių markes: ${p.fixedCarBrands
                    .filter((b) => b !== OTHER_BRAND)
                    .join(", ")}`,
            ]
                .filter(Boolean)
                .join(" "),
        },
        {
            question: "Kiek laiko užtruks darbai?",
            answer: "Preliminari remontų trukmė nurodyta prie kiekvienos paslaugos. Tiksliau remonto trukmę įmanoma pasakyti tik meistrui apžiūrėjus automobilį ir įvertinus jo stovį, bei remonto sudėtingumą.",
        },
    ];
    return QUESTIONS;
}

const FAQSection: React.FC<{ selectedWorkshop: WorkshopDTO }> = (p) => {
    const GS = useStore();
    const questions = formQuestions({
        fixedCarBrands: p.selectedWorkshop.servicedBrands,
        carDataObj: GS.searchState.carData,
    });
    return (
        <Box pt={3}>
            <Text variant="h2" mb={3}>
                Dažnai užduodami klausimai
            </Text>
            {questions.map((q) => (
                <S.QAccordion
                    arrowColor={"black"}
                    answerColor={"#7B7F93"}
                    key={q.question}
                    question={q.question}
                    answer={q.answer}
                />
            ))}
        </Box>
    );
};

export default FAQSection;
