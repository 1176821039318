import React from "react";
import * as S from "./Footer.styled";
import { Page, pageToMeta } from "@Shared/util/clientPagesMeta";
import { UrlPathnameParser } from "@FEClient/views/pages/Search/Search.logic";
import useStore from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";
import FECLIENT_SERVICES_SEO_PAGES from "@Shared/consts/FECLIENT_SERVICES_SEO_PAGES";
import FECLIENT_BRANDS_SEO_PAGES from "@Shared/consts/FECLIENT_BRANDS_SEO_PAGES";
import lOrderBy from "lodash/orderBy";

const LINKS_TO_SHOW = 15;

const Footer: React.FC<{}> = observer((_p) => {
    const GS = useStore();

    const servicesSeoData = Object.entries(FECLIENT_SERVICES_SEO_PAGES)
        .filter(([url, seoData]) => seoData.footer)
        .slice(0, LINKS_TO_SHOW);

    const brandsSeoData = lOrderBy(
        Object.entries(FECLIENT_BRANDS_SEO_PAGES),
        ([_brand, seoData]) => seoData.sortOrder,
        "desc"
    ).slice(0, LINKS_TO_SHOW);

    return (
        <S.FooterContainer>
            <S.FooterContentContainer>
                <S.SocialMediaRow>
                    <S.IconLink
                        target="_blank"
                        aria-label="nevaziuoja-facebook"
                        href="https://facebook.com/nevaziuoja.lt"
                    >
                        <S.SocialMediaIcon className="icon-facebook" />
                    </S.IconLink>
                    <S.IconLink
                        target="_blank"
                        aria-label="nevaziuoja-instagram"
                        href="https://www.instagram.com/nevaziuoja.lt/"
                    >
                        <S.SocialMediaIcon className="icon-instagram" />
                    </S.IconLink>
                </S.SocialMediaRow>
                <S.FooterLogo className="icon-full-logo" />
                <S.FooterLinksWrapper>
                    <S.ColumnWrapper>
                        <S.ColumnTitle>
                            Automobilio remontas visoje Lietuvoje
                        </S.ColumnTitle>
                        <S.ImportantLink
                            href={UrlPathnameParser.paramsToPathname({
                                city: "Vilnius",
                            })}
                        >
                            Autoservisai Vilniuje
                        </S.ImportantLink>
                        <S.ImportantLink
                            href={UrlPathnameParser.paramsToPathname({
                                city: "Kaunas",
                            })}
                        >
                            Autoservisai Kaune
                        </S.ImportantLink>
                        <S.ImportantLink
                            href={UrlPathnameParser.paramsToPathname({
                                city: "Klaipėda",
                            })}
                        >
                            Autoservisai Klaipėdoje
                        </S.ImportantLink>
                        <S.ImportantLink
                            href={UrlPathnameParser.paramsToPathname({
                                city: "Šiauliai",
                            })}
                        >
                            Autoservisai Šiauliuose
                        </S.ImportantLink>
                        <S.ImportantLink
                            href={UrlPathnameParser.paramsToPathname({
                                city: "Panevėžys",
                            })}
                        >
                            Autoservisai Panevėžyje
                        </S.ImportantLink>
                        <S.ImportantLink
                            href={UrlPathnameParser.paramsToPathname({
                                city: "Alytus",
                            })}
                        >
                            Autoservisai Alytuje
                        </S.ImportantLink>
                    </S.ColumnWrapper>
                    <S.ColumnWrapper>
                        <S.ColumnTitle>
                            Populiarios automobilių remonto paslaugos
                        </S.ColumnTitle>
                        {servicesSeoData.map(([url, seoData]) => {
                            return (
                                <S.ImportantLink href={url} key={url}>
                                    {seoData.seoServiceName}
                                </S.ImportantLink>
                            );
                        })}
                    </S.ColumnWrapper>
                    <S.ColumnWrapper>
                        <S.ColumnTitle>
                            Populiarių markių servisai
                        </S.ColumnTitle>
                        {brandsSeoData.map(([url, seoData]) => {
                            return (
                                <S.ImportantLink href={url} key={url}>
                                    {seoData.seoServiceName}
                                </S.ImportantLink>
                            );
                        })}
                    </S.ColumnWrapper>
                </S.FooterLinksWrapper>
                <S.FooterLinksWrapper mt={2}>
                    <S.ColumnWrapper>
                        <S.ColumnTitle>Kitos nuorodos</S.ColumnTitle>
                        <S.ExternalLink href={"https://nevaziuoja.lt/blog"}>
                            {`Blog'as`}
                        </S.ExternalLink>
                        <S.UnimportantLink href={pageToMeta.WARRANTY.url}>
                            Techninė garantija
                        </S.UnimportantLink>
                        <S.UnimportantLink href={pageToMeta.PARTNERS.url}>
                            Partneriai
                        </S.UnimportantLink>
                        <S.UnimportantLink href={pageToMeta.ABOUT_US.url}>
                            Apie Mus
                        </S.UnimportantLink>
                        <S.UnimportantLink href={pageToMeta.SITEMAP.url}>
                            Svetainės struktūra
                        </S.UnimportantLink>
                        <S.UnimportantLink href={pageToMeta.SEO_SERVICES.url}>
                            Automobilių serviso paslaugos
                        </S.UnimportantLink>
                        <S.UnimportantLink href={pageToMeta.PRIVACY_POLICY.url}>
                            Privatumo politika
                        </S.UnimportantLink>
                        <S.UnimportantLink
                            href={pageToMeta.TERMS_OF_SERVICE.url}
                        >
                            Paslaugų teikimo sąlygos
                        </S.UnimportantLink>
                    </S.ColumnWrapper>
                    <S.ColumnWrapper>
                        <S.ColumnTitle>Kontaktai</S.ColumnTitle>
                        <S.ContactRow>
                            <S.ContactIcon className="icon-card-info" />
                            MB Nevažiuoja, i.k.: 306066889
                        </S.ContactRow>
                        <S.ContactRow>
                            <S.ContactIcon className="icon-email" />
                            <a
                                color="white.main"
                                href="mailto:labas@nevaziuoja.lt"
                            >
                                labas@nevaziuoja.lt
                            </a>
                        </S.ContactRow>
                        {GS.activePage !== Page.WORKSHOP && (
                            <S.ContactRow>
                                <S.ContactIcon className="icon-phone" />
                                <a color="white.main" href="tel:+37067720241">
                                    +370 677 20241
                                </a>
                            </S.ContactRow>
                        )}
                    </S.ColumnWrapper>
                    {/* <S.ColumnWrapper>
                        <S.ColumnTitle>Partneriai</S.ColumnTitle>{" "}
                        <a
                            href={"https://melga.lt"}
                            rel="nofollow"
                            style={{
                                color: "white",
                                fontWeight: 300,
                            }}
                        >
                            Melga
                        </a>
                    </S.ColumnWrapper> */}
                </S.FooterLinksWrapper>
            </S.FooterContentContainer>
        </S.FooterContainer>
    );
});

export default Footer;
