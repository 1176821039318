import { styled, css } from "@mui/material/styles";
import InternalLink from "@FEShared/components/InternalLink/InternalLink";
import { Page } from "@Shared/util/clientPagesMeta";

export const NavBarContainer = styled("div")<{ $page?: Page }>`
    display: flex;
    margin-left: auto;

    ${(p) =>
        p.$page === Page.SEARCH &&
        css`
            align-items: center;
            font-size: 16px;
        `}
`;

export const NavBarLink = styled(InternalLink)<{
    $border?: boolean;
}>`
    margin-right: 60px;
    font-weight: 500;
    color: inherit;
    vertical-align: middle;
    display: flex;
    align-items: center;

    &:last-child {
        margin-right: 0;
    }

    ${(p) =>
        p.$border &&
        css`
            border: 1px solid ${(p) => p.theme.palette.primary.main};
            border-radius: ${(p) => p.theme.shape.borderRadius}px;
            padding: 8px;
        `}

    ${(p) => p.theme.breakpoints.down("sm")} {
        margin-right: 15px;
    }
`;
