import React from "react";
import * as S from "./TopSearchBar.styled";
import useStore from "@FEClient/logic/store";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import _remove from "lodash/remove";
import SearchInputs, {
    CITY_DROPDOWN_CLASSNAME,
} from "../../Home/SearchForm/SearchInputs/SearchInputs";
import formatVehicleDescription from "@Shared/util/formatVehicleDescription";
import { useInitiateSearchMutation } from "@FEShared/graphql/generated/graphql";
import showToast from "@FEShared/utils/showToast";
import { AUTOCOMPLETE_WITH_MODAL_CLASS_NAME } from "@FEShared/components/UI/AutocompleteWithModal/AutocompleteWithModal";
import { EXPANSIVE_POPPER_CLASSNAME_IDENTIFIER } from "@FEShared/components/UI/ExpansivePopper/ExpansivePopper";
import { TEST_WORKSHOP_TOKEN } from "@Shared/consts/commonConsts";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";
import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "styled-components";
import { useSearchInputsMobileMode } from "./TopSearchBar.utils";
import Text from "@FEShared/components/UI/Text/Text";
import lRemove from "lodash/remove";
import Box from "@FEShared/components/UI/Box/Box";

export const TOP_SEARCH_BAR_CLASSNAME_IDENTIFIER = "TOP_SEARCH_BAR";

const TopSearchBar: React.FC<{}> = observer(() => {
    const GS = useStore();
    const isSearchInputsMobileMode = useSearchInputsMobileMode();
    const carData = GS.searchState.carData;
    const [searchResult, initiateSearch] = useInitiateSearchMutation();
    const lastInitiateSearchProps = React.useRef(
        GS.searchState
            .initiateSearchParams as typeof GS.searchState.initiateSearchParams
    );
    const theme = useTheme();

    const onSelectionsWrapperClick = React.useCallback(() => {
        runInAction(() => {
            GS.searchPageState.isSearchInputsExpanded = true;
        });
    }, [GS.searchPageState]);

    const handleClickOutside = React.useCallback(
        (event: MouseEvent | TouchEvent) => {
            if (!isSearchInputsMobileMode) return;

            if (!GS.searchPageState.isSearchInputsExpanded) return;
            const eventTarget = event.target as HTMLElement;

            if (
                !eventTarget.closest(
                    `.${TOP_SEARCH_BAR_CLASSNAME_IDENTIFIER}`
                ) &&
                !eventTarget.closest(
                    `.${AUTOCOMPLETE_WITH_MODAL_CLASS_NAME}`
                ) &&
                !eventTarget.closest(
                    `.${EXPANSIVE_POPPER_CLASSNAME_IDENTIFIER}`
                ) &&
                !eventTarget.closest(`.${CITY_DROPDOWN_CLASSNAME}`)
            ) {
                runInAction(() => {
                    GS.searchPageState.isSearchInputsExpanded = false;
                });
            }
        },
        [GS.searchPageState, isSearchInputsMobileMode]
    );

    React.useEffect(() => {
        runInAction(() => {
            if (searchResult.fetching) {
                GS.searchPageState.loaders = ["SEARCH_LOADER", "FAKE_LOADER"];
            } else {
                lRemove(
                    GS.searchPageState.loaders,
                    (v) => v === "SEARCH_LOADER"
                );
                GS.searchPageState.loaders = [...GS.searchPageState.loaders];
            }
        });
    }, [searchResult, GS.searchPageState]);

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [handleClickOutside]);

    const onSearchInputClose = React.useCallback(async () => {
        const newInitiateSearchProps = GS.searchState.initiateSearchParams;

        if (
            JSON.stringify(newInitiateSearchProps) ===
            JSON.stringify(lastInitiateSearchProps.current)
        ) {
            return;
        }

        runInAction(() => {
            lastInitiateSearchProps.current = newInitiateSearchProps;
            GS.searchPageState.resetPosBackup();
        });

        const res = await initiateSearch({
            params: GS.searchState.initiateSearchParams,
        });

        if (res.error) {
            return showToast.error(res.error.message);
        }

        runInAction(() => {
            if (res.data) {
                const servicesToShow =
                    localStorage.getItem(
                        QUERY_PARAMS.TEST_MODE_ENABLE_QUERY_PARAM
                    ) === "true"
                        ? res.data.initiateSearch.services
                        : res.data.initiateSearch.services.filter(
                              (s) => !s.name.includes(TEST_WORKSHOP_TOKEN)
                          );

                GS.searchPageState.primarySearchResults = {
                    order: res.data.initiateSearch.order,
                    services: servicesToShow,
                };
            } else {
                showToast.error("no data");
            }
        });
    }, [
        GS.searchState.initiateSearchParams,
        initiateSearch,
        GS.searchPageState,
    ]);

    const TopSearchBarIndex = (
        <S.TopSearchBarContainer
            $open={GS.searchPageState.isSearchInputsExpanded}
            className={TOP_SEARCH_BAR_CLASSNAME_IDENTIFIER}
        >
            {GS.searchPageState.isSearchInputsExpanded ? (
                <SearchInputs
                    horizontal={!isSearchInputsMobileMode}
                    onClose={onSearchInputClose}
                />
            ) : (
                <S.SelectionsWrapper onClick={onSelectionsWrapperClick}>
                    <S.SelectionIcon className="icon-magnifier" />
                    <S.InfoWrapper>
                        <S.SelectedServices component="h1">
                            {GS.searchState.selectedServicesNames.length > 0
                                ? GS.searchState.selectedServicesNames.join(
                                      ", "
                                  )
                                : "Visos automobilių paslaugos"}
                        </S.SelectedServices>
                        <S.InfoBottomRow>
                            <S.TextIconWrapper mr={0.5}>
                                <Text
                                    component="i"
                                    className="icon-car"
                                    mr={0.5}
                                />
                                <S.SearchH2>
                                    {carData.vehicleBrand
                                        ? formatVehicleDescription({
                                              vehicleBrand:
                                                  carData.vehicleBrand,
                                              vehicleModel:
                                                  carData.vehicleModel,
                                              vehicleYear: carData.vehicleYear
                                                  ? +carData.vehicleYear
                                                  : undefined,
                                          })
                                        : "Visi automobiliai"}
                                </S.SearchH2>
                            </S.TextIconWrapper>
                            <S.TextIconWrapper mr={0.5}>
                                <Text
                                    component="i"
                                    className="icon-calendar"
                                    fontSize={12}
                                    mr={0.5}
                                />
                                <S.EllipsisText>
                                    {GS.searchState.date.formattedTimeString
                                        ? GS.searchState.date
                                              .formattedTimeString
                                        : "Data nesvarbu"}
                                </S.EllipsisText>
                            </S.TextIconWrapper>
                            <S.TextIconWrapper>
                                <Text
                                    component="i"
                                    className="icon-district"
                                    fontSize={12}
                                    mr={0.5}
                                />
                                <S.SearchH2>{GS.searchState.city}</S.SearchH2>
                            </S.TextIconWrapper>
                        </S.InfoBottomRow>
                    </S.InfoWrapper>
                </S.SelectionsWrapper>
            )}
        </S.TopSearchBarContainer>
    );

    return isSearchInputsMobileMode &&
        GS.searchPageState.isSearchInputsExpanded ? (
        <Backdrop open sx={{ zIndex: theme.zIndex.appBar + 1 }}>
            {TopSearchBarIndex}
        </Backdrop>
    ) : (
        TopSearchBarIndex
    );
});

export default TopSearchBar;
