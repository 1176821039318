import React from "react";
import SHIELD_IMG from "./assets/shield.png";
import { runInAction } from "mobx";
import useStore from "@FEClient/logic/store";
import * as S from "./WarrantyBox.styled";
import { SxProps } from "@mui/material";
import WarrantyModal from "@FEClient/views/pages/Warranty/WarrantyModal/WarrantyModal";
import { observer } from "mobx-react-lite";
import Box from "@FEShared/components/UI/Box/Box";

const WarrantyBox: React.FC<{
    sx?: SxProps;
    forceMobileFlow?: boolean;
}> = observer((p) => {
    const GS = useStore();

    return (
        <>
            <WarrantyModal
                isOpen={GS.workshopPageState.warrantyModalOpen}
                onClose={() =>
                    runInAction(() => {
                        GS.workshopPageState.warrantyModalOpen = false;
                    })
                }
            />
            <S.WarrantyBoxContainer
                $forceMobileFlow={p.forceMobileFlow}
                sx={p.sx}
                onClick={() =>
                    runInAction(() => {
                        GS.workshopPageState.warrantyModalOpen = true;
                    })
                }
            >
                <S.ShieldImg src={SHIELD_IMG} />
                <Box alignVertical="center" vertical>
                    <S.Title>100% Darbų garantija</S.Title>
                    <S.PointsWrapper>
                        <S.ListText>
                            <S.Icon className="icon-checkmark-circle" />6
                            mėnesiai arba 10,000 km
                        </S.ListText>
                        <S.ListText>
                            <S.Icon className="icon-checkmark-circle" />
                            Techninio advokato apsauga
                        </S.ListText>
                    </S.PointsWrapper>
                </Box>
            </S.WarrantyBoxContainer>
        </>
    );
});

export default WarrantyBox;
