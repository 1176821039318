import styled from "styled-components";
import { ContentContainer } from "../ContentContainer/ContentContainer.styled";
import IMG_TOP_LEFT_LINES from "@FEClient/commonAssets/img/bottom-right-lines.svg";
import IMG_BOTTOM_RIGHT_LINES from "@FEClient/commonAssets/img/bottom-right-lines.svg";

export const FinalizationContentContainer = styled(ContentContainer)`
    background: #ffffff;
    border-radius: 20px;
    max-height: 490px;
    height: 100%;
    display: flex;
    padding-top: 90px;
    padding-bottom: 120px;
    position: relative;
    z-index: 1;
    flex-direction: column;
    align-items: center;

    box-shadow: 0px 38px 53px rgba(171, 164, 190, 0.17),
        0px 19.0186px 26.5259px rgba(171, 164, 190, 0.129257),
        0px 11.4559px 15.9779px rgba(171, 164, 190, 0.11077),
        0px 7.34153px 10.2395px rgba(171, 164, 190, 0.0970521),
        0px 4.75819px 6.63642px rgba(171, 164, 190, 0.085),
        0px 2.99523px 4.17755px rgba(171, 164, 190, 0.0729479),
        0px 1.72094px 2.40026px rgba(171, 164, 190, 0.0592299),
        0px 0.757432px 1.05642px rgba(171, 164, 190, 0.0407434);
`;

export const Title = styled("div")`
    font-weight: 500;
    font-size: 52px;
    margin-bottom: 25px;
    text-align: center;
`;

export const Icon = styled("i")`
    margin-bottom: 50px;
`;

export const SuccessIcon = styled(Icon)`
    color: #64c584;
    font-size: 105px;
`;

export const FailureIcon = styled(Icon)`
    background: #ff5252;
    color: white;
    font-size: 70px;
    padding: 30px;
    border-radius: 100px;
`;

export const SuccessText = styled("div")`
    font-size: 20px;
    text-align: center;
    max-width: 60%;
`;

export const PersonImg = styled("img")`
    position: absolute;
    bottom: -138px;
    right: -99px;
`;

export const FinalizationPageContainer = styled("div")`
    padding-top: 170px;
    padding-bottom: 170px;
    position: relative;

    &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        background-image: url(${IMG_TOP_LEFT_LINES});
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        pointer-events: none;
    }

    &::after {
        content: " ";
        position: absolute;
        right: 0;
        bottom: 0;
        background-image: url(${IMG_BOTTOM_RIGHT_LINES});
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 100% 100%;
        pointer-events: none;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        ${FinalizationContentContainer} {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        ${Title} {
            font-size: 24px;
            font-weight: 400;
        }

        ${Icon} {
            margin-bottom: 34px;
        }

        ${SuccessIcon} {
            font-size: 64px;
        }

        ${FailureIcon} {
            font-size: 34px;
            padding: 15px;
        }

        ${PersonImg} {
            display: none;
        }
    }
`;
