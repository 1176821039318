import { WorkshopDTO } from "@FEClient/views/pages/Workshop/Workshop.types";

export default function isWorkshopSpecialized(
    workshop: Pick<WorkshopDTO, "specialized"> & {
        services: {
            type: {
                ID: string;
                serviceName: string;
            };
        }[];
    },
    userChosen: { vehicleBrand: string; servicesIDs: string[] }
): {
    isSpecializedForUser: boolean;
    carBrand?: string;
    servicesIDs: string[];
    displayString?: string;
} {
    const res = {
        carBrand: workshop.specialized.vehicleBrands.includes(
            userChosen.vehicleBrand
        )
            ? userChosen.vehicleBrand
            : undefined,
        servicesIDs: userChosen.servicesIDs.filter((userChosenServiceID) =>
            workshop.specialized.servicesIDs.includes(userChosenServiceID)
        ),
    };
    const isSpecializedForUser = !!res.carBrand || res.servicesIDs.length > 0;

    let displayString: string | undefined;
    if (isSpecializedForUser) {
        if (res.carBrand) {
            displayString = `Specializuojasi ${res.carBrand} remonte`;
        } else if (res.servicesIDs.length === 1) {
            const service = workshop.services.find(
                (s) => s.type.ID === res.servicesIDs[0]
            );
            if (service) {
                displayString = `Specializuojasi ${service.type.serviceName}`;
            }
        } else if (res.servicesIDs.length > 1) {
            displayString = "Specializuojasi pasirinktose paslaugose";
        }
    }

    return {
        ...res,
        isSpecializedForUser,
        displayString,
    };
}
