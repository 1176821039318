import { createGlobalStyle, css } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import isMacOS from "@FEShared/utils/isMacOS";

const CookieConsentStyles = css`
    :root {
        --cc-btn-primary-bg: #524ae3;
        --cc-btn-primary-hover-bg: #524ae3;
        --cc-text: black;
        --cc-block-text: black;
    }

    @media (min-width: 689px) {
        #s-bns {
            display: flex;
        }
        #s-all-bn {
            flex: 3;
        }
    }

    #c-p-bn {
        box-shadow: 0px 3px 5px rgba(82, 73, 235, 0.25);
    }
`;

const ToastifyStyles = css`
    .Toastify__toast-container {
        z-index: 9999999 !important;
    }
`;

// const Scroll = css`
//     /* width */
//     ::-webkit-scrollbar {
//         width: 12px;
//         height: 12px;
//     }

//     /* Handle */
//     ::-webkit-scrollbar-thumb {
//         border-radius: 10px;
//         background: #e0e0e0;
//         border-radius: 100px;
//     }
// `;

const MacOSScrollbar = css`
    ${isMacOS() &&
    css`
        body * {
            ::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }
            ::-webkit-scrollbar-thumb {
                background-color: #70757a;
            }

            ::-webkit-scrollbar-track {
                background-color: #f1f3f4;
            }
        }
    `}
`;

const GlobalStyles = createGlobalStyle<{ $disableScroll?: boolean }>`

    body {
        line-height:1.25;
    }

    h1 {
        line-height: 1;
    }

	a {
		text-decoration: none;
        color: black;
	}

    h1,h2,h3,h4,h5,h6 {
        margin: unset;
    }

    ${(p) =>
        p.$disableScroll &&
        css`
            body {
                overflow: hidden;
            }
        `}

    /* Remove arrows from number inputs for Webkit browsers like Chrome and Safari */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Remove arrows from number inputs for Mozilla Firefox */
    input[type="number"]::-moz-outer-spin-button,
    input[type="number"]::-moz-inner-spin-button {
        -moz-appearance: none;
        margin: 0;
    }

    /* Remove arrows from number inputs for MS Edge and Internet Explorer */
    input[type="number"]::-ms-clear {
        display: none;
    }

	${CookieConsentStyles};
    ${ToastifyStyles};
    ${MacOSScrollbar}

    .chatlio-widget {
        // we want this to be above header, but behind modals and etc.
        z-index: ${(p) => p.theme.zIndex.appBar + 1}!important;
    }

    .chatlio-widget-body {
        ${(p) => p.theme.breakpoints.down("md")} {
            height: 100%!important;
            left:0!important;
            border-radius:0!important;
        }
    }
`;

export default GlobalStyles;
