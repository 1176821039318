import React from "react";
import ContentContainer from "../../commonComps/ContentContainer/ContentContainer";

const PrivacyPolicy: React.FC = () => {
    return (
        <ContentContainer>
            <h1>Privatumo politika</h1>
            <p>
                Mes, juridinio asmens pavadinimas Nevažiuoja, MB, juridinio
                asmens kodas 306066889, adresas Krokuvos g. 53-3, LT-09306
                Vilnius, (toliau - <strong>Bendrovė</strong> arba{" "}
                <strong>Mes</strong>), rūpinamės savo kliento, portalo ir
                internetinio puslapio https://nevaziuoja.lt naudotoju (toliau -{" "}
                <strong>Jūs</strong> arba&nbsp;<strong>Naudotojas</strong>)
                duomenų saugumu ir privatumu.&nbsp;
                <br />
                &nbsp;
                <br />
                &Scaron;i Privatumo politika yra skirta pateikti informaciją
                apie tai, kaip Bendrovė tvarko ir saugo Jūsų duomenis, Jums
                naudojantis&nbsp;
                <a href="https://www.nevaziuoja.lt/">
                    https://nevaziuoja.lt
                </a>{" "}
                interneto svetaine.
                <br />
                &nbsp;
                <br />
                Jūsų asmens duomenų rinkimą, tvarkymą ir saugojimą, kiek tai
                susiję su Jūsų naudojimusi interneto svetaine, nustato &scaron;i
                privatumo politika, Lietuvos Respublikos Asmens duomenų teisinės
                apsaugos įstatymas ir kiti teisės aktai.
                <br />
                &nbsp;
                <br />
                <strong>
                    <strong>1. Sąvokos</strong>
                </strong>
                <br />
                &nbsp;
                <br />
                1.1.&nbsp;<strong>Asmens duomenys</strong> - rei&scaron;kia bet
                kokią informaciją, pagal kurią galima tiesiogiai arba
                netiesiogiai nustatyti Jūsų tapatybę. Tvarkomi &scaron;ie Jūsų
                asmens duomenys:&nbsp;
                <br />
                <br />
                <br />- Naudotojo kontaktiniai duomenys - elektroninio
                pa&scaron;to adresas ir telefono numeris;
                <br />- Jūsų interneto svetainėje atliktų užsakymų istoriją;
                <br />- IP adresas;
                <br />- tinklo ir vietos duomenis, kai juos suteikiate;
                <br />- Jūsų užklausų ir nusiskundimų istoriją;
                <br />- kitus vie&scaron;ai prieinamus duomenis, kuriais Jūs
                pasidalinote lankydamasis tinklapyje.
                <br />
                &nbsp;
                <br />
                1.2.&nbsp;<strong>Asmens duomenų tvarkymas</strong> -
                rei&scaron;kia bet kokią su asmens duomenimis atliekamą
                operaciją (tokią kaip - informacijos rinkimas, redagavimas,
                keitimas, įra&scaron;ymas, saugojimas, prieigos suteikimas,
                užklausų pateikimas, archyvavimas ir pan.).
                <br />
                &nbsp;
                <br />
                1.3.&nbsp;<strong>Duomenų valdytojas</strong> - MB
                &ldquo;Nevažiuoja&rdquo;, juridinio asmens kodas 306066889,
                adresas Krokuvos g. 53-3, LT-09306 Vilnius.
                <br />
                &nbsp;
                <br />
                1.4.&nbsp;<strong>Duomenų subjektas</strong> - bet kuris fizinis
                asmuo, kurio duomenis tvarko Bendrovė
                <br />
                &nbsp;
                <br />
                1.5. <strong>Svetainė</strong> -&nbsp;
                <a href="http://www.nevaziuoja.lt">www.nevaziuoja.lt</a>.&nbsp;
                <br />
                &nbsp;
                <br />
                <strong>
                    <strong>
                        2. Jūsų asmens duomenų tvarkymas lankantis Svetainėje
                    </strong>
                </strong>
                <br />
                &nbsp;
                <br />
                2.1. Pateikdamas duomenis Bendrovei, Duomenų subjektas sutinka,
                kad Asmens duomenys būtų naudojami Bendrovei siekiant užtikrinti
                interneto svetainės funkcionavimą Duomenų subjekto
                pageidaujamais tikslais.
                <br />
                &nbsp;
                <br />
                2.2. Jūsų asmens duomenis Bendrovė tvarko &scaron;iais
                pagrindai:
                <br />
                &nbsp;
                <br />- Bendrovė gauna ai&scaron;kiai i&scaron;reik&scaron;tą
                Jūsų sutikimą tvarkyti Jūsų duomenis tokiu būdu, t.y. kai Jūs
                naudojatės interneto svetainės paslaugomis;
                <br />
                &nbsp;
                <br />- Jūs atliekate užsakymą Interneto svetainėje, kuris yra
                priimamas ir vykdomas, tokiu būdu sudarant susitarimą su mūsų
                autoservisų tinkle esančiu tiekėju.&nbsp;
                <br />
                &nbsp;
                <br />- taip pat tais atvejais kai Bendrovė turi tvarkyti Jūsų
                duomenis turėdama teisinę pareigą;
                <br />
                &nbsp;
                <br />- yra įgyvendinami Bendrovės teisėti interesai. Mes
                tvarkysime Jūsų Asmens duomenis, jeigu tam turime teisėtą
                interesą ir toks duomenų tvarkymas nepažeidžia Jūsų interesų ir
                teisių apsaugos.
                <br />
                &nbsp;
                <br />
                2.3. Bendrovė Jūsų duomenis tvarko &scaron;iais tikslais:&nbsp;
                <br />
                &nbsp;
                <br />- Efektyviam, optimaliam ir Jums patogiam interneto
                svetainės naudojimui.
                <br />
                &nbsp;
                <br />- Siekiant užtikrinti sklandų Interneto svetainės
                administravimą;
                <br />
                &nbsp;
                <br />- Jūsų paskyros registravimui ir palaikymui;
                <br />
                &nbsp;
                <br />- Tinkamam Jūsų užsakytų paslaugų suteikimui;
                <br />
                &nbsp;
                <br />- Siekiant Jums pateikti aktualią informaciją, atsakyti į
                Jūsų klausimus, užklausas;
                <br />
                &nbsp;
                <br />- Vykdant statistinę ir kitą analizę, skirtą tobulinti
                Interneto svetainės veiklą ir Jūsų patirtį ja naudojantis;
                <br />
                &nbsp;
                <br />- Kai lankotės Svetainėje, Bendrovė gali tvarkyti Jūsų IP
                adresą, tinklo ir vietos duomenis, &Scaron;ie Asmens duomenys
                yra renkami slapukų ir kitų pana&scaron;ių technologijų pagalba
                Naudotojo sutikimo pagrindu.
                <br />
                &nbsp;
                <br />- Kitiems tikslams, kurie gali būti Jums nurodomi Jūsų
                duomenų pateikimo metu.
                <br />
                &nbsp;
                <br />
                <strong>
                    <strong>
                        3. Asmens duomenų teikimas trečiosioms &scaron;alims
                    </strong>
                </strong>
                <br />
                &nbsp;
                <br />
                3.1. Mes įsipareigojame jūsų Asmens duomenų atžvilgiu laikytis
                konfidencialumo pareigos. Jūsų Asmens duomenys gali būti
                atskleisti trečiosioms &scaron;alims tik tada, jeigu to reikia
                sutarčiai duomenų subjekto naudai sudaryti ir vykdyti, arba dėl
                kitų teisėtų priežasčių.
                <br />
                &nbsp;
                <br />
                3.2. Bendrovė gali pateikti Asmens duomenis savo duomenų
                tvarkytojams, kurie teikia Bendrovei paslaugas ir tvarko asmens
                duomenis Bendrovės vardu. Tokie Bendrovės pasirinkti Asmens
                duomenų tvarkytojai turi teisę tvarkyti Asmens duomenis tik
                pagal Bendrovės nurodymus ir tik tokia apimtimi, kiek tai yra
                būtina siekiant tinkamai vykdyti Bendrovės ir paslaugų tiekėjo
                sutartyje nustatytus įsipareigojimus. Užtikriname Jus, kad
                Bendrovė bendradarbiauja tik su tokiais duomenų tvarkytojais,
                kurie garantuoja tinkamas priemones (organizacines ir technines)
                Asmens duomenų tvarkymui ir užtikrina &nbsp;Asmens duomenų
                tvarkymą pagal teisės aktų reikalavimus.&nbsp;
                <br />
                &nbsp;
                <br />
                3.3. Įmonė taip pat gali teikti asmens duomenis atsakydama į
                teismo arba valstybinių institucijų pra&scaron;ymus ta apimtimi,
                kiek tai būtina siekiant tinkamai vykdyti galiojančius teisės
                aktus ir valstybinių institucijų nurodymus.
                <br />
                &nbsp;
                <br />
                <strong>
                    <strong>4. Asmens duomenų saugojimo terminas</strong>
                </strong>
                <br />
                &nbsp;
                <br />
                4.1. Jūsų asmens duomenis saugome tik tokį laikotarpį, kuris yra
                būtinas privatumo politikoje numatytų tikslų
                įgyvendinimui.&nbsp;
                <br />
                &nbsp;
                <br />
                4.2. Jeigu 10 metų nebūnate aktyvus Interneto svetainėje
                turimoje Naudotojo paskyroje, Jūsų asmens duomenys yra
                i&scaron;trinami.&nbsp;
                <br />
                &nbsp;
                <br />
                4.3. Jūs taip pat galite pareikalauti, kad Jūsų paskyra
                Interneto svetainėje būtų panaikinta.&nbsp;
                <br />
                &nbsp;
                <br />
                <strong>
                    <strong>5. Nepilnamečių Asmens duomenys</strong>
                </strong>
                <br />
                &nbsp;
                <br />
                5.1. Mes rūpinamės nepilnamečių privatumo apsauga. Informuojame,
                kad mūsų Interneto svetainė nėra pritaikytos asmenims,
                jaunesniems nei 16 metų amžiaus.&nbsp;
                <br />
                &nbsp;
                <br />
                5.2. Asmenys, kurie yra jaunesni negu 16 metų, negali teikti
                jokių Asmens duomenų Interneto svetainėje ir Svetainėje.
                <br />
                &nbsp;
                <br />
                5.3. Jei asmuo yra jaunesnis negu 16 metų, norėdamas naudotis
                Interneto svetaine, prie&scaron; pateikiant Asmens duomenis,
                privalo turėti bent vieno i&scaron; teisėtų atstovų (tėvo,
                motinos, globėjo (-os)) ra&scaron;ytinį sutikimą dėl asmens
                duomenų tvarkymo.
                <br />
                &nbsp;
                <br />
                <strong>
                    <strong>6. Duomenų subjektų teisės</strong>
                </strong>
                <br />
                &nbsp;
                <br />
                6.1. Būdamas Duomenų subjektu, turite &scaron;ias Duomenų
                subjekto teises:&nbsp;
                <br />
                &nbsp;
                <br />
                6.1.1. Teisė gauti informaciją apie duomenų tvarkymą
                (&ldquo;teisė žinoti&rdquo;).
                <br />
                &nbsp;
                <br />
                6.1.2. Teisė susipažinti su tvarkomais duomenimis (&ldquo;teisė
                susipažinti&rdquo;).
                <br />
                &nbsp;
                <br />
                6.1.3. Teisė reikalauti i&scaron;taisyti duomenis (&ldquo;teisė
                i&scaron;taisyti&rdquo;).
                <br />
                &nbsp;
                <br />
                6.1.4. Teisė reikalauti i&scaron;trinti duomenis (&ldquo;teisė
                būti pamir&scaron;tam&ldquo;). &Scaron;i teisė netaikoma, jei
                asmens duomenys, kuriuos pra&scaron;oma i&scaron;trinti, yra
                tvarkomi ir kitu teisiniu pagrindu, tokiu kaip tvarkymas būtinas
                sutarties vykdymui arba yra pareigos pagal taikomus teisės aktus
                vykdymas.
                <br />
                &nbsp;
                <br />
                6.1.5. Teisė apriboti duomenų tvarkymą (&ldquo;teisė
                apriboti&rdquo;).
                <br />
                &nbsp;
                <br />
                6.1.6. Teisė nesutikti su duomenų tvarkymu.
                <br />
                &nbsp;
                <br />
                6.1.7. Teisė į duomenų perkeliamumą. Teisė į duomenų
                perkeliamumą negali daryti neigiamo poveikio kitų teisėms ir
                laisvėms. Duomenų subjektas teisės į duomenų perkeliamumą neturi
                tų asmens duomenų atžvilgiu, kurie tvarkomi neautomatiniu būdu
                susistemintose rinkmenose, pavyzdžiui, popierinėse bylose.
                <br />
                &nbsp;
                <br />
                6.1.8. Teisė reikalauti, kad nebūtų taikomas tik automatizuotas
                duomenų tvarkymas, įskaitant profiliavimą.
                <br />
                &nbsp;
                <br />
                6.1.9. Teisė pateikti skundą dėl asmens duomenų tvarkymo
                Valstybinei duomenų apsaugos inspekcijai.
                <br />
                &nbsp;
                <br />
                6.2. Siekdami įgyvendinti savo teises, galite pateikti mums
                paklausimą ar pra&scaron;ymą ra&scaron;tu:&nbsp;
                <a href="mailto:info@nevaziuoja.lt">info@nevaziuoja.lt</a>. Gavę
                tokio pobūdžio pra&scaron;ymą ar paklausimą i&scaron; Jūsų,
                galime papra&scaron;yti Jūsų užpildyti reikiamas formas, taip
                pat - pateikti notari&scaron;kai patvirtintą Jūsų asmens
                dokumento kopiją, kuri Mums reikalinga įsitikinti Jūsų tapatybe,
                siekiant i&scaron;vengti Asmens duomenų atskleidimo.&nbsp;
                <br />
                &nbsp;
                <br />
                6.3. Po Jūsų pra&scaron;ymą ar paklausimo dėl Asmens duomenų
                tvarkymo gavimo, Mes įsipareigojame ne vėliau kaip per 1 mėnesį
                nuo kreipimosi dienos pateikti Jums atsakymą ir &nbsp;atlikti
                pra&scaron;yme nurodytus veiksmus arba informuoti Jus, kodėl
                atsisakome juos atlikti. Prireikus nurodytas laikotarpis gali
                būti pratęstas dar 2 mėnesiais, atsižvelgiant į pra&scaron;ymų
                sudėtingumą ir skaičių. Tokiu atveju, per 1 mėnesį nuo
                pra&scaron;ymo gavimo dienos mes informuosime Jus apie tokį
                pratęsimą.
                <br />
                &nbsp;
                <br />
                6.4. Jei Jums papra&scaron;ius Asmens duomenys yra
                i&scaron;trinami, mes saugosime tik informacijos kopijas, kurios
                yra būtinos siekiant apsaugoti mūsų ir kitų asmenų teisėtus
                interesus, laikytis valstybės institucijų įpareigojimų, spręsti
                ginčus, atpažinti trikdžius arba laikytis bet kokių susitarimų,
                kuriuos esate sudarę su mumis.
                <br />
                &nbsp;
                <br />
                6.5. Jei manote, kad Jūsų, kaip Duomenų subjekto teisės buvo
                pažeistos, taip pat galite pateikti skundą Mus prižiūrinčiai
                Asmens duomenų apsaugos institucijai &ndash; Valstybinei duomenų
                apsaugos inspekcijai, daugiau informacijos ir kontaktinius
                duomenis galite rasti inspekcijos svetainėje&nbsp;
                <a href="http://www.ada.lt">www.ada.lt</a>.<br />
                &nbsp;
                <br />
                <strong>
                    <span>7. Slapukų politika</span>
                </strong>
                <br />
                <span>&nbsp;</span>
                <br />
                <span>
                    7.1. Mūsų Svetainėje gali būti naudojami slapukai bei kitos
                    sekimo technologijos. Slapukų pagalba, Mūsų Interneto
                    svetainė gali tam tikrą laiką &bdquo;prisiminti&ldquo; Jūsų
                    veiksmus ir pasirinkimus nar&scaron;ant Svetainėje (pvz.,
                    registracijos duomenis, Jūsų parinkties kalbą ir kitas
                    rodymo parinktis).
                </span>
                <br />
                <span>&nbsp;</span>
                <br />
                <span>
                    7.2. Mes naudojame slapukus bei kitas sekimo technologijas s
                </span>
                <span>
                    ekimo analizei, tinklalapio tobulinimui ir vystymui,
                    vartotojo patirties, naudojantis tinklapiu, gerinimui.
                </span>
                <br />
                <span>&nbsp;</span>
                <br />
                <span>
                    7.3. Jūs galite kontroliuoti Slapukus Svetainėje. Turite
                    teisę pasirinkti, ar norite priimti Slapukus bei kitas Mūsų
                    naudojamas sekimo technologijas, ar norite slapukus
                    i&scaron;jungti visi&scaron;kai, ar tik tam tikrus i&scaron;
                    jų.&nbsp;
                </span>
                <br />
                <span>&nbsp;</span>
                <br />
                <span>
                    7.4. Daugiau bendros informacijos apie &nbsp;slapukų
                    naudojimą galite rasti{" "}
                    <a href="http://AllAboutCookies.org">AllAboutCookies.org</a>
                    . Jeigu nuspręsite i&scaron;jungti visus ar tam tikrus
                    Slapukus mūsų Svetainėje, tai gali turėti įtakos Svetainės
                    veikimui, gali sulėtinti jos veikimo greitį, apriboti tam
                    tikrų funkcijų prieinamumą. Jums taip pat bus reikalinga
                    kaskart, lankantis Svetainėje, i&scaron; naujo nustatyti tam
                    tikras parinktis.
                </span>
                <br />
                &nbsp;
                <br />
                <strong>
                    <strong>8. Baigiamosios nuostatos</strong>
                </strong>
                <br />
                &nbsp;
                <br />
                8.1 Informuojame, kad mūsų Interneto svetainėje gali būti
                pateikiamos nuorodos į kitus tinklalapius, įskaitant trečiųjų
                &scaron;alių valdomus tinklalapius, kuriems nėra taikoma
                &scaron;i privatumo politika. Pra&scaron;ome susipažinti ir
                įvertinti &scaron;ių tinklalapių privatumo politiką prie&scaron;
                pateikiant bet kokius savo Asmens duomenis.&nbsp;
                <br />
                &nbsp;
                <br />
                8.2. Esant poreikiui, atsižvelgdami į Asmens duomenų apsaugos
                priemones, kurias taikome, galime atnaujinti ir pakeisti
                &scaron;ią privatumo politiką. Apie Jums taikomus Privatumo
                politikos pokyčius Jūs būsite informuoti tarp Jūsų ir mūsų
                įprastomis komunikacijos priemonėmis.
                <br />
                &nbsp;
                <br />
                <strong>
                    <strong>9. Marketingo komunikacija </strong>
                </strong>
                <br />
                &nbsp;
                <br />
                9.1. Jei sutiksite, susisieksime su jumis el. paštu arba
                telefonu, kad informuotume apie mūsų veiksmus. Atkreipkite
                dėmesį, kad jūsų sutikimas nėra pirkimo sąlyga.
                <br />
                &nbsp;
                <br />
                9.2. Susisiekdami su jumis telefonu, kaip numatyta 9.1. punkte,
                SMS ir (arba) tekstinius pranešimus iš mūsų gausite per savo
                belaidžio ryšio paslaugų teikėją į jūsų nurodytą mobiliojo
                telefono numerį. SMS žinutės / tekstiniai pranešimai gali būti
                siunčiami naudojant automatinę telefono rinkimo sistemą arba
                kitą technologiją. Žinutės siunčiamos skirtingu dažnumu. Gali
                būti taikomi pranešimų ir duomenų perdavimo tarifai.
                <br />
                &nbsp;
                <br />
                9.3. Bet kuriuo metu galite atsisakyti gauti bet kokius
                rinkodaros pranešimus. Tai galite padaryti pasirinkę atitinkamą
                nuorodą bet kuriame iš mūsų rinkodaros pranešimų arba susisiekę
                su mumis mūsų interneto svetainėje nurodytomis priemonėmis. Jei
                9.1. punkte numatytais pagrindais gaunate rinkodaros pranešimus
                ir el. paštu, ir telefonu ir norite atsisakyti jų gavimo,
                turėsite atsisakyti atskirai, pasirinkdami atitinkamą nuorodą
                bet kuriame mūsų rinkodaros pranešime arba susisiekdami su mumis
                mūsų interneto svetainėje numatytomis priemonėmis.
                <br />
                <br />
                9.4 Jums įvykdžius bet kurį iš nurodytų veiksmų, mes
                atnaujinsime jūsų profilį, kad ateityje nebegautumėte mūsų
                rinkodaros pranešimų. &nbsp;
                <br />
                <br />
                9.5.Informuojame, kad mūsų veiklą sudaro glaudžiai susijusių
                paslaugų tinklas, todėl gali prireikti kelių dienų, kol visos
                sistemos bus atnaujintos, todėl, kol mes tvarkome jūsų prašymą,
                galite ir toliau gauti rinkodaros pranešimus.
                <br />
                <br />
                4.6.Bet kuriuo atveju rinkodaros pranešimų atsisakymas
                nesutrukdys jums gauti pranešimų, tiesiogiai susijusių su
                paslaugų teikimu. &nbsp;
                <br />
            </p>
        </ContentContainer>
    );
};

export default PrivacyPolicy;
