import Text from "@FEShared/components/UI/Text/Text";
import React from "react";
import { PROC } from "../PromoOfferStep/PromoOfferStep";
import { DateTime } from "luxon";
import { dateFormatFull } from "@Shared/util/dateFormat";
import Button from "@FEShared/components/UI/Button/Button";
import DISCOUNT_IMG from "./assets/discount.webp";
import Img from "@FEShared/components/UI/Img/Img";
import Box from "@FEShared/components/UI/Box/Box";
import { PromoActivatedStep } from "../ReservationCancelModal.types";

const EXPIRATION_DAYS = 3;

const OrderCancelledStep: React.FC<{
    orderID: number;
    type: PromoActivatedStep;
    reReg: () => void;
    changeReservationDate: () => void;
    onClose: () => void;
    isLoading?: boolean;
}> = (p) => {
    const stepData = React.useMemo(() => {
        let title: string;
        let btnText: string;
        let contentText: string | undefined;
        let customContent: JSX.Element | undefined;
        let secondaryBtn: { text: string; onClick: () => void } | undefined;

        if (p.type === "3_NO_CANCEL") {
            title = "Nuolaida aktyvuota!";
            customContent = (
                <Text>
                    Autoservisas laukia jūsų atvykstant. Jums bus pritaikyta 10%
                    nuolaida užsakymo sumai.
                </Text>
            );
            btnText = "Supratau";
        } else if (p.type === "3_CANCEL") {
            title = "Rezervacija atšaukta. Nuolaida kitam vizitui";
            contentText = "Naujos registracijos metu";
            btnText = "Registruotis iš naujo";
        } else if (p.type === "3_CHANGE_DATE") {
            title = `Pakeisk rezervacijos laiką ir aktyvuok ${PROC} nuolaida`;
            btnText = "Keisti rezervacijos laiką";
            contentText = "Keisdamas vizito laiką";
            secondaryBtn = {
                text: "Nauja registracija",
                onClick: p.reReg,
            };
        } else if (p.type === "3_RE_REG") {
            title = `Rezervacija atšaukta. Atlik naują registraciją ir aktyvuok ${PROC} nuolaida`;
            contentText = "Atlikdamas registraciją";
            btnText = "Nauja registracija";
            secondaryBtn = {
                text: "Keisti rezervacijos laiką",
                onClick: p.changeReservationDate,
            };
        } else {
            // shouldn't ever happen.
            console.error("Unknown type in PromoActivatedStep: ", p.type);
            title = "Nuolaida aktyvuota";
            btnText = "Uždaryto";
        }

        const content = customContent || (
            <>
                <Text>
                    {contentText} panaudok promo kodą:{" "}
                    <Text span semiBold>
                        NUOLAIDA
                        {p.orderID}
                    </Text>{" "}
                    ir gauk{" "}
                    <Text span semiBold>
                        {PROC}
                    </Text>{" "}
                    nuolaidą.
                    <Text mt={1}>
                        Kodas galioja {EXPIRATION_DAYS} dienas, atlikus
                        registracija iki:{" "}
                        <Text span semiBold>
                            {dateFormatFull(
                                DateTime.now()
                                    .plus({ days: EXPIRATION_DAYS })
                                    .toJSDate()
                            )}
                        </Text>
                    </Text>
                </Text>
            </>
        );

        return {
            title,
            btnText,
            content,
            secondaryBtn,
        };
    }, [p.type, p.orderID, p.changeReservationDate, p.reReg]);

    return (
        <Box>
            <Box pb={2}>
                <Text
                    mb={2}
                    semiBold
                    center
                    sx={(theme) => ({
                        fontSize: 30,
                        [theme.breakpoints.down("md")]: {
                            fontSize: 18,
                        },
                    })}
                >
                    {stepData.title}
                </Text>
                <Box textAlign="center">
                    <Img
                        src={DISCOUNT_IMG}
                        mt={3}
                        mb={3}
                        ml="auto"
                        mr="auto"
                        height={225}
                        sx={{ textAlign: "center" }}
                    />
                </Box>
                <Box textAlign="center">
                    {stepData.content}{" "}
                    {/* <Text mt={1}>
                    Po sėkmingo vizito, Nevažiuoja komanda su tavim susisieks ir
                    grąžins į tavo banko sąskaitą {PROC} remonto sumos.
                </Text> */}
                    <Text variant="subtitle2">
                        * Nuolaida galioja tik kai dalys sudaro mažiau nei 75%
                        remonto sumos
                    </Text>
                </Box>
                <Button
                    isLoading={Boolean(p.isLoading)}
                    fullWidth
                    onClick={() => {
                        if (p.type === "3_CHANGE_DATE") {
                            p.changeReservationDate();
                        } else if (
                            p.type === "3_RE_REG" ||
                            p.type === "3_CANCEL"
                        ) {
                            p.reReg();
                        } else {
                            p.onClose();
                        }
                    }}
                    sx={{ mt: 4 }}
                >
                    {stepData.btnText}
                </Button>
                {/* {stepData.secondaryBtn && (
                <Button
                    fullWidth
                    onClick={stepData.secondaryBtn?.onClick}
                    color="greyish"
                    sx={{ mt: 2 }}
                >
                    {stepData.secondaryBtn?.text}
                </Button>
            )} */}
            </Box>
        </Box>
    );
};

export default OrderCancelledStep;
