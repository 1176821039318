import React from "react";
import Text from "@FEShared/components/UI/Text/Text";
import Box from "@FEShared/components/UI/Box/Box";
import Button from "@FEShared/components/UI/Button/Button";
import ButtonsMenu from "@FEShared/components/UI/ButtonsMenu/ButtonsMenu";
import useStore from "@FEClient/logic/store";
import HEART_BROKEN from "./assets/heart-broken.png";
import Img from "@FEShared/components/UI/Img/Img";
import { ModalStep } from "../ReservationCancelModal.types";

export const PROC = "10%";

const PromoOfferStep: React.FC<{
    nextStep: (step: ModalStep) => void;
    onClose: () => void;
    isLoading?: ModalStep;
}> = (p) => {
    const GS = useStore();

    const order = GS.reservationSuccessPageState.completedOrder;

    if (!order) return <div>Kraunama...</div>;

    return (
        <div>
            <Text mb={2} semiBold center fontSize={28}>
                Tai ne tu, tai mes...
            </Text>
            <Box textAlign="center">
                <Img
                    src={HEART_BROKEN}
                    mt={3}
                    mb={3}
                    ml="auto"
                    mr="auto"
                    height={225}
                    sx={{ textAlign: "center" }}
                />
            </Box>
            <Text center>
                Apmaudu, bet nenorime visko užbaigti čia… 🤧 Neatšauk vizito ar
                persiregistruok (kitu laiku ar į kitą autoservisą) ir{" "}
                <Text span semiBold>
                    gauk {PROC} nuolaidą 💸
                </Text>
            </Text>
            <Box
                mt={4}
                displayFlex
                sx={(theme) => ({
                    [theme.breakpoints.down("md")]: {
                        flexDirection: "column",
                    },
                })}
            >
                <Button
                    sx={(theme) => ({
                        mr: 2,
                        [theme.breakpoints.down("md")]: {
                            mr: 0,
                            mb: 2,
                            flexDirection: "column",
                        },
                    })}
                    fullWidth
                    disabled={Boolean(p.isLoading)}
                    isLoading={p.isLoading === "3_NO_CANCEL"}
                    onClick={async () => {
                        p.nextStep("3_NO_CANCEL");
                    }}
                >
                    Neatšaukti ir aktyvuoti {PROC} nuolaidą
                </Button>
                <ButtonsMenu
                    paperProps={{
                        sx: {
                            boxShadow: 3,
                        },
                    }}
                    btnProps={{ color: "greyish", fullWidth: true }}
                    menuButtonText="Atšaukti/Persiregistruoti"
                    options={[
                        {
                            disabled: Boolean(p.isLoading),
                            isLoading: p.isLoading === "3_CHANGE_DATE",
                            value: `Keisti vizito laiką (${PROC} nuolaida)`,
                            title: `Keisti vizito laiką (${PROC} nuolaidą)`,
                            onClick: async () => {
                                p.nextStep("3_CHANGE_DATE");
                            },
                        },
                        {
                            disabled: Boolean(p.isLoading),
                            isLoading: p.isLoading === "3_RE_REG",
                            value: `Nauja registracija (${PROC} nuolaidą)`,
                            title: `Nauja registracija (${PROC} nuolaidą)`,
                            onClick: async () => {
                                p.nextStep("3_RE_REG");
                            },
                        },
                        {
                            disabled: Boolean(p.isLoading),
                            isLoading: p.isLoading === "3_CANCEL",
                            value: "Atšaukti rezervaciją",
                            title: "Atšaukti rezervaciją",
                            onClick: async () => {
                                p.nextStep("3_CANCEL");
                            },
                        },
                    ]}
                />
            </Box>
        </div>
    );
};

export default PromoOfferStep;
