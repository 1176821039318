import { toast } from "react-toastify";

export const TOAST_CLASSNAME = "Toastify";

const UNEXPECTED_ERROR_TEXT = `Jeigu klaida kartosis susisiekite su administracija per live chat'ą, +370 677 20241 ar labas@nevaziuoja.lt`;

function createToast(
    type: "warn" | "error" | "info" | "success",
    message: string
) {
    let adjustedMsg: string;
    if (message.toLowerCase().includes("failed to fetch")) {
        adjustedMsg = `Įvyko netikėta klaida. Klaidas kodas: #DC. ${UNEXPECTED_ERROR_TEXT}`;
    } else {
        adjustedMsg = message.replace("[GraphQL]", ""); // [GraphQL] part is added by the FE GQL lib.
    }

    // TBD: Add logging for every error and warning into here.
    toast[type](adjustedMsg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
    });
}

const showToast = {
    error: (message: string, breakingError?: boolean) => {
        if (breakingError) console.error(message);

        createToast(
            "error",
            [message, breakingError && UNEXPECTED_ERROR_TEXT]
                .filter(Boolean)
                .join(" ")
        );
    },
    warn: (message: string) => createToast("warn", message),
    info: (message: string) => createToast("info", message),
    success: (message: string) => createToast("success", message),
};

export default showToast;
