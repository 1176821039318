import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import React from "react";
import * as S from "./NewBenefitsSection.styled";

// import CHECKMARK_IMG from "./assets/checkmark.png";
// import CURSOR_IMG from "./assets/cursor.png";
import PINPOINT_IMG from "./assets/pinpoint.png";
// import SOFA_IMG from "./assets/sofa.png";
// import SPECIALIZED_IMG from "./assets/specialized.png";
import PRICE_IMG from "./assets/price.png";
import STAR_IMG from "./assets/star.png";

import Section from "../Section/Section";
import { getSeoPageData } from "../FAQ/FAQ.util";

export const BENEFITS = [
    // {
    //     title: "Dėl remonto jauskis užtikrintai",
    //     descr: "Visom atliktom paslaugoms taikoma garantija",
    //     imgUrl: CHECKMARK_IMG,
    // },
    // {
    //     title: "Pamiršk skambučius",
    //     descr: "Registruokis internetu, kelių mygtukų paspaudimu",
    //     imgUrl: CURSOR_IMG,
    // },
    // {
    //     title: "Specializuoti autoservisai",
    //     descr: "Autoservisai specializuoti paslaugoj išskirti ženkliuku",
    //     imgUrl: SPECIALIZED_IMG,
    // },
    {
        title: "Palygink kainas",
        descr: "Autoservisų įkainiai nurodyti visoms paslaugoms",
        imgUrl: PRICE_IMG,
    },
    {
        title: "Rask artimiausią",
        descr: "Remontą atlik kur patogiau tau - šalia namų ar darbo",
        imgUrl: PINPOINT_IMG,
    },

    {
        title: "Skaityk atsiliepimus",
        descr: "Verifikuoti klientų atsiliepimai",
        imgUrl: STAR_IMG,
    },
    // {
    //     title: "Registruokis patogiu metu",
    //     descr: "Registruokis patogiu metu - kad ir po darbo valandų",
    //     imgUrl: SOFA_IMG,
    // },
];

const NewBenefitsSection: React.FC = () => {
    const seoData = getSeoPageData();

    return (
        <Section
            title={
                seoData?.seoServiceName && seoData.type !== "DISTRICT"
                    ? `${seoData?.seoServiceName} - šalia tavęs`
                    : `Rask geriausią servisą šalia tavęs`
            }
            titleProps={{ variant: "h2" }}
        >
            <S.NewBenefitsSection>
                <S.FeaturesWrapper>
                    {BENEFITS.map((f) => (
                        <S.FeatureWrapper key={f.title}>
                            <S.FeatureImg src={f.imgUrl} />
                            <Box vertical>
                                <S.FeatureTitle>{f.title}</S.FeatureTitle>
                                <Text variant="subtitle1">{f.descr}</Text>
                            </Box>
                        </S.FeatureWrapper>
                    ))}
                    <S.FeatureTitle></S.FeatureTitle>
                </S.FeaturesWrapper>
            </S.NewBenefitsSection>
        </Section>
    );
};

export default NewBenefitsSection;
