import { ContentContainer } from "@FEClient/views/commonComps/ContentContainer/ContentContainer.styled";
import React from "react";
import WarrantyContent from "./WarrantyContent/WarrantyContent";
import * as S from "./Warranty.styled";

const Warranty: React.FC = () => {
    return (
        <ContentContainer pt="32px">
            <S.WarrantyTitle style={{ marginBottom: 8 }}>
                Techninė garantija
            </S.WarrantyTitle>
            <WarrantyContent />
        </ContentContainer>
    );
};

export default Warranty;
