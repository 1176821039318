import { Interval, DateTime } from "luxon";
import Holidays, { HolidaysTypes } from "date-holidays";
import { Weekday, Calendar, CalendarDay } from "../types/calendar";
import { WEEKDAYS } from "../consts/commonConsts";
import {
    BlockedTimeslot,
    EmployeeTimeblocks,
} from "@FEShared/graphql/generated/graphql";
import lOrderBy from "lodash/orderBy";
import lSumBy from "lodash/sumBy";
import isFullDaySearch from "./isFullDaySearch";

const MINS_MARGIN_FOR_IMMEDIATE = 60;
export const DAYS_TO_BUSINESS_MINS_MULTIPLIER = 9 * 60;

type DateHandlerEmployee = {
    employee: EmployeeTimeblocks;
    bizDateHandler: BusinessDateHandler;
    blockedTimeslots?: BlockedTimeslot[];
    /**
     * All means, that employee provides all services that workshop provides.
     * If array has same amount of elements as the `wantedServices` that means that employee provides all the wanted services (but has seperately marked workshop services)
     */
    matchingServices: "ALL" | WantedService[];
    // businessMinsLeftCache: Map<string /* YYYY-MM-DD */, number /* minutes */>;
};

export type WantedService = {
    /* ServiceCategory (AKA ServiceDefinition) ID */
    ID: string;
    durationMins: number;
};

const ltHolidays = new Holidays("LT");

// For now, timeblock for specific employees only work for all services, not for specific services. But don't think this will be needed anyway.
// TBD adjust this API to accept Date instead of DateTime
export default class BusinessDateHandler {
    // mini cache to retrieve holidays faster instead of calling `ltHolidays.getHolidays(year);` everytime because it seems that this operation is quite slow (when done in bulk)
    private readonly ltHolidays: Record<number, HolidaysTypes.Holiday[]> = {};

    private readonly workHoursCalendar: Calendar;
    private readonly wantedServices?: WantedService[];
    private get wantedServicesDuration(): number {
        return lSumBy(this.wantedServices, "durationMins");
    }
    private readonly employees?: EmployeeTimeblocks[];
    private readonly employeesThatProvideServices = new Map<
        /* Employee.ID */
        number,
        DateHandlerEmployee
    >();
    private readonly generalBlockEvents = [] as BlockedTimeslot[];
    private readonly acceptanceDaysMargin?: number;
    private _canAcceptFromDT?: DateTime;

    public get canAcceptFromDT() {
        if (!this.acceptanceDaysMargin) return;
        if (this._canAcceptFromDT) return this._canAcceptFromDT;

        const canAcceptFrom = this.deltaBusinessMinutes(
            DateTime.now(),
            this.acceptanceDaysMargin * DAYS_TO_BUSINESS_MINS_MULTIPLIER
        );
        this._canAcceptFromDT = canAcceptFrom;

        return this._canAcceptFromDT;
    }

    private get shouldCheckEmployeesAvailability(): boolean {
        if (!this.wantedServices || this.wantedServices.length === 0)
            return false;

        if (!this.employees || this.employees.length === 0) return false;

        return true;
    }

    constructor(p: {
        workHoursCalendar: Calendar;
        /**
         * If event has no employee attached - that means it's a general workshop timeslot block event.
         * If event has employees attached - that means it's a block event for those specific employees.
         * Therefore make sure to not pass standard calendar events, where workshop didn't attach any employee to the event.
         **/
        blockedTimeslots?: BlockedTimeslot[];
        wantedServices?: WantedService[];
        employees?: EmployeeTimeblocks[];
        acceptanceDaysMargin?: number;
    }) {
        if (Object.values(p.workHoursCalendar).length === 0)
            throw new Error(
                `workHoursCalendar is empty is businessDateHandler`
            );

        this.acceptanceDaysMargin = p.acceptanceDaysMargin;
        this.workHoursCalendar = p.workHoursCalendar;
        this.wantedServices = p.wantedServices;
        this.employees = p.employees;

        if (p.blockedTimeslots) {
            this.generalBlockEvents = p.blockedTimeslots.filter(
                (blockEvent) =>
                    !blockEvent.employeesIDs ||
                    blockEvent.employeesIDs.length === 0
            );
        }

        if (this.shouldCheckEmployeesAvailability) {
            p.employees?.forEach((e) => {
                let matchingServices: WantedService[] | "ALL" = [];

                if (e.servicesDefinitionsIDs.length === 0) {
                    // either employee provides all the same services as the workshop does (no services set)
                    matchingServices = "ALL";
                } else {
                    // or if employee has services set, than check if atleast one service is provided by the employee
                    p.wantedServices?.forEach((wantedService) => {
                        if (
                            e.servicesDefinitionsIDs.includes(
                                wantedService.ID
                            ) &&
                            Array.isArray(matchingServices)
                        ) {
                            matchingServices.push(wantedService);
                        }
                    });
                }

                if (matchingServices === "ALL" || matchingServices.length > 0) {
                    const employeeEvents = p.blockedTimeslots?.filter(
                        (blockEvent) => blockEvent.employeesIDs?.includes(e.ID)
                    );

                    this.employeesThatProvideServices.set(e.ID, {
                        employee: e,
                        bizDateHandler: new BusinessDateHandler({
                            workHoursCalendar:
                                e.workHoursCalendar || p.workHoursCalendar,
                            blockedTimeslots: employeeEvents?.map((e) => {
                                // remove employeesIDs from blockedTimeslots, so it would be considered as a general block event from employee BusinessDateHandler PoV for `businessMinsBetweenIncludingGeneralBlocks`
                                return {
                                    ...e,
                                    employeesIDs: undefined,
                                };
                            }),
                        }),
                        matchingServices,
                        blockedTimeslots: employeeEvents,
                        // businessMinsLeftCache: new Map(),
                    });
                }
            });
        }
    }

    /** Adds a business day, and makes sure that returned dateTime is the earliest OPEN time of that business day. */
    public plusBusinessDay(dateTime: DateTime, days?: number): DateTime {
        let newDateTime = DateTime.fromMillis(dateTime.toMillis());
        let sanityCheckCounter = 0;
        let isBusinessDay = false;

        while (!isBusinessDay) {
            if (sanityCheckCounter > 30) {
                throw new Error(`Sanity check failed for businessDay`);
            }
            newDateTime = newDateTime.plus({ day: 1 });
            const calendarDay = this.getDateCalendarDay(newDateTime);
            if (!calendarDay) continue;
            newDateTime = newDateTime.set({
                hour: calendarDay.open.hour,
                minute: calendarDay.open.minute,
                second: 0,
                millisecond: 0,
            });

            if (this.isDTInsideBusinessHours(newDateTime)) isBusinessDay = true;
            sanityCheckCounter++;
        }
        if (days && days > 0) {
            return this.plusBusinessDay(newDateTime, days - 1);
        }

        return newDateTime;
    }

    /** Minuses a business day, and makes sure that returned dateTime is the CLOSE time of that business day. */
    public minusBusinessDay(dateTime: DateTime): DateTime {
        let isBusinessDay = false;
        let sanityCheckCounter = 0;
        let newDateTime = DateTime.fromMillis(dateTime.toMillis());

        while (!isBusinessDay) {
            if (sanityCheckCounter > 30) {
                throw new Error(`Sanity check failed for businessDay`);
            }
            newDateTime = newDateTime.minus({
                day: 1,
            });
            const calendarDay = this.getDateCalendarDay(newDateTime);
            if (!calendarDay) continue;
            newDateTime = newDateTime.set({
                hour: calendarDay.close.hour,
                minute: calendarDay.close.minute,
                second: 0,
                millisecond: 0,
            });
            isBusinessDay = this.isDTInsideBusinessHours(newDateTime);
            sanityCheckCounter++;
        }

        return newDateTime;
    }

    public getDateCalendarDay(
        dateTime: DateTime
    ): CalendarDay | undefined | null {
        const weekday = WEEKDAYS[dateTime.weekday - 1] as Weekday;
        // const calendarDay = this.useFakeCalendar
        //     ? FAKE_MOCK_CALENDAR[weekday]
        //     : this.serviceWorkHoursCalendar[weekday];
        const calendarDay = this.workHoursCalendar[weekday];
        return calendarDay;
    }

    private isPublicHoliday(dateTime: DateTime): boolean {
        const year = dateTime.year;

        let retrievedHolidays: HolidaysTypes.Holiday[];
        if (this.ltHolidays[year]) {
            retrievedHolidays = this.ltHolidays[year];
        } else {
            retrievedHolidays = ltHolidays.getHolidays(year);
            this.ltHolidays[year] = retrievedHolidays;
        }

        const foundHoliday = retrievedHolidays.find((holiday) => {
            const yyyymmdd = holiday.date.split(" ")[0];
            return yyyymmdd === dateTime.toISODate();
        });
        return !!foundHoliday;
    }

    public isDateInsideCalendarBusinessHours(dateTime: DateTime): boolean {
        const calendarDay = this.getDateCalendarDay(dateTime);
        if (!calendarDay) return false;

        const openTime = DateTime.fromMillis(dateTime.toMillis()).set({
            hour: +calendarDay.open.hour,
            minute: +calendarDay.open.minute,
            second: 0,
            millisecond: 0,
        });
        const closeTime = DateTime.fromMillis(dateTime.toMillis()).set({
            hour: +calendarDay.close.hour,
            minute: +calendarDay.close.minute,
            second: 1, // Add 1s extra on purpose, so that exact closeTime would be included inside .contains
            millisecond: 0,
        });

        const serviceWorkHoursInterval = Interval.fromDateTimes(
            openTime,
            closeTime
        );

        const isInsideWorkingHours =
            serviceWorkHoursInterval.contains(dateTime);

        return isInsideWorkingHours;
    }

    public hasAvailableEmployees(p: {
        arrivalDT: DateTime;
        immediateReservation?: boolean;
    }): boolean {
        let checkForServicesCount: number = this.wantedServices?.length || 0;
        if (!this.shouldCheckEmployeesAvailability) {
            return true;
        }

        if (this.employeesThatProvideServices.size === 0) {
            // if employees were provided, but there are no employees that can provide this service,
            // but this case only should happen if workshop has marked employee services incorrectly.
            console.error(
                `No employees that can provide this services: ${this.wantedServices?.map(
                    (w) => w.ID
                )}. Employees: ${this.employees?.map((e) => e.ID).join(", ")}`
            );
            return true;
        } else {
            // check for scenarios, when one service is not provided by employees but the other is provided.

            const coveredServicesIDs = new Set<string>();
            let differentServicesCountCheck = false;
            for (const e of this.employeesThatProvideServices.values()) {
                if (e.matchingServices === "ALL") {
                    break; // if atleast one employee provides all services, then we don't need to change matchingServicesCount
                } else {
                    differentServicesCountCheck = true;
                    e.matchingServices.forEach((s) =>
                        coveredServicesIDs.add(s.ID)
                    );
                }
            }
            if (differentServicesCountCheck) {
                checkForServicesCount = coveredServicesIDs.size;
            }
        }

        // as a quick heuristic, check first employees biggest range of services and lowest count of blocked events, since that should be the highest probability of finding a match faster.
        const sortedEmployees = lOrderBy(
            Array.from(this.employeesThatProvideServices.values()),
            [
                (e) =>
                    e.matchingServices === "ALL"
                        ? 999
                        : e.matchingServices.length,
                (e) => e.blockedTimeslots?.length || 0,
            ],
            ["desc", "asc"]
        );

        const coveredServicesIDs = new Set<string>();

        for (const employeeData of sortedEmployees) {
            if (
                !employeeData.bizDateHandler.isDTInsideBusinessHours(
                    p.arrivalDT
                )
            ) {
                continue;
            }
            if (
                this.isDateInsideEmployeeBlockedTimeslots(
                    p.arrivalDT,
                    employeeData
                )
            ) {
                continue;
            }

            if (
                !p.immediateReservation ||
                (p.immediateReservation &&
                    this.doesEmployeeHaveAvailableBizMins({
                        arrivalDT: p.arrivalDT,
                        employee: employeeData,
                    }))
            ) {
                if (
                    employeeData.matchingServices === "ALL" ||
                    employeeData.matchingServices.length ===
                        this.wantedServices?.length
                ) {
                    return true;
                } else {
                    employeeData.matchingServices.forEach((s) =>
                        coveredServicesIDs.add(s.ID)
                    );

                    if (coveredServicesIDs.size === checkForServicesCount) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    private doesEmployeeHaveAvailableBizMins(p: {
        arrivalDT: DateTime;
        employee: DateHandlerEmployee;
    }): boolean {
        let freeBizMins = 0;

        if (isFullDaySearch(p.arrivalDT)) {
            const employeeCalendarDay =
                p.employee.bizDateHandler.getDateCalendarDay(p.arrivalDT);
            if (!employeeCalendarDay) return false;

            const startDT = p.arrivalDT.set({
                hour: employeeCalendarDay.open.hour,
                minute: employeeCalendarDay.open.minute,
            });

            const endDT = p.arrivalDT.set({
                hour: employeeCalendarDay.close.hour,
                minute: employeeCalendarDay.close.minute,
            });

            freeBizMins =
                p.employee.bizDateHandler.businessMinsBetweenIncludingGeneralBlocks(
                    {
                        startDT,
                        endDT,
                    }
                );

            return freeBizMins >= this.wantedServicesDuration;
        } else {
            const checkingDate = {
                dt: p.arrivalDT,
                YYYY_MM_DD: p.arrivalDT.toFormat("YYYY-MM-DD"),
            };

            const employeeCalendarDay =
                p.employee.bizDateHandler.getDateCalendarDay(checkingDate.dt);

            if (!employeeCalendarDay) return false;

            freeBizMins =
                p.employee.bizDateHandler.businessMinsBetweenIncludingGeneralBlocks(
                    {
                        startDT: checkingDate.dt,
                        endDT: checkingDate.dt.plus({
                            minutes:
                                this.wantedServicesDuration +
                                MINS_MARGIN_FOR_IMMEDIATE,
                        }),
                    }
                );

            return freeBizMins >= this.wantedServicesDuration;
        }
    }

    public isDateInsideEmployeeBlockedTimeslots(
        dateTime: DateTime,
        employee: DateHandlerEmployee
    ): boolean {
        if (
            !employee.blockedTimeslots ||
            employee.blockedTimeslots.length === 0
        ) {
            return false;
        }

        const isInsideBlockedTimeslots = employee.blockedTimeslots.some(
            (blockCalendarEvent) => {
                const blockCalendarEventStart = DateTime.fromJSDate(
                    blockCalendarEvent.startDate
                );
                const blockCalendarEventEnd = DateTime.fromJSDate(
                    blockCalendarEvent.endDate
                );
                const blockCalendarEventInterval = Interval.fromDateTimes(
                    blockCalendarEventStart,
                    blockCalendarEventEnd
                );
                return (
                    blockCalendarEventInterval.contains(dateTime) ||
                    // .equals check is needed, because the interval.contains excludes the exact the end date from interval
                    dateTime.equals(blockCalendarEventInterval.end)
                );
            }
        );

        return isInsideBlockedTimeslots;
    }

    public isDateInsideBlockedGeneralTimeslots(dateTime: DateTime): boolean {
        if (!this.generalBlockEvents || this.generalBlockEvents.length === 0)
            return false;

        const isInsideBlockedTimeslots = this.generalBlockEvents.some(
            (blockCalendarEvent) => {
                if (
                    this.wantedServices?.length === 0 &&
                    blockCalendarEvent.blockedServicesIDs &&
                    blockCalendarEvent.blockedServicesIDs.length > 0
                ) {
                    // if checking general availability (not for any specific service), and timeblock calendar event is for specific service, then it's not relevant for general availability, so we skip that.
                    return false;
                }

                if (this.wantedServices) {
                    if (
                        blockCalendarEvent.blockedServicesIDs &&
                        blockCalendarEvent.blockedServicesIDs.length > 0 &&
                        this.wantedServices.length > 0 &&
                        !this.wantedServices.some((wantedService) => {
                            const res =
                                blockCalendarEvent.blockedServicesIDs?.includes(
                                    wantedService.ID
                                );
                            return res;
                        })
                    ) {
                        // if this is a specific service block, and it's not blocking any one of the wanted services, we skip it.
                        return false;
                    }
                }

                const blockCalendarEventStart = DateTime.fromJSDate(
                    blockCalendarEvent.startDate
                );
                const blockCalendarEventEnd = DateTime.fromJSDate(
                    blockCalendarEvent.endDate
                );
                const blockCalendarEventInterval = Interval.fromDateTimes(
                    blockCalendarEventStart,
                    blockCalendarEventEnd
                );
                return (
                    blockCalendarEventInterval.contains(dateTime) ||
                    // .equals check is needed, because the interval.contains excludes the exact the end date from interval
                    dateTime.equals(blockCalendarEventInterval.end)
                );
            }
        );

        return isInsideBlockedTimeslots;
    }

    /** Should be only called for fullDaySearch OR immediate registrations only */
    public doesWorkshopHaveAvailableBizMins(p: { arrivalDT: DateTime }) {
        if (isFullDaySearch(p.arrivalDT)) {
            const workshopCalendarDay = this.getDateCalendarDay(p.arrivalDT);

            if (!workshopCalendarDay) return false;

            const startDT = p.arrivalDT.set({
                hour: workshopCalendarDay.open.hour,
                minute: workshopCalendarDay.open.minute,
            });
            const endDT = p.arrivalDT.set({
                hour: workshopCalendarDay.close.hour,
                minute: workshopCalendarDay.close.minute,
            });

            const freeBizMins = this.businessMinsBetweenIncludingGeneralBlocks({
                startDT,
                endDT,
            });

            return freeBizMins >= this.wantedServicesDuration;
        } else {
            // if it's not full day search, then it's immediate reservation;
            const employeeCalendarDay = this.getDateCalendarDay(p.arrivalDT);

            if (!employeeCalendarDay) return false;

            const freeBizMins = this.businessMinsBetweenIncludingGeneralBlocks({
                startDT: p.arrivalDT,
                endDT: p.arrivalDT.plus({
                    minutes:
                        this.wantedServicesDuration + MINS_MARGIN_FOR_IMMEDIATE,
                }),
            });
            return freeBizMins >= this.wantedServicesDuration;
        }
    }

    public isAvailableForServices(
        dateTime: DateTime,
        options?: {
            checkGeneralBlockedTimeslots?: boolean;
            checkEmployeesAvailability?: boolean;
            immediateReservation?: boolean;
        }
    ) {
        const isFullDay = isFullDaySearch(dateTime);
        const finalOptions = {
            checkGeneralBlockedTimeslots: true,
            checkEmployeesAvailability: true,
            immediateReservation: false,
            ...options,
        };

        if (!this.isDTInsideBusinessHours(dateTime)) return false;

        if (this.canAcceptFromDT && this.canAcceptFromDT > dateTime)
            return false;

        if (
            finalOptions.checkGeneralBlockedTimeslots &&
            !isFullDay && // if it's full day search, then it's not relevant to check blocked timeslots, because nothing won't be ever found.
            this.isDateInsideBlockedGeneralTimeslots(dateTime)
        ) {
            return false;
        }

        if (
            finalOptions.checkGeneralBlockedTimeslots &&
            (finalOptions.immediateReservation || isFullDay) &&
            !this.doesWorkshopHaveAvailableBizMins({
                arrivalDT: dateTime,
            })
        ) {
            return false;
        }

        if (
            finalOptions.checkEmployeesAvailability &&
            !this.hasAvailableEmployees({
                arrivalDT: dateTime,
                immediateReservation: finalOptions.immediateReservation,
            })
        ) {
            return false;
        }

        return true;
    }

    public isDTInsideBusinessHours(dateTime: DateTime): boolean {
        if (isFullDaySearch(dateTime)) {
            if (!this.getDateCalendarDay(dateTime)) {
                return false;
            }
        } else {
            if (!this.isDateInsideCalendarBusinessHours(dateTime)) {
                return false;
            }
        }

        if (this.isPublicHoliday(dateTime)) {
            return false;
        }

        return true;
    }

    public earliestBusinessDate(): Date {
        const nowDT = DateTime.now();
        if (this.isDTInsideBusinessHours(nowDT)) return nowDT.toJSDate();

        const earliestBusinessDate = this.plusBusinessDay(nowDT);
        return earliestBusinessDate.toJSDate();
    }

    private businessMinsBetweenIncludingGeneralBlocks(p: {
        startDT: DateTime;
        endDT: DateTime;
    }): number {
        const { startDT, endDT } = p;

        const relevantBlockEvents = this.generalBlockEvents.filter(
            (blockEvent) => {
                const eventStartDT = DateTime.fromJSDate(blockEvent.startDate);
                const eventEndDT = DateTime.fromJSDate(blockEvent.endDate);

                // either event start date or end date is within the interval;
                return (
                    (eventEndDT >= startDT && eventEndDT <= endDT) ||
                    (eventStartDT >= startDT && eventStartDT <= endDT)
                );
            }
        );
        const relevantBlockEventsOrdered = lOrderBy(
            relevantBlockEvents,
            (e) => e.endDate,
            "asc"
        );

        let currentStartDT = startDT;
        let totalBusinessMinutes = 0;

        while (currentStartDT < endDT) {
            const calendarDay = this.getDateCalendarDay(currentStartDT);

            if (calendarDay && !this.isPublicHoliday(currentStartDT)) {
                const openTime = DateTime.fromObject({
                    year: currentStartDT.year,
                    month: currentStartDT.month,
                    day: currentStartDT.day,
                    hour: calendarDay.open.hour,
                    minute: calendarDay.open.minute,
                });
                const closeTime = DateTime.fromObject({
                    year: currentStartDT.year,
                    month: currentStartDT.month,
                    day: currentStartDT.day,
                    hour: calendarDay.close.hour,
                    minute: calendarDay.close.minute,
                });

                if (currentStartDT < openTime) {
                    // user is booking before opening hours of current day. Set currentDT to openTime.
                    currentStartDT = currentStartDT.set({
                        hour: calendarDay.open.hour,
                        minute: calendarDay.open.minute,
                        second: 0,
                        millisecond: 0,
                    });
                }

                if (openTime <= currentStartDT && currentStartDT < closeTime) {
                    // If currentStartDT is within the business hours of checking day

                    const thisDayEvents = relevantBlockEventsOrdered.filter(
                        (blockEvent) => {
                            const eventStartDT = DateTime.fromJSDate(
                                blockEvent.startDate
                            );
                            const eventEndDT = DateTime.fromJSDate(
                                blockEvent.endDate
                            );

                            const interval = Interval.fromDateTimes(
                                currentStartDT,
                                // whichever comes first.
                                closeTime < endDT
                                    ? closeTime.plus({ minutes: 1 })
                                    : endDT.plus({ minutes: 1 }) // add 1 min to avoid equality check.
                            );

                            return (
                                interval.contains(eventEndDT) ||
                                interval.contains(eventStartDT)
                            );
                        }
                    );

                    thisDayEvents.forEach((calEvent) => {
                        const eventStartDT = DateTime.fromJSDate(
                            calEvent.startDate
                        );
                        const eventEndDT = DateTime.fromJSDate(
                            calEvent.endDate
                        );

                        if (currentStartDT < calEvent.startDate) {
                            const remainingWorkMinutes = Interval.fromDateTimes(
                                currentStartDT,
                                eventStartDT
                            ).toDuration("minutes").minutes;
                            totalBusinessMinutes += remainingWorkMinutes;
                            currentStartDT = eventEndDT;
                        } else if (
                            currentStartDT >= eventStartDT &&
                            currentStartDT <= eventEndDT
                        ) {
                            currentStartDT = eventEndDT;
                        }
                    });

                    if (endDT > currentStartDT && endDT <= closeTime) {
                        // If endDT is within the current working day, calculate only the necessary minutes
                        totalBusinessMinutes += Interval.fromDateTimes(
                            currentStartDT,
                            endDT
                        ).toDuration("minutes").minutes;
                        break;
                    } else {
                        // Add remaining minutes in the current working day
                        const remainingWorkMinutes = Interval.fromDateTimes(
                            currentStartDT,
                            closeTime
                        ).toDuration("minutes").minutes;
                        totalBusinessMinutes += remainingWorkMinutes;
                        currentStartDT = closeTime;
                    }
                }
            }

            // If it's a non-working day or after business hours, just move to the next day
            currentStartDT = this.plusBusinessDay(currentStartDT);
        }

        return totalBusinessMinutes;
    }

    public businessMinsBetween(p: {
        startDT: DateTime;
        endDT: DateTime;
    }): number {
        // Ensure startDT is before endDT
        const { startDT, endDT } = p;

        let currentStartDT = startDT;
        let totalBusinessMinutes = 0;

        while (currentStartDT < endDT) {
            const calendarDay = this.getDateCalendarDay(currentStartDT);

            if (calendarDay && !this.isPublicHoliday(currentStartDT)) {
                const openTime = DateTime.fromObject({
                    year: currentStartDT.year,
                    month: currentStartDT.month,
                    day: currentStartDT.day,
                    hour: calendarDay.open.hour,
                    minute: calendarDay.open.minute,
                });
                const closeTime = DateTime.fromObject({
                    year: currentStartDT.year,
                    month: currentStartDT.month,
                    day: currentStartDT.day,
                    hour: calendarDay.close.hour,
                    minute: calendarDay.close.minute,
                });

                if (currentStartDT < openTime) {
                    // user is booking before opening hours of current day. Set currentDT to openTime.
                    currentStartDT = currentStartDT.set({
                        hour: calendarDay.open.hour,
                        minute: calendarDay.open.minute,
                        second: 0,
                        millisecond: 0,
                    });
                }

                if (openTime <= currentStartDT && currentStartDT < closeTime) {
                    // If endDT is within the current working day, calculate only the necessary minutes
                    if (endDT > currentStartDT && endDT <= closeTime) {
                        totalBusinessMinutes += Interval.fromDateTimes(
                            currentStartDT,
                            endDT
                        ).toDuration("minutes").minutes;
                        break;
                    } else {
                        // Add remaining minutes in the current working day
                        const remainingWorkMinutes = Interval.fromDateTimes(
                            currentStartDT,
                            closeTime
                        ).toDuration("minutes").minutes;
                        totalBusinessMinutes += remainingWorkMinutes;
                        currentStartDT = closeTime;
                    }
                }
            }

            // If it's a non-working day or after business hours, just move to the next day
            currentStartDT = this.plusBusinessDay(currentStartDT);
        }

        return totalBusinessMinutes;
    }

    /** It will either add or subtract business minutes to dateTime. Adds if positive, subtracts if negative */
    public deltaBusinessMinutes(dateTime: DateTime, minutes: number): DateTime {
        const isAdding = minutes >= 0;
        minutes = Math.abs(minutes);
        let currentDT = dateTime;

        while (minutes > 0) {
            const workHours = this.getDateCalendarDay(currentDT);

            if (workHours && !this.isPublicHoliday(currentDT)) {
                const openTime = DateTime.fromObject({
                    year: currentDT.year,
                    month: currentDT.month,
                    day: currentDT.day,
                    hour: workHours.open.hour,
                    minute: workHours.open.minute,
                });
                const closeTime = DateTime.fromObject({
                    year: currentDT.year,
                    month: currentDT.month,
                    day: currentDT.day,
                    hour: workHours.close.hour,
                    minute: workHours.close.minute,
                });

                if (isAdding) {
                    if (currentDT >= closeTime) {
                        currentDT = currentDT.plus({ days: 1 }).startOf("day"); // might just make sense to use // plusBusiness/minusBusiness
                    } else if (currentDT < openTime) {
                        currentDT = openTime;
                    } else {
                        const remainingTodayInterval = Interval.fromDateTimes(
                            currentDT > openTime ? currentDT : openTime,
                            closeTime
                        );
                        const remainingWorkMinutes =
                            remainingTodayInterval.toDuration(
                                "minutes"
                            ).minutes;

                        const minutesToAdd = Math.min(
                            minutes,
                            remainingWorkMinutes
                        );
                        currentDT = currentDT.plus({ minutes: minutesToAdd });
                        minutes -= minutesToAdd;
                    }
                } else {
                    if (currentDT <= openTime) {
                        currentDT = currentDT.minus({ days: 1 }).endOf("day"); // might just make sense to use // plusBusiness/minusBusiness
                    } else if (currentDT > closeTime) {
                        currentDT = closeTime;
                    } else {
                        const remainingTodayInterval = Interval.fromDateTimes(
                            openTime,
                            currentDT
                        );
                        const remainingWorkMinutes =
                            remainingTodayInterval.toDuration(
                                "minutes"
                            ).minutes;

                        const minutesToSubtract = Math.min(
                            minutes,
                            remainingWorkMinutes
                        );
                        currentDT = currentDT.minus({
                            minutes: minutesToSubtract,
                        });
                        minutes -= minutesToSubtract;
                    }
                }
            } else {
                // If it's a non-working day, just move to the next day (or previous if subtracting)
                currentDT = isAdding
                    ? this.plusBusinessDay(currentDT)
                    : this.minusBusinessDay(currentDT);
            }
        }

        return currentDT;
    }
}
