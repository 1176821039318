import useToken from "@FEClient/logic/hooks/useToken/useToken";
import {
    ServicesCompletionType,
    useOrderReviewSummaryQuery,
    useUpdateWarrantyInfoMutation,
} from "@FEShared/graphql/generated/graphql";
import useShowLoadingScreen from "@FEShared/hooks/useShowLoadingScreen";
import showToast from "@FEShared/utils/showToast";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import CompletionSumStep from "./steps/CompletionSumStep/CompletionSumStep";
import useStore, { history } from "@FEClient/logic/store";
import { Review, ReviewOrder } from "./WarrantyActivation.types";
import gqlResHandler from "@FEShared/utils/gqlResHandler";
import { runInAction } from "mobx";
import { ContentContainer } from "@FEClient/views/commonComps/ContentContainer/ContentContainer.styled";
import Text from "@FEShared/components/UI/Text/Text";
import AllServicesProvidedStep from "./steps/AllServicesProvidedStep/AllServicesProvidedStep";
import ReviewStep from "./steps/ReviewStep/ReviewStep";
import Box from "@FEShared/components/UI/Box/Box";
import formatVehicleDescription from "@Shared/util/formatVehicleDescription";
import BackButton from "@FEShared/components/UI/BackButton/BackButton";
import { pageToMeta } from "@Shared/util/clientPagesMeta";
import CompletedStep from "./steps/CompletedStep/CompletedStep";
import isNullableValue from "@Shared/util/isNullableValue";

const WarrantyActivation: React.FC = observer(() => {
    const GS = useStore();
    const LS = useLocalObservable(() => ({
        step: 1 as number,
        order: undefined as undefined | ReviewOrder,
        warrantyData: {
            clientProvidedPrice: undefined as undefined | number,
            goodPrice: undefined as undefined | boolean,
            allServicesProvided: undefined as
                | undefined
                | ServicesCompletionType,
        },
        review: undefined as undefined | Review,
        reviewOnly: false,
    }));

    const token = useToken();
    const { orderId } = useParams<{ orderId: string }>();
    const [summaryRes, summaryReq] = useOrderReviewSummaryQuery({
        variables: { orderId: +orderId, accessToken: token },
        pause: !token || !orderId,
    });
    const [_, updateWarrantyInfoReq] = useUpdateWarrantyInfoMutation();

    React.useEffect(() => {
        gqlResHandler(summaryRes, (data) => {
            if (!data.order) {
                return console.error(
                    `Didn't find workshop in reservation succ page! OrderID: ${data.order}`
                );
            }

            runInAction(() => {
                LS.order = data.order;
                LS.review = data.orderReview;
                if (
                    !isNullableValue(data.order.goodPrice) &&
                    !isNullableValue(data.order.servicesCompletion)
                ) {
                    LS.warrantyData.goodPrice = data.order.goodPrice;
                    LS.warrantyData.allServicesProvided =
                        data.order.servicesCompletion;
                }
            });
        });
    }, [summaryRes, LS]);

    useShowLoadingScreen(summaryRes, GS);

    React.useEffect(() => {
        summaryReq();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (LS.order && LS.order.status !== "completed") {
            showToast.warn("Užsakymas dar nėra užbaigtas.");
            history.push(pageToMeta.HOME.url);
            return;
        } else if (
            LS.order &&
            !isNullableValue(LS.order.goodPrice) &&
            !isNullableValue(LS.order.servicesCompletion) &&
            !LS.review
        ) {
            // warranty activated but no review
            runInAction(() => {
                LS.reviewOnly = true;
                LS.step = 3;
            });
        } else if (
            LS.order &&
            !isNullableValue(LS.order.goodPrice) &&
            !isNullableValue(LS.order.servicesCompletion)
        ) {
            runInAction(() => {
                LS.step = 4;
            });
            return;
        }
    }, [LS.order, LS]);

    if (!LS.order || !LS.order.acceptedService) {
        return <div>Kraunama..</div>;
    }

    const endFlow = async () => {
        if (!LS.order) {
            return showToast.error("Netikėta klaida.", true);
        }
        await updateWarrantyInfoReq({
            p: {
                accessToken: token,
                orderID: LS.order.ID,
                goodPrice: LS.warrantyData.goodPrice,
                servicesCompletion: LS.warrantyData.allServicesProvided,
                clientProvidedPrice: LS.warrantyData.clientProvidedPrice,
            },
        });
        runInAction(() => {
            LS.step = 4;
        });
    };

    let stepComponent;
    if (LS.step === 1) {
        stepComponent = (
            <CompletionSumStep
                onNewPriceChange={(num) => {
                    runInAction(() => {
                        LS.warrantyData.clientProvidedPrice = num;
                    });
                }}
                clientProvidedPrice={LS.warrantyData.clientProvidedPrice}
                onChange={(isGoodPrice) => {
                    runInAction(() => {
                        LS.warrantyData.goodPrice = isGoodPrice;
                    });
                }}
                goodPrice={LS.warrantyData.goodPrice}
                order={LS.order}
                onBtnClick={() => {
                    runInAction(() => {
                        LS.step = 2;
                    });
                }}
            />
        );
    } else if (LS.step === 2) {
        stepComponent = (
            <AllServicesProvidedStep
                btnText={LS.review ? "Baigti" : "Tęsti"}
                onChange={(allServicesProvided) => {
                    runInAction(() => {
                        LS.warrantyData.allServicesProvided =
                            allServicesProvided;
                    });
                }}
                allServicesReceived={LS.warrantyData.allServicesProvided}
                order={LS.order}
                onBtnClick={async () => {
                    if (LS.review) {
                        await endFlow();
                    } else {
                        runInAction(() => {
                            LS.step = 3;
                        });
                    }
                }}
            />
        );
    } else if (LS.step === 3) {
        stepComponent = (
            <ReviewStep
                onReviewChange={(review) => {
                    runInAction(() => {
                        LS.review = review;
                    });
                }}
                reviewOnly={LS.reviewOnly}
                order={LS.order}
                onBtnClick={async () => {
                    await endFlow();
                }}
            />
        );
    } else if (LS.step === 4) {
        stepComponent = (
            <CompletedStep
                error={LS.warrantyData.goodPrice === false}
                review={LS.review}
            />
        );
    } else {
        showToast.error("Iškilo netikėta klaida. #FF", true);
        return null;
    }

    let title;
    if (LS.step === 4) {
        title = "🔧 Užsakymas baigtas";
    } else if (LS.reviewOnly) {
        title = "⭐ Pasidalinkite patirtimi";
    } else if (
        window.location.pathname.includes(pageToMeta.WARRANTY_N_REVIEW.url) ||
        window.location.pathname.includes(
            pageToMeta.WARRANTY_N_REVIEW.shortUrl as string
        )
    ) {
        title = "🔓 Aktyvuok garantija ir palik atsiliepimą";
    } else {
        title = "🔒 Garantijos aktyvacija";
    }

    return (
        <ContentContainer
            pt={3}
            pb={3}
            sx={{
                maxWidth: "550px!important",
            }}
        >
            <Box
                sx={{ bgcolor: "white.main", minHeight: 300 }}
                rounded
                padding={2}
                vertical
            >
                <Text mb={2} fontSize={24} semiBold>
                    {title}
                </Text>
                <Box borderBottom="1px solid #e0e0e0" pb={1} mb={1}>
                    <Text variant="subtitle1">Rezervacija #{LS.order.ID}</Text>
                    <Text variant="subtitle1">
                        {[
                            LS.order.acceptedService.name,
                            LS.order.acceptedService.address,
                            LS.order.acceptedService.city,
                        ].join(", ")}
                    </Text>
                    <Text variant="subtitle1">
                        {formatVehicleDescription(LS.order)}
                    </Text>
                    <Box>
                        <Text variant="subtitle1">
                            {LS.order?.categories
                                .map((c) => c.serviceName)
                                .join(", ")}
                        </Text>
                    </Box>
                </Box>
                <Box mb={2}>
                    {LS.step > 1 && LS.step < 4 && !LS.reviewOnly && (
                        <BackButton
                            onClick={() => {
                                runInAction(() => {
                                    LS.step--;
                                });
                            }}
                        >
                            Atgal
                        </BackButton>
                    )}
                </Box>
                {stepComponent}
            </Box>
        </ContentContainer>
    );
});

export default WarrantyActivation;
