import useStore from "@FEClient/logic/store";
import React from "react";
import * as S from "./MobileServiceSelection.styled";
import { WorkshopOmitted } from "../../Search.types";
import isWorkshopSpecialized from "@FEClient/logic/utils/isWorkshopSpecialized";
import SpecializedBadge from "../../SpecializedBadge/SpecializedBadge";
import Text from "@FEShared/components/UI/Text/Text";
import { observer } from "mobx-react-lite";
import Box from "@FEShared/components/UI/Box/Box";
import { dateToMonthHourWithPrettyText } from "@Shared/util/dateFormat";
import Icon from "@FEShared/components/UI/Icon/Icon";

export const MOBILE_SERVICE_SELECTION_CLASS_IDENTIFIER =
    "MobileServiceSelection";

const MobileServiceSelection: React.FC<{ mapItem: WorkshopOmitted }> = observer(
    (p) => {
        const GS = useStore();
        const SPS = GS.searchPageState;
        const specialization = isWorkshopSpecialized(p.mapItem, {
            vehicleBrand: GS.searchState.carData.vehicleBrand,
            servicesIDs: GS.searchState.selectedServiceIds,
        });

        const priceNDuration = GS.getSelectedServicesPriceNDurationForWorkshop(
            p.mapItem,
            true
        );

        const newArrivalTime =
            GS.searchPageState.altFlow.newWorkshopSuggestedTime(p.mapItem.ID);

        return (
            <S.MobileServiceSelectionContainer
                className={MOBILE_SERVICE_SELECTION_CLASS_IDENTIFIER}
                onClick={(_e) => {
                    GS.searchPageState.onWorkshopChoose({
                        ID: p.mapItem.ID,
                        name: p.mapItem.name,
                    });
                }}
            >
                <S.PicInfoWrapper>
                    <S.PicWrapper>
                        <S.Pic src={p.mapItem.logoURL || "#"} />
                    </S.PicWrapper>
                    <S.InfoWrap>
                        <Box vertical>
                            <S.Title>{p.mapItem.name}</S.Title>
                            {/* <S.Address>{p.mapItem.address}</S.Address> */}
                            {specialization.isSpecializedForUser && (
                                <SpecializedBadge>
                                    {specialization.displayString}
                                </SpecializedBadge>
                            )}
                            {newArrivalTime && (
                                <Text
                                    color={
                                        newArrivalTime.new
                                            ? "warning.main"
                                            : "grey"
                                    }
                                    fontSize={11}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <>
                                        <Text
                                            mr={0.5}
                                            component="i"
                                            className="icon-clock"
                                            fontSize="inherit"
                                        />
                                        {newArrivalTime.text}
                                    </>
                                </Text>
                            )}
                            {p.mapItem.earliestAvailableDate &&
                                !GS.searchPageState.altFlow.isAlt && (
                                    <Text fontSize={12}>
                                        <Icon
                                            mr={0.5}
                                            className="icon-clock-rotate"
                                            fontSize={"10px!important"}
                                        />
                                        Artimiausias:{" "}
                                        <Text semiBold span variant="inherit">
                                            {dateToMonthHourWithPrettyText(
                                                new Date(
                                                    p.mapItem.earliestAvailableDate
                                                )
                                            )}
                                        </Text>
                                    </Text>
                                )}
                            {p.mapItem.badgeOrdersCount ? (
                                <Box
                                    padding={0.5}
                                    bgcolor={"#f0f2f9"}
                                    fontSize={12}
                                    borderRadius={"5px"}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        alignSelf: "flex-start",
                                    }}
                                >
                                    <Icon
                                        color={"#40BD81"}
                                        mr={0.5}
                                        className="icon-chart-up"
                                        fontSize={"10px!important"}
                                    />
                                    {p.mapItem.badgeOrdersCount}+ užsakymų per
                                    30d.
                                </Box>
                            ) : undefined}
                        </Box>
                        <S.BottomWrap>
                            <S.Price>
                                {priceNDuration ? (
                                    <S.PriceLabel>
                                        {priceNDuration.servicesPrice.text}
                                    </S.PriceLabel>
                                ) : (
                                    <>
                                        <S.PriceLabel>
                                            €{p.mapItem.hourCost}
                                        </S.PriceLabel>
                                        <S.PriceText>val. įkainis</S.PriceText>
                                    </>
                                )}
                            </S.Price>
                            <S.StarsWrapper>
                                <S.StarIcon className="icon-star-full" />
                                <S.ReviewScore>
                                    {(p.mapItem.reviewRatingGmap || 5).toFixed(
                                        1
                                    )}{" "}
                                </S.ReviewScore>
                                <S.ReviewCount>
                                    ({p.mapItem.reviewCountGmap || 1})
                                </S.ReviewCount>
                            </S.StarsWrapper>
                        </S.BottomWrap>
                    </S.InfoWrap>
                </S.PicInfoWrapper>
                <S.BottomBtn fullWidth>{SPS.workshopBtnText()}</S.BottomBtn>
            </S.MobileServiceSelectionContainer>
        );
    }
);

export default MobileServiceSelection;
