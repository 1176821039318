import { CATEGORIES_ORDER } from "@Shared/consts/commonConsts";
import STAR_IMG from "./assets/star.png";
import CHASSIS_IMG from "./assets/chassis.png";
import ENGINE_IMG from "./assets/engine.png";
import GEARBOX_IMG from "./assets/gearbox.png";
import OIL_IMG from "./assets/oil.png";

import DOOR_IMG from "./assets/door.png";
import INSPECTION_IMG from "./assets/inspection.png";
import CONDITIONER_IMG from "./assets/conditioner.png";
import EXHAUST_IMG from "./assets/exhaust.png";
import SEAT_IMG from "./assets/seat.png";
import WHEEL_IMG from "./assets/wheel.png";
import CABELS_IMG from "./assets/cabels.png";
import BRAKES_IMG from "./assets/brakes.png";

import BULB_IMG from "./assets/bulb.png";
import STEERING_WHEEL from "./assets/steering-wheel.png";
import BRUSH_IMG from "./assets/brush.png";
import WINDOW_IMG from "./assets/window.png";

export const OTHER_CATEG = "Kita";

export const PART_CATEG_TO_IMG_URL: Record<
    (typeof CATEGORIES_ORDER)[number],
    string | undefined
> = {
    Populiariausi: STAR_IMG,
    Aptarnavimai: OIL_IMG,
    Variklis: ENGINE_IMG,
    "Pavarų dėžė": GEARBOX_IMG,
    "Važiuoklė (Pakaba)": CHASSIS_IMG,
    Duslintuvas: EXHAUST_IMG,
    Elektronika: CABELS_IMG,
    "Švara ir Estetika": BRUSH_IMG,
    Kondicionierius: CONDITIONER_IMG,
    Kėbulas: DOOR_IMG,
    Patikra: INSPECTION_IMG,
    Ratai: WHEEL_IMG,
    Salonas: SEAT_IMG,
    Stabdžiai: BRAKES_IMG,
    Stiklai: WINDOW_IMG,
    Vairas: STEERING_WHEEL,
    Žibintai: BULB_IMG,
    Kita: undefined,
};
