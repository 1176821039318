import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import GoogleMap from "./GoogleMap/GoogleMap";
import * as S from "./SearchMap.styled";
import useIsMobile from "../../../../../../FEShared/hooks/useIsMobile";
import MobileServiceSelection from "./MobileServiceSelection/MobileServiceSelection";
import useStore from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";
import { WorkshopOmitted } from "../Search.types";
import { ALL_CITIES, CITIES_WITH_ALL } from "@FEShared/consts/CITIES";
import { LiteralValuesFromArray } from "@Shared/types/utils";
import Box from "@FEShared/components/UI/Box/Box";
import showToast from "@FEShared/utils/showToast";
import { runInAction } from "mobx";

const CITY_TO_COORDS_MAP: Record<
    LiteralValuesFromArray<typeof CITIES_WITH_ALL>,
    { lat: number; lng: number }
> = {
    Vilnius: { lat: 54.704909, lng: 25.265015 },
    Kaunas: {
        lat: 54.897174,
        lng: 23.921823,
    },
    Klaipėda: {
        lat: 55.69267177399215,
        lng: 21.15565199738188,
    },
    Panevėžys: {
        lat: 55.731401963750905,
        lng: 24.360492642963635,
    },
    Šiauliai: {
        lat: 55.93274417912639,
        lng: 23.31399448130401,
    },
    Alytus: {
        lat: 54.39619534624808,
        lng: 24.047829885647555,
    },
    [ALL_CITIES]: {
        lat: 55.27987337538571,
        lng: 24.07375031434483,
    },
};

interface P {
    mapItems: WorkshopOmitted[];
    city: string;
}
const SearchMap: React.FC<P> = observer((p) => {
    const isMobile = useIsMobile();
    const GS = useStore();
    const SPS = GS.searchPageState;
    const activeMapItem = p.mapItems.find(
        (i) => i.ID === SPS.selectedServiceId
    );
    // const LS = useLocalObservable(() => ({
    //     showBanner: new Date().getDate() % 3 === 0,
    // }));

    React.useEffect(() => {
        // delete backup pos on search. Overall, this is a bit hacky. Best would be to control everything directly
        // via state, without any backups, but there wasn't a proper react gmap wrapper.
        SPS.resetPosBackup();
    }, [SPS]);

    return (
        <S.SearchMapContainer
            onTouchEnd={() => {
                const currentScrollPos =
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (isMobile && currentScrollPos !== 0) {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            }}
        >
            {SPS.isLoading && (
                <S.LoaderWrapper>
                    <S.CircularLoader />
                    <S.CircularLoader />
                    <S.CircularLoader />
                </S.LoaderWrapper>
            )}
            {/* {LS.showBanner && (
                <S.LagBanner>
                    Šiuo metu gauname daug užsakymų, todėl SMS patvirtinimai
                    gali užtrukti iki 2 minučių.
                    <S.CloseIcon
                        className="icon-cross"
                        onClick={() =>
                            runInAction(() => (LS.showBanner = false))
                        }
                    />
                </S.LagBanner>
            )} */}
            <Wrapper
                apiKey={
                    window._IS_PROD
                        ? "AIzaSyDUaz1Wnv_95NR6Dcm9s24jV_VvaSX0zv0"
                        : "AIzaSyAQXWud2x-ccHSAILkHn8Rrz_AZoLQ79_g"
                }
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: isMobile ? "16px" : "98px",
                        right: "10px",
                        zIndex: 2,
                    }}
                    displayFlex
                    alignVertical="center"
                    align="center"
                    vertical
                >
                    <S.MapControlIcon
                        sx={{
                            cursor: "pointer",
                            width: "40px",
                            height: "40px",
                            bgcolor: "rgb(247, 247, 247)",
                            fontSize: 20,
                            color: "black",
                        }}
                        onClick={() => {
                            if (navigator.geolocation) {
                                navigator.geolocation.getCurrentPosition(
                                    (position: GeolocationPosition) => {
                                        runInAction(() => {
                                            SPS.currentDevicePos = {
                                                latitude:
                                                    position.coords.latitude,
                                                longitude:
                                                    position.coords.longitude,
                                            };
                                        });
                                    },
                                    () => {
                                        showToast.warn(
                                            "Įvyko klaida nustatant jūsų buvimo vietą."
                                        );
                                    }
                                );
                            } else {
                                showToast.warn(
                                    "Jūsų naršyklė/įrenginys negali nustatyti jūsų buvimo vietos."
                                );
                            }
                        }}
                        className="icon-location-arrow"
                    />
                </Box>
                <GoogleMap
                    key={p.city}
                    disabled={!!SPS.isLoading}
                    center={
                        SPS.posBackup.city === p.city && SPS.posBackup.position
                            ? SPS.posBackup.position
                            : CITY_TO_COORDS_MAP[p.city]
                    }
                    zoom={
                        SPS.posBackup.zoom || (p.city === ALL_CITIES ? 8 : 12)
                    }
                    mapItems={p.mapItems}
                />
            </Wrapper>
            {!GS.searchPageState.isLoading && isMobile && activeMapItem && (
                <MobileServiceSelection mapItem={activeMapItem} />
            )}
        </S.SearchMapContainer>
    );
});

export default SearchMap;
