import showToast from "@FEShared/utils/showToast";
import { pageToMeta } from "@Shared/util/clientPagesMeta";
import React from "react";
import { Redirect } from "react-router-dom";

const ClientOnly: React.FC = () => {
    React.useEffect(() => {
        console.log("effect");
        showToast.warn(
            "Šis puslapis skirtas tik klientui, todėl buvote nukreiptas į pagrindinį puslapį."
        );
    }, []);
    return <Redirect to={pageToMeta.HOME.url} />;
};

export default ClientOnly;
