import { Fade } from "@mui/material";
import React from "react";
import * as S from "./LoaderScreen.styled";

const LoaderScreen: React.FC<{ show: boolean }> = (p) => {
    return (
        <Fade
            in={p.show}
            timeout={{
                exit: 500,
                appear: 0,
            }}
        >
            <S.LoaderContainer>
                <S.LoaderLogo />
                <S.Loader />
            </S.LoaderContainer>
        </Fade>
    );
};

export default LoaderScreen;
