import { CATEGORIES_ORDER } from "@Shared/consts/commonConsts";

const SUBCATEGORIES_ORDER: Record<
    (typeof CATEGORIES_ORDER)[number],
    Record<string, number | undefined> | undefined
> = {
    Aptarnavimai: {
        "Tepalai ir filtrai": 100013,
        "Diržai ir grandinės": 10009,
    },
    Elektronika: {
        Diagnostika: 100055,
        "Elektronikos remontas": 100044,
        "Parktronikai (parkavimo sistemos)": 100034,
        "Apsaugos sistemos, raktai, spynos": 100031,
        "Garso/video sistemos": 100022,
        "Navigacija (GPS)": 100016,
        "Komforto sistemos": 100012,
        "Vaizdo kameros": 10008,
        Kita: 10004,
    },
    Duslintuvas: {
        "Duslintuvų remontas": 100015,
        "Duslintuvų diagnostika": 100010,
        "Dūmingumo matavimas": 10003,
    },
    Kondicionierius: {
        "Kondicionieriaus pildymas": 100012,
        "Kondicionieriaus remontas": 10008,
    },
    Kėbulas: {
        "Kėbulo apsauga": 100033,
        Dažymas: 100027,
        "Kėbulo remontas": 100017,
        "Kėbulo lyginimas (be dažymo)": 100011,
        Kita: 10006,
    },
    "Pavarų dėžė": {
        Sankaba: 100021,
        "Pavarų dėžės tepalų keitimas": 100018,
        "Pavarų dėžės remontas": 100014,
        "Transmisijos remontas": 10007,
    },
    Ratai: { "Padangų montavimas": 100023, Ratlankiai: 10006 },
    Salonas: {
        "AirBag (oro pagalvės)": 100022,
        Sėdynės: 100019,
        "Automobilio interjeras": 100011,
    },
    Stabdžiai: { "Stabdžių remontas": 100016 },
    Stiklai: {
        "Stiklų remontas": 100014,
        "Stiklų keitimas": 100011,
        "Langų tamsinimas/tonavimas": 10007,
    },
    Vairas: {},
    "Važiuoklė (Pakaba)": {
        "Važiuoklės remontas": 100018,
        Amortizatoriai: 10009,
    },
    Variklis: {
        "Variklio remontas": 100049,
        "Diržai ir grandinės": 100043,
        "Kuro sistema": 100039,
        Purkštukai: 100035,
        Turbina: 100027,
        "Aušinimo sistema": 100023,
        Žvakės: 100015,
        Dujos: 100012,
        Kompresorius: 10008,
        "Degalų bakas": 10003,
    },
    "Švara ir Estetika": { "Vidaus valymas": 100016, "Kėbulo plovimas": 10007 },
    Žibintai: {},
    Populiariausi: {},
    Patikra: {},
    Kita: {},
};
export default SUBCATEGORIES_ORDER;
