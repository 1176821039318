import { css } from "@mui/material/styles";
import styled from "styled-components";

export const Logo = styled("i")`
    max-width: 121px;
    margin-right: 5px;
    color: ${(p) => p.theme.palette.primary.main};
    font-size: 32px;
    vertical-align: middle;
`;

export const MobileLogo = styled("i")`
    color: ${(p) => p.theme.palette.primary.main};
    vertical-align: middle;
    margin-right: 5px;
    font-size: 32px;
`;

export const TransparentLogo = styled("i")`
    color: white;
    vertical-align: middle;
    margin-right: 5px;
    font-size: 32px;
`;

export const LogoMark = styled("i")`
    color: ${(p) => p.theme.palette.primary.main};
    margin-right: 10px;
`;

export const LogoText = styled("i")``;

export const LogoWrapper = styled("div")<{ $white?: boolean }>`
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    color: black;

    ${(p) =>
        p.$white &&
        css`
            color: white;
            ${LogoMark} {
                color: white;
            }
        `}
`;
