import { WorkshopService } from "@FEShared/graphql/generated/graphql";
import getServicePriceObj from "./getServicePriceObj";
import priceObjToPriceStringObj, {
    PriceStringObj,
} from "./priceObjToPriceStringObj";

export default function getServicePriceString(
    service: Pick<
        WorkshopService,
        "fixedPrice" | "fromPrice" | "toPrice" | "durationFromMins"
    > & {
        type: Pick<
            WorkshopService["type"],
            "durationMinsPerUnit" | "defaultUnitCount" | "priceType"
        >;
    },
    hourCost: number
): PriceStringObj {
    const priceObj = getServicePriceObj(service, hourCost);
    return priceObjToPriceStringObj({ priceObj });
}
