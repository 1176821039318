import React from "react";
import { Redirect, useParams } from "react-router-dom";
import {
    useCreateReviewMutation,
    useOrderReviewSummaryQuery,
} from "@FEShared/graphql/generated/graphql";
import gqlResHandler from "@FEShared/utils/gqlResHandler";
import useToken from "@FEClient/logic/hooks/useToken/useToken";
import { pageToMeta } from "@Shared/util/clientPagesMeta";
import ReviewForm from "./ReviewForm/ReviewForm";
import SavedReview from "./SavedReview/SavedReview";
import { SaveReviewParams } from "./ReviewForm/ReviewForm.types";
import useShowLoadingScreen from "@FEShared/hooks/useShowLoadingScreen";
import useStore from "@FEClient/logic/store";

const ReviewPage: React.FC = () => {
    const token = useToken();
    const GS = useStore();
    const { orderId } = useParams<{ orderId: string }>();

    const [_, createReview] = useCreateReviewMutation();

    const [summaryRes, summaryReq] = useOrderReviewSummaryQuery({
        variables: { orderId: +orderId, accessToken: token },
        pause: !token || !orderId,
    });
    useShowLoadingScreen(summaryRes, GS);

    const { data, error, fetching } = summaryRes;
    const { order, orderReview } = data || {};

    const saveReview = async (p: SaveReviewParams) => {
        const response = await createReview({
            accessToken: token,
            orderId: +orderId,
            params: {
                rating: p.rating,
                text: p.reviewText || null,
                authorName: p.name || null,
            },
        });

        gqlResHandler(response, () => {
            summaryReq({ requestPolicy: "network-only" });
        });
    };

    if (error || (!fetching && !data)) {
        console.log("return");
        return <Redirect to={pageToMeta.HOME.url} />;
    }

    if (fetching) {
        return null;
    }

    if (!order) {
        console.error("Couldn't find order for review", orderId);
        return <Redirect to={pageToMeta.HOME.url} />;
    }

    if (order && orderReview) {
        return (
            <SavedReview
                workshopName={order.acceptedService?.name ?? ""}
                workshopAddress={order.acceptedService?.address ?? ""}
                serviceName={order.categories
                    .map((c) => c.serviceName)
                    .join(", ")}
                rating={orderReview.rating}
                reviewText={orderReview.text}
                customerName={orderReview.authorName}
                date={new Date(orderReview.createdAt)}
            />
        );
    }

    return <ReviewForm order={order} onSave={saveReview} />;
};

export default ReviewPage;
