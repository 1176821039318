import { pageToMeta } from "@Shared/util/clientPagesMeta";
import { history } from "../../store";
import React from "react";
import getToken from "@FEClient/logic/utils/getToken";

const useToken = (): string => {
    const token = React.useMemo(() => {
        return getToken();
    }, []);

    if (!token) {
        history.push(pageToMeta.HOME.url);
        return "";
    }

    return token;
};

export default useToken;
