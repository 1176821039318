import { SeoPages } from "@FEShared/types/common";
import capFirst from "@Shared/util/capFirst";
import urlifyString from "@FEShared/utils/urlifyString";
import ALL_SERVICES from "@FEShared/consts/ALL_SERVICES";
import FECLIENT_SERVICES_SEO_PAGES from "./FECLIENT_SERVICES_SEO_PAGES";

const customServicesLandings = Object.values(FECLIENT_SERVICES_SEO_PAGES).map(
    (page) => page.serviceDefinitionID
);
const filteredServices = ALL_SERVICES.filter(
    (s) => !customServicesLandings.includes(s.ID)
);

const TEMPLATE_FAQ = [
    {
        question:
            "Kur atliekama {serviceName} Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
        answer: "Pasirink savo automobilį, paslaugas, norimą atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje surastum autoservisą, kuris atlieka {serviceName} Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
    },
    {
        question:
            "Kur atliekama {serviceName} savaitgalį (šeštadienį, sekmadienį)?",
        answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {serviceName} savaitgalį (šeštadienį/sekmadienį).",
    },
    {
        question: "Kur atliekama {serviceName} po darbo valandų?",
        answer: "Pasirink savo automobilį, paslaugas ir norimą atvykimo laiką, kad paieškos puslapyje surastum autoservisą, kur atliekama {serviceName} po darbo valandų.",
    },
    {
        question: "Kur atliekama pigus {serviceName}?",
        answer: "Pasirinkus automobilį ir reikiamą paslaugą, paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekama pigus {serviceName}.",
    },
];

const FECLIENT_ALL_SERVICES_SEO_PAGES: SeoPages = (() => {
    return filteredServices.reduce((acc, service) => {
        acc["/" + urlifyString(service.serviceName)] = {
            seoServiceName: service.serviceName,
            serviceDefinitionID: service.ID,
            faq: TEMPLATE_FAQ.map((f) => {
                return {
                    question: capFirst(
                        f.question.replace(
                            /{serviceName}/g,
                            service.serviceName
                        ),
                        false
                    ),
                    answer: capFirst(
                        f.answer.replace(/{serviceName}/g, service.serviceName),
                        false
                    ),
                };
            }),
        };
        return acc;
    }, {} as SeoPages);
})();

export default FECLIENT_ALL_SERVICES_SEO_PAGES;
