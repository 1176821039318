import ContentContainer from "@FEClient/views/commonComps/ContentContainer/ContentContainer";
import Box from "@FEShared/components/UI/Box/Box";
import Button from "@FEShared/components/UI/Button/Button";
import styled from "styled-components";

export const ChosenServicesTitle = styled("div")`
    font-weight: 500;
    color: white;
`;

export const ChosenServicesWrapper = styled("div")`
    display: flex;
`;

export const ServiceWrapper = styled("div")`
    background: #efefef;
    border-radius: 10px;
    padding: 8px 16px;
    color: black;
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-right: 8px;
    }
`;

export const ServiceText = styled("div")`
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
`;

export const CrossIcon = styled("i")`
    font-size: 14px;
    margin-left: 16px;
    height: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e0e0e0;
    border-radius: 7px;
    cursor: pointer;
`;

export const RightSideWrapper = styled("div")`
    margin-left: 16px;
    display: flex;
    align-items: center;
    margin-top: -8px;
    margin-bottom: -8px;
    height: 100%;
`;

export const TotalPrice = styled("div")`
    font-size: 16px;
    margin-right: 16px;
    color: white;
`;

export const CheckmarkIcon = styled("i")`
    cursor: pointer;
    margin-left: 16px;
`;

export const ConfirmBtn = styled(Button)`
    height: 100%;
    border-radius: 0;
    background: #462bbe !important;
    white-space: nowrap;
    padding-left: 8px;
    font-size: 14px;
    padding-right: 8px;
`;

export const StickyBottomBarContainer = styled("div")`
    position: fixed;
    background: #5e39fe;
    z-index: 5000;
    display: flex;
    align-items: center;
    color: black;
    justify-content: space-between;
    overflow: hidden;
    width: 100% !important;
    left: 0;
    bottom: 0;
    height: 60px;
    padding-left: 8px;

    ${RightSideWrapper} {
        margin-left: unset;
    }
    ${TotalPrice} {
        min-width: 80px;
        font-size: 12px;
        text-align: center;
        margin-right: 8px;
        margin-left: 8px;
    }
`;
