import {
    DONT_KNOW_MODEL,
    DONT_KNOW_YEAR,
} from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.consts";
import { OrderCarData } from "@Shared/types/types";

export default function formatVehicleDescription(order: OrderCarData) {
    const vehicleModel =
        order.vehicleModel && order.vehicleModel !== DONT_KNOW_MODEL
            ? `${order.vehicleModel}`
            : undefined;

    const vehicleYear =
        order.vehicleYear && order.vehicleYear !== DONT_KNOW_YEAR
            ? `${order.vehicleYear}m.`
            : undefined;

    return [
        order.vehicleLicensePlate && order.vehicleLicensePlate + ",",
        order.vehicleBrand,
        vehicleModel,
        vehicleYear,
    ]
        .filter(Boolean)
        .join(" ");
}
