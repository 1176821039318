import { BoxP } from "@FEShared/components/UI/Box/Box";
import React from "react";
import * as S from "./ContentContainer.styled";

/** This component enforces content to be inside our "content width" that is set in the theme. */
interface P extends BoxP {
    noMobilePadding?: boolean;
    fullWidth?: boolean;
}
const ContentContainer: React.FCC<P> = (p) => {
    const { noMobilePadding, ...restProps } = p;
    return (
        <S.ContentContainer
            {...restProps}
            $noMobilePadding={noMobilePadding}
            $fullWidth={p.fullWidth}
        >
            {p.children}
        </S.ContentContainer>
    );
};

export default ContentContainer;
