import Box from "@FEShared/components/UI/Box/Box";
import React from "react";
import Text from "@FEShared/components/UI/Text/Text";
import FECLIENT_DISTRICT_SEO_PAGES from "@Shared/consts/FECLIENT_DISTRICT_SEO_PAGES";
import FECLIENT_SERVICES_SEO_PAGES from "@Shared/consts/FECLIENT_SERVICES_SEO_PAGES";
import * as S from "./Sitemap.styled";
import ALL_SERVICES from "@FEShared/consts/ALL_SERVICES";
import CITIES from "@FEShared/consts/CITIES";
import { UrlPathnameParser } from "../Search/Search.logic";
import FECLIENT_BRANDS_SEO_PAGES from "@Shared/consts/FECLIENT_BRANDS_SEO_PAGES";
import FECLIENT_ALL_SERVICES_SEO_PAGES from "@Shared/consts/FECLIENT_ALL_SERVICES_SEO_PAGES";

const Sitemap: React.FC = () => {
    return (
        <Box padding={3}>
            <Text variant="h1" center mb={2}>
                Svetainės struktūra
            </Text>
            <Box
                displayFlex
                justifyContent="space-between"
                sx={{
                    "&>*": {
                        flex: 1,
                    },
                }}
            >
                <Box vertical mr={2}>
                    <Text variant="h2" mb={2}>
                        Autoservisai pagal miesta ir paslaugą
                    </Text>
                    {CITIES.flatMap((city) => {
                        return (
                            <>
                                <Text variant="h3">{city}</Text>
                                {ALL_SERVICES.map((service) => {
                                    return (
                                        <S.Url
                                            key={service.serviceName + city}
                                            href={UrlPathnameParser.paramsToPathname(
                                                {
                                                    city: city,
                                                    selectedServiceName:
                                                        service.serviceName,
                                                }
                                            )}
                                        >
                                            {`${service.serviceName} | Autoservisai ${city}`}
                                        </S.Url>
                                    );
                                })}
                            </>
                        );
                    })}
                </Box>
                <Box vertical mr={2}>
                    <Text variant="h2" mb={2}>
                        Autoservisai pagal mikrorajonus
                    </Text>
                    {Object.keys(FECLIENT_DISTRICT_SEO_PAGES).map((url) => (
                        <S.Url key={url} href={url}>
                            {url.replace(/-/g, " ").replace("/", "")}
                        </S.Url>
                    ))}
                </Box>
                <Box vertical mr={2}>
                    <Text variant="h2" mb={2}>
                        Autoservisai pagal markes
                    </Text>
                    {Object.keys(FECLIENT_BRANDS_SEO_PAGES).map((url) => (
                        <S.Url key={url} href={url}>
                            {url.replace(/-/g, " ").replace("/", "")}
                        </S.Url>
                    ))}
                </Box>
                <Box vertical>
                    <Text variant="h2" mb={2}>
                        Automobilių remonto paslaugos
                    </Text>
                    {Object.keys(FECLIENT_SERVICES_SEO_PAGES).map((url) => (
                        <S.Url key={url} href={url}>
                            {url.replace(/-/g, " ").replace("/", "")}
                        </S.Url>
                    ))}
                    {Object.keys(FECLIENT_ALL_SERVICES_SEO_PAGES).map((url) => (
                        <S.Url key={url} href={url}>
                            {url.replace(/-/g, " ").replace("/", "")}
                        </S.Url>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Sitemap;
