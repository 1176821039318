import Box from "@FEShared/components/UI/Box/Box";
import React from "react";
import QuestionAccordion from "./QuestionAccordion/QuestionAccordion";
import Section from "../Section/Section";
import { Helmet } from "react-helmet";
import { FAQType } from "./FAQ.types";

// const QUESTIONS = [
//     {
//         question: "Ar registracija į autoservisą tikrai nemokama?",
//         answer: "Registracija į autoservisą visą parą Vilniuje, Kaune, Klaipėdoje, Panevėžyje ir Alytuje yra visiškai nemokama.",
//     },
//     {
//         question: "Ar galiu palyginti autoservisų atsiliepimus, kainas?",
//         answer: "Autoserviso puslapyje yra visa reikiama informacija pasirinkimui - remonto kaina, autoserviso darbo laikas, autoserviso paslaugos, autoserviso adresas.",
//     },
//     {
//         question: "Ar galiu būti užtikrintas dėl remonto kokybės?",
//         answer: "Atidžiai vertiname savo partnerius pagal klientų atsiliepimus. Platformoje atrinkti geriausi autoservisai Vilniuje, Kaune, Klaipėdoje, Panevėžyje ir Alytuje, o visiems darbams yra suteikiama 100% kokybės garantiją.",
//     },
//     {
//         question: "Kaip pakeisti rezervacijos laiką?",
//         answer: "Jei negali atvykti, pakeisk vizito laiką ar atšauk rezervaciją per užsakymo valdymo puslapį, kurį gavai SMS/Email žinute po registracijos.",
//     },
// ];

const FAQ: React.FC<{ faq: FAQType[]; title: string }> = (p) => {
    return (
        <Section title={p.title} bgcolor="background.default">
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "type": "FAQPage",
                            "mainEntity": [
                                ${p.faq.map((q) => {
                                    return `
                                    {
                                        "@type": "Question",
                                        "name": "${q.question}",
                                        "acceptedAnswer": {
                                          "@type": "Answer",
                                          "text": "${q.answer}"
                                        }
                                    }
                                    `;
                                })}
                            ]
                        }
                    `}
                </script>
            </Helmet>
            <Box
                displayFlex
                sx={(theme) => ({
                    flexDirection: "column",
                    // [theme.breakpoints.down("md")]: {
                    //     flexDirection: "column",
                    // },
                })}
            >
                <Box
                    vertical
                    // sx={(theme) => ({
                    //     width: "50%",
                    //     mr: 2,
                    //     [theme.breakpoints.down("md")]: {
                    //         mr: 0,
                    //         width: "100%",
                    //     },
                    // })}
                >
                    {p.faq.slice(0, Math.floor(p.faq.length / 2)).map((q) => (
                        <QuestionAccordion
                            defaultExpanded
                            key={q.question}
                            question={q.question}
                            answer={q.answer}
                        />
                    ))}
                </Box>
                <Box
                    vertical
                    // sx={(theme) => ({
                    //     width: "50%",
                    //     [theme.breakpoints.down("md")]: {
                    //         width: "100%",
                    //     },
                    // })}
                >
                    {p.faq
                        .slice(Math.floor(p.faq.length / 2), p.faq.length)
                        .map((q) => (
                            <QuestionAccordion
                                defaultExpanded
                                key={q.question}
                                question={q.question}
                                answer={q.answer}
                            />
                        ))}
                </Box>
            </Box>
        </Section>
    );
};

export default FAQ;
