import React from "react";
import { createBrowserHistory } from "history";
import { makeAutoObservable, runInAction } from "mobx";
import {
    genWorkshopUrl,
    Page,
    pageToMeta,
    UrlToPage,
} from "@Shared/util/clientPagesMeta";
import {
    BookServiceMutation,
    InitiateSearchMutation,
    OrderQuery,
    ParticipationStatus,
    ServiceCategory,
    ServicePackage,
} from "@FEShared/graphql/generated/graphql";
import { matchPath } from "react-router-dom";
import {
    CALENDAR_FULL_DAY_SEARCH_TIME,
    TEST_WORKSHOP_TOKEN,
} from "@Shared/consts/commonConsts";
import {
    CarDataObj,
    CarDataArr,
} from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.types";
import { makePersistable } from "mobx-persist-store";
import showToast from "@FEShared/utils/showToast";
import { ConfirmationWorkshop } from "@FEClient/views/commonComps/ConfirmationSidePanel/ConfirmationSidePanel.types";
import getToken from "./utils/getToken";
import { carDataArrToObj } from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete.utils";
import { DateObj } from "@Shared/types/types";
import {
    SearchServiceOption,
    Workshop,
    WorkshopServiceDTO,
} from "@FEClient/types/types";
import mapKeyBy from "@Shared/util/mapKeyBy";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";
import IStore from "@FEShared/types/IStore";
import { ConfStep } from "@FEClient/views/pages/ReservationConfirmation/ReservationConfirmation";
import { getIsSearchInputsMobileMode } from "@FEClient/views/pages/Search/TopSearchBar/TopSearchBar.utils";
import { DateTime } from "luxon";
import BusinessDateHandler from "@Shared/util/BusinessDateHandler";
import { dateToMonthDayHour } from "@Shared/util/dateFormat";
import { createClient } from "urql";
import lSumBy from "lodash/sumBy";
import serviceCategoriesToWantedServices from "@Shared/util/serviceCategoriesToWantedServices";
import { City } from "@FEShared/consts/CITIES";
import getServicesPriceNDuration, {
    ServicesPriceNDuration,
} from "@Shared/util/getServicesPriceNDuration";

export const urqlClient = createClient({
    url: window.location.host.includes("nevaziuoja.lt")
        ? "https://api.nevaziuoja.lt/graphql"
        : `http://${window.location.hostname}:3000/graphql`,
});

export const history = createBrowserHistory();

// could be refactored into seperate stores, if this would get too big in the future.
class Store implements IStore {
    constructor() {
        makeAutoObservable(this);

        // makePersistable(this.searchState.date, {
        //     name: "searchState.date",
        //     properties: ["obj"],
        //     storage: window.localStorage,
        //     expireIn: 1 * 24 * 60 * 60 * 1000, // 7d
        // });
        makePersistable(this.searchState, {
            name: "searchState.selectedServicesNames",
            properties: ["selectedServicesNames"],
            storage: window.localStorage,
            expireIn: 1 * 12 * 60 * 60 * 1000, // 12h
        });
        makePersistable(this.searchState, {
            name: "searchState",
            properties: ["carDataArr", "city"],
            storage: window.localStorage,
            expireIn: 2 * 365 * 24 * 60 * 60 * 1000,
        });
        makePersistable(this.reservationConfirmationPageState, {
            name: "reservationConfirmationPageState",
            properties: ["emailInputVal", "phoneInputVal"],
            storage: window.localStorage,
            expireIn: 2 * 365 * 24 * 60 * 60 * 1000,
        });
        makePersistable(this, {
            name: "visitHistory",
            properties: ["visitHistory"],
            storage: window.localStorage,
            expireIn: 2 * 365 * 24 * 60 * 60 * 1000,
        });
    }

    public refFrom: undefined | "KUROHUDAS";

    public activeGqlQueries = new Set<string>();
    public activePage: Page | undefined = UrlToPage[history.location.pathname];

    get activePageMeta() {
        return this.activePage ? pageToMeta[this.activePage] : undefined;
    }

    public bodyScrollEnabled = true;
    public showSidebar = false;
    public visitHistory: [
        string /* date */,
        string /*referrer*/,
        string /* clid */
    ][] = [];
    public adsCampaign = {
        city: undefined as undefined | City,
        carBrand: undefined as undefined | string,
        service: undefined as undefined | string,
    };

    public get showLoadingScreen() {
        return this.activeGqlQueries.size > 0;
    }

    public addLoadingGqlQuery(queryID?: string): string {
        const randomQueryID = queryID || Math.random().toString();
        this.activeGqlQueries = this.activeGqlQueries.add(randomQueryID);
        return randomQueryID;
    }

    public removeLoadingGqlQuery(queryID: string): void {
        this.activeGqlQueries.delete(queryID);
    }

    public searchState = {
        /** possible services options returned by BE */
        setInitateSearchRes: (
            res: InitiateSearchMutation["initiateSearch"]
        ) => {
            const servicesToShow =
                localStorage.getItem(
                    QUERY_PARAMS.TEST_MODE_ENABLE_QUERY_PARAM
                ) === "true"
                    ? res.services
                    : res.services.filter(
                          (s) => !s.name.includes(TEST_WORKSHOP_TOKEN)
                      );

            if (servicesToShow.length === 0) {
                return showToast.warn(
                    `Pasirinktu laiku nei vienas servisas negali jūsų priimti. Prašome pasirinkti kitą laiką.`
                );
            }
            this.searchPageState.primarySearchResults = {
                order: res.order,
                services: servicesToShow,
            };
        },
        city: "Vilnius",
        servicePackages: [] as ServicePackage[],
        searchServicesOptions: [] as SearchServiceOption[],
        earliestAvailableTime: undefined as Date | undefined,
        selectedServicesNames: [] as ServiceCategory["serviceName"][],
        carDataArr: [] as CarDataArr,
        get carData(): CarDataObj {
            return carDataArrToObj(this.carDataArr);
        },
        get selectedServiceIds() {
            if (this.searchServicesOptions.length === 0) return [];
            const searchServicesOptionsByNameMap = mapKeyBy(
                this.searchServicesOptions,
                (s) => s.serviceName
            );
            const ids = this.selectedServicesNames
                .map((serviceName) => {
                    const service =
                        searchServicesOptionsByNameMap.get(serviceName);
                    if (!service) {
                        console.error(
                            `Didnt find service in selectedServiceIds: ${serviceName}`
                        );
                        return;
                    }
                    return service.ID;
                })
                .filter(Boolean) as string[];
            return ids;
        },
        get selectedServicesTypes() {
            if (this.searchServicesOptions.length === 0) return [];
            const searchServicesOptionsByNameMap = mapKeyBy(
                this.searchServicesOptions,
                (s) => s.serviceName
            );
            const services = this.selectedServicesNames
                .map((serviceName) => {
                    const service =
                        searchServicesOptionsByNameMap.get(serviceName);
                    if (!service) {
                        console.error(
                            `Didnt find service in selectedServicesTypes: ${serviceName}`
                        );
                        return;
                    }
                    return service;
                })
                .filter(Boolean) as ServiceCategory[];
            return services;
        },
        showImmediateCheckbox: (
            workshop?: Pick<Workshop, "services" | "hourCost">
        ) => {
            let durationMins: number;
            const priceNDuration = workshop
                ? this.getSelectedServicesPriceNDurationForWorkshop(workshop)
                : undefined;

            if (priceNDuration) {
                durationMins = priceNDuration.duration.mins;
            } else {
                durationMins = lSumBy(
                    this.searchState.selectedServicesTypes,
                    (s) => s.durationMinsPerUnit * s.defaultUnitCount || 0
                );
            }

            return durationMins <= 120;
        },
        immediateValChange: (enabled: boolean) => {
            runInAction(() => {
                this.searchState.immediate = enabled;
            });
        },
        date: {
            obj: {
                YYYY_MM_DD: undefined as undefined | string,
                HH_MM: undefined as undefined | string,
            } as DateObj,
            get fullDate(): Date | undefined {
                return this.obj.YYYY_MM_DD
                    ? new Date(
                          `${this.obj.YYYY_MM_DD}T${
                              this.obj.HH_MM
                                  ? this.obj.HH_MM
                                  : `${CALENDAR_FULL_DAY_SEARCH_TIME.hour}:${CALENDAR_FULL_DAY_SEARCH_TIME.minute}`
                          }`
                      )
                    : undefined;
            },
            get formattedTimeString() {
                return this.fullDate
                    ? dateToMonthDayHour(this.fullDate, true)
                    : "";
            },
        },
        immediate: false,
        self: this,
        get initiateSearchParams() {
            return {
                arrivalDate: this.date.fullDate,
                city: this.city,
                categoryIDs: this.selectedServiceIds,
                categoryNames: this.selectedServicesNames,
                vehicleBrand: this.carDataArr[0],
                vehicleModel: this.carDataArr[1],
                vehicleYear: +this.carDataArr[2],
                clientWillWait: this.immediate,
                clid: this.self.visitHistory
                    .map((historyLog) => historyLog.join(", "))
                    .join(" || ") as string,
            };
        },
    };

    // Combining Alternative and Primary search state into same state object is not very elegant as its 2 seperate pages that share similar components
    // probably can be improved in the future (when doing refactoring to seperate stores or whenever)
    public searchPageState = {
        page: 1,
        loaders: [] as ("FAKE_LOADER" | "SEARCH_LOADER")[],
        get isLoading() {
            return this.loaders.length > 0;
        },
        primarySearchResults: undefined as
            | undefined
            | {
                  services: InitiateSearchMutation["initiateSearch"]["services"];
                  order: InitiateSearchMutation["initiateSearch"]["order"];
              },
        /** Sorted workshops that have bigger (CollapsedMapPoint) */
        sortedTopWorkshopIDs: [] as number[],
        /** All workshops that are visible in map, as either bigger or smaller map point */
        visibleWorkshopIDs: [] as number[],
        workshopIDsInMapBounds: [] as number[],
        hoveringOnServiceId: undefined as undefined | number,
        selectedServiceId: undefined as undefined | number,
        isSearchInputsExpanded: !getIsSearchInputsMobileMode(),
        /**
         * this isn't direct state of google maps, but rather just backup that is inited on google map init.
         */
        posBackup: {
            city: undefined as undefined | string,
            zoom: undefined as undefined | number,
            position: null as google.maps.LatLng | null,
        },
        currentDevicePos: {
            latitude: undefined as undefined | number,
            longitude: undefined as undefined | number,
        },
        resetPosBackup: () => {
            this.searchPageState.posBackup = {
                city: this.searchState.city,
                zoom: undefined,
                position: null,
            };
        },
        altFlow: {
            get isAlt() {
                const match = matchPath(location.pathname, {
                    path: pageToMeta.ALTERNATIVES_SEARCH.path,
                    exact: true,
                    strict: true,
                });
                return match && match.isExact;
            },
            order: undefined as undefined | OrderQuery["order"],
            get primaryInv() {
                return this.order?.invitations.find(
                    (i) =>
                        i.primary &&
                        i.status === ParticipationStatus.PendingCustomerApproval
                );
            },
            newWorkshopSuggestedTime(workshopID: number):
                | {
                      text: string;
                      new: boolean;
                  }
                | undefined {
                if (!this.order) return;
                const inv = this.order.invitations.find(
                    (w) => w.service.ID === workshopID
                );
                if (!inv) {
                    console.error("Invitation not found!");
                    return;
                }
                const workshop = inv.service;
                const orderServicesIDs = this.order.categories.map((c) => c.ID);
                const wantedServices = workshop.services
                    .filter((s) => orderServicesIDs.includes(s.type.ID))
                    .map((s) => serviceCategoriesToWantedServices(s));

                const bizDateHandler = new BusinessDateHandler({
                    workHoursCalendar: workshop.workHoursCalendar,
                    blockedTimeslots: workshop.blockedTimeslots.map(
                        (timeslot) => ({
                            ...timeslot,
                            startDate: new Date(timeslot.startDate),
                            endDate: new Date(timeslot.endDate),
                        })
                    ),
                    wantedServices,
                    employees: workshop.employees,
                });

                if (
                    bizDateHandler.isAvailableForServices(
                        DateTime.fromJSDate(new Date(this.order.arrivalDate)),
                        {
                            immediateReservation:
                                this.order.clientWillWait ?? undefined,
                        }
                    )
                ) {
                    return {
                        new: false,
                        text: `Gali priimti pirminiu laiku`,
                    };
                } else {
                    return {
                        new: true,
                        text: `Pasiūlė kitus laikus`,
                    };
                }
            },
        },
        workshopBtnText: (): string => {
            let btnText: string | undefined;
            if (this.searchPageState.altFlow.isAlt) {
                btnText = "Pasirinkti";
            } else {
                btnText = "Rezervuoti";
            }
            return btnText;
        },
        workshopChooseUrl: (p: { ID: number; name: string }): string => {
            if (this.searchPageState.altFlow.isAlt) {
                return `${
                    pageToMeta.ALTERNATIVE_RESERVATION_CONFIRMATION.url
                }/?${QUERY_PARAMS.USER_ORDER_TOKEN}=${getToken()}`;
            } else {
                return genWorkshopUrl(p);
            }
        },
        onWorkshopChoose: (p: { ID: number; name: string }): void => {
            this.searchPageState.selectedServiceId = p.ID;
            this.reservationConfirmationPageState.date.obj = {
                YYYY_MM_DD: this.searchState.date.obj.YYYY_MM_DD,
                HH_MM: this.searchState.date.obj.HH_MM,
            };

            this.altFlowState.newArrivalDateObj = {
                HH_MM: undefined,
                YYYY_MM_DD: undefined,
            };
            history.push(this.searchPageState.workshopChooseUrl(p));
        },
    };

    public altFlowState = {
        newArrivalDateObj: {
            YYYY_MM_DD: undefined as undefined | string,
            HH_MM: undefined as undefined | string,
        },
    };

    public workshopPageState = {
        fromBookNow: false,
        isLoading: false,
        earliestAvailableTime: new Date(),
        warrantyModalOpen: false,
        resetState: () => {
            this.workshopPageState.earliestAvailableTime = new Date();
        },
        date: {
            obj: {
                YYYY_MM_DD: undefined as undefined | string,
                HH_MM: undefined as undefined | string,
            } as DateObj,
            onChange: (dateObj: DateObj) => {
                this.workshopPageState.date.obj = {
                    ...this.workshopPageState.date.obj,
                    ...dateObj,
                };
            },
        },
        onConfirm: () => {
            console.log("current obj", this.workshopPageState.date.obj);
            this.reservationConfirmationPageState.date.onDateChange(
                this.workshopPageState.date.obj
            );
            history.push(pageToMeta.RESERVATION_CONFIRM.url);
        },
    };

    public reservationConfirmationPageState = {
        promoActivated: false,
        date: {
            obj: {
                YYYY_MM_DD: undefined as undefined | string,
                HH_MM: undefined as undefined | string,
            } as DateObj,
            get fullDate(): Date | undefined {
                return this.obj.YYYY_MM_DD
                    ? new Date(
                          `${this.obj.YYYY_MM_DD}T${
                              this.obj.HH_MM
                                  ? this.obj.HH_MM
                                  : `${CALENDAR_FULL_DAY_SEARCH_TIME.hour}:${CALENDAR_FULL_DAY_SEARCH_TIME.minute}`
                          }`
                      )
                    : undefined;
            },
            get formattedTimeString() {
                return this.fullDate
                    ? dateToMonthDayHour(this.fullDate, true)
                    : "";
            },
            onDateChange: (dateObj: DateObj) => {
                this.reservationConfirmationPageState.date.obj = {
                    ...this.reservationConfirmationPageState.date.obj,
                    ...dateObj,
                };
            },
        },
        selectedWorkshop: undefined as undefined | ConfirmationWorkshop,
        emailInputVal: "" as string,
        phoneInputVal: "+3706" as string,
        comment: "" as string,
        step: ConfStep.First,
        rebookingFromOrderID: undefined as undefined | number,
    };

    public reservationSuccessPageState = {
        completedOrder: undefined as
            | BookServiceMutation["bookService"]
            | undefined,
        selectedWorkshop: undefined as ConfirmationWorkshop | undefined,
    };

    public togglePageScroll(enabled: boolean) {
        this.bodyScrollEnabled = enabled;
    }

    public getWorkshopSelectedServices = (
        workshop: Pick<Workshop, "services" | "name">
    ): Workshop["services"] => {
        const kWorkshopServicesByName = mapKeyBy(
            workshop.services,
            (s) => s.type.serviceName
        );
        return this.searchState.selectedServicesNames
            .map((serviceName) => {
                const service = kWorkshopServicesByName.get(serviceName);
                if (!service) {
                    return console.error(
                        `Workshop ${workshop.name} does not have service with type ${serviceName}`
                    );
                }

                return service;
            })
            .filter(Boolean) as Workshop["services"];
    };

    public getWorkshopService(
        serviceDefinitionID: string,
        workshop: Pick<Workshop, "services">
    ): WorkshopServiceDTO | undefined {
        const kServicesByID = mapKeyBy(workshop.services, (s) => s.type.ID);
        return kServicesByID.get(serviceDefinitionID);
    }

    public getSelectedServicesPriceNDurationForWorkshop(
        workshop: Pick<Workshop, "hourCost" | "services">,
        showAverage?: boolean,
        noUnits?: boolean
    ): ServicesPriceNDuration | undefined {
        const kServicesByName = mapKeyBy(
            workshop.services,
            (s) => s.type.serviceName
        );

        const selectedServices = this.searchState.selectedServicesNames
            .map((serviceName) => {
                const service = kServicesByName.get(serviceName);
                if (!service) {
                    console.error(
                        `Didn't find service: ${serviceName} in workshop services`
                    );
                }

                return service;
            })
            .filter(Boolean) as typeof workshop.services;

        if (selectedServices.length === 0) return;

        const priceNDurationSummedObj = getServicesPriceNDuration(
            selectedServices,
            workshop.hourCost,
            showAverage,
            noUnits
        );

        return priceNDurationSummedObj;
    }
}

const storeContext = React.createContext<Store>(new Store());
export default function useStore(): Store {
    const store = React.useContext(storeContext);
    return store;
}
