import {
    CITY_TO_URLIFIED_MAP,
    URLIFIED_CAR_BRANDS_MAP,
    URLIFIED_CITIES_MAP,
    SERVICE_TO_URLIFIED_MAP,
    URLIFIED_SERVICES_MAP,
    BRAND_TO_URLIFIED_MAP,
} from "./Search.consts";
import { pageToMeta } from "@Shared/util/clientPagesMeta";
import capFirst from "@Shared/util/capFirst";
import mapKeyBy from "@Shared/util/mapKeyBy";
import ALL_SERVICES from "@FEShared/consts/ALL_SERVICES";

export const ALL_SERVICES_NAME_FILLER = "visos-paslaugos";

interface ParsedSearchParams {
    city: string;
    carBrand?: string;
    /* provide the service ID or serviceName*/
    selectedServiceID?: string;
    /* provide the service ID or serviceName*/
    selectedServiceName?: string;
}

export class UrlPathnameParser {
    private static urlifyBrand(brand?: string): string {
        if (!brand) return "";
        const urlifiedBrand = BRAND_TO_URLIFIED_MAP.get(brand);
        if (!urlifiedBrand) {
            console.error(`Couldn't urlify brand: ${brand}`);
            return "";
        }
        return urlifiedBrand;
    }

    private static unurlifyBrand(brand?: string): string {
        if (!brand) return "";

        const unurlifiedBrand = URLIFIED_CAR_BRANDS_MAP.get(
            brand.toLowerCase()
        );
        if (!unurlifiedBrand) {
            console.error(`Couldn't unurlify brand: ${brand}`);
            return "";
        }
        return unurlifiedBrand;
    }

    /* serviceID should be made required after full transition to serviceIDs. */
    public static urlifyService(p: {
        serviceName: string | undefined;
        useAllServicesFiller?: boolean;
        serviceID?: string;
    }): string {
        if (!p.serviceName) {
            return p.useAllServicesFiller ? ALL_SERVICES_NAME_FILLER : "";
        }

        const urlifiedService = SERVICE_TO_URLIFIED_MAP.get(p.serviceName);
        if (!urlifiedService) {
            console.error(
                `Couldn't urlify service: ${p.serviceName}. urlified: ${urlifiedService}`
            );
            return "";
        }

        return [urlifiedService, p.serviceID].filter(Boolean).join("-");
    }

    public static serviceIDToName(serviceID: string): string {
        if (serviceID === ALL_SERVICES_NAME_FILLER) return "";

        const servicesMap = mapKeyBy(ALL_SERVICES, (s) => s.ID);
        const service = servicesMap.get(serviceID);
        if (!service) {
            console.error(`Couldn't find service from ID: ${serviceID}`);
            return "";
        }
        return service.serviceName;
    }

    public static serviceNameToID(serviceName: string): string {
        if (serviceName === ALL_SERVICES_NAME_FILLER) return "";

        const servicesMap = mapKeyBy(ALL_SERVICES, (s) => s.serviceName);
        const service = servicesMap.get(serviceName);
        if (!service) {
            console.error(`Couldn't find service from ID: ${serviceName}`);
            return "";
        }
        return service.ID;
    }

    public static unurlifyService(urlifiedServiceName: string): string {
        if (urlifiedServiceName === ALL_SERVICES_NAME_FILLER) return "";

        const unurlifiedService =
            URLIFIED_SERVICES_MAP.get(urlifiedServiceName);

        if (!unurlifiedService) {
            console.error(`Couldn't unurlify service: ${urlifiedServiceName}`);
            return "";
        }
        return unurlifiedService.serviceName;
    }

    public static urlifyCity(city: string): string {
        const urlifiedCity = CITY_TO_URLIFIED_MAP.get(city);
        if (!urlifiedCity) {
            console.error(
                `Couldn't urlify city: ${city}. urlified: ${urlifiedCity}`
            );
            // idk if this is good but w.e for now
            return "Vilnius";
        }
        return urlifiedCity;
    }

    public static unurlifyCity(urlifiedCity: string): string {
        const unurlifiedCity = URLIFIED_CITIES_MAP.get(urlifiedCity);
        if (!unurlifiedCity) {
            console.error(
                `Couldn't unurlify city: ${urlifiedCity}. Unurlified: ${unurlifiedCity}`
            );
            // idk if this is good but w.e for now
            return "Vilnius";
        }
        return unurlifiedCity;
    }

    /**
     * Query format: `/autoservisai/vilnius/<service_name>/<car_brand>` E.G. `/autoservisai/vilnius/padangu-montavimas/bmw`
     * Filler for all services <service_name> is `visos-paslaugos`
     */
    public static pathnameToParams(pathname: string): ParsedSearchParams {
        const dataPart = pathname
            .replace("/autoservisai/", "")
            .replace("/autoservisai", "");
        let redirect = false;
        if (!dataPart) {
            console.warn(`Couldn't parse pathname to params: ${pathname}`);
            return {
                city: "Vilnius",
            };
        }
        // vilnius/padangu-montavimas/bmw
        const [city, serviceUrlPart, carBrand] = dataPart
            .split("/")
            .filter(Boolean);

        let serviceName: string | undefined;
        if (serviceUrlPart && serviceUrlPart.includes("_")) {
            // it includes id
            const serviceDefinitionID = serviceUrlPart.split("-").pop();
            serviceName = serviceDefinitionID
                ? this.serviceIDToName(serviceDefinitionID)
                : undefined;
        } else if (serviceUrlPart) {
            // it only includes name. This is legacy & deprecated. Can be deleted after there won't be any URLs left that are cached by google just with urlified service name (without service ID).
            serviceName = this.unurlifyService(serviceUrlPart);

            if (
                !serviceName &&
                serviceUrlPart !== ALL_SERVICES_NAME_FILLER &&
                window.location.pathname.includes(pageToMeta.SEARCH.url)
            ) {
                // this redirect is needed for old URLs that were cached by google when we changed services names. Shouldn't be needed in the future since we added services IDs to URls
                redirect = true;
            }
        }

        const finalParsedData = {
            city: capFirst(this.unurlifyCity(city)),
            carBrand: carBrand ? this.unurlifyBrand(carBrand) : undefined,
            selectedServiceName: serviceName,
        };

        if (redirect) {
            const newUrl = this.paramsToPathname(finalParsedData);
            window.location.replace(newUrl);
        }

        return finalParsedData;
    }

    /*
     * make sure this matches SitemapGenerator.ts
     * should be made in the future so that SitemapGenerator would use this function.
     * When full tranition is done to use only service IDs everywhere, the logic for "selectedServiceName" should be removed.
     */
    public static paramsToPathname(p: ParsedSearchParams): string {
        const startingPath = pageToMeta.SEARCH.url;
        const serviceID =
            p.selectedServiceID ||
            (p.selectedServiceName
                ? this.serviceNameToID(p.selectedServiceName)
                : undefined);

        const fullBuiltPathname = [
            startingPath,
            this.urlifyCity(p.city),
            this.urlifyService({
                serviceName: p.selectedServiceID
                    ? this.serviceIDToName(p.selectedServiceID)
                    : p.selectedServiceName,
                serviceID: serviceID || "",
                useAllServicesFiller: !!p.carBrand,
            }),
            p.carBrand && this.urlifyBrand(p.carBrand),
        ]
            .filter(Boolean)
            .join("/")
            .toLowerCase();

        return fullBuiltPathname;
    }
}
