import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import sendPixelEvent from "@FEShared/utils/sendPixelEvent";

const EXPERIMENT_KEYS_LS_KEY = "EXPERIMENT_KEYS";
let eventWasSent = false;

const ABTest: React.FC<{
    newComponentPercentage?: number;
    experimentKey: string;
    newComponent: JSX.Element;
    defaultComponent: JSX.Element;
}> = observer((p) => {
    const LS = useLocalObservable(() => ({
        key: undefined as undefined | string,
    }));

    React.useEffect(() => {
        const defaultCaseKey = `DEFAULT_${p.experimentKey}`;
        const lsExperimentKeys = localStorage.getItem(EXPERIMENT_KEYS_LS_KEY);
        const existingKeys = lsExperimentKeys
            ? (JSON.parse(lsExperimentKeys) as string[])
            : undefined;

        let keyToSet: string;
        if (
            existingKeys &&
            (existingKeys.includes(p.experimentKey) ||
                existingKeys.includes(defaultCaseKey))
        ) {
            keyToSet = existingKeys.includes(p.experimentKey)
                ? p.experimentKey
                : defaultCaseKey;
        } else {
            const chance = Math.random();
            const drawnExperimentKey =
                chance <= (p.newComponentPercentage || 0.5)
                    ? p.experimentKey
                    : defaultCaseKey;

            keyToSet = drawnExperimentKey;

            const newKeysArr = [keyToSet].concat(existingKeys || []);
            localStorage.setItem(
                EXPERIMENT_KEYS_LS_KEY,
                JSON.stringify(newKeysArr)
            );
        }

        if (!eventWasSent) {
            sendPixelEvent({ eventName: `EXPERIMENT_${keyToSet}` });
            eventWasSent = true;
        }
        runInAction(() => {
            LS.key = keyToSet;
        });
    }, [LS, p.experimentKey, p.newComponentPercentage]);

    if (!LS.key) return null; // prevent flicker until key is set;

    return (
        <>{LS.key === p.experimentKey ? p.newComponent : p.defaultComponent}</>
    );
});

export default ABTest;
