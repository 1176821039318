import { CALENDAR_FULL_DAY_SEARCH_TIME } from "@Shared/consts/commonConsts";
import { DateObj } from "@Shared/types/types";
import capFirst from "./capFirst";
import isValidDate from "./isValidDate";

function getTimeZoneOffsetString(date: Date) {
    const offset = date.getTimezoneOffset();
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;

    return `GMT${offset <= 0 ? "+" : "-"}${String(hours).padStart(
        2,
        "0"
    )}${String(minutes).padStart(2, "0")}`;
}

/** Returns YYYY-MM-DD */
export default function dateFormat(date: Date): string {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    if (!parsedDate || !isValidDate(parsedDate)) {
        console.error(`Received invalid date: ${date}`);
        return "Invalid date";
    }
    return parsedDate.toLocaleDateString("lt-LT");
}

export function dateFormatFull(date: Date | string): string {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    if (!parsedDate || !isValidDate(parsedDate)) {
        console.error(`Received invalid date: ${date}`);
        return "";
    }
    const YYYY_MM_DD = parsedDate.toLocaleDateString("lt-LT");
    const splitTimeString = parsedDate.toLocaleTimeString("lt-LT").split(":");
    const HH_MM = `${splitTimeString[0]}:${splitTimeString[1]}`;

    return `${YYYY_MM_DD} ${HH_MM}`;
}

export function dateFormatFullReadableBuildable(date: Date | string): string {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    if (!parsedDate || !isValidDate(parsedDate)) {
        console.error(`Received invalid date: ${date}`);
        return "";
    }
    const YYYY_MM_DD = parsedDate.toLocaleDateString("lt-LT");
    const splitTimeString = parsedDate.toLocaleTimeString("lt-LT").split(":");
    const HH_MM = `${splitTimeString[0]}:${splitTimeString[1]}`;

    return `${YYYY_MM_DD} ${HH_MM} ${getTimeZoneOffsetString(parsedDate)}`;
}

export function dateToObj(date: Date): DateObj {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    if (!parsedDate || !isValidDate(parsedDate)) {
        console.error(`Received invalid date: ${date}`);
        return {
            YYYY_MM_DD: undefined,
            HH_MM: undefined,
        };
    }
    const YYYY_MM_DD = parsedDate.toLocaleDateString("lt-LT");
    const splitTimeString = parsedDate.toLocaleTimeString("lt-LT").split(":");
    const HH_MM = `${splitTimeString[0]}:${splitTimeString[1]}`;

    return {
        YYYY_MM_DD,
        HH_MM,
    };
}

export function dateToPrettyStringsObj(date: Date): {
    HH_MM: string;
    weekday: string;
    month: string;
    monthDay: string;
} {
    const parsedDate = typeof date === "string" ? new Date(date) : date;

    const HH_MM = parsedDate.toLocaleTimeString("lt-LT").slice(0, -3);
    const weekday = capFirst(
        parsedDate.toLocaleDateString("lt-LT", { weekday: "long" })
    );
    const month = capFirst(
        parsedDate.toLocaleDateString("lt-LT", { month: "long" })
    );
    const monthDay = parsedDate.toLocaleDateString("lt-LT", {
        day: "numeric",
    });

    return { HH_MM, monthDay, weekday, month };
}

export function dateObjToString(p: Partial<DateObj>): string {
    return p.YYYY_MM_DD || p.HH_MM
        ? `${p.YYYY_MM_DD || ""} ${p.HH_MM || ""}`
        : "";
}

export function dateObjToDate(p: DateObj): Date {
    return new Date(`${p.YYYY_MM_DD}T${p.HH_MM}`);
}

export function dateToLongMonthDayYear(date: Date): string {
    const [year, month, day] = date
        .toLocaleDateString("lt-LT", {
            year: "numeric",
            month: "long",
            day: "numeric",
        })
        .replace("m. ", "")
        .split(" ");

    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

    return `${capitalizedMonth} ${day} d., ${year}m.`;
}

export function dateToMonthDayHour(
    date: Date,
    handleFullDayHour?: boolean
): string {
    const [_year, month, day] = date
        .toLocaleDateString("lt-LT", {
            year: "numeric",
            month: "long",
            day: "numeric",
        })
        .replace("m. ", "")
        .split(" ");

    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

    const HH = date.getHours() as number;
    const MM = date.getMinutes() as number;

    // Adding leading zero to hours and minutes if less than 10
    const paddedHH = HH < 10 ? "0" + HH : HH;
    const paddedMM = MM < 10 ? "0" + MM : MM;

    return `${capitalizedMonth} ${day} d.${
        handleFullDayHour &&
        HH === CALENDAR_FULL_DAY_SEARCH_TIME.hour &&
        MM === CALENDAR_FULL_DAY_SEARCH_TIME.minute
            ? ``
            : `, ${paddedHH}:${paddedMM}`
    }`;
}

/** Returns May 2d. 09:15 or Today/Tommorow 09:15 */
export function dateToMonthHourWithPrettyText(date: Date): string {
    const [_year, month, day] = date
        .toLocaleDateString("lt-LT", {
            year: "numeric",
            month: "long",
            day: "numeric",
        })
        .replace("m. ", "")
        .split(" ");

    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

    const HH = date.getHours() as number;
    const MM = date.getMinutes() as number;

    let prettyText: string | undefined;
    const dateToCheckAgainst = new Date(date);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    // Resetting the hours, minutes, seconds, and milliseconds for comparison
    dateToCheckAgainst.setHours(0, 0, 0, 0);
    tomorrow.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (dateToCheckAgainst.getTime() === today.getTime()) {
        prettyText = "Šiandien,";
    } else if (dateToCheckAgainst.getTime() === tomorrow.getTime()) {
        prettyText = "Rytoj,";
    }

    // Adding leading zero to hours and minutes if less than 10
    const paddedHH = HH < 10 ? "0" + HH : HH;
    const paddedMM = MM < 10 ? "0" + MM : MM;

    return `${
        prettyText ? prettyText : `${capitalizedMonth} ${day} d.`
    } ${paddedHH}:${paddedMM}`;
}
