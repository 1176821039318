import {
    Checkbox,
    FormControl,
    OutlinedInput,
    SelectProps,
} from "@mui/material";
import React from "react";
import * as S from "./Dropdown.styled";
import keyBy from "lodash/keyBy";
import { useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import Text from "@FEShared/components/UI/Text/Text";

type DropdownP<T> = {
    className?: string;
    menuClassName?: string;
    placeholder?: string;
    options: {
        value: string | number;
        text: string | JSX.Element;
        iconClassName?: string;
    }[];
    value: T;
    leftIconClass?: string;
    forceError?: boolean;
    helperText?: string;
    /* how many max values dropdown wants to see*/
    limit?: number;
} & SelectProps<T>;

const Dropdown = <T,>(p: DropdownP<T>) => {
    const {
        options,
        forceError,
        className: _className,
        leftIconClass: _leftIconClass,
        menuClassName: _menuClassName,
        sx: _sx,
        ...MUISelectProps
    } = p;
    const kOptionsByValue = React.useMemo(() => {
        return keyBy(options, "value");
    }, [options]);

    const LS = useLocalObservable(() => ({
        wasFocusedOnce: false,
        isFocused: false,
    }));

    const isErr = React.useMemo(() => {
        return !LS.isFocused && (forceError || LS.wasFocusedOnce) && p.error;
    }, [LS.isFocused, forceError, LS.wasFocusedOnce, p.error]);

    return (
        <FormControl
            size={p.size}
            className={p.className}
            fullWidth={p.fullWidth}
            sx={p.sx}
        >
            <S.MUISelect
                {...MUISelectProps}
                value={p.value === undefined || p.value === null ? "" : p.value}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                input={<OutlinedInput />}
                startAdornment={
                    p.leftIconClass && (
                        <S.LeftIcon className={p.leftIconClass} />
                    )
                }
                renderValue={(val) => {
                    return val === undefined ||
                        val === null ||
                        val === "" ||
                        (Array.isArray(val) && val.length === 0) ? (
                        <S.Placeholder>{p.placeholder}</S.Placeholder>
                    ) : p.renderValue ? (
                        p.renderValue(val)
                    ) : Array.isArray(val) ? (
                        val
                            .map((v) => kOptionsByValue[v as string].text)
                            .slice(0, p.limit || 999)
                            .concat(
                                p.limit && val.length > p.limit
                                    ? `+${val.length - p.limit}`
                                    : []
                            )
                            .join(", ")
                    ) : (
                        kOptionsByValue[val as string].text
                    );
                }}
                MenuProps={{
                    className: p.menuClassName,
                    style: {
                        ...p.MenuProps?.style,
                        maxHeight: 500,
                    },
                }}
                onFocus={() => {
                    runInAction(() => {
                        LS.wasFocusedOnce = true;
                        LS.isFocused = true;
                    });
                }}
                onBlur={() => {
                    runInAction(() => {
                        LS.isFocused = false;
                    });
                }}
                error={isErr}
            >
                {options.map((opt) => (
                    <S.MUIMenuItem key={opt.value} value={opt.value}>
                        {p.multiple && (
                            <Checkbox
                                checked={
                                    Array.isArray(p.value)
                                        ? p.value.includes(opt.value)
                                        : p.value === opt.value
                                }
                            />
                        )}
                        {opt.iconClassName && (
                            <S.LeftIcon className={opt.iconClassName} />
                        )}
                        {opt.text}
                    </S.MUIMenuItem>
                ))}
            </S.MUISelect>
            {isErr && (
                <Text fontSize={12} color="error.main">
                    {p.helperText}
                </Text>
            )}
        </FormControl>
    );
};

export default Dropdown;
