import latinize from "latinize";

export default function urlifyString(s: string): string {
    return latinize(s)
        .toLowerCase()
        .trim()
        .replace(/\//g, "-")
        .replace(/ /g, "-")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/,/g, "")
        .replace(/\./g, "");
}
