import { SeoPages } from "@FEShared/types/common";
import capFirst from "@Shared/util/capFirst";
import urlifyString from "@FEShared/utils/urlifyString";
import brandsModelsData from "@FEShared/components/UI/CarAutocomplete/brandsModelsData.json";

const BRANDS = Object.entries(brandsModelsData).filter(
    ([brand, _models]) => brand !== "-Kita-"
);
export const BRANDS_URL_PREFIX = `servisas`;

const TEMPLATE_FAQ = [
    {
        question:
            "Kur galiu rasti {carModel} autoservisą, kuriame atliekamas {carModel} remontas?",
        answer: "Paieškoje rasi {carModel} autoservisus, kuriuose atliekamas visų populiariausių paslaugų {carModel} remontas - {carModel} diagnostika, {carModel} variklių remontas, {carModel} žibintų remontas, {carModel} automatinių dėžių remontas, {carModel} važiuoklės remontas, {carModel} chip tuning ir daugelis kitų.",
    },
    {
        question:
            "Ar atliekamas {carModel} remontas Vilnius, Kaunas, Klaipėda, Panevėžys ar Alytus miestuose?",
        answer: "Taip, pasirink {carModel} automobilio modelį, norimas paslaugas, atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje matytum autoservisus, kur atliekamas {carModel} remontas Vilniuje, Kaune, Klaipėdoje, Panevėžyje ar Alytuje.",
    },
    {
        question:
            "Ar atliekamas {carModel} remontas savaitgalį (šeštadienį ir sekmadienį)?",
        answer: "Taip, pasirink {carModel} automobilio modelį, norimas paslaugas, atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje matytum autoservisus, kur atliekamas {carModel} remontas savaitgalį (šeštadienį/sekmadienį).",
    },
    {
        question: "Ar atliekamas {carModel} remontas po darbo valandų?",
        answer: "Taip, pasirink {carModel} automobilio modelį, norimas paslaugas, atvykimo laiką bei reikiamą miestą, kad paieškos puslapyje matytum autoservisus, kur atliekamas {carModel} remontas po darbo valandų.",
    },
    {
        question: "Ar atliekamas pigus {carModel} remontas?",
        answer: "Taip, pasirink {carModel} automobilio modelį, norimas paslaugas, atvykimo laiką bei reikiamą miestą, tuomet paieškos puslapyje pakeisk autoservisų rūšiavimą pagal žemiausią kainą, kad surasti kur atliekamas pigus {carModel} remontas.",
    },
];

const FECLIENT_BRANDS_SEO_PAGES: SeoPages = (() => {
    return BRANDS.reduce((acc, [b, models]) => {
        acc["/" + urlifyString(`${b}-${BRANDS_URL_PREFIX}`.toLowerCase())] = {
            seoServiceName: `${b} Servisas`,
            sortOrder: +models.c,
            carBrand: b,
            faq: TEMPLATE_FAQ.map((f) => {
                return {
                    question: capFirst(
                        f.question.replace(/{carModel}/g, b),
                        false
                    ),
                    answer: capFirst(f.answer.replace(/{carModel}/g, b), false),
                };
            }),
        };
        return acc;
    }, {} as SeoPages);
})();

export default FECLIENT_BRANDS_SEO_PAGES;
