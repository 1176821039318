import ALL_SERVICES from "@FEShared/consts/ALL_SERVICES";
import CAR_MODELS from "@FEShared/components/UI/CarAutocomplete/CarModels.json";
import urlifyString from "@FEShared/utils/urlifyString";
import { CITIES_WITH_ALL } from "@FEShared/consts/CITIES";

type Service = (typeof ALL_SERVICES)[number];

function reverseMapKeysWithValues(
    map: Map<string, string>
): Map<string, string> {
    const reversedKeyValuePairs = Array.from(map).map(([key, value]) => [
        value,
        key,
    ]);

    return new Map(reversedKeyValuePairs as [string, string][]);
}

export const URLIFIED_SERVICES_MAP: Map<
    string /* urlified */,
    Service /* original */
> = Object.values(ALL_SERVICES)
    .flat()
    .reduce((acc, currentOption) => {
        acc.set(urlifyString(currentOption.serviceName), currentOption);
        return acc;
    }, new Map());

export const SERVICE_TO_URLIFIED_MAP = (() => {
    const reversedKeyValuePairs = Array.from(URLIFIED_SERVICES_MAP).map(
        ([urlifiedName, service]) => [service.serviceName, urlifiedName]
    );

    return new Map(reversedKeyValuePairs as [string, string][]);
})();

export const URLIFIED_CAR_BRANDS_MAP: Map<
    string /* urlified */,
    string /* original */
> = Object.keys(CAR_MODELS).reduce((acc, carBrand) => {
    acc.set(urlifyString(carBrand), carBrand);
    return acc;
}, new Map());

export const BRAND_TO_URLIFIED_MAP = reverseMapKeysWithValues(
    URLIFIED_CAR_BRANDS_MAP
);

export const URLIFIED_CITIES_MAP: Map<
    string /* urlified */,
    string /* original */
> = Object.values(CITIES_WITH_ALL)
    .flat()
    .reduce((acc, cityName) => {
        acc.set(urlifyString(cityName), cityName);
        return acc;
    }, new Map());

export const CITY_TO_URLIFIED_MAP =
    reverseMapKeysWithValues(URLIFIED_CITIES_MAP);
