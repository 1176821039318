export const UNKNOWN_PROBLEM_OPTION =
    "Automobilio diagnostika (Nežinau problemos)";

const POPULAR_SEARCH_OPTIONS = [
    "Tepalų keitimas",
    "Važiuoklės (Pakabos) remontas",
    UNKNOWN_PROBLEM_OPTION,
    "Stabdžių kaladėlių keitimas (priekis)",
    "Stabdžių kaladėlių keitimas (galas)",
    "Techninės būklės patikra",
    "Kondicionierių remontas",
    "Kondicionieriaus pildymas freonu (Nežinau freono tipo)",
    "Automatinės pavarų dėžės tepalų keitimas",
    "Žibintų remontas",
    "Variklio diagnostika",
    "Variklio grandinės keitimas",
];
export default POPULAR_SEARCH_OPTIONS;
