import { LiteralValuesFromArray } from "@Shared/types/utils";

export const ALL_CITIES = "Visi";

const CITIES = [
    "Vilnius",
    "Kaunas",
    "Klaipėda",
    "Panevėžys",
    "Šiauliai",
    "Alytus",
] as const;
export default CITIES;

export const CITIES_WITH_ALL = [...CITIES, ALL_CITIES] as const;

export type City = (typeof CITIES)[number];

export const WHERE_CITY: Record<
    LiteralValuesFromArray<typeof CITIES_WITH_ALL>,
    string
> = {
    Vilnius: "Vilniuje",
    Kaunas: "Kaune",
    Klaipėda: "Klaipėdoje",
    Panevėžys: "Panevėžyje",
    Šiauliai: "Šiauliuose",
    Alytus: "Alytuje",
    [ALL_CITIES]: "Lietuvoje",
};
