import Box from "@FEShared/components/UI/Box/Box";
import styled, { createGlobalStyle, css } from "styled-components";
import Text from "@FEShared/components/UI/Text/Text";

export const ContentWrapper = styled("div")`
    display: flex;
    flex-direction: column;
`;

export const VerifiedTag = styled("span")`
    color: #5e39fe;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;

    ${(p) => p.theme.breakpoints.down("md")} {
        font-size: 14px;
        line-height: 100%;
    }
`;

export const VerifiedIcon = styled("img")`
    margin-right: 8px;
`;

export const Title = styled(Text)`
    font-weight: 500;
    font-size: 30px;
    margin: 0 0 12px 0;
    line-height: 30px;

    ${(p) => p.theme.breakpoints.down("md")} {
        font-size: 20px;
        margin: 0 0 8px 0;
        line-height: 100%;
    }
`;

export const Rating = styled("div")`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const RatingScore = styled("span")<{ $fontSize: string }>`
    font-weight: 600;
    font-size: ${(p) => p.$fontSize};
    line-height: 30px;
    margin-right: 8px;

    ${(p) => p.theme.breakpoints.down("md")} {
        font-size: 16px;
        /* line-height: 100%; */
    }
`;

export const RatingCount = styled("span")`
    color: #727272;
    font-size: 16px;
    line-height: 24px;
    margin-left: 16px;

    ${(p) => p.theme.breakpoints.down("md")} {
        font-size: 14px;
        margin-left: 14px;
    }
`;

export const Icon = styled("i")<{ $primary?: boolean }>`
    margin-right: 24px;
    width: 20px;
    height: 20px;
    padding-top: 2px;

    ${(p) =>
        p.$primary &&
        css`
            color: ${p.theme.palette.primary.main};
        `}

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-right: 8px;
    }
`;

export const IconPlus = styled("i")``;

export const DetailsWrap = styled("div")`
    display: flex;
    flex-direction: column;

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: initial;
        flex: 1;
        justify-content: space-between;
    }
`;

export const PrimaryDetail = styled("span")`
    font-size: 16px;
    margin-bottom: 2px;

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-bottom: 0px;
        flex-direction: initial;
        margin-right: 16px;
    }
`;

export const WorkshopSection = styled(Box)<{
    $limitHeight?: boolean;
    $marginBottom?: number;
}>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(p) => p.$marginBottom || 96}px;

    ${(p) =>
        p.$limitHeight &&
        css`
            max-height: 810px;
        `}

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: column;
        margin-bottom: 32px;
        max-height: unset;
    }
`;

export const WorkshopServicesLeftSide = styled("div")`
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-right: 16px;

    ${(p) => p.theme.breakpoints.down("md")} {
        width: 100%;
        margin-right: initial;
        overflow-y: unset;
    }
`;

export const WorkshopServicesRightSide = styled("div")<{ $noScroll?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 75%;
    overflow-y: ${(p) => (p.$noScroll ? "initial" : "auto")};

    ${(p) => p.theme.breakpoints.down("md")} {
        width: initial;
    }
`;

export const ServicesTitleWrap = styled("div")`
    display: flex;
    height: min-content;
    margin-bottom: 8px;
`;

export const ServicesTitleSubWrap = styled("div")`
    display: flex;
    flex-direction: column;
`;

export const PurpleVerticalBar = styled("div")`
    background: ${(p) => p.theme.palette.primary.main};
    width: 5px;
    margin-right: 22px;

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-right: 16px;
    }
`;

export const ServicesTitle = styled("h2")<{ $fontSize?: number }>`
    font-weight: 600;
    font-size: ${(p) => p.$fontSize || 20}px;

    ${(p) => p.theme.breakpoints.down("md")} {
        font-size: 16px;
    }
`;

export const ServicesSubTitle = styled("span")`
    font-weight: 500;
    font-size: 16px;
    color: #727272;
`;

export const PricesTagsWrap = styled("div")`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
`;

export const PriceBeforeDiscount = styled("span")`
    font-weight: 400;
    font-size: 16px;
    color: #727272;
    text-decoration-line: line-through;
    margin-top: 4px;
`;

export const WorkshopCategoriesWrap = styled("div")`
    display: flex;
    flex-direction: column;
    padding-right: 8px;

    ${(p) => p.theme.breakpoints.down("md")} {
        overflow-y: unset;
    }
`;

export const ServiceCategoryName = styled("h3")`
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    font-size: 16px;
    margin-right: 4px;
    display: inline;

    ${(p) => p.theme.breakpoints.down("md")} {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
    }
`;

export const ServiceCategoryTotal = styled("span")`
    font-weight: 400;
    font-size: 16px;
    color: #c0c0c0;
`;

export const Chevron = styled("i")<{ $rotated: boolean }>`
    color: #c0c0c0;
    cursor: pointer;

    ${(p) =>
        p.$rotated &&
        css`
            transform: rotate(-90deg);
        `}
`;

export const WorkshopCategoryRow = styled("div")<{ $active: boolean }>`
    padding: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    ${(p) =>
        p.$active &&
        css`
            ${ServiceCategoryName} {
                text-decoration: underline;
            }

            ${ServiceCategoryName}, ${ServiceCategoryTotal} {
                color: ${(p) => p.theme.palette.primary.main}!important;
            }
        `}

    ${(p) => p.theme.breakpoints.down("md")} {
        padding: 12px 0;
        background-color: initial;
        display: flex;
    }
`;

export const PinkVerticalBar = styled("div")`
    background: #ff63a4;
    width: 5px;
    margin-right: 22px;
`;

export const ReviewsSection = styled("div")`
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-bottom: 32px;
    }
`;

export const ReviewRow = styled("div")`
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const ReviewLeftSide = styled("div")`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

export const ReviewText = styled("span")`
    font-weight: 500;
    font-size: 16px;
    margin: 8px 16px 0px 0;
`;

export const ReviewServiceTagWrap = styled("div")`
    margin-bottom: 8px;
    margin-top: 8px;

    ${(p) => p.theme.breakpoints.down("md")} {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const ReviewServiceTag = styled("span")`
    font-weight: 400;
    font-size: 16px;
    padding: 4px 12px;
    display: inline-flex;
    color: black;
    background: #f0f2f8;
    border-radius: 5px;
    margin-right: 8px;
    margin-bottom: 8px;

    &:last-child {
        margin-right: 0;
    }

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-bottom: 8px;
    }
`;

export const ReviewTimeAgo = styled("span")`
    color: #c0c0c0;
    margin-left: auto;
`;

export const ServiceInformationWrap = styled("div")`
    display: flex;
    flex-direction: column;

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-bottom: 16px;
    }
`;

export const WorkCalendar = styled("div")`
    display: flex;
    flex-direction: column;
    width: 30%;

    ${(p) => p.theme.breakpoints.down("md")} {
        width: 100%;
    }
`;

export const WorkCalendarRow = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 8px;

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: row;
    }
`;

export const OpenCircle = styled("div")<{ $open: boolean }>`
    background-color: ${(p) => (p.$open ? "#64C584" : "#E0E0E0")};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
`;

export const DayOfWeek = styled("span")<{ $className: string }>`
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;

    ${(p) =>
        p.$className.includes("close") &&
        css`
            color: #c0c0c0;
        `}
`;

export const WorkTimeRange = styled("span")<{ $className: string }>`
    font-weight: 500;
    font-size: 16px;

    ${(p) =>
        p.$className.includes("close") &&
        css`
            color: #c0c0c0;
        `}
`;

export const ServiceInformationSection = styled("div")`
    display: flex;
    width: 100%;
    margin-bottom: 36px;

    ${(p) => p.theme.breakpoints.down("md")} {
        flex-direction: column;
    }
`;

export const AboutWorkshop = styled("div")`
    display: flex;
    flex-direction: column;
    width: 70%;

    ${(p) => p.theme.breakpoints.down("md")} {
        width: 100%;
    }
`;

export const AboutWorkshopTitle = styled("span")`
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;
`;

export const AboutWorkshopDescription = styled("span")`
    color: #727272;
    font-weight: 400;
    font-size: 16px;
    white-space: break-spaces;
`;

export const WSPageGlobalStyles = createGlobalStyle`
    #chatlio-widget-container {
        right: 8px!important;
        display:none;
    }

    /* ${(p) => p.theme.breakpoints.down("md")} {
        #chatlio-widget-container {
            bottom: 50px;
            left: -8px!important;
            right:unset!important;
        }

        .chatlio-widget-body {
            height: 100%;
            left:10px!important;
            right:unset;
        }

        .chatlio-widget.chatlio-closed .chatlio-title-bar.chatlio-title-bar-chip.chatlio-bottom-right {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 40px!important;
        }
    } */
`;
