import parsePhoneNumber from "libphonenumber-js";

const PHONE_NUMBER_REGEX = new RegExp(/(86|\+3706|0)\d{7}/);

export default function isValidPhoneNr(
    phoneNr: string,
    allCountries?: boolean
): boolean {
    const trimmedPhoneNr = phoneNr.trim().replace(/ /g, "");

    const validLTPhoneNumber =
        PHONE_NUMBER_REGEX.test(trimmedPhoneNr) &&
        ((trimmedPhoneNr.startsWith("86") && trimmedPhoneNr.length === 9) ||
            (trimmedPhoneNr.startsWith("+3706") &&
                trimmedPhoneNr.length === 12) ||
            (trimmedPhoneNr.startsWith("0") && trimmedPhoneNr.length === 9));

    if (!validLTPhoneNumber && allCountries) {
        const parsedPhoneNumber = parsePhoneNumber(trimmedPhoneNr);
        console.log("parsedPhoneNumber valid", parsedPhoneNumber);
        return Boolean(parsedPhoneNumber && parsedPhoneNumber.isValid());
    } else {
        return validLTPhoneNumber;
    }
}
