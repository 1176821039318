import React from "react";
import * as S from "./ProviderCard.styled";
import useIsMobile from "@FEShared/hooks/useIsMobile";

const ProviderCard: React.FC<{
    id: number;
    review: React.ReactNode;
    companyName: string;
    personName: string;
    bgColor: string;
    bgImg: {
        desktop: string;
        mobile: string;
    };
}> = (p) => {
    const isMobile = useIsMobile();

    return (
        <S.Container
            $bgImg={isMobile ? p.bgImg.mobile : p.bgImg.desktop}
            $bgColor={p.bgColor}
        >
            {p.review}
            <S.BottomSection>
                {isMobile && (
                    <S.TopRatedWrap>
                        <S.Star className="icon-star-full" />
                        <S.TopRatedText>Top Rated 2023</S.TopRatedText>
                    </S.TopRatedWrap>
                )}
                <S.NameCompanyGroup $even={p.id % 2 === 0}>
                    {isMobile && <S.PersonName>{p.personName}, </S.PersonName>}
                    <S.CompanyName>{p.companyName}</S.CompanyName>
                    {!isMobile && <S.PersonName>{p.personName}</S.PersonName>}
                </S.NameCompanyGroup>
            </S.BottomSection>
        </S.Container>
    );
};

export default ProviderCard;
