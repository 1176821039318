import React from "react";
import * as S from "./BecomePartner.styled";
import * as IFS from "../../commonComps/InfoPage/InfoPage.styled";
import { ABOVE_WORKSHOPS_COUNT } from "@FEClient/consts/consts";
import Text from "@FEShared/components/UI/Text/Text";
import Button from "@FEShared/components/UI/Button/Button";
import WorkshopLogos from "./WorkshopLogos/WorkshopLogos";
import Box from "@FEShared/components/UI/Box/Box";
import ContentContainer from "@FEClient/views/commonComps/ContentContainer/ContentContainer";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import { useMediaQuery } from "@mui/material";
import MOBILE_LANDING_IMG from "./assets-new/mobile-landing.png";
import YELLOW_COLOR_SPLASH_IMG from "./assets-new/desktop-yellow.webp";
import RED_COLOR_SPLASH from "./assets-new/desktop-red.webp";
import BLUE_COLOR_SPLASH from "./assets-new/desktop-blue.webp";
import MOBILE_RED_IMG from "./assets-new/mobile-red.webp";
import MOBILE_BLUE_IMG from "./assets-new/mobile-blue.webp";
import MOBILE_YELLOW_IMG from "./assets-new/mobile-yellow.webp";
import CIRCLE_IMG from "./assets-new/top-blue-splash.png";
import SPLASH_IMG from "./assets-new/devices.webp";
import prodRealUsersOnly from "@FEClient/logic/utils/prodRealUsersOnly";
import showToast from "@FEShared/utils/showToast";
import PartnerRecomendationCards from "@FEClient/views/commonComps/PartnerRecomendationCards/PartnerRecomendationCards";

const SUCCESS_REDIRECT_QUERY_PARAM = "?success";

const BecomePartner: React.FC = () => {
    // const theme = useTheme();
    const formRef = React.useRef<HTMLDivElement>(null);
    const onBtnClick = () => {
        formRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
        } as any);
    };
    const isMobile = useIsMobile();
    const isBelowBreakpoint = useMediaQuery(
        `(max-width:${S.TOP_LANDING_BREAKPOINT}px)`
    );

    React.useEffect(() => {
        if (window.location.search.includes(SUCCESS_REDIRECT_QUERY_PARAM)) {
            showToast.success(
                "Jūsų duomenis sėkmingai gavome. Vienos darbo dienos bėgyje su jumis susisieks nevaziuoja.lt atstovas."
            );
            prodRealUsersOnly(() => {
                window.gtag?.("event", "become_partner");
                window.fbq?.("track", "become_partner");
                window.fbq?.("track", "SubmitApplication");
                window.hj?.("event", "become_partner");
            });
        } else {
            prodRealUsersOnly(() => {
                window.gtag?.("event", "become_partner_landing");
                window.fbq?.("track", "become_partner_landing");
                window.fbq?.("track", "ViewContent");
                window.hj?.("event", "become_partner_landing");
            });
        }
    }, []);

    return (
        <S.BecomePartnerContainer>
            <S.TopBanner>
                <ContentContainer>
                    <S.Left>
                        <S.Title>Nemokama autoserviso programa</S.Title>
                        <S.Descr>
                            Klientai mygtuko paspaudimu. Automatizuok savo
                            verslą, taupyk laiką ir pinigus.
                        </S.Descr>
                        {isBelowBreakpoint && (
                            <>
                                <S.MobileLandingImg src={MOBILE_LANDING_IMG} />
                            </>
                        )}
                        <S.FeaturesWrapper>
                            <S.FeatureBox>
                                <S.FeatureTitle>24/7</S.FeatureTitle>
                                <S.FeatureDescr>
                                    Registracijos internetu
                                </S.FeatureDescr>
                            </S.FeatureBox>
                            <S.FeatureBox>
                                <S.FeatureTitle>100%</S.FeatureTitle>
                                <S.FeatureDescr>
                                    Surenkami visų klientų atsiliepimai
                                </S.FeatureDescr>
                            </S.FeatureBox>
                            <S.FeatureBox>
                                <S.FeatureTitle>65%</S.FeatureTitle>
                                <S.FeatureDescr>
                                    Mažiau klientų nepasirodymų
                                </S.FeatureDescr>
                            </S.FeatureBox>
                        </S.FeaturesWrapper>
                        <S.BecomePartnerBtn
                            color="tertiary"
                            onClick={onBtnClick}
                        >
                            Tapk partneriu nemokamai
                        </S.BecomePartnerBtn>
                    </S.Left>
                </ContentContainer>
                {!isBelowBreakpoint && (
                    <>
                        <S.CircleImg src={CIRCLE_IMG} style={{ zIndex: 0 }} />
                        <S.RightImg src={SPLASH_IMG} />
                    </>
                )}
            </S.TopBanner>
            <ContentContainer
                sx={(theme) => ({
                    pt: "64px",
                    pb: "64px",
                    [theme.breakpoints.down("md")]: {
                        pt: "48px",
                        pb: "48px",
                    },
                })}
            >
                <PartnerRecomendationCards />
            </ContentContainer>
            <IFS.GreySection
                style={{ marginTop: 0, paddingTop: 48, paddingBottom: 48 }}
            >
                <S.SectionTitle style={{ marginTop: 0, fontSize: 24 }}>
                    Mumis pasitiki jau daugiau nei{" "}
                    <Text
                        color="primary"
                        component="span"
                        fontSize="inherit"
                        fontWeight="inherit"
                    >
                        {ABOVE_WORKSHOPS_COUNT}+ autoservisų
                    </Text>{" "}
                    Lietuvoje
                </S.SectionTitle>
                <WorkshopLogos />
                <Box mt={4}>
                    <Button
                        color="tertiary"
                        onClick={onBtnClick}
                        sx={{ margin: "0 auto", maxWidth: "273px", width: 1 }}
                    >
                        Tapk partneriu nemokamai
                    </Button>
                </Box>
            </IFS.GreySection>
            <S.VideoSection>
                <S.VideoTitle>Kliento kelionė į Tavo autoservisą:</S.VideoTitle>
                <S.VideoIframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/YE0Wu90fUCo"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                />
            </S.VideoSection>
            <S.ColorSection $color="#EEA600">
                <ContentContainer
                    sx={(theme) => ({
                        [theme.breakpoints.down("md")]: {
                            pt: "32px",
                        },
                    })}
                >
                    <S.SectionContentWrapper $type="left">
                        <S.SectionTitle>
                            <S.SectionTitleEmphasis>
                                Užsakymai
                            </S.SectionTitleEmphasis>{" "}
                            vieno mygtuko paspaudimu
                        </S.SectionTitle>
                        {isMobile && (
                            <>
                                <S.PercentageBubble>
                                    46% registracijų į autoservisus vyksta po
                                    darbo valandų*
                                    <Text
                                        mt={1}
                                        fontSize={14}
                                        color={"rgba(255,255,255,0.75)"}
                                    >
                                        *remiantis mūsų užsakymų statistika
                                    </Text>
                                </S.PercentageBubble>
                                <S.ColorSectionMobileImg
                                    src={MOBILE_YELLOW_IMG}
                                />
                            </>
                        )}
                        <S.SectionDescr>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                Nemokami automatiniai SMS priminimai klientams
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                Galimybė registruotis į autoservisą 24/7
                                internetu
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                Nauji klientai mygtuko paspaudimu
                            </S.SectionFeature>
                            <Button onClick={onBtnClick}>
                                Tapk partneriu nemokamai
                            </Button>
                        </S.SectionDescr>
                    </S.SectionContentWrapper>
                </ContentContainer>
                {!isMobile && (
                    <S.ColorSplash
                        $multiplier={0.3}
                        $type="right"
                        $bgImgUrl={YELLOW_COLOR_SPLASH_IMG}
                    />
                )}
            </S.ColorSection>
            <S.ColorSection $color="#FF7678">
                <ContentContainer>
                    <S.SectionContentWrapper $type="right">
                        <S.SectionTitle>
                            Modernus{" "}
                            <S.SectionTitleEmphasis>
                                autoserviso puslapis
                            </S.SectionTitleEmphasis>
                        </S.SectionTitle>
                        {isMobile && (
                            <S.ColorSectionMobileImg src={MOBILE_RED_IMG} />
                        )}
                        <S.SectionDescr>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                Automatiškai surenkami atsiliepimai iš 100%
                                klientų
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                Patvirtinti atsiliepimai
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                Paprastai keičiama puslapio informacija
                            </S.SectionFeature>
                            <Button onClick={onBtnClick}>
                                Tapk partneriu nemokamai
                            </Button>
                        </S.SectionDescr>
                    </S.SectionContentWrapper>
                </ContentContainer>

                {!isMobile && (
                    <S.ColorSplash
                        $type="left"
                        $bgImgUrl={RED_COLOR_SPLASH}
                        $multiplier={0.65}
                    />
                )}
            </S.ColorSection>
            <S.ColorSection $color="#5E39FE">
                {/* <S.GreySectionImg /> */}
                <ContentContainer>
                    <S.SectionContentWrapper $type="left">
                        <S.SectionTitle>
                            Išmani autoserviso{" "}
                            <S.SectionTitleEmphasis>
                                valdymo sistema
                            </S.SectionTitleEmphasis>
                        </S.SectionTitle>
                        {isMobile && (
                            <S.ColorSectionMobileImg src={MOBILE_BLUE_IMG} />
                        )}
                        <S.SectionDescr>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                Skaitmeninis kalendorius
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                Sąskaitos, užsakymų istorija, užsakymo paskyros,
                                atvykimo formos
                            </S.SectionFeature>
                            <S.SectionFeature>
                                <S.FeatureCheckmark className="icon-checkmark" />
                                Dalių sandėlis
                            </S.SectionFeature>
                            <Button onClick={onBtnClick}>
                                Tapk partneriu nemokamai
                            </Button>
                        </S.SectionDescr>
                    </S.SectionContentWrapper>
                </ContentContainer>

                {!isMobile && (
                    <S.ColorSplash
                        $type="right"
                        // $multiplier={isBelowBreakpoint ? 0.4 : 0.2}
                        $multiplier={isBelowBreakpoint ? 0.4 : 0.2}
                        $bgImgUrl={BLUE_COLOR_SPLASH}
                    />
                )}
            </S.ColorSection>
            <S.BottomSection>
                <S.FormWrapper ref={formRef}>
                    <S.FormTitle>
                        Nori{" "}
                        <S.EmphasisText>
                            pritraukti daugiau klientų
                        </S.EmphasisText>{" "}
                        ir modernizuoti savo verslą? Registruokis ir tapk
                        partneriu <S.EmphasisText>nemokamai.</S.EmphasisText>
                    </S.FormTitle>
                    <S.FormDescr>
                        Įvesk savo informaciją ir su tavimi per 2d.d. susisieks
                        mūsų komandos narys.
                    </S.FormDescr>
                    <S.FormIframeWrapper>
                        <S.FormIframe
                            src="https://tally.so/r/wzjYM8?transparentBackground=1"
                            scrolling="no"
                        />
                    </S.FormIframeWrapper>
                </S.FormWrapper>
            </S.BottomSection>
        </S.BecomePartnerContainer>
    );
};

export default BecomePartner;
