import { ServiceAutocompleteOption } from "@FEShared/components/UI/ServicesAutocomplete/ServicesAutocomplete.types";
import {
    ServiceCategory,
    ServicePackage,
} from "@FEShared/graphql/generated/graphql";
import mapKeyBy from "./mapKeyBy";
import lOrderBy from "lodash/orderBy";

export type MergedServiceOrPackage = Pick<
    ServiceCategory,
    | "partCategory"
    | "serviceName"
    | "searchAliases"
    | "sortOrder"
    | "important"
    | "categorization"
    | "defaultUnit"
> & {
    /** Bullet points. For now this is only used for serviceNames. When it won't be used for serviceNames only, make sure to adjust `WorkshopServiceRow.tsx` */
    additionalTexts?: string[];
    imgUrl?: string;
    recommendationText?: string | null;
    bulky?: boolean;
    package?: {
        servicesDefinitionsIDs: string[];
    };
};

export default function mergeServicesWithPackages(p: {
    services: ServiceCategory[];
    packages: ServicePackage[];
}): ServiceAutocompleteOption[] {
    const servicesMapByID = mapKeyBy(p.services, (s) => s.ID);
    const merged = [] as ServiceAutocompleteOption[];

    p.packages.forEach((pkg) => {
        const allServices = pkg.servicesDefinitionsIDs
            .map((packageServiceID) => servicesMapByID.get(packageServiceID))
            .filter(Boolean) as ServiceCategory[];

        if (allServices.length !== pkg.servicesDefinitionsIDs.length) {
            return false;
        }

        merged.push({
            important: false,
            partCategory: pkg.partCategory,
            searchAliases: allServices.map((s) => s.searchAliases).flat(),
            serviceName: pkg.name,
            sortOrder: pkg.sortOrder,
            additionalTexts: allServices.map((s) => s.serviceName),
            bulky: true,
            package: {
                servicesDefinitionsIDs: pkg.servicesDefinitionsIDs,
            },
            imgUrl: pkg.imgUrl,
            recommendationText: pkg.recommendationText,
            categorization: [], // rn there is no implementation for categorization for packages.
        });
    });

    p.services.forEach((s) => {
        merged.push({
            important: s.important,
            partCategory: s.partCategory,
            searchAliases: s.searchAliases,
            serviceName: s.serviceName,
            sortOrder: s.sortOrder,
            bulky: false,
            categorization: s.categorization,
        });
    });

    const sorted = lOrderBy(
        merged,
        [(s) => s.bulky, (s) => s.sortOrder],
        ["desc", "desc"]
    );

    return sorted;
}
