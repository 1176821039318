import React from "react";
import SearchForm from "../SearchForm/SearchForm";
import * as S from "./Splash.styled";
import Text from "@FEShared/components/UI/Text/Text";
import SHIELD_IMG from "./assets/shield.png";
import POINT_IMG from "./assets/point.png";
import { observer } from "mobx-react-lite";
import { WHERE_CITY } from "@FEShared/consts/CITIES";
import useStore from "@FEClient/logic/store";
import Box from "@FEShared/components/UI/Box/Box";
import Icon from "@FEShared/components/UI/Icon/Icon";
import { ABOVE_WORKSHOPS_COUNT } from "@FEClient/consts/consts";
import { CountUp } from "use-count-up";
import { getCarsAmount } from "./Splash.utils";
import { getSeoPageData } from "../FAQ/FAQ.util";

const Splash: React.FC<{}> = observer(() => {
    const GS = useStore();
    const carsAmount = getCarsAmount(new Date().toDateString(), 512, 746);
    const seoPageData = getSeoPageData();
    let splashTitle;
    if (seoPageData) {
        splashTitle = (
            <>{seoPageData.seoServiceName} - registracija internetu 24/7</>
        );
    } else if (GS.adsCampaign.service) {
        splashTitle = `${GS.adsCampaign.service} - registraciju internetu 24/7`;
    } else if (GS.adsCampaign.carBrand) {
        splashTitle = (
            <>
                Geriausi {GS.adsCampaign.carBrand} autoservisai - registracija
                internetu 24/7
            </>
        );
    } else {
        splashTitle = (
            <>
                Registruokis į autoservisą{" "}
                {GS.adsCampaign.city
                    ? ` ${WHERE_CITY[GS.adsCampaign.city]}`
                    : ""}{" "}
                internetu 24/7
            </>
        );
    }

    return (
        <S.SplashContainer>
            <S.SplashContent>
                <S.LeftSideWrapper>
                    <S.SplashTexts>
                        <S.SplashTitle variant="h1">
                            {splashTitle}
                        </S.SplashTitle>
                    </S.SplashTexts>
                    <SearchForm />
                    <S.SplashFeatures>
                        <Box displayFlex alignItems="flex-start">
                            <S.FeatureImg src={SHIELD_IMG} />
                            <Box
                                sx={{
                                    mr: 4,
                                }}
                            >
                                <Text color="white.main" semiBold>
                                    100% Darbų garantija
                                </Text>
                                <Text
                                    color="rgba(255, 255, 255, 0.75)"
                                    mt={0.5}
                                    fontSize={14}
                                >
                                    <Icon className="icon-lock" mr={0.5} />{" "}
                                    Techninio advokato apsauga
                                </Text>
                                <Text
                                    color="rgba(255, 255, 255, 0.75)"
                                    mt={0.5}
                                    fontSize={14}
                                >
                                    <Icon className="icon-lock" mr={0.5} /> 6
                                    mėn. arba 10,000 km
                                </Text>
                            </Box>
                        </Box>
                        <Box
                            displayFlex
                            alignItems="flex-start"
                            sx={(p) => ({
                                [p.breakpoints.down("md")]: {
                                    mt: 2,
                                },
                            })}
                        >
                            <S.FeatureImg src={POINT_IMG} />
                            <Box>
                                <Text color="white.main" semiBold>
                                    {ABOVE_WORKSHOPS_COUNT}+ verifikuotų servisų
                                </Text>
                                <Text
                                    color="rgba(255, 255, 255, 0.75)"
                                    mt={0.5}
                                    fontSize={14}
                                >
                                    {/* <Icon className="icon-car" mr={0.5} />{" "} */}
                                    Remontuojama{" "}
                                    <Text display="inline-block" width="34px">
                                        <CountUp
                                            isCounting
                                            start={10}
                                            end={carsAmount}
                                            duration={2}
                                        />{" "}
                                    </Text>
                                    automobilių
                                </Text>
                            </Box>
                        </Box>
                    </S.SplashFeatures>
                </S.LeftSideWrapper>
                {/* <S.ImgWrapper
                    flex={1}
                    ml={"auto"}
                    displayFlex
                    alignItems="flex-end"
                    sx={(theme) => ({
                        [theme.breakpoints.up("md")]: {
                            maxWidth: "45%",
                        },
                    })}
                > */}
                {/* <S.SplashImg src={NEXT_STEP_IMG} /> */}
                {/* </S.ImgWrapper> */}
            </S.SplashContent>
        </S.SplashContainer>
    );
});

export default Splash;
