import React from "react";
import * as S from "./ConfirmationRowItem.styled";
import Text from "@FEShared/components/UI/Text/Text";

interface P {
    id: number;
    value: string;
    label?: string;
    iconClassName?: string;
    onClick?: () => void;
}

const ConfirmationRowItem: React.FC<P> = (p) => {
    return (
        <S.OrderDetailRow onClick={p.onClick}>
            {p.iconClassName ? (
                <S.OrderDetailIcon className={p.iconClassName} />
            ) : (
                <span style={{ marginRight: "48px" }} />
            )}

            <S.OrderDetailTextWrapper>
                <S.OrderDetailText>{p.value}</S.OrderDetailText>
                <S.OrderDetailLabel>{p.label}</S.OrderDetailLabel>
            </S.OrderDetailTextWrapper>
            {p.onClick && (
                <Text
                    color="primary"
                    sx={{ textDecoration: "underline" }}
                    ml="auto"
                >
                    Keisti
                </Text>
            )}
        </S.OrderDetailRow>
    );
};

export default ConfirmationRowItem;
