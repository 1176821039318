import styled, { css } from "styled-components";

export const OrderDetailRow = styled("div")`
    display: flex;
    align-items: flex-start;
    margin-bottom: 14px;

    ${(p) =>
        p.onClick &&
        css`
            cursor: pointer;
        `}
`;

export const OrderDetailIcon = styled("i")`
    font-size: 14px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
    border-radius: 45px;
    margin-right: 16px;

    ${(p) =>
        p.className?.includes("special") &&
        css`
            color: #5e39fe;
            background-color: rgba(94, 57, 254, 0.25);
        `}
`;

export const OrderDetailLabel = styled("span")`
    font-weight: 500;
    font-size: 16px;
    color: #9c9c9c;
`;

export const OrderDetailText = styled("span")`
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 4px;
`;

export const OrderDetailTextWrapper = styled("div")`
    display: flex;
    flex-direction: column;
`;
