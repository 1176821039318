import { CarAutocompleteStage } from "./CarAutocomplete.types";
import CAR_MODELS from "./CarModels.json";
export const BRANDS = Object.keys(CAR_MODELS);

export const CURRENT_YEAR = 2023;
export const LAST_CAR_YEAR = 1985;
export const STAGES_ORDER: CarAutocompleteStage[] = [
    CarAutocompleteStage.BRAND,
    CarAutocompleteStage.MODEL,
    CarAutocompleteStage.YEAR,
    // CarAutocompleteStage.VEHICLE_BODY,
];
export const STAGE_TO_TEXT: Record<CarAutocompleteStage, string> = {
    [CarAutocompleteStage.BRAND]: "Markė",
    [CarAutocompleteStage.MODEL]: "Modelis",
    [CarAutocompleteStage.YEAR]: "Metai",
    // [CarAutocompleteStage.VEHICLE_BODY]: "Kėbulo tipas",
};

export const DONT_KNOW_MODEL = "Nežinau modelio";
export const DONT_KNOW_YEAR = "Nežinau metų";
export const OTHER_MODEL_OR_YEAR = "-kita-";
export const OTHER_BRAND = "-Kita-";
