import React from "react";
import * as S from "./NavBar.styled";
import { pageToMeta } from "@Shared/util/clientPagesMeta";
import useStore from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";

export const NAV_ITEMS = [
    {
        name: "100% Darbų garantija",
        url: pageToMeta.WARRANTY.url,
        showOnDesktop: true,
    },
    {
        name: "Autoservisams",
        url: pageToMeta.BECOME_PARTNER.url,
    },
    {
        name: "Autoserviso paieška",
        url: pageToMeta.SEARCH.url,
    },
];

const NavBar: React.FC<{
    isSideBar?: boolean;
}> = observer((p) => {
    const GS = useStore();
    return (
        <S.NavBarContainer $page={GS.activePage}>
            {NAV_ITEMS.filter((i) => {
                if (p.isSideBar) {
                    return true;
                } else {
                    if (i.showOnDesktop) {
                        return true;
                    }
                }
                return false;
            }).map((item) => {
                return (
                    <S.NavBarLink key={item.url} to={item.url}>
                        {item.name}
                    </S.NavBarLink>
                );
            })}
        </S.NavBarContainer>
    );
});

export default NavBar;
