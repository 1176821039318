import React from "react";

import PartnerImgDesk0 from "./assets/partner_ds0.webp";
import PartnerImgDesk1 from "./assets/partner_ds1.webp";
import PartnerImgDesk2 from "./assets/partner_ds2.webp";
import PartnerImgDesk3 from "./assets/partner_ds3.webp";
import PartnerImgMob0 from "./assets/partner_s0.webp";
import PartnerImgMob1 from "./assets/partner_s1.webp";
import PartnerImgMob2 from "./assets/partner_s2.webp";
import PartnerImgMob3 from "./assets/partner_s3.webp";
import { Review } from "./PartnerRecomendationCards.styled";

export const reviews = [
    {
        id: 0,
        review: (
            <Review>
                “<b>Daugiau klientų</b>, spartesnis administracijos darbas.”
            </Review>
        ),
        companyName: "EDLER",
        personName: "Edvard Namirskij",
        bgColor: "#281382",
        bgImg: {
            desktop: PartnerImgDesk0,
            mobile: PartnerImgMob0,
        },
    },
    {
        id: 1,
        review: (
            <Review>
                “<b>Visiškai atnaujintas</b> autoserviso įvaizdis.”
            </Review>
        ),
        companyName: "ExpressAuto",
        personName: "Arvydas Krakauskas",
        bgColor: "#E83C3F",
        bgImg: {
            desktop: PartnerImgDesk1,
            mobile: PartnerImgMob1,
        },
    },
    {
        id: 2,
        review: (
            <Review>
                “<b>Klientai registruojasi</b> kada jiems patogu,{" "}
                <b>visą parą</b>.”
            </Review>
        ),
        companyName: "FlixAuto",
        personName: "Mantas Grinkevičius",
        bgColor: "#FF9900",
        bgImg: {
            desktop: PartnerImgDesk2,
            mobile: PartnerImgMob2,
        },
    },
    {
        id: 3,
        review: (
            <Review>
                “Autoservisą valdau iš telefono, <b>dirbam daug efektyviau</b>.”
            </Review>
        ),
        companyName: "EDauta",
        personName: "Edvinas Juodis",
        bgColor: "#1BCC77",
        bgImg: {
            desktop: PartnerImgDesk3,
            mobile: PartnerImgMob3,
        },
    },
];
