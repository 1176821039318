import { ABOVE_WORKSHOPS_COUNT } from "@FEClient/consts/consts";
import { ContentContainer } from "@FEClient/views/commonComps/ContentContainer/ContentContainer.styled";
import React from "react";
import * as S from "./AboutUs.styled";
import DEVS_IMG from "./assets/devs.webp";
import MACHINERY_IMG from "./assets/machinery.webp";

const AboutUs: React.FC = () => {
    return (
        <S.AboutUsContainer>
            <S.PicBanner $backgroundImgUrl={MACHINERY_IMG}>
                <ContentContainer>
                    <S.Title>Apie Mus</S.Title>
                    <S.Descr>
                        Esame pasiryžę pakeisti senamadišką požiūrį į
                        automobilių remontą. Siūlome patogų ir greitą būdą
                        registruotis į tavo pasirinktą autoservisą. Jokių
                        skambučių, 4 mygtuko paspaudimai ir baigta.
                    </S.Descr>
                </ContentContainer>
            </S.PicBanner>
            <S.TransparentSection>
                <ContentContainer>
                    <S.BoxFeaturesWrapper>
                        <S.BoxFeature>
                            <S.BoxFeatureTitle>
                                Kaina ir kokybė
                            </S.BoxFeatureTitle>
                            <S.BoxFeatureDescr>
                                Dirbame su patikimiausiais paslaugų teikėjais,
                                siekdami užtikrinti kokybiškas paslaugas už
                                prieinamą kainą.
                            </S.BoxFeatureDescr>
                        </S.BoxFeature>
                        <S.BoxFeature>
                            <S.BoxFeatureTitle>
                                Greitis ir patogumas
                            </S.BoxFeatureTitle>
                            <S.BoxFeatureDescr>
                                Palygink autoservisus vienoje vietoje. Lengvai
                                rask ir užsisakyk tau reikalingą paslaugą,
                                neišeinant iš namų.
                            </S.BoxFeatureDescr>
                        </S.BoxFeature>
                        <S.BoxFeature>
                            <S.BoxFeatureTitle>
                                Kokybės garantija
                            </S.BoxFeatureTitle>
                            <S.BoxFeatureDescr>
                                Nesklandumus nemokamai padės išspręsti mūsų
                                techninis advokatas.
                            </S.BoxFeatureDescr>
                        </S.BoxFeature>
                    </S.BoxFeaturesWrapper>
                </ContentContainer>
            </S.TransparentSection>
            <S.GreySection>
                <S.GreySectionTitle>
                    Rinkis iš mūsų partnerių
                </S.GreySectionTitle>
                <S.GreySectionFeaturesWrapper>
                    <S.GreySectionFeature>
                        <S.GreySectionFeatureEmphasis>
                            {ABOVE_WORKSHOPS_COUNT}+
                        </S.GreySectionFeatureEmphasis>
                        <S.GreySectionFeatureDescr>
                            partneriai
                        </S.GreySectionFeatureDescr>
                    </S.GreySectionFeature>
                    <S.GreySectionFeature>
                        <S.GreySectionFeatureEmphasis>
                            6
                        </S.GreySectionFeatureEmphasis>
                        <S.GreySectionFeatureDescr>
                            miestai
                        </S.GreySectionFeatureDescr>
                    </S.GreySectionFeature>
                    <S.GreySectionFeature>
                        <S.GreySectionFeatureEmphasis>
                            100+
                        </S.GreySectionFeatureEmphasis>
                        <S.GreySectionFeatureDescr>
                            rajonai
                        </S.GreySectionFeatureDescr>
                    </S.GreySectionFeature>
                </S.GreySectionFeaturesWrapper>
            </S.GreySection>
            <S.OurStorySection>
                <S.OurStoryContentContainer>
                    <S.OurStoryWrapper>
                        <S.OurStoryTitle>Mūsų istorija</S.OurStoryTitle>
                        <S.OurStoryDescr>
                            Pastebėjome, kad daugeliui žmonių sunku rasti laiko
                            ir jėgų planuoti rezervacijas į autoservisus.
                            Nevažiuoja - moderni aplikacija leidžianti
                            užsiregistruoti vizitui į autoservisą vos kelių
                            mygtukų paspaudimu. Rinkis pagal kokybę, kainą ir
                            laisvą laiką. Viskuo kitu pasirūpins ilgametę
                            patirtį turintys meistrai.
                            <S.OurStoryEndingLine>
                                Išbandyk mūsų aplikaciją šiandien ir nusimesk
                                rūpesčius.
                            </S.OurStoryEndingLine>
                        </S.OurStoryDescr>
                    </S.OurStoryWrapper>
                    <S.OurStoryImg src={DEVS_IMG} />
                </S.OurStoryContentContainer>
            </S.OurStorySection>
        </S.AboutUsContainer>
    );
};

export default AboutUs;
