import prodRealUsersOnly from "@FEClient/logic/utils/prodRealUsersOnly";

/*  tbd need to add a solution in the future */
export default function sendPixelEvent(p: { eventName: string }): void {
    console.log("send ev", p);
    prodRealUsersOnly(() => {
        if (window.gtag) {
            window.gtag("event", p.eventName);
        } else {
            console.error(`gtag not found`);
            setTimeout(() => {
                if (window.gtag) {
                    window.gtag("event", p.eventName);
                } else {
                    console.error(`gtag not found after timeout`);
                }
            }, 10000);
        }

        if (window.fbq) {
            window.fbq("track", p.eventName);
        } else {
            setTimeout(() => {
                if (window.fbq) {
                    window.fbq("event", p.eventName);
                } else {
                    console.error(`fbq not found after timeout`);
                }
            }, 10000);
        }
    });
}
