import FECLIENT_SERVICES_SEO_PAGES from "@Shared/consts/FECLIENT_SERVICES_SEO_PAGES";
import urlifyString from "@FEShared/utils/urlifyString";
import _reduce from "lodash/reduce";
import FECLIENT_DISTRICT_SEO_PAGES from "@Shared/consts/FECLIENT_DISTRICT_SEO_PAGES";
import FECLIENT_BRANDS_SEO_PAGES from "@Shared/consts/FECLIENT_BRANDS_SEO_PAGES";
import FECLIENT_ALL_SERVICES_SEO_PAGES from "@Shared/consts/FECLIENT_ALL_SERVICES_SEO_PAGES";

// The name of the enum should match the name of the page file in CamelCase
export enum Page {
    HOME = "HOME",
    PARTNERS = "PARTNERS",
    SEARCH = "SEARCH",
    /** Page where client can choose an alternative service to his initially selected service */
    ALTERNATIVES_SEARCH = "ALTERNATIVES_SEARCH",
    /**Page where client can review service reviews, work hours */
    WORKSHOP = "WORKSHOP",
    /** Same as RESERVATION_CONFIRMATION just user is redirected to this page after ALTERNATIVES_SEARCH */
    ALTERNATIVE_RESERVATION_CONFIRMATION = "ALTERNATIVE_RESERVATION_CONFIRMATION",
    /** Page where client needs to do final confirmation of all of his selections */
    RESERVATION_CONFIRM = "RESERVATION_CONFIRM",
    /** Page where client sees that his reservation is successful */
    RESERVATION_SUCCESS = "RESERVATION_SUCCESS",
    INVITATION_EXPIRED = "INVITATION_EXPIRED",
    INTERNAL_ORDER_REVIEW = "INTERNAL_ORDER_REVIEW",
    SERVICE_CLIENT_DECLINED = "SERVICE_CLIENT_DECLINED",
    ABOUT_US = "ABOUT_US",
    PRIVACY_POLICY = "PRIVACY_POLICY",
    TERMS_OF_SERVICE = "TERMS_OF_SERVICE",
    SEO_SERVICES = "SEO_SERVICES",
    BECOME_PARTNER = "BECOME_PARTNER",
    PARTNER_FORM = "PARTNER_FORM",
    WARRANTY = "WARRANTY",
    CLIENT_ONLY = "CLIENT_ONLY",
    // the only difference between WARRANTY_N_REVIEW And this, is just the title of the page for better conversion of warranty acitvation/review leaving.
    WARRANTY_ACTIVATION = "WARRANTY_ACTIVATION",
    WARRANTY_N_REVIEW = "WARRANTY_N_REVIEW",
    /* SEO Landing page for specific service */
    SEO_PAGES = "SEO_PAGES",
    SITEMAP = "SITEMAP",
    ADMIN_PROMO = "ADMIN_PROMO",
}

// recommendations: 60 chars for title, 160 for description.
// FUTURE IDEA: Can hold header/footer options inside this object;
export const pageToMeta: {
    [k in Page]: {
        title: string;
        description: string;
        /** for react router match */
        path?: string | string[];
        shortUrl?: string;
        url: string;
        // genPathname?: <T>(p: T) => string; TBD would be good if it worked like this. Not sure how to pass generic down.
        notExactMatch?: boolean;
        indexable: boolean;
        hideNavBar?: boolean;
        unclickableLogo?: boolean;
        notSticky?: boolean;
    };
} = {
    HOME: {
        indexable: true,
        url: "/",
        title: "Registruokis į autoservisą internetu 24/7",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
    },
    SEARCH: {
        indexable: false,
        url: "/autoservisai",
        path: "/autoservisai/:params?",
        title: "TOP 20 Autoservisai | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        notExactMatch: true,
    },
    WORKSHOP: {
        indexable: false,
        url: "/autoservisas",
        path: "/autoservisas/:workshopName",
        title: "Išsirink autoservisą patogiai, greitai, paprastai | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        notSticky: true,
    },
    RESERVATION_CONFIRM: {
        indexable: false,
        url: "/rezervacijos-patvirtinimas",
        title: "Užsirašyk į autoservisą internetu 24/7 | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        hideNavBar: true,
        unclickableLogo: true,
        notSticky: true,
    },
    RESERVATION_SUCCESS: {
        indexable: false,
        url: "/rezervacija-sekminga",
        shortUrl: "/r",
        path: [`/rezervacija-sekminga/:orderId`, `/r/:orderId`],
        title: "Užsirašyk į autoservisą internetu 24/7 | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
    },
    ALTERNATIVES_SEARCH: {
        indexable: false,
        url: "/p",
        path: ["/alternatyvu-paieska/:orderId", "/p/:orderId"],
        title: "Užsirašyk į autoservisą internetu 24/7 | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        hideNavBar: true,
    },
    ALTERNATIVE_RESERVATION_CONFIRMATION: {
        indexable: false,
        url: "/naujo-autoserviso-patvirtinimas",
        title: "Naujo autoserviso patvirtinimas | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        hideNavBar: true,
    },
    SERVICE_CLIENT_DECLINED: {
        indexable: false,
        url: "/kliento-priemimas-atsauktas",
        title: "Užsirašyk į autoservisą internetu 24/7 | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
    },
    INVITATION_EXPIRED: {
        indexable: false,
        url: "/rezervacija-atsaukta",
        title: "Užsirašyk į autoservisą internetu 24/7 | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
    },
    ABOUT_US: {
        indexable: true,
        url: "/apie-mus",
        title: "Apie mus | Nevaziuoja.lt",
        description:
            "Norime sutaupyti jūsų laiką ir pinigus atliekant automobilio remonto darbus ar aptarnavimus. Apie nevaziuoja.lt",
    },
    PRIVACY_POLICY: {
        indexable: false,
        url: "/privatumo-politika",
        title: "Privatumo politika | Nevaziuoja.lt",
        description: "Slapukų ir privatumo politika.",
    },
    TERMS_OF_SERVICE: {
        indexable: false,
        url: "/paslaugu-teikimo-salygos",
        title: "Paslaugų teikimo sąlygos | Nevaziuoja.lt",
        description: "Paslaugų teikimo sąlygos ir kita teisinė informacija.",
    },
    SEO_SERVICES: {
        indexable: true,
        url: "/automobiliu-serviso-paslaugos",
        title: "Visos automobilių remonto ir aptarnavimų paslaugos | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
    },
    /** Used for internal orders */
    INTERNAL_ORDER_REVIEW: {
        indexable: false,
        url: "/atsiliepimas",
        shortUrl: "/at",
        path: ["/atsiliepimas/:orderId", "/at/:orderId"],
        title: "Atsiliepimas | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
        hideNavBar: true,
    },
    BECOME_PARTNER: {
        indexable: true,
        url: "/partneriai",
        title: "Nemokama autoserviso programa | Nauji klientai",
        description:
            "Nauji klientai mygtuko paspaudimu, autoserviso puslapis, sąskaitų išrašymas, SMS priminimai, užsakymų formavimas ir dar daugiau!",
    },
    PARTNER_FORM: {
        indexable: false,
        url: "/partnerio-forma",
        title: "Užsirašyk į autoservisą internetu 24/7 | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
    },
    WARRANTY: {
        indexable: true,
        url: "/remonto-garantija",
        title: "Automobilių remonto garantija | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
    },
    CLIENT_ONLY: {
        indexable: false,
        url: "/tik-klientui",
        title: "Tik klientui | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
    },
    WARRANTY_N_REVIEW: {
        indexable: false,
        url: "/garantija-atsiliepimas",
        shortUrl: "/g",
        path: [
            "/garantija-atsiliepimas/:orderId",
            "/g/:orderId",
            "/ga/:orderId" /* remove /ga later*/,
        ],
        title: "Garantijos aktyvacija | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
    },
    WARRANTY_ACTIVATION: {
        indexable: false,
        url: "/garantijos-aktyvacija",
        shortUrl: "/a",
        path: ["/garantijos-aktyvacija/:orderId", "/a/:orderId"],
        title: "Garantijos aktyvacija | Nevaziuoja.lt",
        description:
            "Darbų kainos, 24/7 Registracija, 100% Garantija. Lygink servisus Vilniuje, Kaune, Klaipėdoje, Panevėžyje, Alytuje pagal kainą, užimtumą, atsiliepimus.",
    },
    SEO_PAGES: {
        indexable: false,
        url: "/",
        path: Object.keys(FECLIENT_SERVICES_SEO_PAGES)
            .concat(Object.keys(FECLIENT_DISTRICT_SEO_PAGES))
            .concat(Object.keys(FECLIENT_BRANDS_SEO_PAGES))
            .concat(Object.keys(FECLIENT_ALL_SERVICES_SEO_PAGES)),
        title: "Registruokis į autoservisą internetu 24/7",
        description:
            "24/7 registracija. Darbų kainos. 100% Garantija. Lygink servisus aplink tave, skaityk verifikuotus atsiliepimus ir užsiregistruok 24/7.",
    },
    SITEMAP: {
        indexable: true,
        url: "/svetaines-struktura",
        title: "Svetainės struktūra | Nevažiuoja",
        description: "Nevažiuoja puslapio struktūra",
    },
    PARTNERS: {
        indexable: true,
        url: "/partneriai-autoservisai",
        title: "Partneriai autoservisai | Nevažiuoja",
        description: "Nevažiuoja partneriai - autoservisai",
    },
    ADMIN_PROMO: {
        hideNavBar: true,
        indexable: false,
        url: "/internal/admin/promo",
        title: "Admin nuolaidos generatorius | Nevažiuoja",
        description: "Nevažiuoja admin panel",
    },
};

export const pageRedirects = [
    {
        fromPath: "/r/kondicionieriu-remontas",
        toPath: `/autoservisai/vilnius/kondicionieriu-remontas-2_8`,
    },
] as { fromPath: string; toPath: string }[];

export const UrlToPage = _reduce(
    pageToMeta,
    (res, meta, pageEnum) => {
        const revObj = {} as { [url: string]: Page };
        revObj[(meta.path || meta.url) as string] = pageEnum as Page;
        return {
            ...res,
            ...revObj,
        };
    },
    {}
) as { [url: string]: Page };

// TBD: put into WORKSHOP.genPathname
export function genWorkshopUrl(p: { ID: number; name: string }): string {
    // TBD might make this more SEO friendly by doing name+address combo instead of name+ID
    return `${pageToMeta.WORKSHOP.url}/${urlifyString(p.name)}-${p.ID}`;
}

/** @ID - orderID */
export function genOrderSuccessUrl(p: {
    ID: number;
    userAccessToken: string;
}): string {
    return `${pageToMeta.RESERVATION_SUCCESS.url}/${p.ID}?token=${p.userAccessToken}`;
}
