import { Amenity } from "@FEShared/graphql/generated/graphql";

export const GROUPED_AMENITIES: {
    leftText?: string;
    amenities: Amenity[];
    iconClass: string;
}[] = [
    {
        leftText: "Kalba:",
        iconClass: "icon-chat1",
        amenities: [
            Amenity.LanguageLt,
            Amenity.LanguageEn,
            Amenity.LanguageRu,
            Amenity.LanguagePl,
        ],
    },
    {
        leftText: "Atsiskaitymas:",
        iconClass: "icon-card",
        amenities: [
            Amenity.PaymentCash,
            Amenity.PaymentBank,
            Amenity.PaymentCard,
        ],
    },
    {
        iconClass: "icon-couch",
        amenities: [
            Amenity.WaitingArea,
            Amenity.Wifi,
            Amenity.RestaurantNearby,
        ],
    },
    {
        iconClass: "icon-car-1",
        amenities: [
            Amenity.ReplacementVehicle,
            Amenity.VehicleDropOff,
            Amenity.VehicleReturnService,
        ],
    },
];
