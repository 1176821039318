import React from "react";
import * as S from "./ExpansivePopperHead.styled";
import cs from "classnames";

export const EXPANSIVE_POPPER_HEAD_CLASSNAME = "EXPANSIVE_POPPER_HEAD";

const ExpansivePopperHead: React.FC<{
    onCloseClick: () => void;
    onChooseClick?: () => void;
    className?: string;
}> = (p) => {
    return (
        <S.Head className={cs(p.className, EXPANSIVE_POPPER_HEAD_CLASSNAME)}>
            <S.HeadBackButton
                circle
                onClick={() => {
                    p.onCloseClick();
                }}
            />
            {p.onChooseClick && (
                <S.SubmitBtn onClick={p.onChooseClick}>Pasirinkti</S.SubmitBtn>
            )}
        </S.Head>
    );
};

export default ExpansivePopperHead;
