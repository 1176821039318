import styled, { css } from "styled-components";
import { Specialized } from "./WorkshopServiceRow/WorkshopServiceRow.styled";
import CarAutocomplete from "@FEShared/components/UI/CarAutocomplete/CarAutocomplete";
import { MobileInput } from "@FEShared/components/UI/AutocompleteWithModal/AutocompleteWithModal.styled";

export const CheckmarkIcon = styled("i")`
    margin-left: 8px;
    color: #3c8b56;
`;

export const ServicesWrapper = styled("div")<{ $visible: boolean }>`
    display: none;
    ${(p) =>
        p.$visible &&
        css`
            display: block;
        `}
`;

export const SpecializedIcon = styled(Specialized)`
    margin-left: unset;
    margin-right: 4px;
`;

export const CategoryRow = styled("div")`
    display: flex;
`;

export const StyledCarAutocomplete = styled(CarAutocomplete)<{
    $primary: boolean;
}>`
    margin-top: 4px;
    ${(p) =>
        p.$primary &&
        css`
            ${p.theme.breakpoints.up("md")} {
                .MuiInputBase-root {
                    background: #541dfe;
                    color: white;
                }
                .MuiAutocomplete-endAdornment > .MuiIconButton-root {
                    color: white;
                }
            }

            ${MobileInput} {
                .MuiInputBase-root {
                    background: #541dfe;
                    color: white;
                }
                .MuiInputBase-input {
                    -webkit-text-fill-color: #ffffff;
                    &::placeholder {
                        color: #ffffff;
                    }
                }
            }
        `}
`;
