import {
    Typography,
    TypographyProps as MUITypographyProps,
} from "@mui/material";
import { DefaultTheme, useTheme } from "styled-components";
import React from "react";
interface TypographyProps extends MUITypographyProps {
    component?: React.ElementType;
    href?: string;
    target?: string;
}
export interface TextP extends TypographyProps {
    semiBold?: boolean;
    bold?: boolean;
    span?: boolean;
    center?: boolean;
    underline?: boolean;
    pointer?: boolean;
}
const Text: React.FCC<TextP> = (p) => {
    const {
        semiBold: _,
        bold: _1,
        span: _2,
        center: _3,
        underline: _4,
        pointer: _5,
        ...MUITypographyProps
    } = p;
    const { children: _c, ...propsWithoutChildren } = p;
    const theme = useTheme();

    const propModifiers = React.useMemo(
        () => getPropModifiers(propsWithoutChildren, theme),
        [propsWithoutChildren, theme]
    );

    return (
        <Typography {...MUITypographyProps} {...propModifiers}>
            {p.children}
        </Typography>
    );
};

const getPropModifiers = (p: TextP, _theme: DefaultTheme): TypographyProps => {
    const modifiers: TypographyProps = {};

    if (p.semiBold) {
        modifiers.fontWeight = 500;
    }
    if (p.bold) {
        modifiers.fontWeight = 900;
    }
    if (p.span) {
        modifiers.component = "span";
    }
    if (p.center) {
        modifiers.textAlign = "center";
    }

    if (p.underline) {
        modifiers.sx = {
            ...modifiers.sx,
            textDecoration: "underline",
        };
    }

    if (p.pointer) {
        modifiers.sx = {
            ...modifiers.sx,
            cursor: "pointer",
        };
    }

    return modifiers;
};

export default Text;
