import React from "react";
import * as S from "./FinalizationPage.styled";
import PersonImg from "../assets/standing-person.png";

interface P {
    title: string;
    description?: string;
    failureStatus?: boolean;
}
const FinalizationPage: React.FC<P> = (p) => {
    return (
        <S.FinalizationPageContainer>
            <S.FinalizationContentContainer $insidePadding>
                <S.Title>{p.title}</S.Title>
                {p.failureStatus ? (
                    <S.FailureIcon className="icon-cross" />
                ) : (
                    <S.SuccessIcon className="icon-checkmark-circle" />
                )}

                {p.description && (
                    <S.SuccessText>{p.description}</S.SuccessText>
                )}
                <S.PersonImg src={PersonImg} />
            </S.FinalizationContentContainer>
        </S.FinalizationPageContainer>
    );
};

export default FinalizationPage;
