import React from "react";
import * as S from "./AmenityRow.styled";
import Box from "@FEShared/components/UI/Box/Box";
import { Amenity } from "@FEShared/graphql/generated/graphql";
import Icon from "@FEShared/components/UI/Icon/Icon";
import Text from "@FEShared/components/UI/Text/Text";
import lOrderBy from "lodash/orderBy";

const AMENITY_TO_TEXT: Record<Amenity, string> = {
    [Amenity.LanguageEn]: "anglų",
    [Amenity.LanguageLt]: "lietuvių",
    [Amenity.LanguagePl]: "lenkų",
    [Amenity.LanguageRu]: "rusų",
    [Amenity.PaymentCash]: "grynais",
    [Amenity.PaymentCard]: "kortele",
    [Amenity.PaymentBank]: "banku",
    [Amenity.ReplacementVehicle]: "Pakaitinis automobilis",
    [Amenity.VehicleReturnService]: "Automobilio pristatymas",
    [Amenity.VehicleDropOff]: "Galima palikti automobilį 24/7",
    [Amenity.WaitingArea]: "Laukiamasis",
    [Amenity.Wifi]: "Wi-Fi",
    [Amenity.RestaurantNearby]: "Šalia kavinė",
};

const AmenityRow: React.FC<{
    leftText?: string;
    amenities: Amenity[];
    enabledAmenities: Amenity[];
    iconClass: string;
}> = (p) => {
    // show enabled first;
    const orderedAmenities = lOrderBy(
        p.amenities,
        [(a) => p.enabledAmenities.includes(a)],
        ["desc"]
    );

    const hasAtleastOneEnabled = p.amenities.some((a) =>
        p.enabledAmenities.includes(a)
    );

    return (
        <Box mb={1}>
            <Icon
                width={30}
                className={p.iconClass}
                fontSize={20}
                mr={1}
                center
                display={"inline-block"}
                sx={{
                    opacity: hasAtleastOneEnabled ? 1 : 0.4,
                }}
            />
            {p.leftText && (
                <Text
                    span
                    sx={{
                        opacity: hasAtleastOneEnabled ? 1 : 0.4,
                    }}
                >
                    {p.leftText}{" "}
                </Text>
            )}
            {orderedAmenities.map((a, index) => (
                <Text
                    span
                    key={a}
                    sx={{ opacity: p.enabledAmenities.includes(a) ? 1 : 0.4 }}
                >
                    {AMENITY_TO_TEXT[a]}
                    {index !== p.amenities.length - 1 && ", "}
                </Text>
            ))}
        </Box>
    );
};

export default AmenityRow;
