import React from "react";
import { useTheme } from "styled-components";
import Rating from "FEShared/components/UI/Rating/Rating";
import InputsGroup from "@FEShared/components/UI/InputsGroup/InputsGroup";
import WorkshopDetailsHeader from "../WorkshopDetailsHeader/WorkshopDetailsHeader";
import * as S from "./ReviewForm.styled";
import { AlignToBottomOnMobile } from "../ReviewPage.styled";
import { SaveReviewParams } from "./ReviewForm.types";
import Input from "@FEShared/components/UI/Input/Input";
import { OrderReviewSummaryQuery } from "@FEShared/graphql/generated/graphql";
import Box from "@FEShared/components/UI/Box/Box";

const RATING_LABELS = {
    1: "Labai blogai",
    2: "Blogai",
    3: "Patenkinamai",
    4: "Gerai",
    5: "Puikiai",
};

const ReviewForm: React.FC<{
    order: OrderReviewSummaryQuery["order"];
    onSave: (params: SaveReviewParams) => void;
}> = (p) => {
    const theme = useTheme();
    const [rating, setRating] = React.useState<number | null>(null);
    const [name, setName] = React.useState("");
    const [reviewText, setReviewText] = React.useState("");

    const save = () => {
        if (!rating) return;

        p.onSave({
            name,
            rating,
            reviewText,
        });
    };

    const onChangeRating = (_: React.SyntheticEvent, value: number | null) => {
        if (value !== null) {
            setRating(value);
        }
    };

    if (!p.order.acceptedService) {
        console.error("No accepted service in ReviewForm", p.order);
        return null;
    }

    return (
        <>
            <WorkshopDetailsHeader
                name={p.order.acceptedService?.name}
                address={p.order.acceptedService?.address}
            />
            <S.ReviewPageContentContainer
                pt="32px"
                sx={{ maxWidth: "610px!important" }}
            >
                <S.RatingContainer>
                    <S.RatingTitle>
                        Kaip vertinate{" "}
                        <S.RatingTitleWorkshopName>
                            {p.order.acceptedService?.name}
                        </S.RatingTitleWorkshopName>{" "}
                        atliktas paslaugas?
                    </S.RatingTitle>
                    <Rating
                        size="large"
                        value={rating}
                        fillColor={theme.palette.primary.main}
                        onChange={onChangeRating}
                    />
                    <S.RatingLabel>
                        {rating && RATING_LABELS[rating]}
                    </S.RatingLabel>
                </S.RatingContainer>
                <AlignToBottomOnMobile>
                    <Box mt="32px" mb="32px">
                        <InputsGroup>
                            <Input
                                bgcolor="#ffffff"
                                fullWidth
                                placeholder="Vardas"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <S.CommentInput
                                bgcolor="#ffffff"
                                fullWidth
                                multiline
                                placeholder="Komentaras"
                                value={reviewText}
                                onChange={(e) => setReviewText(e.target.value)}
                            />
                        </InputsGroup>
                        <S.ConfirmBtn
                            disabled={!(rating && (name || reviewText))}
                            onClick={save}
                        >
                            Baigta
                        </S.ConfirmBtn>
                    </Box>
                </AlignToBottomOnMobile>
            </S.ReviewPageContentContainer>
        </>
    );
};

export default ReviewForm;
