import useStore from "@FEClient/logic/store";
import POPULAR_SEARCH_OPTIONS from "@FEShared/consts/POPULAR_SEARCH_OPTIONS";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import remOrAddToArr from "@Shared/util/remOrAddToArr";
import { runInAction } from "mobx";
import React from "react";
import WorkshopServiceRow from "./WorkshopServiceRow/WorkshopServiceRow";
import { observer, useLocalObservable } from "mobx-react-lite";
import { WorkshopDTO } from "../Workshop.types";
import * as WS from "../Workshop.styled";
import * as S from "./ServicesSection.styled";
import lOrderBy from "lodash/orderBy";
import { CATEGORIES_ORDER } from "@Shared/consts/commonConsts";
import latinize from "latinize";
import { byStartAsc, Fzf } from "fzf";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import mergeServicesWithPackagesForWorkshop, {
    MergedServiceOrPackageForWorkshop,
} from "@Shared/util/mergeServicesWithPackagesForWorkshop";
import mapMapValues from "@Shared/util/mapMapValues";
import mapMultiGroupBy from "@Shared/util/mapMultiGroupBy";
import {
    getAllPackageNServices,
    isPackage,
    isSubCateg,
    servicesOrSubcategsToNames,
} from "./ServicesSection.util";
import { ServiceOrSubCateg, SubCategory } from "./ServicesSection.types";
import ServiceOrSubcategRow from "./ServiceOrSubcategRow/ServiceOrSubcategRow";

type CategServiceOrSubcategPairs = [
    string,
    (MergedServiceOrPackageForWorkshop | SubCategory)[]
][];

type CategServiceOrPackagePairs = [
    string,
    MergedServiceOrPackageForWorkshop[]
][];

const POPULAR_CATEG = "Populiariausi";

const ServicesSection: React.FC<{ selectedWorkshop: WorkshopDTO }> = observer(
    function ServicesSection(p) {
        const GS = useStore();
        const isMobile = useIsMobile();
        const LS = useLocalObservable(() => ({
            expandedCategory: undefined as undefined | string,
            searchVal: undefined as undefined | string,
        }));
        const initSelectedServices = React.useRef(
            GS.searchState.selectedServicesNames
        );

        const autocompletePrimary = GS.searchState.carDataArr.length === 3;

        const workshopServices = React.useMemo(() => {
            let finalOpts = mergeServicesWithPackagesForWorkshop(
                p.selectedWorkshop
            );

            if (LS.searchVal) {
                const fzfSearchOptions = finalOpts.map((s) => ({
                    searchName: latinize(
                        `${s.serviceName}, ${s.searchAliases.join(",")}`
                    ),
                    data: s,
                }));
                const fzf = new Fzf(fzfSearchOptions, {
                    casing: "case-insensitive",
                    selector: (s) => s.searchName,
                    tiebreakers: [byStartAsc],
                });
                const fzfOpts = fzf.find(latinize(LS.searchVal));
                finalOpts = fzfOpts.map((fzfOpt) => fzfOpt.item.data);
            }

            return finalOpts;
        }, [LS.searchVal, p.selectedWorkshop]);

        const popularWorkshopServices = React.useMemo(() => {
            const filteredPopular = workshopServices.filter(
                (s) =>
                    POPULAR_SEARCH_OPTIONS.includes(s.serviceName) ||
                    s.partCategory === POPULAR_CATEG
            );
            return lOrderBy(
                filteredPopular,
                (s) => POPULAR_SEARCH_OPTIONS.indexOf(s.serviceName),
                ["asc"]
            );
        }, [workshopServices]);

        const isServiceOrPackageSelected = React.useCallback(
            (s: MergedServiceOrPackageForWorkshop): boolean => {
                if (s.package) {
                    return Boolean(
                        s.additionalTexts?.every((serviceName) => {
                            return GS.searchState.selectedServicesNames.includes(
                                serviceName
                            );
                        })
                    );
                } else {
                    return GS.searchState.selectedServicesNames.includes(
                        s.serviceName
                    );
                }
            },
            [GS.searchState.selectedServicesNames]
        );

        const handleServiceSelect = React.useCallback(
            (serviceOrPackage: MergedServiceOrPackageForWorkshop) => {
                if (serviceOrPackage.package) {
                    if (isServiceOrPackageSelected(serviceOrPackage)) {
                        runInAction(() => {
                            GS.searchState.selectedServicesNames =
                                GS.searchState.selectedServicesNames.filter(
                                    (serviceName) => {
                                        return !serviceOrPackage.additionalTexts?.includes(
                                            serviceName
                                        );
                                    }
                                );
                        });
                    } else {
                        runInAction(() => {
                            serviceOrPackage.additionalTexts?.forEach(
                                (serviceName) => {
                                    if (
                                        GS.searchState.selectedServicesNames.includes(
                                            serviceName
                                        )
                                    )
                                        return;
                                    GS.searchState.selectedServicesNames = [
                                        ...GS.searchState.selectedServicesNames,
                                        serviceName,
                                    ];
                                }
                            );
                        });
                    }
                } else {
                    GS.searchState.selectedServicesNames = [
                        ...remOrAddToArr(
                            GS.searchState.selectedServicesNames,
                            serviceOrPackage.serviceName
                        ),
                    ];
                }
            },
            [isServiceOrPackageSelected, GS.searchState]
        );

        const servicesGroupedByCateg = React.useMemo(() => {
            const groupedByCategMap = mapMultiGroupBy(workshopServices, [
                (s) => [s.partCategory],
                (s) => s.categorization.map((opc) => opc.partCategory),
            ]);
            const groupedByCategAndSubCateg = mapMapValues(
                groupedByCategMap,
                (categServices) => {
                    const thisCateg = categServices[0].partCategory;
                    let servicesOrSubcategs = categServices.reduce((acc, s) => {
                        const thisCategSubcategs = s.categorization.filter(
                            (opc) =>
                                opc.partCategory === thisCateg &&
                                opc.subCategory
                        );
                        if (thisCategSubcategs.length > 0) {
                            thisCategSubcategs.forEach((opc) => {
                                const foundExistingSubcateg = acc.find(
                                    (serviceOrSubcateg) => {
                                        return (
                                            isSubCateg(serviceOrSubcateg) &&
                                            serviceOrSubcateg.name ===
                                                opc.subCategory
                                        );
                                    }
                                );

                                if (foundExistingSubcateg) {
                                    if (!isSubCateg(foundExistingSubcateg)) {
                                        // shouldnt happen
                                        return console.error(
                                            `Existing subcateg is not a subcateg: ${JSON.stringify(
                                                foundExistingSubcateg
                                            )}`
                                        );
                                    }
                                    if (s.specialized) {
                                        // if one of the services from subcateg is specialized, then mark the whole subcateg as specialized
                                        foundExistingSubcateg.specialized =
                                            true;
                                    }
                                    foundExistingSubcateg.services.push(s);
                                } else {
                                    if (!opc.subCategory) {
                                        return console.error(
                                            `Subcateg not found for opc: ${JSON.stringify(
                                                opc
                                            )}`
                                        );
                                    }
                                    acc.push({
                                        type: "SUBCATEGORY",
                                        specialized: s.specialized,
                                        name: opc.subCategory,
                                        sortOrder: opc.sortOrder,
                                        services: [s],
                                    } as SubCategory);
                                }
                            });
                        } else {
                            acc.push(s);
                        }
                        return acc;
                    }, [] as ServiceOrSubCateg[]);
                    // sort subcategory services
                    servicesOrSubcategs = servicesOrSubcategs.map(
                        (serviceOrSubcateg) => {
                            if (isSubCateg(serviceOrSubcateg)) {
                                serviceOrSubcateg.services = lOrderBy(
                                    serviceOrSubcateg.services,
                                    (s) => {
                                        const sortOrder = s.categorization
                                            ? s.categorization.find((c) => {
                                                  return (
                                                      c.partCategory ===
                                                          thisCateg &&
                                                      c.subCategory ===
                                                          serviceOrSubcateg.name
                                                  );
                                              })?.sortOrder || 1
                                            : 1;
                                        return sortOrder;
                                    },
                                    ["desc"]
                                );
                            }
                            return serviceOrSubcateg;
                        }
                    );
                    return servicesOrSubcategs;
                }
            );

            if (popularWorkshopServices.length > 0) {
                groupedByCategAndSubCateg.set(
                    POPULAR_CATEG,
                    popularWorkshopServices
                );
            }

            return groupedByCategAndSubCateg;
        }, [workshopServices, popularWorkshopServices]);

        const mergedPackagesNServicesPairs: CategServiceOrSubcategPairs =
            React.useMemo(() => {
                const entries: [
                    string /* partCategory */,
                    ServiceOrSubCateg[]
                ][] = [...servicesGroupedByCateg.entries()];
                // show categs that have atleast one selection in the top.
                const orderedByCategs = lOrderBy(
                    entries,
                    [
                        ([_categName, servicesOrSubcategs]) => {
                            const thisCategServiceNames =
                                servicesOrSubcategs.flatMap((s) => {
                                    if (isSubCateg(s)) {
                                        return s.services.map(
                                            (s) => s.serviceName
                                        );
                                    }
                                    return s.serviceName;
                                });
                            return initSelectedServices.current.some(
                                (serviceName) =>
                                    thisCategServiceNames.includes(serviceName)
                            );
                        },
                        ([categName, _services]) => {
                            return CATEGORIES_ORDER.indexOf(
                                categName as (typeof CATEGORIES_ORDER)[number]
                            );
                        },
                    ],
                    ["desc", "asc"]
                );

                const orderedCategsAndServices = orderedByCategs.map(
                    ([categName, servicesOrSubcategs]) => {
                        return [
                            categName,
                            lOrderBy(
                                servicesOrSubcategs,
                                [
                                    // sort selected services to be shown in top
                                    (serviceOrSubcateg) => {
                                        return initSelectedServices.current.some(
                                            (serviceName) => {
                                                const thisCategServiceNames =
                                                    servicesOrSubcategsToNames([
                                                        serviceOrSubcateg,
                                                    ]);

                                                return thisCategServiceNames.includes(
                                                    serviceName
                                                );
                                            }
                                        );
                                    },
                                    // sort popular category services. In the future this could be deleted after "POPULAR" category is created via "categorization (AKA .categorization)"
                                    (serviceOrSubcateg) => {
                                        if (categName !== POPULAR_CATEG)
                                            return 99;

                                        if (isSubCateg(serviceOrSubcateg)) {
                                            const biggestSortId = lOrderBy(
                                                serviceOrSubcateg.services,
                                                (s) => s.sortOrder,
                                                ["desc"]
                                            )[0];
                                            return biggestSortId;
                                        } else {
                                            return POPULAR_SEARCH_OPTIONS.indexOf(
                                                serviceOrSubcateg.serviceName
                                            );
                                        }
                                    },
                                    // and after everything
                                    (serviceOrSubcateg) => {
                                        // console.log(
                                        //     "serviceOrSubcateg",
                                        //     serviceOrSubcateg,
                                        //     serviceOrSubcateg.sortOrder
                                        // );
                                        return serviceOrSubcateg.sortOrder;
                                    },
                                ],
                                ["desc", "asc", "desc"]
                            ),
                        ];
                    }
                ) as CategServiceOrSubcategPairs;

                return orderedCategsAndServices;
            }, [servicesGroupedByCateg]);

        const packagesPairs = React.useMemo(() => {
            return mergedPackagesNServicesPairs.map(
                ([categName, mergedServices]) => [
                    categName,
                    mergedServices.filter((s) => isPackage(s)),
                ]
            ) as CategServiceOrPackagePairs;
        }, [mergedPackagesNServicesPairs]);

        const servicesPairs = React.useMemo(() => {
            return mergedPackagesNServicesPairs.map(
                ([categName, mergedServices]) => [
                    categName,
                    mergedServices.filter((s) => !isPackage(s)),
                ]
            ) as CategServiceOrSubcategPairs;
        }, [mergedPackagesNServicesPairs]);

        React.useEffect(() => {
            if (
                mergedPackagesNServicesPairs.length === 0 ||
                LS.expandedCategory
            )
                return;
            const [partCategName] = mergedPackagesNServicesPairs[0];
            runInAction(() => {
                LS.expandedCategory = isMobile ? undefined : partCategName;
            });
        }, [LS, p.selectedWorkshop, isMobile, mergedPackagesNServicesPairs]);

        return (
            <WS.WorkshopSection $limitHeight>
                <Box mb={1}>
                    <WS.ServicesTitleWrap>
                        <WS.PurpleVerticalBar />
                        <WS.ServicesTitleSubWrap>
                            {/* <WS.ServicesSubTitle>
                                Ieškai daugiau?
                            </WS.ServicesSubTitle> */}
                            <WS.ServicesTitle>Paslaugos</WS.ServicesTitle>
                            <Text variant="subtitle1">
                                Pasirink automobilį, kad matytum paslaugų ir
                                dalių kainas pagal automobilį
                            </Text>
                            <Box maxWidth="300px">
                                <S.StyledCarAutocomplete
                                    placeholder="Pasirink automobilį"
                                    $primary={true}
                                    allowedCarBrands={
                                        p.selectedWorkshop.servicedBrands
                                    }
                                    value={GS.searchState.carDataArr}
                                    onChange={(val) => {
                                        runInAction(() => {
                                            GS.searchState.carDataArr = val;
                                        });
                                    }}
                                />
                            </Box>
                        </WS.ServicesTitleSubWrap>
                    </WS.ServicesTitleWrap>
                </Box>
                <Box
                    displayFlex
                    sx={{
                        overflow: "hidden",
                        opacity: autocompletePrimary ? 1 : 0.6,
                    }}
                >
                    <WS.WorkshopServicesLeftSide>
                        <WS.WorkshopCategoriesWrap>
                            {/* <Input
                                value={LS.searchVal}
                                onChange={(e) => {
                                    runInAction(() => {
                                        LS.searchVal = e.target.value;
                                    });
                                }}
                                placeholder="Įvesk paslaugą"
                                leftIconClass="icon-search"
                            /> */}
                            {mergedPackagesNServicesPairs.map(
                                ([category, servicesOrSubcategs]) => {
                                    const packages = servicesOrSubcategs.filter(
                                        (s) => isPackage(s)
                                    ) as MergedServiceOrPackageForWorkshop[];
                                    const servicesOrSubcategsOnly =
                                        servicesOrSubcategs.filter(
                                            (s) => !isPackage(s)
                                        );
                                    const flatServices =
                                        servicesOrSubcategsOnly.flatMap(
                                            (serviceOrSubcateg) => {
                                                if (
                                                    isSubCateg(
                                                        serviceOrSubcateg
                                                    )
                                                ) {
                                                    return serviceOrSubcateg.services;
                                                }
                                                return serviceOrSubcateg;
                                            }
                                        );

                                    const packagesNServices =
                                        getAllPackageNServices(
                                            servicesOrSubcategs
                                        );

                                    return (
                                        <>
                                            <WS.WorkshopCategoryRow
                                                $active={
                                                    LS.expandedCategory ===
                                                    category
                                                }
                                                onClick={() => {
                                                    runInAction(() => {
                                                        LS.expandedCategory =
                                                            LS.expandedCategory ===
                                                                category &&
                                                            isMobile
                                                                ? undefined
                                                                : category;
                                                    });
                                                }}
                                            >
                                                <div>
                                                    {flatServices.some(
                                                        (s) => s.specialized
                                                    ) && (
                                                        <S.SpecializedIcon className="icon-wrench-full" />
                                                    )}
                                                    <WS.ServiceCategoryName>
                                                        {category}
                                                    </WS.ServiceCategoryName>
                                                    <WS.ServiceCategoryTotal>
                                                        {`(${flatServices.length})`}
                                                    </WS.ServiceCategoryTotal>
                                                    {packagesNServices.some(
                                                        (s) =>
                                                            isServiceOrPackageSelected(
                                                                s
                                                            )
                                                    ) && (
                                                        <S.CheckmarkIcon className="icon-checkmark" />
                                                    )}
                                                </div>
                                                {isMobile && (
                                                    <WS.Chevron
                                                        $rotated={
                                                            LS.expandedCategory ===
                                                            category
                                                        }
                                                        className="icon-arrow-left"
                                                    />
                                                )}
                                            </WS.WorkshopCategoryRow>
                                            {isMobile && (
                                                <S.ServicesWrapper
                                                    $visible={
                                                        LS.expandedCategory ===
                                                        category
                                                    }
                                                >
                                                    {packages.map((s) => (
                                                        <WorkshopServiceRow
                                                            isSelected={
                                                                isServiceOrPackageSelected
                                                            }
                                                            selectedWorkshop={
                                                                p.selectedWorkshop
                                                            }
                                                            key={s.serviceName}
                                                            service={s}
                                                            onBtnClick={
                                                                handleServiceSelect
                                                            }
                                                        />
                                                    ))}
                                                    {servicesOrSubcategsOnly.map(
                                                        (s, i) => (
                                                            <ServiceOrSubcategRow
                                                                isSelected={
                                                                    isServiceOrPackageSelected
                                                                }
                                                                selectedWorkshop={
                                                                    p.selectedWorkshop
                                                                }
                                                                key={
                                                                    isSubCateg(
                                                                        s
                                                                    )
                                                                        ? s.name
                                                                        : s.serviceName
                                                                }
                                                                serviceOrSubcateg={
                                                                    s
                                                                }
                                                                onBtnClick={
                                                                    handleServiceSelect
                                                                }
                                                                hidden={
                                                                    LS.expandedCategory !==
                                                                    category
                                                                }
                                                                isLast={
                                                                    i ===
                                                                    servicesOrSubcategs.length -
                                                                        1
                                                                }
                                                                isFirst={
                                                                    i === 0
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </S.ServicesWrapper>
                                            )}
                                        </>
                                    );
                                }
                            )}
                        </WS.WorkshopCategoriesWrap>
                    </WS.WorkshopServicesLeftSide>
                    {!isMobile && (
                        <WS.WorkshopServicesRightSide>
                            {packagesPairs.map(([categ, packages]) => {
                                return packages.map((pkg, i) => (
                                    <WorkshopServiceRow
                                        isSelected={isServiceOrPackageSelected}
                                        selectedWorkshop={p.selectedWorkshop}
                                        key={pkg.serviceName}
                                        service={pkg}
                                        onBtnClick={handleServiceSelect}
                                        hidden={LS.expandedCategory !== categ}
                                    />
                                ));
                            })}
                            {servicesPairs.map(
                                ([categ, servicesOrSubcategs]) => {
                                    return servicesOrSubcategs.map((s, i) => {
                                        return (
                                            <ServiceOrSubcategRow
                                                isSelected={
                                                    isServiceOrPackageSelected
                                                }
                                                selectedWorkshop={
                                                    p.selectedWorkshop
                                                }
                                                key={
                                                    isSubCateg(s)
                                                        ? s.name
                                                        : s.serviceName
                                                }
                                                serviceOrSubcateg={s}
                                                onBtnClick={handleServiceSelect}
                                                hidden={
                                                    LS.expandedCategory !==
                                                    categ
                                                }
                                                isFirst={i === 0}
                                                isLast={
                                                    i ===
                                                    servicesOrSubcategs.length -
                                                        1
                                                }
                                            />
                                        );
                                    });
                                }
                            )}
                        </WS.WorkshopServicesRightSide>
                    )}
                </Box>
            </WS.WorkshopSection>
        );
    }
);

export default ServicesSection;
