const QUERY_PARAMS = {
    INVITATION_ID: "i",
    MAGIC_LINK_REDIRECT_URL: "r",
    MAGIC_LINK_QUERY_PARAMS: "q",
    USER_ORDER_TOKEN: "t",
    USER_ORDER_TOKEN_LEGACY: "token", // deprecated, to be removed.
    BOOK_NOW: "book-now",
    ERROR: "error",
    TEST_MODE_ENABLE_QUERY_PARAM: "test1337",
    IGNORE_REDIRECT: "ignore-redirect",
};
export default QUERY_PARAMS;
