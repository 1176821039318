import styled, { css } from "styled-components";
import Chip from "@mui/material/Chip/Chip";
import Radio from "../Radio/Radio";
import * as AWMS from "@FEShared/components/UI/AutocompleteWithModal/AutocompleteWithModal.styled";
import ListItemMUI from "@mui/material/ListItem/ListItem";
import Button from "../Button/Button";
import Text from "@FEShared/components/UI/Text/Text";

export const ExpansionValueChip = styled(Chip)`
    margin-right: 3px;
    overflow: hidden;
`;

export const ServiceRadio = styled(Radio)`
    margin-left: auto;
`;

export const CategRightWrapper = styled("div")`
    margin-left: auto;
    color: #727272;
    display: flex;
    align-items: center;
`;

export const RightArrow = styled("i")`
    margin-left: 8px;
    color: #000000;
    margin-right: 12px;
`;

export const MobileButtonsWrapper = styled("div")`
    display: flex;
`;

export const DesktopCategsWrapper = styled("div")`
    height: 100%;
    overflow-y: scroll;
    overscroll-behavior: contain;
    max-width: 220px;
    min-width: 220px;
    overflow-x: hidden;
`;

export const DropdownContainer = styled(AWMS.DropdownContainer)`
    display: flex;
    height: 100%;
    overscroll-behavior: contain;
    position: relative;

    .MuiAutocomplete-listbox {
        width: 100%;
        padding-top: 0;
        height: 100%;
        overflow-y: auto;
        max-height: unset;
        padding-bottom: 16px;
        /* so that scrollbar wouldnt touch the list items */
        padding-right: 8px;

        .MuiListSubheader-root {
            top: -1px;
            margin-top: 8px;
        }

        .MuiAutocomplete-groupLabel {
            display: none;
        }

        ${(p) => p.theme.breakpoints.down("md")} {
            // on mobile no need extra space for scroll bar;
            padding-right: 0px;

            .MuiAutocomplete-groupLabel {
                display: block;
                padding-bottom: 4px;
            }
        }
    }
`;

export const AddOtherServiceBtn = styled(Button)`
    margin-top: 16px;
`;

export const Bold = styled("span")`
    font-weight: 500;
`;

export const ListItem = styled(ListItemMUI)<{
    $active?: boolean;
    $noPaddingRight?: boolean;
    $bulky?: boolean;
}>`
    padding: 12px !important;
    max-height: 48px !important;
    margin-bottom: 4px;
    border-radius: 10px;
    background: #f0f2f8 !important;
    cursor: pointer;
    padding-right: 3px !important;
    line-height: 1.2;

    ${(p) =>
        p.$active &&
        css`
            background: #462bbe !important;
            font-weight: 500;
            color: white;
        `}

    ${(p) => p.theme.breakpoints.down("md")} {
        // use && to override !important
        && {
            padding-right: ${(p) => (p.$noPaddingRight ? "8px" : "8px")};
            padding-left: 0;
        }
    }
`;

export const CommentText = styled(Text)`
    font-size: 12px;
`;

export const ListItemImg = styled("img")`
    width: 32px;
    height: 32px;
    margin-right: 16px;
`;

export const PopperComponent = styled(AWMS.PopperComponent)`
    max-width: 650px !important;
    width: 100% !important;
    margin-top: 8px !important;
    height: 450px;

    ${(p) => p.theme.breakpoints.down("md")} {
        height: unset;
        max-width: unset !important;
        overflow-y: auto;
    }
` as typeof AWMS.PopperComponent;
