export enum CancelReason {
    BOOKED_FOR_NEW_TIME_SAME_WORKSHOP = "BOOKED_FOR_NEW_TIME_SAME_WORKSHOP",
    WILL_BOOK_FOR_NEW_TIME_SAME_WORKSHOP = "WILL_BOOK_FOR_NEW_TIME_SAME_WORKSHOP",
    BOOKED_OTHER_PLATFORM_WORKSHOP = "BOOKED_OTHER_PLATFORM_WORKSHOP",
    WANT_BOOK_OTHER_PLATFORM_WORKSHOP = "WANT_BOOK_OTHER_PLATFORM_WORKSHOP",
    FOUND_NON_PLATFORM_WORKSHOP = "FOUND_NON_PLATFORM_WORKSHOP",
    WORKSHOP_CANT_ACCEPT_ME = "WORKSHOP_CANT_ACCEPT_ME",
    OTHER = "OTHER",
}

export enum OtherWorkshopFindReason {
    "EXPENSIVE_PRICES" = "EXPENSIVE_PRICES",
    "NEEDED_EARLIER_TIME" = "NEEDED_EARLIER_TIME",
    "DIDNT_FIND_CONVENIENT_TIME" = "DIDNT_FIND_CONVENIENT_TIME",
    "DIDNT_FIND_CONVENIENT_LOCATION" = "DIDNT_FIND_CONVENIENT_LOCATION",
    "NOT_ENOUGH_TRUST" = "NOT_ENOUGH_TRUST",
    "OTHER" = "OTHER",
}

export const REASON_TO_TEXT: Record<CancelReason, string> = {
    [CancelReason.FOUND_NON_PLATFORM_WORKSHOP]:
        "Radau kitą autoservisą ne jūsų platformoje",
    [CancelReason.WORKSHOP_CANT_ACCEPT_ME]:
        "Autoservisas informavo, kad negalės priimti",
    [CancelReason.BOOKED_FOR_NEW_TIME_SAME_WORKSHOP]:
        "Užsiregistravau kitu laiku tam pačiam autoservise",
    // split this step into know/dont-know. If know, offer new new-time flow
    [CancelReason.WILL_BOOK_FOR_NEW_TIME_SAME_WORKSHOP]:
        "Noriu registruotis kitu laiku tam pačiam autoservise",
    [CancelReason.BOOKED_OTHER_PLATFORM_WORKSHOP]:
        "Užsiregistravau į kitą autoservisą Nevažiuoja platformoje",
    [CancelReason.WANT_BOOK_OTHER_PLATFORM_WORKSHOP]:
        "Noriu registruotis į kitą autoservisą Nevažiuoja platformoje",
    [CancelReason.OTHER]: "Kita",
};

export const OTHER_WORKSHOP_FIND_REASON_TO_TEXT: Record<
    OtherWorkshopFindReason,
    string
> = {
    [OtherWorkshopFindReason.NOT_ENOUGH_TRUST]:
        "Nepasitikėjau nevažiuoja platforma ir atlikta registracija",
    [OtherWorkshopFindReason.DIDNT_FIND_CONVENIENT_LOCATION]:
        "Dėl patogesnės vietos",
    [OtherWorkshopFindReason.EXPENSIVE_PRICES]: "Dėl pigesnės kainos",
    [OtherWorkshopFindReason.NEEDED_EARLIER_TIME]: "Galėjo priimti greičiau",
    [OtherWorkshopFindReason.DIDNT_FIND_CONVENIENT_TIME]:
        "Galėjo priimti patogesniu laiku",
    [OtherWorkshopFindReason.OTHER]: "Kita",
};
