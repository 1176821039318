import React from "react";
import { observer } from "mobx-react-lite";
import * as WS from "../Workshop.styled";
import { WorkshopDTO } from "../Workshop.types";
import useStore from "@FEClient/logic/store";
import ReservationBoxContent from "../ServicesSection/ReservationBox/ReservationBoxContent/ReservationBoxContent";

const MobileTimePickerSection: React.FC<{ selectedWorkshop: WorkshopDTO }> =
    observer((p) => {
        const GS = useStore();
        return (
            <WS.WorkshopSection id="time-section">
                <WS.ServicesTitleWrap>
                    <WS.PurpleVerticalBar />
                    <WS.ServicesTitleSubWrap>
                        <WS.ServicesTitle>
                            Laisvi laikai registracijai
                        </WS.ServicesTitle>
                    </WS.ServicesTitleSubWrap>
                </WS.ServicesTitleWrap>
                <ReservationBoxContent
                    selectedWorkshop={p.selectedWorkshop}
                    showTitle={false}
                />
            </WS.WorkshopSection>
        );
    });

export default MobileTimePickerSection;
