import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
    Page,
    pageRedirects,
    pageToMeta,
    UrlToPage,
} from "@Shared/util/clientPagesMeta";
import MetaWrapper from "FEShared/utils/MetaWrapper";
import Home from "@FEClient/views/pages/Home/Home";
import Search from "../../pages/Search/Search";
import ReservationConfirmation from "../../pages/ReservationConfirmation/ReservationConfirmation";
import ReservationSuccess from "../../pages/ReservationSuccess/ReservationSuccess";
import ServiceClientDeclined from "../../pages/ServiceClientDeclined/ServiceClientDeclined";
import AlternativesSearch from "../../pages/AlternativesSearch/AlternativesSearch";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "../../pages/TermsOfService/TermsOfService";
import AboutUs from "../../pages/AboutUs/AboutUs";
import SeoServices from "../../pages/SeoServices/SeoServices";
import ReviewPage from "../../pages/ReviewPage/ReviewPage";
import InvitationExpired from "@FEClient/views/pages/InvitationExpired/InvitationExpired";
import BecomePartner from "@FEClient/views/pages/BecomePartner/BecomePartner";
import PartnerForm from "@FEClient/views/pages/PartnerForm/PartnerForm";
import Workshop from "@FEClient/views/pages/Workshop/Workshop";
import OnRouteChange from "../../../../../FEShared/components/OnRouteChange/OnRouteChange";
import AlternativeReservationConfirmation from "@FEClient/views/pages/AlternativeReservationConfirmation/AlternativeReservationConfirmation";
import { runInAction } from "mobx";
import useStore from "@FEClient/logic/store";
import Warranty from "@FEClient/views/pages/Warranty/Warranty";
import ClientOnly from "@FEClient/views/pages/ClientOnly/ClientOnly";
import WarrantyActivation from "@FEClient/views/pages/WarrantyActivation/WarrantyActivation";
import Sitemap from "@FEClient/views/pages/Sitemap/Sitemap";
import Partners from "@FEClient/views/pages/Partners/Partners";
import AdminPromo from "@FEClient/views/pages/AdminPromo/AdminPromo";

const PAGE_TO_COMPONENT: Record<Page, React.ReactElement> = {
    [Page.HOME]: <Home />,
    [Page.SEO_PAGES]: <Home />,
    [Page.SEARCH]: <Search />,
    [Page.ALTERNATIVES_SEARCH]: <AlternativesSearch />,
    [Page.WORKSHOP]: <Workshop />,
    [Page.RESERVATION_CONFIRM]: <ReservationConfirmation />,
    [Page.RESERVATION_SUCCESS]: <ReservationSuccess />,
    [Page.SERVICE_CLIENT_DECLINED]: <ServiceClientDeclined />,
    [Page.TERMS_OF_SERVICE]: <TermsOfService />,
    [Page.PRIVACY_POLICY]: <PrivacyPolicy />,
    [Page.ABOUT_US]: <AboutUs />,
    [Page.SEO_SERVICES]: <SeoServices />,
    [Page.INTERNAL_ORDER_REVIEW]: <ReviewPage />,
    [Page.INVITATION_EXPIRED]: <InvitationExpired />,
    [Page.BECOME_PARTNER]: <BecomePartner />,
    [Page.PARTNER_FORM]: <PartnerForm />,
    [Page.WARRANTY]: <Warranty />,
    [Page.ALTERNATIVE_RESERVATION_CONFIRMATION]: (
        <AlternativeReservationConfirmation />
    ),
    [Page.CLIENT_ONLY]: <ClientOnly />,
    [Page.WARRANTY_ACTIVATION]: <WarrantyActivation />,
    [Page.WARRANTY_N_REVIEW]: <WarrantyActivation />,
    [Page.SITEMAP]: <Sitemap />,
    [Page.PARTNERS]: <Partners />,
    [Page.ADMIN_PROMO]: <AdminPromo />,
};

const PagesRoutes: React.FC = () => {
    const GS = useStore();

    return (
        <Switch>
            {Object.entries(pageToMeta).map(([page, meta]) => {
                return (
                    <Route
                        exact={!meta.notExactMatch}
                        path={meta.path || meta.url}
                        key={meta.url}
                    >
                        <>
                            <OnRouteChange
                                onRouteChange={(matchedRoute) => {
                                    const pair = Object.entries(UrlToPage).find(
                                        ([pathNamesString, _page]) => {
                                            return pathNamesString
                                                .split(",")
                                                .some(
                                                    (pathName) =>
                                                        pathName ===
                                                        matchedRoute.path
                                                );
                                        }
                                    );

                                    runInAction(() => {
                                        if (!pair) {
                                            return console.error(
                                                `Active page not found for matchedRoute: ${JSON.stringify(
                                                    matchedRoute
                                                )}`
                                            );
                                        }
                                        const [_pathNamesString, page] = pair;
                                        GS.activePage = page;
                                    });
                                }}
                            />
                            <MetaWrapper
                                title={meta.title}
                                descr={meta.description}
                            >
                                {PAGE_TO_COMPONENT[page]}
                            </MetaWrapper>
                        </>
                    </Route>
                );
            })}
            {pageRedirects.map((r) => {
                return (
                    <Route path={r.fromPath} key={r.fromPath}>
                        <Redirect to={r.toPath} />
                    </Route>
                );
            })}
        </Switch>
    );
};
export default PagesRoutes;
