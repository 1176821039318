import React from "react";
import { Router } from "react-router-dom";
import * as S from "./App.styled";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import muiTheme from "@FEClient/styled/theme";
import ScrollToTop from "FEShared/utils/ScrollToTop";
import Header from "./views/commonComps/Header/Header";
import PagesRoutes from "./views/commonComps/PagesRoutes/PagesRoutes";
import Footer from "./views/commonComps/Footer/Footer";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "FEShared/styled/global.styled";
import { Provider as UrqlProvider } from "urql";
import { Page } from "@Shared/util/clientPagesMeta";
import useStore, { history, urqlClient } from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";
import UseCookieConsent from "./logic/hooks/useCookieConsent";
import "@FEShared/utils/setupGlobals";
import showToast from "@FEShared/utils/showToast";
import LoaderScreen from "@FEShared/components/LoaderScreen/LoaderScreen";
import Sidebar from "./views/commonComps/Sidebar/Sidebar";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";
import { runInAction } from "mobx";
import {
    dateFormatFullReadableBuildable,
    dateObjToString,
} from "@Shared/util/dateFormat";
import { City } from "@FEShared/consts/CITIES";
import getCookie from "@FEShared/utils/getCookie";
import formatVehicleDescription from "@Shared/util/formatVehicleDescription";
import { UrlPathnameParser } from "./views/pages/Search/Search.logic";

const WebContent: React.FC = observer(() => {
    const GS = useStore();
    const chatlioWidgetRef = React.useRef<HTMLDivElement>(null);

    // tbd should just move showFooter/showHeader into clientPageMeta as a type of "ALL" | "MOBILE" | "DESKTOP"
    const showFooter =
        !GS.refFrom &&
        GS.activePage &&
        ![
            Page.SEARCH,
            Page.ALTERNATIVES_SEARCH,
            Page.RESERVATION_CONFIRM,
            Page.ALTERNATIVE_RESERVATION_CONFIRMATION,
            Page.INTERNAL_ORDER_REVIEW,
            Page.ADMIN_PROMO,
        ].includes(GS.activePage);

    React.useEffect(() => {
        // this hook must be placed here, instead of `Search` page, to make sure its executed only once and only then the search page was visited directly via link and not via react router.
        // this is mostly needed to avoid google bot/organic traffic, that visits our "saved" search pages (e.g /autoservisai/vilnius/visos-paslaugos/bmw) to prevent showing services or car brands that were saved in localStorage earlier. Otherwise it might be bad UX or google bot will find our pages confusing/duplicate content, etc.

        if (GS.activePage !== Page.SEARCH) return;

        const pathname = location.pathname;
        const parsedParams = UrlPathnameParser.pathnameToParams(pathname);

        if (!parsedParams.selectedServiceName) {
            GS.searchState.selectedServicesNames = [];
        }
        if (!parsedParams.carBrand) {
            GS.searchState.carDataArr = [];
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (
            window.location.search.includes(
                QUERY_PARAMS.TEST_MODE_ENABLE_QUERY_PARAM
            )
        ) {
            showToast.success(`Testiniai servisai įjungti`);
            localStorage.setItem(
                QUERY_PARAMS.TEST_MODE_ENABLE_QUERY_PARAM,
                "true"
            );
        }
    }, []);

    React.useEffect(() => {
        if (window.location.search.toLowerCase().includes("kurohudas")) {
            runInAction(() => {
                GS.refFrom = "KUROHUDAS";
            });
        }
    }, [GS]);

    React.useEffect(() => {
        const clid = window.location.search;
        const cityPrefixes: Record<string, City> = {
            c_kns: "Kaunas",
            c_vln: "Vilnius",
            c_klp: "Klaipėda",
            c_pnv: "Panevėžys",
            c_alt: "Alytus",
        };
        const carBrandPrefixes: Record<string, string> = {
            c_bmw: "BMW",
            c_audi: "Audi",
            c_vw: "Volkswagen",
            c_mb: "Mercedes-Benz",
            c_ty: "Toyota",
        };
        const servicesPrefixes: Record<string, string> = {
            p_tk: "Tepalų keitimas",
            p_sr: "Stabdžių remontas",
            p_vr: "Važiuoklės (pakabos) remontas",
            p_pm: "Padangų montavimas (Nežinau dydžio)",
            p_vd: "Variklio diagnostika",
            p_vgk: "Variklio grandinės keitimas",
            p_rs: "Ratų suvedimas",
            p_vkr: "Variklio kapitalinis remontas",
            p_tbp: "Techninės būklės patikra",
            p_kfk: "Kuro filtro keitimas",
            p_rgk: "Rato guolio keitimas",
            p_apdtk: "Automatinės pavarų dėžės tepalų keitimas",
            p_kr: "Kondicionierių remontas",
            p_tr: "Turbinų (turbokompresoriaus) restauravimas (remontas)",
            p_tmk: "Termostato keitimas",
            p_pzk: "Pakaitinimo žvakių keitimas",
            p_pta: "Paruošimas techninei apžiūrai",
            p_vp: "Variklio plovimas",
            p_er: "Elektronikos remontas (Autoelektriko paslaugos)",
            p_dr: "Duslintuvų remontas",
            p_vakr: "Vairo kolonelių restauracija (remontas)",
            p_pp: "Purkštukų patikra",
            p_ad: "Automobilio diagnostika (Nežinau problemos)",
            p_str: "Starterių remontas",
            p_sk: "Sankabos keitimas",
            p_var: "Variklio remontas",
            p_gr: "Generatoriaus remontas",
            p_rr: "Radiatoriaus remontas",
            p_ksr: "Kuro siurblių remontas",
            p_bpk: "Benzininių purkštukų keitimas",
            p_1_skp: "Stiklo keitimas (Priekinio)",
            p_vad: "Viso automobilio dažymas",
            p_zr: "Žibintų remontas",
            p_pdk: "Pagrindinio diržo keitimas",
            p_vsr: "Vairo stiprintuvo remontas",
            p_sdr: "Stabdžių diskų remontas",
            p_1_skkp: "Stabdžių kaladėlių keitimas (priekis)",
            p_apdr: "Automatinės pavarų dėžės remontas",
            p_sdt: "Stabdžių diskų tekinimas",
        };

        const matchingCityPrefix = Object.keys(cityPrefixes).find((prefix) =>
            clid.includes(prefix)
        );
        const matchingBrandPrefix = Object.keys(carBrandPrefixes).find(
            (prefix) => clid.includes(prefix)
        );
        const matchingServicePrefix = Object.keys(servicesPrefixes).find(
            (prefix) => clid.includes(prefix)
        );

        if (matchingCityPrefix) {
            runInAction(() => {
                GS.searchState.city = cityPrefixes[matchingCityPrefix];
                GS.adsCampaign.city = cityPrefixes[matchingCityPrefix];
            });
        }

        if (matchingBrandPrefix) {
            runInAction(() => {
                const carBrand = carBrandPrefixes[matchingBrandPrefix];
                GS.searchState.carDataArr = [carBrand];
                GS.adsCampaign.carBrand = carBrand;
            });
        }

        if (matchingServicePrefix) {
            const service = servicesPrefixes[matchingServicePrefix];
            runInAction(() => {
                GS.adsCampaign.service = service;
                GS.searchState.selectedServicesNames = [service];
            });
        }

        runInAction(() => {
            const currentReferrer = document.referrer;

            if (
                GS.visitHistory.some(([dateString, referrerUrl, clid]) => {
                    const date = new Date(dateString);
                    if (!date || isNaN(date.getTime())) {
                        return console.warn(`invalid date: ${date}`);
                    }

                    const HOUR_IN_MS = 1 * 60 * 60 * 1000;
                    if (
                        referrerUrl === currentReferrer &&
                        clid === clid &&
                        date.getTime() >= new Date().getTime() - HOUR_IN_MS
                    ) {
                        return true;
                    }
                })
            ) {
                return;
            }

            GS.visitHistory = [
                ...GS.visitHistory,
                [
                    dateFormatFullReadableBuildable(new Date()),
                    document.referrer,
                    clid,
                ],
            ];

            // limit logs to 100 size.
            if (GS.visitHistory.length >= 10) GS.visitHistory.shift();
        });
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        const chatlioWidget = document.createElement("chatlio-widget");
        chatlioWidget.setAttribute(
            "widgetid",
            "c4a9e3df-17e0-4ce5-70f4-e370f2dfaa6d"
        );

        // Append it to the ref'd div
        if (chatlioWidgetRef.current) {
            chatlioWidgetRef.current.appendChild(chatlioWidget);
        }

        document.addEventListener("chatlio.expanded", (e) => {
            window._chatlio?.identify?.(getCookie("_ga") || Math.random(), {
                email: GS.reservationConfirmationPageState.emailInputVal,
                phoneNumber: GS.reservationConfirmationPageState.phoneInputVal,
                city: GS.searchState.city,
                car: formatVehicleDescription(GS.searchState.carData),
                arrivalDate: dateObjToString(GS.searchState.date.obj),
                selectedServices:
                    GS.searchState.selectedServicesNames.join(", "),
                selectedWorkshop: [
                    GS.reservationConfirmationPageState.selectedWorkshop?.ID,
                    GS.reservationConfirmationPageState.selectedWorkshop?.name,
                    GS.reservationConfirmationPageState.selectedWorkshop
                        ?.address,
                ]
                    .filter(Boolean)
                    .join(", "),
            });
        });
    }, []);

    return (
        <UrqlProvider value={urqlClient}>
            <MUIThemeProvider theme={muiTheme}>
                <ThemeProvider theme={muiTheme}>
                    <CssBaseline />
                    <GlobalStyles $disableScroll={!GS.bodyScrollEnabled} />
                    <LoaderScreen show={GS.showLoadingScreen} />
                    <S.AllContentContainer>
                        <Header />
                        <Sidebar />
                        <S.PageContainer>
                            <PagesRoutes />
                        </S.PageContainer>
                        {showFooter && <Footer />}
                        <ToastContainer />
                        <div ref={chatlioWidgetRef}></div>
                    </S.AllContentContainer>
                </ThemeProvider>
            </MUIThemeProvider>
        </UrqlProvider>
    );
});

const WebContainer: React.FC<{}> = () => {
    return (
        <>
            <UseCookieConsent />
            <Router history={history}>
                <WebContent />
                <ScrollToTop />
            </Router>
        </>
    );
};

export default WebContainer;
