import React from "react";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import Button from "@FEShared/components/UI/Button/Button";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import { observer, useLocalObservable } from "mobx-react-lite";
import { runInAction } from "mobx";
import {
    OrderReviewSummaryQuery,
    ServicesCompletionType,
} from "@FEShared/graphql/generated/graphql";

const ServicesProvidedStep: React.FC<{
    onBtnClick: () => void;
    order: OrderReviewSummaryQuery["order"];
    allServicesReceived?: ServicesCompletionType;
    onChange: (value: ServicesCompletionType) => void;
    btnText: string;
}> = observer((p) => {
    const LS = useLocalObservable(() => ({
        error: false,
    }));

    return (
        <Box>
            <Box displayFlex vertical>
                <FormControl>
                    <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        sx={{ color: "black!important", fontWeight: 500 }}
                    >
                        Ar autoservisas atliko visas norimas/reikiamas
                        paslaugas?
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={p.allServicesReceived}
                        onChange={(e) => {
                            p.onChange(
                                e.target.value as ServicesCompletionType
                            );
                        }}
                    >
                        <FormControlLabel
                            value={ServicesCompletionType.No}
                            control={<Radio />}
                            label="Ne"
                        />
                        <FormControlLabel
                            value={ServicesCompletionType.NoMeetingBooked}
                            control={<Radio />}
                            label="Ne, bet esu užsiregistravęs kitam vizitui užbaigti darbus"
                        />
                        <FormControlLabel
                            value={ServicesCompletionType.Yes}
                            control={<Radio />}
                            label="Taip"
                        />
                    </RadioGroup>
                </FormControl>
                {LS.error && p.allServicesReceived === undefined && (
                    <Text color="error">
                        Pasirinkite ar buvo suteiktos reikiamos paslaugos
                    </Text>
                )}
                <Button
                    sx={{ minWidth: 240, mt: 1 }}
                    onClick={async () => {
                        if (p.allServicesReceived === undefined) {
                            runInAction(() => {
                                LS.error = true;
                            });
                            return;
                        }

                        p.onBtnClick();
                    }}
                >
                    {p.btnText}
                </Button>
            </Box>
        </Box>
    );
});

export default ServicesProvidedStep;
