import Box from "@FEShared/components/UI/Box/Box";
import Icon from "@FEShared/components/UI/Icon/Icon";
import Text from "@FEShared/components/UI/Text/Text";

import React from "react";
import { Review } from "../../WarrantyActivation.types";
import FollowSocialButtons from "@FEShared/components/UI/FollowSocialButtons/FollowSocialButtons";
import { dateToMonthDayHour } from "@Shared/util/dateFormat";
import { Rating } from "@mui/material";

const CompletedStep: React.FC<{ error?: boolean; review?: Review }> = (p) => {
    return (
        <Box
            flex={1}
            align="center"
            alignVertical="center"
            displayFlex
            vertical
        >
            <Box>
                {/* {p.error ? (
                    <Text color="warning.main" fontSize={18}>
                        <Icon className="icon-exclamation-mark" /> Nevažiuoja
                        komanda su jumis susisieks 2 d.d bėgyje dėl įsivėlusios
                        klaidos su kaina.
                    </Text>
                ) : (
                    <Text color="success.main" fontSize={18}>
                        <Icon className="icon-checkmark-circle" /> Garantija
                        sėkmingai aktyvuota!
                    </Text>
                )} */}
                <Text color="success.main" fontSize={18}>
                    <Icon className="icon-checkmark-circle" /> Garantija
                    sėkmingai aktyvuota!
                </Text>
            </Box>
            {p.review && (
                <Box mt={2} width={1}>
                    <Text semiBold>Atsiliepimas</Text>
                    <Box align="space-between" alignVertical="center">
                        <Text fontSize={16} lineHeight="20px">
                            {p.review.authorName}
                        </Text>
                        <Rating readOnly size="small" value={p.review.rating} />
                    </Box>
                    <Box align="space-between" alignVertical="center">
                        <Text fontSize={12} lineHeight="20px" color="#727272">
                            {dateToMonthDayHour(new Date(p.review.createdAt))}
                        </Text>
                    </Box>
                    <Text
                        mt="4px"
                        fontSize={16}
                        fontWeight={300}
                        lineHeight="20px"
                    >
                        {p.review.text}
                    </Text>
                </Box>
            )}
            {!p.review ||
                (p.review && p.review.rating >= 3 && (
                    <Box mt="32px" mb="24px" width={1}>
                        <FollowSocialButtons />
                    </Box>
                ))}
        </Box>
    );
};

export default CompletedStep;
