export const getTitleAndEmoji = (rating: number) => {
    switch (rating) {
        case 1:
        case 2:
            return {
                title: "Atsiprašome už nesklandumus. Į situaciją atsižvelgsime ir tobulėsime.",
                emoji: "🙁",
            };
        case 3:
            return {
                title: "Ačiū už įvertinimą. Jūsų komentarai padeda mums tobulėti.",
                emoji: "😕",
            };
        case 4:
        case 5:
            return {
                title: "Ačiū už įvertinimą!",
                emoji: "🎉",
            };
        default:
            throw new Error("Invalid rating");
    }
};
