export enum CarAutocompleteStage {
    BRAND = "vehicleBrand",
    MODEL = "vehicleModel",
    YEAR = "vehicleYear",
    // VEHICLE_BODY = "vehicleBody",
}

export type CarDataObj = Record<CarAutocompleteStage, string>;

/** [brand, model, year ] */
export type CarDataArr = string[];

// export enum CarVehicleBody {
//     SEDAN = "Sedanas",
//     COUPE = "Kupė (Coupe)",
//     HATCHBACK = "Hečbekas",
//     UNIVERSAL = "Universalas",
//     MINIVAN = "Minivanas",
//     PICKUP_TRUCK = "Pikapas",
//     CONVERTIBLE = "Kabrioletas",
//     SUV = "Visureigis",
// }
