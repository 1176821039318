import { SeoPages } from "@FEShared/types/common";
import DISTRICTS from "./DISTRICTS";
import capFirst from "@Shared/util/capFirst";
import urlifyString from "@FEShared/utils/urlifyString";

export const DISTRICTS_URL_PREFIX = `autoservisas`;

const TEMPLATE_FAQ = [
    {
        question: "Autoservisas rajone {districtName} - kaip rasti geriausią?",
        answer: "Nevažiuoja naršyk geriausius autoservisus rajone {districtName}. Pasirink automobilio modelį, reikiamas paslaugas, norimą atvykimo laiką ir rezervuok vizitą pas patikimus meistrus savo automobilio remontui.",
    },
    {
        question: "Kur atliekama tepalų keitimas rajone {districtName}?",
        answer: "Tepalų keitimas rajone {districtName}. Pasirink tepalų keitimo paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą tepalų keitimui sau patogioje vietoje.",
    },
    {
        question: "Kur atliekama padangų montavimas rajone {districtName}?",
        answer: "Padangų montavimas rajone {districtName}. Pasirink padangų montavimo paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą padangų montavimui sau patogioje vietoje.",
    },
    {
        question:
            "Kur atliekama pakabos patikra/remontas rajone {districtName}?",
        answer: "Pakabos patikra/remontas rajone {districtName}. Pasirink pakabos patikros/remonto paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą pakabos patikrai/remontui sau patogioje vietoje.",
    },
    {
        question: "Kur atliekama stabdžių remontas rajone {districtName}?",
        answer: "Stabdžių remontas rajone {districtName}. Pasirink stabdžių remonto paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą stabdžių remontui sau patogioje vietoje.",
    },
    {
        question: "Kur atliekama variklio remontas rajone {districtName}?",
        answer: "Variklio remontas rajone {districtName}. Pasirink variklio remonto paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą variklio remontui sau patogioje vietoje.",
    },
    {
        question: "Kur atliekama automobilio patikra rajone {districtName}?",
        answer: "Automobilio patikra rajone {districtName}. Pasirink automobilio patikros paslaugą, naršyk geriausius autoservisus ir rezervuok vizitą automobilio patikrai sau patogioje vietoje.",
    },
];

const FECLIENT_DISTRICT_SEO_PAGES: SeoPages = (() => {
    const t = Object.entries(DISTRICTS).reduce((acc, [city, districts]) => {
        districts.forEach((d) => {
            acc[
                `/${DISTRICTS_URL_PREFIX}-${urlifyString(
                    d
                )}-${city}`.toLowerCase()
            ] = {
                seoServiceName: `Autoservisas ${d}`,
                faq: TEMPLATE_FAQ.map((f) => {
                    return {
                        question: capFirst(
                            f.question.replace(/{districtName}/g, d),
                            false
                        ),
                        answer: capFirst(
                            f.answer.replace(/{districtName}/g, d),
                            false
                        ),
                    };
                }),
            };
        });
        return acc;
    }, {} as SeoPages);

    return t;
})();

// since this is included in
export default FECLIENT_DISTRICT_SEO_PAGES;
