import React from "react";
import Button from "@FEShared/components/UI/Button/Button";

const FollowSocialButtons: React.FC = () => {
    return (
        <div>
            <Button
                fullWidth
                leftIconClass="icon-instagram"
                sx={{ borderRadius: "15px" }}
                onClick={() =>
                    window.open(
                        "https://www.instagram.com/nevaziuoja.lt/",
                        "_blank"
                    )
                }
            >
                Instagram
            </Button>
            <Button
                fullWidth
                leftIconClass="icon-facebook-f"
                variant="outlined"
                sx={{
                    marginTop: "8px",
                    borderRadius: "15px",
                }}
                onClick={() =>
                    window.open("https://facebook.com/nevaziuoja.lt", "_blank")
                }
            >
                Facebook
            </Button>
        </div>
    );
};

export default FollowSocialButtons;
