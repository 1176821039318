import { Button, Chip } from "@mui/material";
import styled from "styled-components";

export const ReviewTextButton = styled(Button)`
    margin-top: 16px;
    color: #5e39fe;
    font-weight: 500;
    font-size: 16px;
    height: 38px;
    justify-content: flex-start;
    align-self: flex-start;
    text-transform: unset;
`;
