import useInitSearchFormInfo from "@FEClient/logic/hooks/useInitSearchFormInto/useInitSearchFormInfo";
import useShowLoadingScreen from "@FEShared/hooks/useShowLoadingScreen";
import useToken from "@FEClient/logic/hooks/useToken/useToken";
import useStore, { history } from "@FEClient/logic/store";
import {
    OrderStatus,
    ParticipationStatus,
    useOrderQuery,
} from "@FEShared/graphql/generated/graphql";
import showToast from "@FEShared/utils/showToast";
import { pageToMeta } from "@Shared/util/clientPagesMeta";
import { runInAction } from "mobx";
import { observer, useLocalObservable } from "mobx-react-lite";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import * as S from "../Search/Search.styled";
import SearchMap from "../Search/SearchMap/SearchMap";
import SearchSidebar from "../Search/SearchSidebar/SearchSidebar";
import NewTimeFlow from "./NewTimeFlow/NewTimeFlow";
import getToken from "@FEClient/logic/utils/getToken";
import QUERY_PARAMS from "@Shared/consts/QUERY_PARAMS";

const AlternativesSearch: React.FC = observer((p) => {
    const GS = useStore();
    const SPS = GS.searchPageState;
    const params = useParams<{ orderId: string }>();
    const token = useToken();
    const LS = useLocalObservable(() => ({
        step: undefined as undefined | "NEW_TIME" | "ALT_PICK",
    }));

    const [orderReq] = useOrderQuery({
        variables: { id: +params.orderId, accessToken: token },
        pause: !token || !params.orderId,
    });

    // need this to load GS.searchState.searchServicesOptions for specializations resolving. Specialization resolving could be achieved without this, but doesn't matter much now.
    useInitSearchFormInfo();
    useShowLoadingScreen(orderReq, GS);

    const order = !orderReq.fetching && !orderReq.error && orderReq.data?.order;

    React.useEffect(() => {
        if (order) {
            const primaryWorkshopInv = order.invitations.find(
                (i) =>
                    i.primary &&
                    i.status === ParticipationStatus.PendingCustomerApproval
            );
            runInAction(() => {
                LS.step = primaryWorkshopInv ? "NEW_TIME" : "ALT_PICK";
            });
        }
    }, [order, LS]);

    React.useEffect(() => {
        if (order) {
            if (order.status !== OrderStatus.AltServicesCustomerPick) {
                showToast.warn(
                    `Jūsų alternatyvaus serviso pasirinkimo laikas baigėsi. Prašome atlikti naują rezervaciją.`
                );
                history.push(pageToMeta.HOME.url);
                return;
            }
            runInAction(() => {
                GS.searchPageState.altFlow.order = order;
                GS.searchState.carDataArr = [order.vehicleBrand as string];
                GS.searchState.selectedServicesNames = order.categories.map(
                    (c) => c.serviceName
                );
            });
        }
    }, [GS, order]);

    if (!orderReq.operation || orderReq.fetching) {
        return <div>Kraunama...</div>;
    }

    if (orderReq.error || !order) {
        // TBD: Add error reporting
        return <Redirect to={pageToMeta.HOME.url} />;
    }

    // TBD: Add skeleton loading or loading screen instead of return null
    if (!order) return <div>Kraunama...</div>;

    const services = order.invitations
        .filter((i) => i.status === ParticipationStatus.PendingCustomerApproval)
        .map((inv) => inv.service);

    if (LS.step === "NEW_TIME") {
        return (
            <NewTimeFlow
                onDecline={() => {
                    runInAction(() => {
                        LS.step = "ALT_PICK";
                    });
                }}
                onConfirm={() => {
                    const primaryWorkshopInv = order.invitations.find(
                        (i) =>
                            i.primary &&
                            i.status ===
                                ParticipationStatus.PendingCustomerApproval
                    );
                    if (!primaryWorkshopInv) {
                        console.error(`Primary workshop was not found!`);
                        showToast.error(
                            `Įvyko netikėta klaida. Kviečiame registruotis iš naujo.`
                        );
                        history.push(pageToMeta.HOME.url);
                        return;
                    }
                    runInAction(() => {
                        SPS.selectedServiceId = primaryWorkshopInv.service.ID;
                    });
                    history.push(
                        `${
                            pageToMeta.ALTERNATIVE_RESERVATION_CONFIRMATION.url
                        }/?${QUERY_PARAMS.USER_ORDER_TOKEN}=${getToken()}`
                    );
                }}
            />
        );
    } else if (LS.step === "ALT_PICK") {
        return (
            <S.SearchContainer>
                <S.SidebarNMapWrapper>
                    <SearchSidebar
                        isLoading={false}
                        carDescrTitle={`${order.vehicleBrand} ${order.vehicleYear}`}
                        workshops={services}
                    />
                    <SearchMap mapItems={services} city={order.city} />
                </S.SidebarNMapWrapper>
            </S.SearchContainer>
        );
    } else {
        return <div>Kraunama...</div>;
    }
});

export default AlternativesSearch;
