import ContentContainer from "@FEClient/views/commonComps/ContentContainer/ContentContainer";
import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import React from "react";

const Section: React.FCC<{
    bgcolor?: string;
    title: string;
    beforeTitle?: JSX.Element;
    boxContainerProps?: React.ComponentProps<typeof Box>;
    contentContainerProps?: React.ComponentProps<typeof ContentContainer>;
    titleProps?: React.ComponentProps<typeof Text>;
    titleAlign?: "center" | "left";
}> = (p) => {
    return (
        <Box
            bgcolor={p.bgcolor || "#F0F2F8"}
            {...p.boxContainerProps}
            sx={(theme) => ({
                pt: "96px",
                pb: "64px",
                [theme.breakpoints.down("md")]: {
                    pt: "48px",
                },
                ...(p.boxContainerProps?.sx as object),
            })}
        >
            <ContentContainer {...p.contentContainerProps}>
                {p.beforeTitle}
                <Text
                    variant="h3"
                    fontSize="30px"
                    mb={"48px"}
                    textAlign={p.titleAlign ? p.titleAlign : "center"}
                    sx={(theme) => ({
                        [theme.breakpoints.down("md")]: {
                            mb: "24px",
                        },
                    })}
                    {...p.titleProps}
                >
                    {p.title}
                </Text>
                {p.children}
            </ContentContainer>
        </Box>
    );
};

export default Section;
