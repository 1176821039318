import { Weekday } from "@Shared/types/calendar";

export const CALENDAR_FULL_DAY_SEARCH_TIME = {
    hour: 23,
    minute: 59,
    second: 0,
    ms: 0,
};

export const TEST_WORKSHOP_TOKEN = "[T]";

export const WEEKDAYS = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
] as Weekday[];

export const LT_WEEKDAY_TO_EN_WEEKDAY: Record<string, Weekday> = {
    pirmadienis: "monday",
    antradienis: "tuesday",
    trečiadienis: "wednesday",
    ketvirtadienis: "thursday",
    penktadienis: "friday",
    šeštadienis: "saturday",
    sekmadienis: "sunday",
};

export const EN_WEEKDAY_TO_LT_WEEKDAY: Record<Weekday, string> = {
    monday: "pirmadienis",
    tuesday: "antradienis",
    wednesday: "trečiadienis",
    thursday: "ketvirtadienis",
    friday: "penktadienis",
    saturday: "šeštadienis",
    sunday: "sekmadienis",
};

export const CATEGORIES_ORDER = [
    "Populiariausi",
    "Aptarnavimai",
    "Variklis",
    "Pavarų dėžė",
    "Važiuoklė (Pakaba)",
    "Elektronika",
    "Ratai",
    "Kėbulas",
    "Stabdžiai",
    "Patikra",
    "Kondicionierius",
    "Salonas",
    "Duslintuvas",
    "Žibintai",
    "Stiklai",
    "Vairas",
    "Švara ir Estetika",
    "Kita",
] as const;

export const VAT_RATE = 0.21;

export const ONESIGNAL_WORKSHOP_ID_PREFIX = "WORKSHOP_";

export const STANDARD_FEE = 0.2;

export const FEE_CONFIG = {
    MIN: 0.165,
    MAX: STANDARD_FEE,
    CLIENT_MISS: 0.015,
    CLIENT_ACCEPT: 0.005,
};

export const STANDARD_WORKHOURS_DURATION = 9;

export const MINS_FOR_IMMEDIATE_BOOKING = 120;
