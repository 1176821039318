import styled from "styled-components";

export const Iframe = styled("iframe")`
    margin-top: -100px;
    width: 100%;
    outline: unset;
    border: unset;
    min-height: 10500px;

    ${(p) => p.theme.breakpoints.down("md")} {
        margin-top: -30px;
    }
    ${(p) => p.theme.breakpoints.down("sm")} {
        min-height: 12000px;
    }

    margin-bottom: -100px;
`;
