import React from "react";
import * as S from "./Logo.styled";
import { observer } from "mobx-react-lite";

// maybe add white prop if will be reused everywhere
const Logo = observer<{ className?: string; white?: boolean }>((p) => {
    return (
        <S.LogoWrapper className={p.className} $white={p.white}>
            <S.LogoMark className="icon-logo-symbol"></S.LogoMark>
            <S.LogoText className="icon-text-logo"></S.LogoText>
        </S.LogoWrapper>
    );
    // return <S.Logo className="icon-full-logo" />;
});

export default Logo;
